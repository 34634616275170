import React, { useContext, useEffect, useState } from 'react'
import { Cascader, List, Card } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { LangContext } from '../Translation'
import translations from './translations'
import Backend from '../../api/Backend'
import { CATEGORY_TYPES } from '../../pages/HSPDashboard/index'

export const CategorySelectCard = ({
	requestedContentCategories,
	requestedHSPCategories,
	setRequestedContentCategories,
	setRequestedHSPCategories
}) => {
	const lang = useContext(LangContext)(translations)
	return (
		<>
			<Card>
				<label
					style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
					title="selected-categories"
					className="ant-form-item-required">
					<h1>{lang('desired-categories')}</h1>
				</label>
				<p>{lang('category-blurb')}</p>
				<div className="category-select-card">
					<CategorySelector selection={requestedHSPCategories} setSelection={setRequestedHSPCategories} type={CATEGORY_TYPES.HSP} />
					<CategorySelector
						selection={requestedContentCategories}
						setSelection={setRequestedContentCategories}
						type={CATEGORY_TYPES.CONTENT}
					/>
				</div>
			</Card>
		</>
	)
}

export const CategorySelector = ({ type, selection, setSelection }) => {
	const lang = useContext(LangContext)(translations)
	const [currentSelect, setCurrentSelect] = useState('')
	const [categories, setCategories] = useState()

	const backend = new Backend()

	useEffect(() => {
		// sets selected subcategories to disabled.
		let selectedSubcategory = selection && Array.isArray(selection) && selection.length >= 1 ? selection.map((selection) => selection.title) : []
		categories &&
			categories.forEach((category) => {
				category.children.forEach((subcategory) => {
					subcategory.disabled = false
					if (selectedSubcategory.some((selected) => selected === subcategory?.label)) {
						subcategory.disabled = true
					}
				})
			})
	}, [selection, categories])

	useEffect(() => {
		if (type === CATEGORY_TYPES.HSP) {
			backend.HSPCategories({
				cache: false,
				cb: (data) => {
					if (!data || !data?.data) {
						return
					}
					data = data.data
					let categories = data
						.map((category) => {
							if (category?.item && Array.isArray(category.item) && category.item.length > 0) {
								// is a category
								let children = category.item.map((subcategory) => {
									// subcategories
									return {
										value: subcategory?.id,
										label: subcategory?.title,
										disabled: false
									}
								})
								return {
									value: category?.id,
									label: category?.title,
									children
								}
							}
						})
						.filter((category) => category !== undefined) // This is filtering out categories with no subcategories.
					setCategories(categories)
				}
			})
		} else if (type === CATEGORY_TYPES.CONTENT) {
			backend.cmsCategories({
				cache: false,
				cb: (res) => {
					let categories = res
						.map((category) => {
							if (category?.item && Array.isArray(category.item) && category.item.length > 0) {
								// Is a category
								let children = category.item.map((subcategory) => {
									// subcategories
									return {
										value: subcategory?.id,
										label: subcategory?.title
									}
								})
								return {
									value: category?.id,
									label: category?.title,
									children
								}
							} else {
								return
							}
						})
						.filter((category) => category !== undefined) // This is filtering out categories with no subcategories.
					setCategories(categories)
				}
			})
		} else {
			return
		}
	}, [])

	function onChange(value, selectedOptions) {
		setSelection((prev) => [...prev, { title: selectedOptions[1].label, id: value[1] }])
	}

	function remove(value, idx) {
		setSelection(selection.filter((item, index) => index !== idx))
	}

	return (
		<div className="category-picker-card">
			<label
				style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
				title="selected-categories"
				className="ant-form-item-required">
				<h2>{lang(type)}</h2>
			</label>
			<Cascader
				size="large"
				style={{ width: '90%' }}
				value={currentSelect}
				expandTrigger="hover"
				options={categories}
				onChange={onChange}
				placeholder={lang('select-category')}
			/>
			<div>
				<label
					style={{ marginBottom: '.6rem', marginTop: '2rem', display: 'flex', justifyContent: 'center' }}
					title="selected-categories"
					className="ant-form-item-required">
					<h3>{lang('selected-categories')}</h3>
				</label>

				<div className="category-list">
					<List
						size="small"
						dataSource={selection}
						renderItem={(item, idx) => (
							<List.Item>
								<div>{item?.title}</div>
								<MinusCircleOutlined style={{ float: 'right' }} onClick={() => remove(item, idx)} />
							</List.Item>
						)}
					/>
				</div>
			</div>
		</div>
	)
}
