import React, { useState, useEffect } from 'react'
import { Form, Input, Button as AButton, Space, Card } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { LangContext } from '../Translation'
import translations from './translations'

const HOUR_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

// This function should be used inside of AntD Form tags
const OpeningHours = ({ day, initialValue }) => {
	const lang = React.useContext(LangContext)(translations)

	let dayArray = []
	Array.isArray(initialValue) &&
		initialValue.forEach((element, index) => {
			if (element.weekday === day.toUpperCase()) {
				dayArray.push({ opening: element.from, closing: element.till })
			}
		})

	return (
		<Card style={{ marginBottom: '40px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px' }}>
			<label style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }} title="day" className="ant-form-item-required">
				<h2>{lang(day.toLowerCase())}</h2>
			</label>
			<Form.List name={day + '_hours'} initialValue={dayArray}>
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => (
							<Space key={key} style={{ display: 'flex', marginBottom: 8, justifyContent: 'center' }} align="baseline">
								<Form.Item
									{...restField}
									name={[name, 'opening']}
									rules={[
										{ required: true, message: lang('missing-opening-hours') },
										{
											validator(_, value) {
												if (!value.match(HOUR_REGEX)) {
													return Promise.reject(lang('invalid-hour-format'))
												} else {
													return Promise.resolve()
												}
											}
										}
									]}>
									<Input placeholder={lang('opening')} />
								</Form.Item>
								-
								<Form.Item
									{...restField}
									name={[name, 'closing']}
									rules={[
										{ required: true, message: lang('missing-closing-hours') },
										{
											validator(_, value) {
												if (!value.match(HOUR_REGEX)) {
													return Promise.reject(lang('invalid-hour-format'))
												} else {
													return Promise.resolve()
												}
											}
										}
									]}>
									<Input placeholder={lang('closing')} />
								</Form.Item>
								<MinusCircleOutlined onClick={() => remove(name)} />
							</Space>
						))}
						<Form.Item>
							<AButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
								{lang('add-hours')}
							</AButton>
						</Form.Item>
					</>
				)}
			</Form.List>
		</Card>
	)
}

export default OpeningHours
