export function mapOnboardingFormAnswersToStage(response) {
	switch (response.blockId) {
		case '0':
			return response.answers[0].answer ? 'Kostenübernahmeantrag nicht eingereicht' : 'Kostenübernahme antrag nicht erhalten'
		case '1':
			return response.answers[0].answer ? 'Warten auf Antwort auf Kostenübernahmeantrag' : 'Kostenübernahmeantrag nicht eingereicht'
		case '2':
			if (!response.answers[0].answer) {
				return 'Warten auf Antwort auf Kostenübernahmeantrag'
			} else {
				return response.answers[1].answer ? 'Antrag angenommen' : 'Antrag abelehnt'
			}
		case '3':
			return response.answers[0].answer ? 'Widerspruchsschreiben noch nicht weitergeleitet' : 'Widerspruchsschreiben noch nicht erhalten'
		case '4':
			return response.answers[0].answer ? 'Warten auf Antwort bzgl. Widerspruch' : 'Widerspruchsschreiben noch nicht weitergeleitet'
		case '5':
			if (!response.answers[0].answer) {
				return 'Warten auf Antwort bzgl. Widerspruch'
			} else {
				return response.answers[1].answer ? 'Antrag angenommen' : 'Antrag abelehnt'
			}
		default:
			return 'Unbekannt'
	}
}
