import React from 'react'
import './index.css'
import Utils from '../Utils'

const DetailList = ({ 
	data, 
	inLine = false,
	className = "" 
}) => {
	const utils = new Utils()
	className = `${className} exo-list ${inLine ? 'inLine' : ''}`

	return <div className={ className }>{
		data.map((dat) => {
			const details = dat[1] === 'activated' ? utils.toDate({ dateString: dat[0] }) : dat[1]
			return (
					<dl key={`key-${dat[0]}`} className={ inLine ? 'inLine' : ''}>
						<dt>{dat[0]}</dt>
						<dd>{ details }{ dat.length >= 2 ? dat[2] : null }</dd>
					</dl>
			)
		})
	}</div>
}

export default DetailList
