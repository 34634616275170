import React, { useState, useEffect } from 'react'
import { LangContext } from '../../Translation'
import './index.css'
import Skeleton from '../../Skeleton'
import Chart from 'react-apexcharts'
import Backend from '../../../api/Backend'
import translations from './translations'
import { Card } from 'antd'

export default function RecruitementTrendChart({ center }) {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)

	const [recruitmentPerDay, setRecruitmentPerDay] = useState([])

	useEffect(() => {
		backend.recruitmentPerDay({ center, cb: setRecruitmentPerDay })
	}, [center])


	const aim = 400
	let data = Array.isArray(recruitmentPerDay) && recruitmentPerDay.length > 1 ? recruitmentPerDay.map((d, index) => index+1) : []

	const overallRecruitingRatio =
		Array.isArray(recruitmentPerDay) && recruitmentPerDay.length > 1
			? Math.round((recruitmentPerDay[recruitmentPerDay.length - 1].num / aim) * 100)
			: ''



	const options = {
		type: 'area',
		fontFamily: 'inherit',
		height: 140.0,
		sparkline: {
			enabled: true
		},
		animations: {
			enabled: true
		},
		dataLabels: {
			enabled: false
		},
		fill: {
			opacity: 0.16,
			type: 'solid'
		},
		stroke: {
			width: 2,
			dashArray: [0, 3],
			lineCap: 'round',
			curve: 'smooth'
		},
		grid: {
			strokeDashArray: 4
		},
		xaxis: {
			labels: {
				padding: 0
			},
			tooltip: {
				enabled: false
			},
			axisBorder: {
				show: false
			},
			type: 'datetime'
		},
		yaxis: {
			labels: {
				padding: 4
			}
		},
		labels: Array.isArray(recruitmentPerDay) ? recruitmentPerDay.map((d) => d.ts) : [],
		colors: ['#206bc4', '#a8aeb7'],
		legend: {
			show: false
		}
	}

	const series = [
		{
			name: lang('recruitment'),
			data: data
		},
		{
			name: lang('aim'),
			data: Array.isArray(recruitmentPerDay) ? recruitmentPerDay.map((d) => aim) : []
		}
	]

	return (
		<Card bordered={false}>
			<div className="subheader">{lang('recruitement_total')}</div>
			<div className="d-flex align-items-baseline">
				<div className="h1 mb-0 me-2">{overallRecruitingRatio}%</div>
				<div className="me-auto">
					<span className="text-green d-inline-flex align-items-center lh-1">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon ms-1"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<polyline points="3 17 9 11 13 15 21 7" />
							<polyline points="14 7 21 7 21 14" />
						</svg>
					</span>
				</div>
			</div>
			<Skeleton type={'card'} hide={recruitmentPerDay}>
				<Chart options={options} series={series} type="area" className="chart-sm p10" />
			</Skeleton>
		</Card>
	)
}
