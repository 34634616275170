import 'antd/dist/antd.css'
import { Location } from '../../countly'

import React from 'react'

import '@tabler/core/dist/css/tabler.css'
import 'apexcharts/dist/apexcharts.css'
import './index.css'
import './antd.css'
import 'react-toastify/dist/ReactToastify.min.css'

import Translation from '../../components/Translation'
import ErrorBoundary from '../../components/ErrorBoundary'
import ConditionalWrapper from '../../components/ConditionalWrapper'

import { useGlobalContext } from '../../components/AppContext'

import Layout from './Layout'
import { ToastContainer } from 'react-toastify'
import { useKeycloak } from '@react-keycloak/web'

import { Utils } from '../../components/Utils'

import { BrowserRouter as Router } from 'react-router-dom'

function App() {
	const { isProductionSystem } = useGlobalContext()

	const { keycloak } = useKeycloak()

	const locale = Utils.getLocale(keycloak)

	return (
		<Router>
			<Location>
				<ConditionalWrapper condition={isProductionSystem()} wrapper={(children) => <ErrorBoundary>{children}</ErrorBoundary>}>
					<Translation locale={locale}>
						<ToastContainer
							position="top-center"
							autoClose={3000}
							hideProgressBar
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<Layout />
					</Translation>
				</ConditionalWrapper>
			</Location>
		</Router>
	)
}

export default App
