import React, { useState, useEffect } from 'react'
import { Collapse, Form, Button, Input } from 'antd'

import { LangContext } from '../Translation'
import translations from './translations'
import List from '../List'

import Backend from '../../api/Backend'

import Utils from '../Utils'

import './index.css'

const { Panel } = Collapse

export default function Notes({ className = '', patientId, taskId, type = 'GENERAL' }) {
	const backend = new Backend()
	const utils = new Utils()

	const lang = React.useContext(LangContext)(translations)

	const icons = {
		NOTE: (
			<svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
				<rect fill="none" height="24" width="24" />
				<path d="M19,5v9l-5,0l0,5H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h10l6-6V5C21,3.9,20.1,3,19,3z M12,14H7v-2h5V14z M17,10H7V8h10V10z" />
			</svg>
		)
	}

	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [notesData, setNotesData] = useState()
	const [isNewNoteVisible, setIsNewNoteVisible] = useState(false)

	const refresh = () => {
		if (patientId || taskId) {
			setLoading(true)
			backend.notes({
				patientId,
				taskId,
				noteTtype: type,
				cb: (data) => {
					setNotesData(data)
					form.setFieldsValue({ text: '' })
					setLoading(false)
				}
			})
		}
	}

	useEffect(() => {
		refresh()
	}, [patientId, taskId])

	const customValidity = (e) => {
		e.target.setCustomValidity('')
		e.target.setCustomValidity(lang('input-required'))
	}

	const handleNoteSubmit = async () => {
		try {
			await form.validateFields()
			const fields = form.getFieldsValue()
			const text = fields.text.trim()
			hideNewNoteVisible()
			const body = JSON.stringify({
				text,
				type,
				patientId,
				taskId
			})
			backend.notes({
				type: backend.type.CREATE,
				body,
				cb: () => {
					refresh()
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	const deleteNote = async (id) => {
		backend.notes({ id, type: backend.type.DELETE, cb: refresh })
	}

	const handleNoteRemove = (note) => {
		deleteNote(note.id)
	}

	const toogleNewNoteVisible = () => {
		setIsNewNoteVisible(!isNewNoteVisible)
	}

	const hideNewNoteVisible = () => {
		setIsNewNoteVisible(false)
	}

	const notes = Array.isArray(notesData)
		? notesData.map((d) => {
				let fullName = `${d.resource.name || ''} ${d.resource.surname || ''}`.trim()
				fullName = fullName === '' ? null : fullName
				return {
					description: (
						<div>
							<div>{utils.toDate({ dateString: d.resource.ts })}</div>
						</div>
					),
					content: <div>{d.resource.txt}</div>,
					title: fullName,
					icon: icons[d.type],
					id: d.id
				}
		  })
		: []

	const latestNote = notes.length > 0 ? [notes.shift()] : []

	return (
		<div className={`${className} exo-notes`}>
			<div className="col-md-12 col-xl-12">
				<div className="notes">
					<div className="header-wrapper">
						<label className="form-label">{lang('notes')}</label>
						<Collapse expandIcon={() => {}} activeKey={isNewNoteVisible ? '1' : null} ghost onChange={toogleNewNoteVisible}>
							<Panel key="1" extra={<Button type="primary">{lang('new-note')}</Button>}>
								<div className="notes-new">
									<Form form={form} layout="vertical" onFinish={handleNoteSubmit}>
										<Form.Item
											name="text"
											label={lang('text')}
											rules={[
												{
													required: true,
													message: lang('missing-input')
												}
											]}>
											<Input.TextArea className="mb-2 form-control" name="note-text" placeholder={lang('placeholder-text')} />
										</Form.Item>
										<Button type="primary" htmlType="submit" className="w-100">
											{lang('add')}
										</Button>
									</Form>
								</div>
							</Panel>
						</Collapse>
					</div>
					<List loading={loading} emptyText={lang('no-data')} onRemove={handleNoteRemove} data={latestNote} pagination={null} />
					{notes.length > 0 ? (
						<Collapse ghost>
							<Panel key="1" header={lang('show-all')}>
								<List loading={loading} onRemove={handleNoteRemove} data={notes} />
							</Panel>
						</Collapse>
					) : null}
				</div>
			</div>
		</div>
	)
}
