import React from 'react'
import Utils from '../Utils'
import './index.css'

const NotificationBubble = ({alarms}) => {
    const utils = new Utils()
	const notifications = alarms ? utils.groupAlarms([...alarms]) : null
    return (
        <span className="badge-rounded bg-red badge">{notifications.length}</span>
    )
}

export default NotificationBubble

