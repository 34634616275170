import React, { useState, useEffect } from 'react'
import { Card } from 'antd'

import Skeleton from '../../components/Skeleton'
import Tabs from '../../components/Tabs'
import Tag from '../../components/Tag'
import SimpleLineChart from '../../components/charts/SimpleLineChart'
import Backend from '../../api/Backend'
import Utils from '../../components/charts/Utils'

import { LangContext } from '../../components/Translation'
import translations from './translations'
import HealthChart from '../../components/charts/HealthChart'

const PatientChart = ({ patientId, patientName, settingId }) => {
	//TODO. RETRIEVE TRIAL Length FROM STUDY
	const trialMonths = 6
	const numTrialPoints = 6

	const lang = React.useContext(LangContext)(translations)

	const extendKPIDetails = (kpi) => {
		const skala1 = [
			{ key: 1, value: 'Überhaupt nicht' },
			{ key: 2, value: 'Wenig' },
			{ key: 3, value: 'Mäßig' },
			{ key: 4, value: 'Sehr' }
		]
		const skala2 = [
			{ key: 1, value: 'Sehr schlecht' },
			{ key: 7, value: 'Ausgezeichnet' }
		]
		const skala3 = [
			{ key: 0, value: 'Sehr gut' },
			{ key: 33, value: 'Gut' },
			{ key: 66, value: 'Schlecht' },
			{ key: 100, value: 'Sehr schlecht' }
		]
		const skala4 = [
			{ key: 0, value: 'Sehr schlecht' },
			{ key: 33, value: 'schlecht' },
			{ key: 66, value: 'gut' },
			{ key: 100, value: 'Sehr gut' }
		]

		const positive = kpi === 'EQVAS' ? 'max' : 'min'
		return { key: kpi, txt: kpi, min: 0, max: 100, skala: kpi === 'EQVAS' ? skala4 : skala3, positive }
	}

	const setPatientsScale = (patients) => {
		patients.forEach((patient) => {
			patient = Utils.addPatientTrialDates({ patient, trialMonths })
		})
		setPatients(patients)
	}

	const defaultSelectedKPI = extendKPIDetails('EQVAS')

	const [patients, setPatients] = useState([])
	const [data, setData] = useState()
	const [selectedKPI, setSelectedKPI] = useState(defaultSelectedKPI)
	const [types, setTypes] = useState([])

	const backend = new Backend()

	useEffect(() => {
		// get cohortes scores
		//	backend.assessmentScores({ setting: settingId, cb: setScores })
	}, [patientId, settingId])

	useEffect(() => {
		// get individual score
		backend.patientHealth({ patientId, cb: setData })
	}, [patientId])

	/* TODO: approach used for symptoms??? LS 1.11.21
	const patientsGraphData = (graphData) => {
		return graphData.map((answer) => {
			const patient = getPatient(answer.patient_id)
			const series = Utils.mapDatePoints2TrialPoints({ 
				dates: [answer.created], 
				trialDates: [patient.startDate, patient.endDate], 
				numTrialPoints
			}).pop()
			return {
				ts: answer.created, series, value: answer.score, key: answer.patient_id
			}
		})
	}
*/
	/*
	const patientsGraphData = (graphData) => {
		return graphData.map((answer) => {
			let patient = getPatient(answer.patient_id)
			let series = []
			if (!patient) {
				// console.error("Could not resolve patient entry for #"+answer.patient_id)
				// TODO: tbc. Problem is that Cohortes are cross center and thereof assessments too, while patients are center only.
				// Solution, the math has to be done in the backend.
				patient = {
					startDate: new Date('2021-01-15T23:00:00.000Z'),
					endDate: new Date('2022-01-15T23:00:00.000Z')
				}
			}
			series = Utils.mapDatePoints2TrialPoints({
				dates: [answer.created],
				trialDates: [patient.startDate, patient.endDate],
				numTrialPoints
			}).pop()
			return {
				ts: answer.created,
				series,
				value: answer.score,
				key: answer.patient_id
			}
		})
	}

	const groupData = (data) => {
		let kpis = {}
		if (!data) return kpis
		data.forEach((questionnaire) => {
			if (!kpis[questionnaire.questionnaire_type]) {
				kpis[questionnaire.questionnaire_type] = []
			}
			kpis[questionnaire.questionnaire_type].push(questionnaire)
		})
		for (let kpi in kpis) {
			kpis[kpi] = patientsGraphData(kpis[kpi])
			kpis[kpi] = kpis[kpi].sort((a, b) => {
				return new Date(a.series) - new Date(b.series)
			})
		}
		return kpis
	}

	const getSubKPIsOfKPIType = (kpiType) => {
		const kpis = []
		for (let kpi in data) {
			if (kpi.includes(kpiType)) {
				kpis.push(kpi)
			}
		}
		return kpis.map(extendKPIDetails)
	}

	function getKPIs(kpiType) {
		return getSubKPIsOfKPIType(kpiType).map((kpi) => {
			let className = 'kpi-list-item list-group-item'
			className = selectedKPI.key === kpi.key ? className + ' selected' : className

			const patientData = data[kpi.key] ? data[kpi.key].filter((p) => p.key === patientId) : []
			const groupData = data[kpi.key] ? data[kpi.key] : []
			return (
				<div className={className} key={kpi.key} id={kpi.key} onClick={() => setSelectedKPI(kpi)}>
					<div className="row">
						<div className="col-auto">
							<a href="#">
								<SimpleLineChart width={75} height={50} patientData={patientData} groupData={groupData} kpi={kpi} />
							</a>
						</div>
						<div className="col">
							<div className="text-muted mt-n1">{kpi.txt}</div>
						</div>
					</div>
				</div>
			)
		})
	}
*/
	// const tabData = () => {
	// 	return types
	// 		? types.map((type) => {
	// 				return { key: type.key, title: type.title, content: getKPIs(type.key) }
	// 		  })
	// 		: []
	// }

	return (
		<div className="col-sm-12 col-lg-12">
			<Card title={lang('chart-title')}>
				<Skeleton type="statistics" hide={data}>
					{data && data.length > 0 ? (
						<HealthChart
							width={500}
							height={600}
							chartSwitch={false}
							data={data}
							xAxisTitle={lang('xAxisTitle')}
							xLabelRenderer={(d3, d) => {
								return d3.timeFormat('%B %d, %Y')(new Date(d.ts))
							}}
							yLabelRenderer={(v, d3) => {
								return v % 5 === 0 ? `${v}` : ''
							}}
							yAxisTitle={`Patient: ${patientName} KPI: ${selectedKPI ? selectedKPI.txt : ''}`}
							selectedKPI={selectedKPI}
							patients={patients}
							selectedPatient={patientId}
						/>
					) : (
						<div className="mb20">
							<Tag>{lang('no-assessments')}</Tag>
						</div>
					)}
				</Skeleton>
			</Card>
		</div>
	)
}

export default PatientChart
