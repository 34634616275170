import React, { useEffect } from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import Button from '../Button'
import './index.css'

const { confirm } = Modal


export default function DecisionModal({
	onCancel = () => {},
	onOK = () => {},
	okType = null, // danger
	btn,
	text = { title: null, cancel: null, ok : null },
	open = false, // external trigger & default state usage
	disabled = false
}) {

	useEffect(() => {
		if(open){ // external trigger
			showConfirm()
		}	
	}, [open])

	if(btn){
		btn.type = btn.type ? btn.type : 'info'
		btn.txt = btn.txt ? btn.txt : ''
		btn.className = btn.className ? btn.className : ''
		btn.ghost = btn.ghost !== undefined ? btn.ghost : true
		btn.onClick = showConfirm
		btn.disabled = disabled
	}
	
	function showConfirm() {
		confirm({
		  title: text.title,
		  icon: <ExclamationCircleOutlined />,
		  content: text.details,
		  okText: text.ok,
		  okType,
		  cancelText: text.cancel,
		  onOk: onOK,
		  onCancel,
		})
	  }

	return (
		btn ? <Button {...btn}></Button> : null 
	)
}
