import React, { useState, useEffect } from 'react'

import { useKeycloak } from '@react-keycloak/web'
import { Utils } from '../Utils'
import Backend from '../../api/Backend'
import Select from '../Select'

import { LangContext } from '../Translation'

export default function EventSelect({
	required = false,
	filterOptions = [],
	onChange = () => {},
	defaultOptions,
	value,
	isMulti = false,
	style,
	title,
	placeholder = "",
	disabled,
	className
}) {
	const { keycloak } = useKeycloak()
	const lang = React.useContext(LangContext)()
	const backend = new Backend()
	const [options, setOptions] = useState()

	const locale = Utils.getLocale(keycloak)

	useEffect(() => {
		backend.events({
			cb: events => {
				const options = []
				for (let id in events) {
					let isDisabled = false
					if(filterOptions && filterOptions.includes(id)){
						isDisabled = true
					} 
					options.push({ key: id, value: events[id].title[locale], isDisabled })
				}
				setOptions(options)
			}
		})
	}, [])

	return options ? (
		<Select
			placeholder={placeholder}
			disabled={disabled}
			required={required}
			value={value}
			className={className}
			title={title || title === '' ? title : lang('event')}
			isMulti={isMulti}
			defaultOptions={defaultOptions}
			options={options}
			onChange={onChange}
			allowClear={false}
			style={style}></Select>
	) : null
}
