import React from 'react'
import moment from 'moment'
import { Form, Input, DatePicker } from 'antd'

import translations from './translations'
import { LangContext } from '../../components/Translation'

export const TaskScheduler = ({ form }) => {
    const lang = React.useContext(LangContext)(translations)

    const DATE_FORMAT = 'DD.MM.YYYY'
    const filterDates = current => {
		let res = moment().add(-1, 'days') >= current 
		return res
	}
    const rules = [
        {
            required: true,
            message: lang('missing-input')
        }
    ]

    return (
        <Form
            form={form}
            name="scheduled-form"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off">
            <Form.Item
                name="title"
                label={lang('title')}
                rules={rules}>
                <Input.TextArea
                    rows={1}
                    onChange={(e) => {} }>
                </Input.TextArea>
            </Form.Item>
            <Form.Item
                name="text"
                label={lang('text')}
                rules={rules}>
                <Input.TextArea
                    rows={5}
                    onChange={(e) => {} }>
                </Input.TextArea>
            </Form.Item>
            <Form.Item 
                name="scheduledDate" 
                label={lang('date')}
                rules={rules}
                >
				<DatePicker 
                    format={DATE_FORMAT}
                    disabledDate={filterDates}
                />
			</Form.Item>
        </Form>
    )
}