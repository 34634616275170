import React, { useState, useEffect } from 'react'
import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'
import Button from '../../components/Button'
import { LangContext } from '../../components/Translation'

import { Card } from 'antd'
import CsvDownloader from 'react-csv-downloader'

import Backend from '../../api/Backend'

import translations from './translations'
import { toast } from 'react-toastify'

const PatientScoringExport = () => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)

	const [scoreExportData, setScoreExportData] = useState([])
		
		

	return (
		<div className="container-xl ">
			<Card bordered={false} style={{ height: '170px' }}>
				<PageHeader title={lang('title')}>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{scoreExportData.length < 1 ? (
							<Button
								className="mt20"
								onClick={() => {
									const loadingToast = toast.loading(lang('fetching-data'), { closeOnClick: true })

									backend.getPatientQuestionnaires({
										scoreExport: true,
										cb: (data) => {
											const titles = data[0]?.map(item => item.title)
											data[0] = titles
											const transformedData = data.slice(1).map((row) =>
												row.reduce(
													(obj, answer, index) => ({
														...obj,
														[data[0][index]]: answer
													}),
													{}
												)
											)
											setScoreExportData(transformedData)
											toast.update(loadingToast, {
												render: lang('download-ready'),
												type: 'success',
												isLoading: false,
												closeOnClick: true,
												autoClose: 5000
											})
										}
									})
								}}>
								{lang('request-data')}
							</Button>
						) : (
							<CsvDownloader datas={scoreExportData} separator=";" filename={'scoring_data.csv'}>
								<Button className="mt20" disabled={scoreExportData?.length === 0} type="save">
									{lang('xlsx-download')}
								</Button>
							</CsvDownloader>
						)}
					</div>
				</PageHeader>
			</Card>
		</div>
	)
}

export default PatientScoringExport
