import React, { useState, useEffect } from 'react'
import { Card } from 'antd'

import Backend from '../../api/Backend'
import Table from '../Table'
import Button from '../Button'
import Skeleton from '../Skeleton'

import { LangContext } from '../Translation'

import './index.css'

export default function HspProviderSelect({ clinicId = null,  className = '', value = [], onChange = () => {}, style, type = 'checkbox' }) {
	const lang = React.useContext(LangContext)()
	const backend = new Backend()


	const [hsps, setHsps] = useState([])
	const [loading, setLoading] = useState(true)
	const [selectedRow, setSelectedRow] = useState()

	const refresh = () => {
		setLoading(true)
		backend.healthProvider({
			cache: false,
			isOnboarded: true,
			clinicId,
			cb: (data) => {
				setHsps(
					data
						.filter((n) => n?.name !== '')
						.map((d) => {
							return {
								d: d,
								id: d.id,
								key: d.id,
								name: d.name,
								status: d.status,
								service: d.service
							}
						})
				)
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		refresh()
	}, [])

	return (
		<Skeleton hide={!loading}>
			<div className="exo-content-type-select inline">
				<div className={`list ${selectedRow ? 'hsp-small' : ''}`}>
					<Table
						loading={loading}
						className={className}
						onRow={(row) => {
							return {
								onClick: () => {
									setSelectedRow(row)
								}
							}
						}}
						rowSelection={{
							type: type,
							selectedRowKeys: value.map((d) => parseInt(d)),
							onChange: (selectedRowKeys) => {
								return onChange(selectedRowKeys)
							}
						}}
						data={hsps}
						columns={[
							{
								key: 'name',
								value: lang('title'),
								sorter: (a, b) => a.title.localeCompare(b.title),
								search: true
							},
							{
								key: 'status',
								value: lang('status'),
								sorter: (a, b) => a.type?.title.localeCompare(b.type?.title)
							}
						]}
					/>
				</div>
				{selectedRow ? (
					<div className="details hsp-small">
						<Card
							title={selectedRow?.title}
							extra={
								<Button
									type="close"
									onClick={() => {
										setSelectedRow()
									}}></Button>
							}>
							<div className="block">{selectedRow?.d.service ? <label>{selectedRow?.d.service}</label> : null}</div>
						</Card>
					</div>
				) : null}
			</div>
		</Skeleton>
	)
}
