import React, { useState, useEffect } from 'react'
import { Tabs, Row, Col, Statistic, Badge, Avatar, message, Form } from 'antd'

import PubSub from 'pubsub-js'

import PatientTags from '../../components/PatientTags'
import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'
import Notes from '../../components/Notes'
import AlarmsTable from '../../applets/AlarmsTable'
import Backend from '../../api/Backend'
import Drawer from '../../components/Drawer'
import { TaskScheduler } from '../../applets/TaskScheduler'
import OnboardPatientView from '../../components/OnboardPatientView'

import { AppointmentScheduler } from '../../applets/AppointmentScheduler'
import IntegrationsOverview from '../../applets/Integrations'

import Utils from '../../components/Utils'

import PatientOverview from '../../components/PatientOverview'
import Tag from '../../components/Tag'
import MasterData from '../../applets/MasterData'
import { Assessments } from '../../applets/Assessments'
import Health from '../../applets/Health'
import Studies from '../../applets/Studies'
import { useGlobalContext } from '../../components/AppContext'
import { PatientCareplans } from '../../applets/PatientCareplans'
import Button from '../../components/Button'
import AllowRender from '../../components/AllowRender'

import { LangContext } from '../../components/Translation'
import translations from './translations'

import { useParams } from 'react-router-dom'

import './index.css'
import PatientECRF from '../../applets/PatientECRF'
import PatientUploads from '../../applets/PatientUploads'
import PatientAppointments from '../../applets/PatientAppointments'
import { getLatestFormAnswers } from '../../api-new'
import { mapOnboardingFormAnswersToStage } from '../../helpers/onboarding-form-answers-mapper'
import { UploadSender } from '../../applets/UploadSender'

const { TabPane } = Tabs

const DEFAULT_TOAST_TIMER = 5000

export default function Patient({ id, clinic, fetchPatients, setOpenPatientId = () => {} }) {
	const lang = React.useContext(LangContext)(translations)
	const { studyStates, isBetterCareProject } = useGlobalContext()
	const paramId = useParams().id
	id = id ? id : paramId
	const [newId, setNewId] = useState(undefined)
	const utils = new Utils()

	const backend = new Backend()
	const [form] = Form.useForm()

	const menu = {
		OVERVIEW: 'OVERVIEW',
		MASTER_DATA: 'MASTER_DATA',
		ASSESSMENTS: 'ASSESSMENTS',
		NOTES: 'NOTES',
		ALARMS_TABLE: 'ALARMS',
		HEALTH: 'HEALTH',
		CAREPLANS: 'CAREPLANS',
		STUDIES: 'STUDIES',
		ECRF: 'ECRF',
		UPLOADS: 'UPLOADS',
		APPOINTMENTS: 'APPOINTMENTS',
		INTEGRATIONS: 'INTEGRATIONS'
	}

	const [patientData, setPatientData] = useState(null)
	const [formStage, setFormStage] = useState(null)

	const [openAppointment, setOpenAppointment] = useState()
	const [openUploadSender, setOpenUploadSender] = useState(false)
	const [openScheduledTask, setOpenScheduledTask] = useState(false)
	const [showPatientOnboardingForm, setShowPatientOnboardingForm] = useState(false)

	const [alarms, setAlarms] = useState([])
	const [studies, setStudies] = useState([])
	const [selectedMenuItem, setSelectedMenuItem] = useState(menu.OVERVIEW)

	const isPatientIncluded = () => {
		return lang(studyStates.INCLUDED.toLowerCase()).includes(lang(patientData?.resourceStudy?.status.toLowerCase()))
	}

	const isPatientNotParticipating = () => {
		return ![lang(studyStates.PARTICIPATION.toLowerCase())].includes(lang(patientData?.resourceStudy?.status.toLowerCase()))
	}

	const isPatientNotOnboarding = () => {
		return ![lang(studyStates.ONBOARDING.toLowerCase())].includes(lang(patientData?.resourceStudy?.status.toLowerCase()))
	}

	const isPatientNotExcluded = () => {
		return ![lang(studyStates.EXCLUDED.toLowerCase())].includes(lang(patientData?.resourceStudy?.status.toLowerCase()))
	}

	const isPatientNotDroppedOut = () => {
		return ![lang(studyStates.DROP_OUT.toLowerCase())].includes(lang(patientData?.resourceStudy?.status.toLowerCase()))
	}

	const isPatientNotCompleted = () => {
		return ![lang(studyStates.COMPLETED.toLowerCase())].includes(lang(patientData?.resourceStudy?.status.toLowerCase()))
	}

	const tabPaneData = [
		{
			tab: 'overview',
			key: menu.OVERVIEW,
			disabled: false
		},
		{
			tab: 'master_data',
			key: menu.MASTER_DATA,
			disabled: false
		},
		{
			tab: 'assessments',
			key: menu.ASSESSMENTS,
			disabled:
				isPatientNotParticipating() &&
				isPatientNotOnboarding() &&
				isPatientNotExcluded() &&
				isPatientNotDroppedOut() &&
				isPatientNotCompleted()
		},
		{
			tab: 'careplans',
			key: menu.CAREPLANS,
			disabled:
				isPatientNotParticipating() &&
				isPatientNotOnboarding() &&
				isPatientNotExcluded() &&
				isPatientNotDroppedOut() &&
				isPatientNotCompleted()
		},
		{
			tab: 'notes',
			key: menu.NOTES,
			disabled: false
		},
		{
			tab: 'alarms',
			key: menu.ALARMS_TABLE,
			disabled:
				isPatientNotParticipating() &&
				isPatientNotOnboarding() &&
				isPatientNotExcluded() &&
				isPatientNotDroppedOut() &&
				isPatientNotCompleted()
		},
		{
			tab: 'health',
			key: menu.HEALTH,
			disabled: false
		},
		{
			tab: 'studies',
			key: menu.STUDIES,
			disabled: false
		},
		{
			tab: 'ecrf',
			key: menu.ECRF,
			disabled: false
		},
		{
			tab: 'uploads',
			key: menu.UPLOADS,
			disabled: false
		},
		{
			tab: 'appointments',
			key: menu.APPOINTMENTS,
			disabled: false
		},
		{
			tab: 'integrations',
			key: menu.INTEGRATIONS,
			disabled: false
		}
	]

	const refreshStudies = () => {
		backend.studies({ patient: id, cb: setStudies })
	}

	useEffect(() => {
		if (newId) {
			id = newId
		}
		backend.patient({
			id,
			clinic,
			cb: async (d) => {
				try {
					const answers = await getLatestFormAnswers(id, 'ONBOARDING')
					const stage = mapOnboardingFormAnswersToStage(answers)
					setFormStage(stage)
				} catch (error) {
					if (error.response && error.response.data.statusCode === 404) {
						setFormStage('Noch nicht gestartet')
					} else {
						console.log(error)
					}
				}
				setPatientData(d[0])
			}
		})
		backend.alarms({ patient: id, active: true, state: 'OPEN', cb: setAlarms })
		refreshStudies()
	}, [id, newId])

	const groupResolver = () => {
		if (patientData && patientData.intervention_group) {
			return lang('intervention')
		} else if (
			isBetterCareProject() &&
			!patientData?.intervention_group &&
			(lang(patientData?.resourceStudy?.status.toLowerCase()) === lang(studyStates.ONBOARDING.toLowerCase()) ||
				lang(patientData?.resourceStudy?.status.toLowerCase()) === lang(studyStates.PARTICIPATION.toLowerCase()) ||
				lang(patientData?.resourceStudy?.status.toLowerCase()) === lang(studyStates.DROP_OUT.toLowerCase()))
		) {
			return lang('intervention')
		} else if (
			(!patientData?.intervention_group &&
				lang(patientData?.resourceStudy?.status.toLowerCase()) === lang(studyStates.ONBOARDING.toLowerCase())) ||
			lang(patientData?.resourceStudy?.status.toLowerCase()) === lang(studyStates.PARTICIPATION.toLowerCase()) ||
			lang(patientData?.resourceStudy?.status.toLowerCase()) === lang(studyStates.DROP_OUT.toLowerCase())
		) {
			return lang('control')
		} else {
			return '-'
		}
	}

	const patientName = patientData ? `${patientData?.patient_name?.toUpperCase()}` : ''
	const patientEmail = patientData ? `${patientData?.resource?.email}` : ''
	const patientBirthDate = patientData && patientData.resource ? `${patientData.resource.birthdate}` : ''
	const patientPhoneNumber = patientData && patientData.resource ? `${patientData.resource.phone}` : ''
	const patientCommunication = patientData ? `${patientData.communication}` : ``
	const patientFullName = patientData && patientData.resource ? `${patientData.resource.name} ${patientData.resource.surname} ` : ''
	const patientStatus = patientData ? `${lang(patientData.resourceStudy?.status.toLowerCase())}` : '-'
	const patientOnline = patientData ? patientData.online : false
	const engagementLevel = patientData ? `${patientData.engagementLevel}` : '-'
	const patientGroupDisplayValue = groupResolver()
	const isPatientOfInterventionGroup = patientData && patientData.intervention_group

	let content = <PatientOverview id={id} patientData={patientData} />
	if (selectedMenuItem === menu.OVERVIEW) {
		content = <PatientOverview id={id} patientData={patientData} />
	} else if (selectedMenuItem === menu.MASTER_DATA) {
		content = <MasterData id={id} data={patientData} stage={formStage} />
	} else if (selectedMenuItem === menu.ASSESSMENTS) {
		content = (
			<AllowRender permissions={['PATIENT_R']}>
				<Assessments id={id} />
			</AllowRender>
		)
	} else if (selectedMenuItem === menu.CAREPLANS) {
		content = (
			<AllowRender permissions={['PATIENT_RW']}>
				<PatientCareplans id={id} />
			</AllowRender>
		)
	} else if (selectedMenuItem === menu.NOTES) {
		content = (
			<div className="ant-card">
				<div className="col-sm-12 col-lg-12">
					<div className="ant-card-body">
						<Notes patientId={id} type="GENERAL" />
					</div>
				</div>
			</div>
		)
	} else if (selectedMenuItem === menu.ALARMS_TABLE) {
		content = (
			<AllowRender permissions={['PATIENT_RW']}>
				<AlarmsTable showPatientAction={false} patient={id} redirect={false} />
			</AllowRender>
		)
	} else if (selectedMenuItem === menu.HEALTH) {
		content = <Health patientId={id} />
	} else if (selectedMenuItem === menu.STUDIES) {
		content = (
			<Studies
				data={studies}
				patientId={id}
				patientEmail={patientEmail}
				refreshStudies={refreshStudies}
				fetchPatients={fetchPatients}
				setOpenPatientId={setOpenPatientId}
			/>
		)
	} else if (selectedMenuItem === menu.ECRF) {
		content = <PatientECRF patientId={id} />
	} else if (selectedMenuItem === menu.UPLOADS) {
		content = <PatientUploads patientId={id} />
	} else if (selectedMenuItem === menu.APPOINTMENTS) {
		content = <PatientAppointments patientId={id} />
	} else if (selectedMenuItem === menu.INTEGRATIONS) {
		content = <IntegrationsOverview patientId={id} />
	}

	const patientNameHandler = () => {
		if (patientName === 'undefined') {
			return lang('patient_id_missing')
		}
		return `${patientName} | ${patientFullName}`
	}

	const onlineColor = patientOnline ? 'cyan' : 'default'

	const handleScheduledTaskCreate = async () => {
		form.validateFields().then((fields) => {
			const body = JSON.stringify({
				...fields,
				type: 'SCHEDULED',
				patientId: id,
				scheduledDate: fields.scheduledDate.utc().startOf('day')
			})
			backend.tasks({
				body,
				type: backend.type.CREATE,
				cb: () => {
					setOpenScheduledTask(false)
					message.success(lang('task-save-success'))
					form.resetFields()
				}
			})
		})
	}

	return (
		<div className="exo-patient">
			<PageHeader
				title={lang('title', [patientNameHandler()])}
				tags={<PatientTags data={patientData} />}
				description={
					<Row className="exo-row light">
						<Col span={4}>
							<Statistic title={lang('date-of-birth')} value={utils.toDate({ dateString: patientBirthDate, withoutTime: true })} />
						</Col>
						<Col span={4}>
							<Statistic title={lang('phone')} value={patientPhoneNumber} groupSeparator="" />
						</Col>
						<Col span={4}>
							<Statistic
								title={lang('symptom-alarms')}
								formatter={(value) => (
									<Badge size="small" showZero={false} count={0}>
										<Avatar shape="square">{value}</Avatar>
									</Badge>
								)}
								value={alarms.length}
							/>
						</Col>
						<Col span={4}>
							<Statistic title={lang('group')} value={patientGroupDisplayValue} />
						</Col>
						<Col span={4}>
							<Statistic title={lang('status')} value=" " prefix={<Tag type={patientStatus}>{patientStatus}</Tag>} />
						</Col>
						<Col span={4}>
							<Statistic
								title={lang('engagement-level')}
								value=" "
								prefix={
									<div>
										<Badge count={1} className={`mr10 badge-black`} />
										<Badge count={2} className={`mr10 ${engagementLevel >= 2 ? 'badge-black' : 'badge-grey'}`} />
										<Badge count={3} className={`${engagementLevel === 3 ? 'badge-black' : 'badge-grey'}`} />
									</div>
								}
							/>
						</Col>
					</Row>
				}
				footer={
					<Tabs defaultActiveKey={selectedMenuItem} onChange={setSelectedMenuItem} centered>
						{tabPaneData.map((item) => (
							<TabPane tab={lang(item.tab)} key={item.key} disabled={item.disabled} />
						))}
					</Tabs>
				}
				extra={
					<div>
						{isPatientIncluded() && (
							<Button className="ant-btn-primary btn-first" onClick={() => setShowPatientOnboardingForm(true)}>
								{lang('onboard')}
							</Button>
						)}
						<Button
							className="ant-btn-primary btn-first"
							onClick={() => setOpenUploadSender(true)}
							disabled={isPatientNotParticipating()}>
							{lang('upload-file')}
						</Button>
						<Button className="ant-btn-primary btn-first" onClick={setOpenScheduledTask} disabled={isPatientNotParticipating()}>
							{lang('new-task')}
						</Button>
						<Button
							type="appointment"
							onClick={() => {
								setOpenAppointment(true)
							}}
							disabled={isPatientNotParticipating()}></Button>
						<Badge status={onlineColor} className="ml10" />
						<Button
							disabled={(isPatientNotParticipating() && !patientCommunication.CHAT) || !isPatientOfInterventionGroup}
							type="chat"
							onClick={() => {
								setOpenPatientId(null)
								PubSub.publish('chat:start', { patientId: id, patientName })
							}}></Button>
						<Button
							disabled={(isPatientNotParticipating() && !patientCommunication.VIDEO) || !isPatientOfInterventionGroup}
							type="video"
							className="ml10"
							onClick={() => {
								setOpenPatientId(null)
								PubSub.publish('video:start', { patientId: id, patientName: patientNameHandler() })
							}}></Button>
					</div>
				}>
				<PageContent>
					<Drawer
						open={openAppointment !== undefined}
						onClose={() => {
							setOpenAppointment(undefined)
						}}
						width={700}
						text={{ title: lang('appointment-details') }}>
						<AppointmentScheduler
							data={{ patientId: id }}
							onCreate={() => {
								setOpenAppointment(undefined)
							}}
							isCreateMode={true}
						/>
					</Drawer>
					<Drawer
						open={openScheduledTask}
						onClose={() => {
							setOpenScheduledTask(false)
						}}
						width={700}
						onOK={handleScheduledTaskCreate}
						text={{
							title: lang('new-task'),
							cancel: lang('cancel'),
							ok: lang('create')
						}}>
						<TaskScheduler form={form}></TaskScheduler>
					</Drawer>
					<Drawer
						open={openUploadSender}
						onClose={() => {
							setOpenUploadSender(false)
							form.resetFields()
						}}
						width={500}>
						<UploadSender
							form={form}
							patientId={id}
							completion={() => {
								setOpenUploadSender(false)
								form.resetFields()
							}}></UploadSender>
					</Drawer>
					<OnboardPatientView
						DEFAULT_TOAST_TIMER={DEFAULT_TOAST_TIMER}
						showPatientOnboardingForm={showPatientOnboardingForm}
						setShowPatientOnboardingForm={setShowPatientOnboardingForm}
						newPatientData={patientData}
						fetchPatients={() => {}}
						completion={(newId) => {
							setNewId(newId)
						}}
						rules={[
							{
								required: true,
								message: lang('missing-input')
							}
						]}
					/>
					<div className="container-xl">
						<div className="row row-deck row-cards">{content}</div>
					</div>
				</PageContent>
			</PageHeader>
		</div>
	)
}
