import * as d3 from 'd3';
import React, { useRef, useEffect } from 'react';

import Empty from '../../Empty'

import "./index.css"

function MultiLineChart({
    yAxisLabel = "",
    xAxisLabel = "",
    xLabelRenderer,
    yLabelRenderer,
    emptyText,
    skala = [],
    width = 500,
    height = 500,
    data = [],
    numSeries = 7,
    margin = { top: 25, right: 20, bottom: 35, left: 45 }
}){
    const ref = useRef();
    let svg, xScale, yScale, xAxis, yAxis
    const transition = 800

    const scale = () => {

        const maxNumValues = numSeries//Math.max(...data.map(d => d.values.length))
        const getxAxisTSforSeries = (series) => {
            const res = data.filter(d => d.series === series)
            return res.length > 0 ? res.pop() : null
        }

        xScale = d3.scaleLinear()
            .domain([1, maxNumValues])
            .range([0, chartWidth])

        yScale = d3.scaleLinear()
            .domain([Math.min(...data.map(d => d.min))-1, Math.max(...data.map(d => d.max))+1])
            .range([chartHeight, 0])

        xAxis = d3.axisBottom(xScale).ticks(maxNumValues).tickFormat(xLabelRenderer ?  (series)=>{ return xLabelRenderer(d3, getxAxisTSforSeries(series)) } : d3.format("d"));

        yAxis = d3.axisLeft(yScale).tickFormat((d)=>{
            let label = yLabelRenderer ? yLabelRenderer(d, d3) : d3.format("d")(d)
            for(let s of skala){
                if(s.key == d){
                    label = s.value;
                }
            }
            return label;
        }); 
        
    } 

    useEffect(() => { // component got new props
        scale(data)
        update(data);
    }, [data])

    useEffect(() => { // component did render first time
        scale(data)
        update(data);
    }, [])

    function getSvg(){
        return d3.select(ref.current).select('svg g');
    }

    // set the dimensions and margins of the graph
    let chartWidth = width - margin.left - margin.right,
        chartHeight = height - margin.top - margin.bottom;
      
    const line = d3.line()
        .x(d => {
            return xScale(d.series)
        })
        .y(d => yScale(d.value));
            
    let duration = 250;
    
    let lineOpacity = "1";
    let lineOpacityHover = "1";
    let otherLinesOpacityHover = "0.1";
    
    let circleOpacity = '1';
    let circleOpacityOnLineHover = "0.25"
    let circleRadius = 4;
    let circleRadiusHover = 8;
   
    const color = d3.scaleOrdinal(d3.schemeTableau10);
   
    const draw = (data) => {
        
        svg = d3.select(ref.current)
            .append("svg")
                .attr("width", width)
                .attr("height", height)
            .append("g")
                .attr("transform", `translate(${margin.left}, ${margin.top})`)
        
        let lines = svg.append('g')
            .attr('class', 'lines')
                            
        lines.selectAll('.line-group')
            .data(data).enter()
            .append('g')
            .attr('class', 'line-group')  
            .append('path')
            .attr('class', 'line')  
            .attr('d', d => line(d.values))
            .style('stroke', (d, e) => { return d.color ? d.color : color(d) })
            .style('opacity', lineOpacity)
            .on("mouseover", function(d) {
                d3.selectAll('.line')
                                .style('opacity', otherLinesOpacityHover);
                d3.selectAll('.circle')
                                .style('opacity', circleOpacityOnLineHover);
                d3.select(this)
                    .style('opacity', lineOpacityHover)
                    .style("cursor", "pointer");
                })
            .on("mouseout", function(d) {
                d3.selectAll(".line")
                                .style('opacity', lineOpacity);
                d3.selectAll('.circle')
                                .style('opacity', circleOpacity);
                d3.select(this)
                    .style("cursor", "none");
                });
            
            /* Add circles in the line */
        lines.selectAll("circle-group")
            .data(data).enter()
             .append("g")
            .style("fill", (d, e) => { return d.color ? d.color : color(d) })
            .selectAll("circle")
                .data(d => d.values).enter()
                .append("g")
                .attr("class", "circle")  
                .on("mouseover", function(e, d) {
                    d3.select(this)     
                        .style("cursor", "pointer")
                        .append("text")
                        .attr("class", "text")
                        .text(`${d.value}`)
                        .attr("x", d => xScale(d.series) + 5)
                        .attr("y", d => yScale(d.value) - 10);
                    })
                .on("mouseout", function(d) {
                    d3.select(this)
                        .style("cursor", "none")  
                        .transition()
                        .duration(duration)
                        .selectAll(".text").remove();
                    })
                .append("circle")
                .attr("cx", d => xScale(d.series))
                .attr("cy", d => yScale(d.value))
                .attr("r", circleRadius)
                .style('opacity', circleOpacity)
                .on("mouseover", function(d) {
                        d3.select(this)
                        .transition()
                        .duration(duration)
                        .attr("r", circleRadiusHover);
                    })
                    .on("mouseout", function(d) {
                        d3.select(this) 
                        .transition()
                        .duration(duration)
                        .attr("r", circleRadius)
                    })
                        
        svg.append("g")
                .attr("class", "x axis")
                .attr("transform", `translate(0, ${chartHeight})`)
            .call(xAxis)
            .append('text') 
//                .attr("y", margin.bottom)
                .attr("transform", "translate(" + (width/2) + " ,30)")
                .style("text-anchor", "middle")    
                .attr("fill", "#000")
                .text(xAxisLabel)
            
        svg.append("g")
             .attr("class", "y axis")
            .call(yAxis)
            .append('text')
                .attr("y", margin.left)
                .attr("transform", "rotate(-90)")
                .attr("fill", "#000")
                .text(yAxisLabel)
    }

    const update = (data) => {
        const svg = d3.select(ref.current)
        svg.selectAll('svg').remove()
        if(data.length > 0) draw(data)
         //TODO: dynamic update
        /*const lines = svg.selectAll('.line-group').data(data)
        
        lines.enter()
            .transition(transition)
            .attr('d', d => line(d.values))

        lines.transition(transition)
            .attr('d', d => line(d.values))

        lines
            .exit()
            .attr("class", "exit")
            .transition(transition)
                .style("fill-opacity", 1e-6)
                .remove();  
                */
/*        lines.selectAll("circle-group")
            .data(data).enter()
            //.transition(t)
*/
       /* const svg = getSvg();
        svg.selectAll(".exo-multi-line-chart").remove();
        drawGroupLine(svg, groupData);
        drawPatientLine(svg, patientData);*/
    }

    return (
        <div className="exo-multi-line-chart">
            <div ref={ref}>
                { data.length === 0 ? <Empty description={emptyText}></Empty> : null }
            </div>
        </div>
    )
}

export default MultiLineChart;