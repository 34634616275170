import React from 'react'

import Skeleton from '../Skeleton'
import { Card } from 'antd'

import './index.css'

export default function Stats({ data, title, loading }) {
	const stats = data.map((line) => {
		return (
			<div key={line} className="col-12 inline">
				{line.map((d) => {
					return (
						<div key={d.title + d.value} className="col-3 block">
							<div className="font-weight-medium">{d.title}</div>
							<div className="text-nowrap text-muted mt5">{d.value}</div>
						</div>
					)
				})}
			</div>
		)
	})
	return (
		<Card bordered={false}>
			<h3 className="card-title">{title}</h3>
			<div className="overflow-auto" style={{ height: '100%', padding: '15px' }}>
				<div className="row row-deck row-cards">
					<Skeleton rows={0} type="list" hide={!loading}>
						{stats}
					</Skeleton>
				</div>
			</div>
		</Card>
	)
}
