import * as d3 from 'd3';
import * as d3Collection from 'd3-collection';

const utils = {};

utils.getSumstat = function(data){
    return d3Collection.nest() // nest function allows to group the calculation per level of a factor
        .key(function(d) { return d.series })
        .rollup(function(d) {
            let q1 = d3.quantile(d.map(function(g) { return g.value; }).sort(d3.ascending),.25)
            let median = d3.quantile(d.map(function(g) { return g.value; }).sort(d3.ascending),.5)
            median = d3.mean(d.map(function(g) { return g.value; }));
            let q3 = d3.quantile(d.map(function(g) { return g.value; }).sort(d3.ascending),.75)
            let interQuantileRange = q3 - q1
            let min = d3.min(d.map(function(g) { return g.value;}))
            let max = d3.max(d.map(function(g) { return g.value;}))
            return({ q1, median, q3, interQuantileRange, min, max})
    })
    .entries(data).map((d) => { 
        d.key = parseInt(d.key) 
        return d 
    })
}

utils.mapGroupData = function(data){
    return data.map((d)=>{ return { series: d.key, value: d.value.median }; });
}

utils.addPatientTrialDates = function({ patient, trialMonths }){
    patient.startDate = new Date(patient.resource.terms_accepted_ts) //TODO be discussed with client
    patient.startDate.setDate(patient.startDate.getDate() -7) //TODO temp fix because of inconsistent enable data 
    patient.endDate = new Date(patient.resource.terms_accepted_ts)
    patient.endDate.setMonth(patient.startDate.getMonth() + trialMonths) 
    return patient
}

utils.mapDatePoints2TrialPoints = ({ dates, trialDates, numTrialPoints }) => {
    const timeScale = d3.scaleTime()
                    .domain(trialDates)
                    .range([0, numTrialPoints])

    return dates.map(d => Math.round(timeScale(new Date(d))) ) 
}

utils.fillRange = function(i,j){
    const res = [];
    for (i; i <= j; i++) {
        res.push(i);
    }
    return res;
}

export default utils
