import React, { useState, useEffect, useContext } from 'react'

import { Form, Input, Select, Card } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import MediaLibrary from '../../../applets/MediaLibrary'
import { moduleGroupTypes, moduleTypes, moduleStepTypes } from '../../../applets/Modules'

import Button from '../../../components/Button'
import Drawer from '../../../components/Drawer'

import { LangContext } from '../../../components/Translation'
import translations from './translations'

import Backend from '../../../api/Backend'

import { CMS_ENDPOINT } from '../../../pages/CMS'
import AllowRender from '../../../components/AllowRender'
import Thumbnail from '../../../components/Thumbnail'

const ModuleCreator = ({ type, form }) => {
	const lang = useContext(LangContext)(translations)

	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)
	const [openAudioMediaLibrary, setOpenAudioMediaLibrary] = useState(false)
	const [openVideoMediaLibrary, setOpenVideoMediaLibrary] = useState(false)

	const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState(null)

	const [modules, setModules] = useState([])
	const [moduleGroups, setModuleGroups] = useState([])

	const [moduleStepType, setModuleStepType] = useState({})
	const [loading, setLoading] = useState(false)

	const backend = new Backend()

	useEffect(() => {
		setLoading(true)
		backend.cmsModules({
			cb: (data) => {
				setModules(data)
			}
		})
		backend.cmsModuleGroups({
			cb: (data) => {
				setModuleGroups(data)
				setLoading(false)
			}
		})
	}, [])

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const audioStep = ({ step, rules, name }) => {
		let step_name = [name, 'audio_name']
		let audio
		let audioSource = form.getFieldsValue()
		const { new_steps } = audioSource
		let audioUrl = new_steps[name]?.url

		if (audioUrl) {
			audio = new Audio(`${CMS_ENDPOINT}` + audioUrl)
			audio.load()
		}
		return (
			<div className="media-container">
				<div>
					<Form.Item name={[name, 'audio_name']} label={lang('title')} initialValue={step?.audio?.name} rules={rules}>
						<Input disabled />
					</Form.Item>
					<Form.Item name={name} hidden />
					<Button
						type="primary"
						onClick={() => {
							audio && audio.play()
						}}>
						{lang('play')}
					</Button>
					<Button
						type="primary"
						onClick={() => {
							audio && audio.pause()
						}}>
						{lang('pause')}
					</Button>
					<Button
						type="primary"
						onClick={() => {
							audio && (audio.currentTime = 0)
						}}>
						{lang('reset')}
					</Button>
				</div>
				<Button
					type="primary"
					onClick={() => {
						setOpenAudioMediaLibrary(true)
					}}>
					{lang('select-audio')}
				</Button>
				<Drawer
					open={openAudioMediaLibrary}
					placement="right"
					onClose={() => {
						setOpenAudioMediaLibrary(false)
					}}
					width="65%"
					text={{ title: lang('select-audio'), cancel: lang('cancel') }}>
					<MediaLibrary
						single
						media={{
							onMediaSelect: () => {
								setOpenAudioMediaLibrary(false)
							},
							setSelectedMedia: (audio) => {
								let fields = form.getFieldsValue()
								const { new_steps } = fields

								Object.assign(new_steps[step_name[0]], {
									audio_name: audio.name,
									id: audio.id,
									url: audio.url,
									audio: { ...audio }
								})
								form.setFieldsValue({ new_steps })
							},
							type: 'Audio',
							mime: ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg']
						}}
					/>
				</Drawer>
			</div>
		)
	}

	const videoStep = ({ step, rules, name }) => {
		let step_name = [name, 'video_title']
		return (
			<div className="media-container">
				<div>
					<Form.Item name={[name, 'video_title']} label={lang('title')} initialValue={step?.video?.name} rules={rules}>
						<Input disabled />
					</Form.Item>
				</div>
				<Form.Item name={name} hidden />
				<Button
					type="primary"
					onClick={() => {
						setOpenVideoMediaLibrary(true)
					}}>
					{lang('select-video')}
				</Button>
				<Drawer
					open={openVideoMediaLibrary}
					placement="right"
					onClose={() => {
						setOpenVideoMediaLibrary(false)
					}}
					width={'65%'}
					text={{ title: lang('select-video'), cancel: lang('cancel') }}>
					<MediaLibrary
						single
						media={{
							onMediaSelect: () => setOpenVideoMediaLibrary(false),
							setSelectedMedia: (video) => {
								let fields = form.getFieldsValue()
								const { new_steps } = fields

								Object.assign(new_steps[step_name[0]], {
									video_title: video.name,
									video: { ...video }
								})
								form.setFieldsValue({ new_steps })
							},
							type: 'Video',
							mime: ['video/mp4', 'video/webm']
						}}
					/>
				</Drawer>
			</div>
		)
	}

	const handleRemoveModuleStepType = (index) => {
		let hitFlag = false
		let data = moduleStepType
		let saveState = form.getFieldsValue()
		let { new_steps } = saveState
		if (Object.keys(data).length === 1) {
			setModuleStepType({})
			return true
		}
		let ecrf_inputs
		for (let i = 0; i < Object.keys(data).length; i++) {
			if (i === index && i === length - 1) {
				delete data[i]
				delete new_steps[i]
			}

			if (i === index) {
				hitFlag = true
				continue
			}
			if (hitFlag) {
				// decrement all indexes, move new_steps up
				data[i - 1] = data[i]
				new_steps[i - 1] = {}
				Object.assign(ecrf_inputs[i - 1], ecrf_inputs[i])
			}
			if (i === length - 1) {
				delete data[i]
				delete ecrf_inputs[i]
			}
		}
		form.setFieldsValue({ new_steps })
		setModuleStepType(data)
	}

	const formSheet = (
		<div>
			<Form form={form} layout="vertical">
				<Form.Item name="title" label={lang('title')} rules={rules}>
					<Input />
				</Form.Item>
				<Form.Item name="description" label={lang('description')} rules={rules}>
					<Input />
				</Form.Item>

				<div className="thumbnail-container">
					<div>
						<div className="ant-col ant-form-item-label">
							<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
								Thumbnail
							</label>
						</div>
						<Thumbnail url={selectedThumbnailUrl} />
						<Form.Item name="new_display_picture" rules={rules} />
					</div>
					<div>
						<Button type="primary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
					</div>
				</div>
				<Drawer
					open={openThumbnailMediaLibrary}
					placement="right"
					onClose={() => {
						setOpenThumbnailMediaLibrary(false)
					}}
					width={'65%'}
					text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
					<MediaLibrary
						single
						media={{
							onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
							setSelectedMedia: (thumbnail) => {
								form.setFieldsValue({
									new_display_picture: {
										id: thumbnail.id,
										url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
									}
								})
							},
							setSelectedMediaUrl: setSelectedThumbnailUrl,
							type: 'Thumbnail',
							mime: ['image/jpeg', 'image/png', 'image/svg+xml']
						}}
					/>
				</Drawer>

				{/* MODULE GROUPS*/}
				{type === 'group' && (
					<>
						<AllowRender permissions={['CMS_PUBLISH']}>
							<Form.Item name="state" label={lang('type')} rules={rules} initialValue={moduleGroupTypes.draft}>
								<Select>
									{Object.keys(moduleGroupTypes).map((key) => (
										<Select.Option key={key} value={moduleGroupTypes[key]}>
											{lang(key)}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</AllowRender>

						<Form.Item name="select-modules" label={lang('select-modules')}>
							<Select mode="multiple" placeholder={lang('select-modules')}>
								{modules.map((module) => (
									<Select.Option key={module.id} value={module.id}>
										{module.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</>
				)}

				{type === 'module' && (
					<>
						<Form.Item name="type" label={lang('type')} rules={rules}>
							<Select>
								{Object.keys(moduleTypes).map((key) => (
									<Select.Option key={key} value={moduleTypes[key]}>
										{lang(key)}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.List name="new_steps">
							{(fields, { add, remove }) => (
								<>
									{fields.map(({ key, name, ...restField }) => {
										return (
											<Card key={key}>
												<div>
													<Form.Item
														{...restField}
														name={[name, 'step_type']}
														label={lang('step-type')}
														initialValue={moduleStepTypes.introduction}>
														<Select
															onChange={(value) => {
																setModuleStepType((prevState) => ({
																	...prevState,
																	[name]: value
																}))
															}}>
															{Object.keys(moduleStepTypes).map((step) => {
																return (
																	<Select.Option key={step} value={moduleStepTypes[step]}>
																		{moduleStepTypes[step]}
																	</Select.Option>
																)
															})}
														</Select>
													</Form.Item>

													{(moduleStepType[name] === moduleStepTypes.introduction || !(name in moduleStepType)) && (
														<div>
															<Form.Item name={[name, 'title']} label={lang('title')} rules={rules}>
																<Input />
															</Form.Item>
															<Form.Item name={[name, 'description']} label={lang('description')} rules={rules}>
																<Input.TextArea />
															</Form.Item>
														</div>
													)}
													{moduleStepType[name] === moduleStepTypes.reading && (
														<div>
															<Form.Item name={[name, 'title']} label={lang('title')} rules={rules}>
																<Input />
															</Form.Item>
															<Form.Item name={[name, 'body']} label={lang('body')} rules={rules}>
																<Input.TextArea />
															</Form.Item>
														</div>
													)}
													{moduleStepType[name] === moduleStepTypes.audio && audioStep({ step: restField, rules, name })}
													{moduleStepType[name] === moduleStepTypes.video && videoStep({ step: restField, rules, name })}
													{moduleStepType[name] === moduleStepTypes.question && (
														<div>
															<Form.Item name={[name, 'quiz']} label={lang('quizzes')} rules={rules}>
																<Input.TextArea />
															</Form.Item>
														</div>
													)}
													<Button
														type="remove"
														txt={lang('remove-step')}
														onClick={() => {
															if (handleRemoveModuleStepType(name)) {
																remove(name)
															} else {
																remove(name + 1)
															}
														}}
													/>
												</div>
											</Card>
										)
									})}
									<Form.Item>
										<Button
											type="primary"
											style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
											block
											onClick={(e) => {
												setModuleStepType((prevState) => ({
													...prevState,
													[fields.length]: moduleStepTypes.introduction
												}))
												add()
											}}>
											{lang('add-step')} <PlusOutlined />
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					</>
				)}
			</Form>
		</div>
	)

	return <div>{formSheet}</div>
}

export { ModuleCreator }
