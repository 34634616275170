import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider, Empty } from 'antd'
import App from './pages/App'
import { AppContext } from './components/AppContext'
import ErrorBoundary from './components/ErrorBoundary'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import kcConfig from './kcConfig'

import { CountlyServer } from './countly'

import axios from 'axios'
import { refresh, refreshPath } from './api-new'

const customizeRenderEmpty = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="" />

if (process.env.NODE_ENV !== 'DEVELOPMENT') {
	CountlyServer()
}

axios.defaults.withCredentials = true
axios.interceptors.response.use(
	(response) => {
		return response
	},
	function async(error) {
		const originalRequest = error.config
		const originalUrl = new URL(originalRequest.url)

		if (error.response?.status === 401 && originalUrl.pathname === refreshPath) {
			return Promise.reject(error)
		}
		if (error.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true
			return refresh(`${originalUrl.protocol}//${originalUrl.host}`).then(() => axios(originalRequest))
		} else {
			return Promise.reject(error)
		}
	}
)

ReactDOM.render(
	<React.StrictMode>
		<ReactKeycloakProvider initOptions={kcConfig.users} authClient={keycloak}>
			<ConfigProvider renderEmpty={customizeRenderEmpty}>
				<AppContext>
					<App />
				</AppContext>
			</ConfigProvider>
		</ReactKeycloakProvider>
	</React.StrictMode>,
	document.getElementById('root')
)
