import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'

import Backend from '../../api/Backend'

import { LangContext } from '../../components/Translation'
import Skeleton from '../../components/Skeleton'
import { Card } from 'antd'


import translations from './translations'


const Statistics = () => {
	const lang = React.useContext(LangContext)(translations)

	const backend = new Backend()

	const [appointmentsByTypeData, setAppointmentsByTypeData] = useState([])
	const [appointmentsByScheduler, setAppointmentsByScheduler] = useState([])

	useEffect(() => {
		backend.appointmentsByScheduler({ cb: setAppointmentsByScheduler })
		backend.appointmentsByType({ cb: setAppointmentsByTypeData })
	}, [])

	const pieSeries = Array.isArray(appointmentsByTypeData) ? appointmentsByTypeData.map((d) => parseInt(d.count)) : []

	const pieOptions = {
		series: pieSeries,
		labels: Array.isArray(appointmentsByTypeData) ? appointmentsByTypeData.map((d) => d.title) : []
	}

	const pieSeriesAppointments = appointmentsByScheduler.map((d) => (d.num ? parseInt(d.num) : 0))

	const pieOptionsAppointments = {
		series: pieSeriesAppointments,
		labels: appointmentsByScheduler.map((d) => lang(d.scheduledBy))
	}

	const appointmentDataLoaded = appointmentsByTypeData !== undefined

	return (
		<div className="container-xl">
			<div className="row row-deck row-cards">
				<div className="col-sm-6 col-lg-6">
					<Card bordered={false} title={lang('dates')}>
						<Skeleton type={'statistics'} hide={appointmentDataLoaded}>
							<Chart options={pieOptions} series={pieSeries} type="donut" height={250} />
						</Skeleton>
					</Card>
				</div>
				<div className="col-sm-6 col-lg-6">
					<Card bordered={false} title={lang('appointment')}>
						<Skeleton type={'statistics'} hide={true}>
							<Chart options={pieOptionsAppointments} series={pieSeriesAppointments} type="donut" height={250} />
						</Skeleton>
					</Card>
				</div>
			</div>
		</div>
	)
}

export default Statistics
