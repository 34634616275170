import React from 'react'
import { List as AList, Avatar } from 'antd';

import Empty from '../Empty'
import Skeleton from '../Skeleton'
import Button from '../Button'

import './index.css'

export default function List({
	className = '',
	loading = false,
	data = [],
	pagination = {
		pageSize: 6
	  },
	style = {},
	borderless = false,
	onDetails,
	onRemove,
	onContentAdd,
	onRowClick = () => {},
	emptyText
}) {

	const list = data.map(d => {
		return {
			d,
			title: d.title ,
			icon: d.icon,
			description: d.description,
			content: d.content
		}
	})

	return (

		<Skeleton hide={!loading}>
			{ list.length > 0 ?
				<AList
					className="exo-list"
					itemLayout="horizontal"
					dataSource={list}
					pagination={pagination}
					renderItem={item => (
						<AList.Item
							actions={[
								onContentAdd ? <Button type='open' classname='mr3' onClick={() => onContentAdd(item.d)}></Button> : null,
								onDetails ? <Button type="open"   className="mr3" onClick={() => onDetails(item.d)}></Button> : null,
								onRemove  ? <Button type="remove" className="mr3" onClick={() => onRemove(item.d)}></Button> : null
							]}
						>
							<AList.Item.Meta
								avatar={item.icon ? <Avatar src={item.icon} /> : null}
								title={item.title}
								description={item.description}
							/>
							<div>{ item.content }</div>
						</AList.Item>
					)}
				/>
			: <Empty description={emptyText}></Empty>}
		</Skeleton>
	)
}