import React, { useState, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { Button, Card, Menu, Dropdown } from 'antd'
import Patient from '../../pages/Patient'

import PubSub from 'pubsub-js'

import Table from '../../components/Table'
import Icon from '../../components/Icon'
import Tag from '../../components/Tag'
import Backend from '../../api/Backend'
import Utils from '../../components/Utils'
import Empty from '../../components/Empty'
import Skeleton from '../../components/Skeleton'
import Drawer from '../../components/Drawer'
import moment from 'moment'

import { AppointmentScheduler } from '../../applets/AppointmentScheduler'

import { useGlobalContext } from '../../components/AppContext'

import { LangContext } from '../../components/Translation'
import translations from '../AppointmentScheduler/translations.json'

import './index.css'
import { getPatientAppointments } from '../../api-new'

const Appointments = ({ all, patientId }) => {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()
	const utils = new Utils()

	const { globals } = useGlobalContext()

	const [appointments, setAppointments] = useState([])
	const [openAppointment, setOpenAppointment] = useState()
	const [goToPatient, setGoToPatient] = useState()
	const [openPatientId, setOpenPatientId] = useState()
	const [loading, setLoading] = useState(true)
	const [appointmentRedirect, setAppointmentRedirect] = useState(false)

	const location = useLocation()

	const refresh = async () => {
		setLoading(true)

		const process = (data, healthHubAppointments) => {
			let healthHubAppointmentsMapped = healthHubAppointments
				? healthHubAppointments.map((element) => {
						return { ...element, status: 'CREATED_BY_PATIENT' }
				  })
				: []

			setAppointments(
				[
					...data.map((d) => {
						let fullName = d.resource.patientIdPersonalData
							? d.resource.patientIdPersonalData?.name + ' ' + d.resource.patientIdPersonalData?.surname
							: '-'
						let birthDate = d.resource.patientIdPersonalData
							? utils.toDate({ dateString: d.resource.patientIdPersonalData?.birth_date, withoutTime: true })
							: '-'
						return {
							d,
							status: d.resource?.status,
							scheduledAt: d.resource?.scheduledAt,
							type: d.resource?.type?.title,
							patientId: d.resource?.patientId,
							healthProvider: d.resource?.healthProvider?.name,
							treatment: d.resource?.treatment?.title || '-',
							fullname: fullName,
							dob: birthDate,
							title: d.resource?.title,
							medium: d.resource?.medium
						}
					}),
					...healthHubAppointmentsMapped
				].sort((a, b) => moment(a.scheduledAt).unix() - moment(b.scheduledAt).unix())
			)
			setLoading(false)
		}
		if (patientId) {
			const healthHubAppointments = await getPatientAppointments(patientId)
			backend.appointmentsOfPatient({
				patientId: patientId,
				cb: (data) => {
					process(data, healthHubAppointments)
				}
			})
		} else {
			if (all) {
				backend.appointments({ cb: process, center: globals.selectedCenter || undefined })
			} else {
				backend.appointmentsOfCareteam({ cb: process })
			}
		}
	}

	useEffect(() => {
		if (globals.selectedCenter) {
			refresh()
		}
	}, [globals.selectedCenter])

	const action = {
		GOTO_PATIENT: 'GOTO_PATIENT',
		VIEW_PATIENT: 'VIEW_PATIENT',
		VIEW_APPOINTMENT: 'VIEW_APPOINTMENT',
		CHAT: 'CHAT',
		VIDEO: 'VIDEO'
	}

	const renderActions = ({ row }) => {
		const handleMenuClick = (e) => {
			if (e.key === action.GOTO_PATIENT) {
				setGoToPatient(row.patientId)
			} else if (e.key === action.VIEW_PATIENT) {
				setOpenPatientId(row.patientId)
			} else if (e.key === action.CHAT) {
				PubSub.publish('chat:start', { patientId: row.patientId, patientName: row.fullname })
			} else if (e.key === action.VIDEO) {
				console.log('Video placeholder')
			}
		}

		const menu = (
			<Menu onClick={handleMenuClick}>
				<Menu.Item key={action.VIEW_PATIENT}>
					<a target="_blank" rel="noopener noreferrer">
						{lang('view-patient')}
					</a>
				</Menu.Item>
				<Menu.Item key={action.GOTO_PATIENT}>
					<a target="_blank" rel="noopener noreferrer">
						{lang('goto-patient')}
					</a>
				</Menu.Item>
				<Menu.Item key={action.CHAT}>
					<a target="_blank" rel="noopener noreferrer">
						{lang('chat')}
					</a>
				</Menu.Item>
				<Menu.Item key={action.VIDEO}>
					<a target="_blank" rel="noopener noreferrer">
						{lang('video')}
					</a>
				</Menu.Item>
			</Menu>
		)

		return (
			<Dropdown.Button onClick={() => setOpenAppointment(row.d.resource)} overlay={menu}>
				<Icon type="open"></Icon>
			</Dropdown.Button>
		)
	}

	const renderDate = ({ value }) => {
		return utils.toDate({ dateString: value })
	}

	const columns = [
		{
			value: lang('status'),
			key: 'status',
			width: 150,
			sorter: (a, b) => (a.status ? a.status.localeCompare(b.status) : false),
			render: ({ value }) => (
				<Tag type={value} className="status-tag">
					{lang(value)}
				</Tag>
			),
			searchWithRender: true
		},
		{
			value: lang('date'),
			key: 'scheduledAt',
			width: 110,
			render: renderDate,
			dateSorter: 'scheduledAt',
			defaultSortOrder: 'descend'
		},
		{
			value: lang('medium'),
			key: 'medium',
			width: 110,
			render: ({ value }) => (value ? <Tag type={value}>{lang(value)}</Tag> : null)
		},
		{
			value: lang('title'),
			key: 'title',
			width: 250
		},
		{
			value: lang('health-provider'),
			key: 'healthProvider',
			width: 200
		},
		{
			value: lang('type'),
			key: 'type',
			width: 150
		},
		{
			value: lang('treatment'),
			key: 'treatment',
			width: 150
		},
		...(!patientId
			? [
					{
						value: lang('patient'),
						key: 'fullname',
						width: 200
					},
					{
						value: lang('date-of-birth'),
						key: 'dob',
						width: 110
					}
			  ]
			: [
					{
						value: lang('location'),
						key: 'location',
						width: 150
					}
			  ]),
		{ width: 140, fixed: 'right', key: 'actions', value: lang('actions'), render: renderActions }
	]

	const renderAppointmentOrPatientRedirect = () => {
		if (goToPatient) {
			return (
				<Redirect
					to={{
						pathname: `/patient/${goToPatient}`,
						state: { referrer: location.pathname }
					}}
				/>
			)
		}

		if (appointmentRedirect) {
			return (
				<Redirect
					to={{
						pathname: `/appointments`,
						state: { referrer: location.pathname }
					}}
				/>
			)
		}

		return null
	}

	return (
		<div className="appointments-card-container">
			{renderAppointmentOrPatientRedirect()}
			<Drawer
				placement="right"
				width="90%"
				onClose={() => {
					setOpenPatientId(undefined)
				}}
				open={openPatientId}
				text={{
					title: lang('patient'),
					cancel: lang('cancel')
				}}>
				<Patient id={openPatientId}></Patient>
			</Drawer>
			<Drawer
				open={openAppointment !== undefined}
				onClose={() => {
					setOpenAppointment(undefined)
					refresh()
				}}
				width={700}
				text={{ title: lang('appointment-details') }}>
				<AppointmentScheduler data={openAppointment} />
			</Drawer>
			<Card
				title={patientId ? '' : lang(all ? 'appointments-center' : 'appointments-careteam')}
				extra={
					<div className="card-head-action-container">
						<Button className="ant-btn-primary btn-refresh" onClick={refresh} icon={<Icon type={'refresh'} />} />
						{location.pathname !== '/appointments' ? (
							<Button className="ant-btn ant-btn-primary ml5" onClick={() => setAppointmentRedirect(true)}>
								{lang('go-to-center-appointments')}
							</Button>
						) : null}
					</div>
				}>
				<Skeleton hide={!loading}>
					{appointments.length ? (
						<Table
							data={appointments}
							columns={columns}
							scroll={{ x: '800px' }}
							pagination={{
								defaultPageSize: 5,
								pageSizeOptions: [5, 10, 20, 50, 100],
								showSizeChanger: true,
								position: ['bottomCenter']
							}}
						/>
					) : (
						<Empty description={'No Appointments'} />
					)}
				</Skeleton>
			</Card>
		</div>
	)
}

export default Appointments
