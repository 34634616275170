import React from "react"

import PageHeader from "../../components/PageHeader"

export default function FHIRExport() {


    return (
        <PageHeader
            title={"FHIR Exporter"}
            extra={
                <div className="topbar-wrapper">
                </div>
            }
        >
            <div>
                <h1>Fhir Exporter</h1>
            </div>
        </PageHeader>
    )
}
