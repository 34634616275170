import React, { useState, useEffect } from 'react'
import { Menu, Dropdown, Descriptions, Badge } from 'antd'

import Icon from '../../components/Icon'
import Table from '../../components/Table'
import Utils from '../../components/Utils'
import Button from '../../components/Button'
import Patient from '../../pages/Patient'
import Drawer from '../../components/Drawer'
import TaskEditor from '../../applets/TaskEditor'
import Symptom from '../../components/Symptom'
import ContentItem from '../../components/ContentItem'

import PerformanceChart from '../../components/charts/PerformanceChart'

import Backend from '../../api/Backend'

import './index.css'

import { LangContext } from '../../components/Translation'
import translations from './translations'

import { useGlobalContext } from '../../components/AppContext'

export default function AlarmsTable({
	clinic = null,
	patient = null,
	showPatientAction = true,
	goToPatient = null,
	active = false // display also non active patients, otherwise set to true
}) {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()
	const utils = new Utils()
	const { pushNotification } = useGlobalContext()

	const [alarms, setAlarms] = useState()
	const [openTaskId, setOpenTaskId] = useState()
	const [openPatientId, setOpenPatientId] = useState()
	const [patientResource, setPatientResource] = useState({})
	const [loading, setLoading] = useState(false)

	const refresh = ({ silent = false }) => {
		const mapper = data => {
			return data.map(d => {
				
				return {
					status: d.status,
					patient_pseudo_name: d?.patient_name?.toUpperCase(),
					name: d.patient_resource.name,
					surname: d.patient_resource.surname,
					birthdate: d.patient_resource.birthdate,
					patient_id: d.patient_id,
					symptom: d.resource?.symptom,
					interventions: d.interventions,
					severity: d.resource?.severity,
					task_id: d.task_id,
					duration: getDuration(d),
					score: d.resource?.score,
					type: d.resource?.type,
					alarm_created_at: d.alarm_created_at,
					first_changed_at: d.first_changed_at
				}
			})
		}

		if (clinic) {
			if (!silent) {
				setLoading(true)
			}
			backend.alarms({
				clinic,
				active,
				cb: data => {
					setAlarms(mapper(data))
					setLoading(false)
				}
			})
		} else if (patient) {
			if (!silent) {
				setLoading(true)
			}
			backend.alarms({
				patient,
				active,
				cb: data => {
					setAlarms(mapper(data))
					setLoading(false)
				}
			})
		}
	}

	useEffect(() => {
		if (pushNotification?.type === 'TASK') {
			refresh({ silent: true })
		}
	}, [pushNotification])

	useEffect(() => {
		refresh({ silent: false })
	}, [clinic])

	useEffect(() => {
		refresh({ silent: false })
	}, [patient])

	const getDuration = row => {
		return Math.round(utils.workingHoursBetweenDates(row.alarm_created_at, row.first_changed_at))
	}

	const renderSymptom = ({ row }) => {
		return <Symptom symptom={row.symptom} severity={row.severity} score={row.score} />
	}

	const renderInterventions = row => {
		const renderType = intervention => {
			if (intervention.type === 'CONTENT') {
				return (
					<React.Fragment key={intervention?.id}>
						<Descriptions.Item>{lang('content')}</Descriptions.Item>
						<Descriptions.Item>
							<ContentItem value={[intervention]} renderTag={true} />
						</Descriptions.Item>
						<Descriptions.Item>{utils.toDate({ dateString: intervention.createdAt })}</Descriptions.Item>
						<Descriptions.Item>{intervention.initiator}</Descriptions.Item>
					</React.Fragment>
				)
			} else if (intervention.type === 'HSP') {
				return (
					<React.Fragment key={intervention?.id}>
						<Descriptions.Item>{lang('hsp')}</Descriptions.Item>
						<Descriptions.Item>
							<ContentItem value={[intervention]} renderTag={false} iconType="support" />
						</Descriptions.Item>
						<Descriptions.Item>{utils.toDate({ dateString: intervention.createdAt })}</Descriptions.Item>
						<Descriptions.Item>{intervention.initiator}</Descriptions.Item>
					</React.Fragment>
				)
			} else {
				return (
					<React.Fragment key={intervention?.id}>
						<Descriptions.Item span={2}>{lang(intervention.type?.toLowerCase())}</Descriptions.Item>
						<Descriptions.Item>{utils.toDate({ dateString: intervention.createdAt })}</Descriptions.Item>
						<Descriptions.Item>{intervention.initiator}</Descriptions.Item>
					</React.Fragment>
				)
			}
		}
		return (
			<Descriptions title={lang('interventions')} column={{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 4, xs: 4 }}>
				{row.interventions.map(renderType)}
			</Descriptions>
		)
	}

	const renderDates = ({ value }) => {
		return utils.toDate({ dateString: value })
	}

	const renderDuration = ({ value }) => {
		return <PerformanceChart max={20} performance={value} unit={{ singular: lang('hour'), plural: lang('hours') }} />
	}

	const renderState = ({ value }) => {
		const color = value === 'COMPLETED' ? 'default' : value === 'READY' ? 'red' : 'blue'
		return <Badge status={color} />
	}

	const action = {
		GOTO_PATIENT: 'GOTO_PATIENT',
		VIEW_PATIENT: 'VIEW_PATIENT',
		VIEW_TASK: 'VIEW_TASK'
	}

	const renderActions = ({ row }) => {
		const handleMenuClick = e => {
			if (e.key === action.GOTO_PATIENT) {
				goToPatient(row.patient_id)
			} else if (e.key === action.VIEW_PATIENT) {
				setOpenPatientId(row.patient_id)
			}
		}

		const menu = (
			<Menu onClick={handleMenuClick}>
				{showPatientAction ? (
					<>
						<Menu.Item key={action.VIEW_PATIENT}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('view-patient')}
							</a>
						</Menu.Item>
						<Menu.Item key={action.GOTO_PATIENT}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('goto-patient')}
							</a>
						</Menu.Item>
					</>
				) : null}
			</Menu>
		)

		return (
			<Dropdown.Button
				onClick={() => {
					setOpenTaskId(row.task_id)
					setPatientResource({
						name: row.name,
						surname: row.surname,
						birthdate: row.birthdate
					})
				}}
				overlay={menu}>
				<Icon type="task"></Icon>
			</Dropdown.Button>
		)
	}

	const patientFullName = `${patientResource?.name} ${patientResource?.surname}`

	return (
		<>
			<Drawer
				placement="right"
				width="90%"
				onClose={() => {
					setOpenPatientId(undefined)
				}}
				open={openPatientId}
				text={{
					title: lang('patient'),
					cancel: lang('cancel')
				}}>
				<Patient id={openPatientId}></Patient>
			</Drawer>
			<Drawer
				open={openTaskId !== undefined}
				onClose={() => {
					setOpenTaskId(undefined)
				}}
				width={700}
				text={{ title: lang('task-details') }}>
				<TaskEditor
					birthdate={utils.toDate({ dateString: patientResource.birthdate, withoutTime: true })}
					patientFullName={patientFullName}
					taskId={openTaskId}
				/>
			</Drawer>
			<Table
				loading={loading}
				emptyText={lang('no-data')}
				title={lang('title')}
				expandable={{
					expandedRowRender: renderInterventions,
					defaultExpandAllRows: true,
					columnWidth: '20px',
					rowExpandable: row => row.interventions.length > 0
				}}
				columns={[
					{
						key: 'status',
						value: lang('status'),
						fixed: 'left',
						width: 30,
						render: renderState,
						sorter: (a, b) => (a.status ? a.status.localeCompare(b.status) : false)
					},
					{ key: 'patient_pseudo_name', value: lang('patient-id'), width: 40, search: true },
					{
						key: 'name',
						width: 40,
						value: lang('name'),
						sorter: (a, b) => a.name.localeCompare(b.name),
						search: true
					},
					{
						key: 'surname',
						width: 40,
						value: lang('surname'),
						sorter: (a, b) => a.surname.localeCompare(b.surname),
						search: true
					},
					{
						key: 'birthdate',
						width: 50,
						value: lang('date-of-birth'),
						render: ({ value }) => utils.toDate({ dateString: value, withoutTime: true })
					},
					{ key: 'symptom', value: lang('symptom'), width: 100, render: renderSymptom },
					{
						key: 'alarm_created_at',
						value: lang('created-at'),
						width: 50,
						render: renderDates,
						sorter: (a, b) => new Date(a.alarm_created_at) - new Date(b.alarm_created_at)
					},
					{ key: 'duration', value: lang('duration'), width: 50, render: renderDuration, sorter: (a, b) => a.duration > b.duration },
					{ key: 'actions', value: lang('actions'), width: 50, fixed: 'right', render: renderActions }
				]}
				data={alarms}
				buttons={
					<Button
						type="refresh"
						onClick={() => {
							refresh({ silent: false })
						}}></Button>
				}
			/>
		</>
	)
}
