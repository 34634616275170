import React, { useState, useEffect } from 'react'

import Backend from '../../api/Backend'

import Tag from '../../components/Tag'
import Utils from '../../components/Utils'
import Description from '../../components/Description'
import Table from '../../components/Table'

import { LangContext } from '../../components/Translation'

import './index.css'

const Assessment = ({ requestId, assessment }) => {
	const lang = React.useContext(LangContext)()
	const backend = new Backend()
	const utils = new Utils()

	const [scores, setScores] = useState([])
	const [answers, setAnswers] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (requestId) {
			setLoading(true)
			backend.getPatientQuestionnaireScores({
				requestId,
				cb: (data) => {
					setScores(data)
					setLoading(false)
				}
			})

			backend.getPatientQuestionnaireAnswers({
				requestId,
				cb: (data) => {
					setAnswers(data)
					setLoading(false)
				}
			})
		}
	}, [requestId])

	const getInfo = (data) => {
		if (data && data.length > 0) {
			const d = data[0]
			return [
				{
					key: lang('requested'),
					value: ` ${utils.toDate({ dateString: d.created_at })}`
				},
				{
					key: lang('received'),
					value: ` ${utils.toDate({ dateString: d.updated_at })}`
				},
				{
					key: lang('title'),
					value: ` ${d.title}`
				},
				{
					key: 'Status',
					value: <Tag type={d.status}>{d.status}</Tag>
				}
			]
		} else if (assessment) {
			return [
				{
					key: lang('requested'),
					value: ` ${utils.toDate({ dateString: assessment.created_at })}`
				},
				{
					key: lang('received'),
					value: ` ${utils.toDate({ dateString: assessment.created_at })}`
				},
				{
					key: lang('title'),
					value: `${assessment.title}`
				},
				{
					key: 'Status',
					value: <Tag type={assessment.status}>{assessment.status}</Tag>
				}
			]
		}
		return []
	}

	const getScores = (scores) => {
		return scores && scores.length > 0 ? scores[0].score : []
	}

	return (
		<div className="container-xl">
			<div className="row row-deck row-cards">
				<Description
					data={getInfo(scores)}
					loading={loading}
					emptyText={lang('no_data')}
					bordered
					title={lang('base_data')}
					layout="horizontal"
					column={{ md: 1 }}></Description>
				<div className="col-sm-12 col-lg-12 p10">
					<Table
						columns={[
							{ key: 'key', value: lang('score') },
							{ key: 'value', value: lang('value') }
						]}
						data={getScores(scores)}
						title={lang('scores')}
					/>
				</div>
				<div className="col-sm-12 col-lg-12 p10">
					<Table
						columns={[
							{
								key: 'question',
								value: lang('question'),
								render: ({ value, row }) => {
									return <div style={{ marginLeft: `${(row.level - 1) * 15}px` }}>{value}</div>
								}
							},
							{
								key: 'answers',
								value: lang('answer'),
								render: ({ value }) => {
									if (value[0] === 'TRUE' || value[0] === 'FALSE') {
										return value.map((v) => (
											<Tag key={v} color={value[0] === 'TRUE' ? 'green' : 'red'} value={v}>
												{v}
											</Tag>
										))
									} else if(value[0] === -8888) {
										return lang('not-specified')
									} else {
										return value.map((v) => {
											if (value?.length > 1) {
												return (
													<>
														<div>
															{v}
															{'.'} <br />
														</div>
													</>
												)
											}  else {
												return v
											}
										})
									}
								}
							}
						]}
						data={answers}
						title={lang('answers')}
					/>
				</div>
			</div>
		</div>
	)
}

export default Assessment
