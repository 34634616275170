import React, { useState } from 'react'
import { LangContext } from '../../components/Translation'
import translations from './translations'
import PageHeader from '../../components/PageHeader'
import PageContent from '../../components/PageContent'
import { Card, Button, Form, Input, Select } from 'antd'
import { toast } from 'react-toastify'
import Backend from '../../api/Backend'
import { useGlobalContext } from '../../components/AppContext'

const SEVERITY = {
	MEDIUM: 'MEDIUM',
	CRITICAL: 'CRITICAL'
}

export default function BugReport() {
	const lang = React.useContext(LangContext)(translations)
	const [bugReportForm] = Form.useForm()
	const { globals } = useGlobalContext()
	const backend = new Backend()

	const [submitted, setSubmitted] = useState(false)

	const required = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const submitForm = () => {
		bugReportForm.validateFields().then((values) => {
			const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
			let reqBody = JSON.stringify({ ...values, ...globals })
			backend.bugReport({
				body: reqBody,
				cb: () => {
					toast.update(loadingToast, {
						render: lang('report-submitted'),
						type: 'success',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
					setSubmitted(true)
				},
				cbError: () => {
					toast.update(loadingToast, {
						render: lang('an-error-has-occured'),
						type: 'error',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
				}
			})
		})
	}

	return (
		<PageHeader title={lang('report-a-bug')} extra={<div className="topbar-wrapper"></div>}>
			<PageContent>
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-12 col-lg-12">
							<Card style={{ padding: '2rem' }}>
								{!submitted ? (
									<>
										<div style={{ paddingBottom: '2rem' }}>{lang('bug-report-instructions')}</div>
										<div>
											<Form form={bugReportForm} initialValues={{ severity: SEVERITY.MEDIUM }} layout="vertical">
												<Form.Item label={lang('bug-title')} name="title" rules={required}>
													<Input />
												</Form.Item>
												<Form.Item label={lang('bug-description')} name="description" rules={required}>
													<Input.TextArea rows={4} />
												</Form.Item>
												<Form.Item label={lang('bug-severity')} name="severity">
													<Select value={SEVERITY.MEDIUM} style={{ width: 120 }}>
														<Select.Option value={SEVERITY.MEDIUM}>{lang('medium')}</Select.Option>
														<Select.Option value={SEVERITY.CRITICAL}>{lang('critical')}</Select.Option>
													</Select>
												</Form.Item>
											</Form>
										</div>
										<Button type="primary" onClick={submitForm}>
											{lang('submit')}
										</Button>
									</>
								) : (
									<>
										<div style={{ paddingBottom: '2rem' }}>{lang('bug-report-thanks')}</div>
										<Button
											type="primary"
											onClick={() => {
												setSubmitted(false)
											}}>
											{lang('report-another-bug')}
										</Button>
									</>
								)}
							</Card>
						</div>
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}
