import React, { useState, useEffect } from 'react'

import Backend from '../../api/Backend'
import Select from '../Select'

import { LangContext } from '../Translation'
import translations from './translations'
import { UIConstants } from '../Utils'

export default function ClinicSelect({
	defaultIsAll = true,
	showAllOption = true,
	onAfterInit = () => {},
	onChange = () => {}
}) {
	const lang = React.useContext(LangContext)(translations)

	const backend = new Backend()
	const [clinicOptions, setClinicOptions] = useState()
	const allOption = { key: UIConstants.ALL, value: lang('all') }

	const getDefaultOption = ({ options = clinicOptions }) => {
		return defaultIsAll ? [allOption] : options ? options.slice(0,1) : null
	}

	useEffect(() => {
		backend.clinics({
			cb: (data) => {
				const options = Array.isArray(data)
					? data.map((d) => {
							return { key: d.id, value: d.clinic_name }
					  })
					: []
				if (options.length > 0 && showAllOption) {
					options.push(allOption)
				}
				if (options.length > 0 && !showAllOption) {
				onAfterInit(getDefaultOption({ options }))
				}
				setClinicOptions(options)
			},
		})
	}, [])

	const defaultOption = getDefaultOption({})
	// TODO: make select display loading icon instead of not rendering
	return (
		defaultOption || (showAllOption && defaultIsAll) ? 
		<Select
			overlap={true}			
			title={lang('clinic')}
			defaultOptions={defaultOption}
			options={clinicOptions}
			onChange={onChange}
			closeMenuOnSelect={true}
			style={{ maxWidth: 'fit-content' }}></Select>
		: null
	)
}
