import React, { useEffect } from 'react'
import Countly from 'countly-sdk-web'
import { useLocation } from 'react-router-dom'

const CountlyServer = () => {

	//Removed window.Countly = Countly

	Countly.init({
		app_key: process.env.REACT_APP_COUNTLY_APP_KEY,
		url: process.env.REACT_APP_COUNTLY_URL,
		require_consent: false
	})

	Countly.q.push([
		'group_features',
		{
			activity: ['sessions', 'events', 'views', 'location'],
			interaction: ['scrolls', 'clicks', 'crashes'],
			whereabouts: ['users']
		}
	])

	Countly.q.push(['track_sessions'])
	Countly.q.push(['track_pageview'])
	Countly.q.push(['track_scrolls'])
	Countly.q.push(['track_clicks'])
	Countly.q.push(['track_links'])
	Countly.q.push(['track_errors'])
}

const CountlyReactErrors = (error) => {
	Countly.init({
		app_key: process.env.REACT_APP_COUNTLY_APP_KEY,
		url: process.env.REACT_APP_COUNTLY_URL,
		require_consent: false
	})

	Countly.q.push(['track_errors'])

}

const Location = ({ children }) => {
	const location = useLocation()

	useEffect(() => {
		Countly.q.push(['track_pageview', location.pathname])

		Countly.q.push(['initialize_feedback_popups'])
	}, [location])

	return <>{children}</>
}

export { CountlyServer, Location, CountlyReactErrors }
