import React from 'react'

import { Menu,Button, Dropdown as ADropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export default function Dropdown({
	text = "Select",
	type = "ghost",
	options = [],
	onClick = () => { }
}) {

	const menu = options ? (
		<Menu onClick={onClick}>
			{
				options.map((option) => {
					return <Menu.Item key={option.key}>{option.value}</Menu.Item>
				})
			}
		</Menu>) : null

	const Dropdown = () => {
		switch (type) {
			case 'ghost':
				return (
					<ADropdown overlay={menu}>
						<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
							{text} <DownOutlined />
						</a>
					</ADropdown>

				)
			case 'button':
				return (
					<ADropdown overlay={menu}>
						<Button>
							{text} <DownOutlined />
						</Button>
					</ADropdown>
				)

			default:
				return (
					<span>ERROR</span>
				);
		}
	}

	return (
		<Dropdown />
	)
}
