import * as d3 from 'd3'
import Utils from '../../charts/Utils'
import React, { useRef, useEffect } from 'react'
import './index.css'

/* 
    Intended for miniature linecharts of the patient symptomschart only
*/
function SimpleLineChart(props) {
	const ref = useRef()
	let svg, x, y

	useEffect(() => {
		// component did mount
		draw(props.patientData, Utils.mapGroupData(Utils.getSumstat(props.groupData)))
	}, [])

	useEffect(() => {
		// component got new props
		update(props.patientData, Utils.mapGroupData(Utils.getSumstat(props.groupData)))
	}, [props.patientData, props.groupData])

	var margin = { top: 2, right: 2, bottom: 2, left: 2 },
		width = props.width - margin.left - margin.right,
		height = props.height - margin.top - margin.bottom

	const maxNumValues = props.kpi.max

	let domain = props.groupData.map(d => d.series)

	x = d3
		.scaleBand()
		.range([0, width])
		.domain(domain)
		.paddingInner(1)
		.paddingOuter(0.5)

	y = d3
		.scaleLinear()
		.domain([0, maxNumValues])
		.range([height, 0])

	const line = d3
		.line()
		.x(function(d) {
			return x(d.series)
		})
		.y(function(d) {
			return y(d.value)
		})
	function getSvg() {
		return d3.select(ref.current).select('svg g')
	}

	function isMinPositive() {
		return props.kpi.positive === 'min'
	}

	function drawPatientLine(svg, data) {
		svg.append('path')
			.data([data])
			.attr('class', 'simple-linechart-line patient')
			.attr('stroke', 'url(#judgementGradientSimple_' + props.kpi.key + ')')
			.attr('strokeLinejoin', 'round')
			.attr('stroke-linecap', 'round')
			.attr('d', line)
	}

	function drawGroupLine(svg, data) {
		svg.append('path')
			.data([data])
			.attr('class', 'simple-linechart-line group')
			.attr('strokeLinejoin', 'round')
			.attr('stroke-linecap', 'round')
			.attr('d', line)
	}

	const draw = (patientData, groupData) => {
		svg = d3
			.select(ref.current)
			.append('svg')
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')

		const color = d3.scaleSequential(y.domain(), d3.interpolateRdYlGn)
		const svgDefs = svg.append('defs')
		const gradHeight = height - margin.bottom
		const gradTop = margin.top
		svgDefs
			.append('linearGradient')
			.attr('id', 'judgementGradientSimple_' + props.kpi.key)
			.attr('gradientUnits', 'userSpaceOnUse')
			.attr('x2', 0)
			.attr('y2', isMinPositive() ? gradHeight : gradTop)
			.attr('x1', 0)
			.attr('y1', isMinPositive() ? gradTop : gradHeight)
			.selectAll('stop')
			.data(d3.ticks(0, 1, 4))
			.join('stop')
			.attr('offset', d => d)
			.attr('stop-color', color.interpolator())

		drawGroupLine(svg, groupData)
		drawPatientLine(svg, patientData)
	}

	const update = (patientData, groupData) => {
		const svg = getSvg()
		svg.selectAll('.simple-linechart-line').remove()
		drawGroupLine(svg, groupData)
		drawPatientLine(svg, patientData)
	}

	return (
		<div className="simple-chart">
			<div ref={ref}></div>
		</div>
	)
}

export default SimpleLineChart
