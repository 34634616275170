import React from 'react'
import { Collapse } from 'antd';

import List from '../../../components/List'

import moment from 'moment'

import { LangContext } from '../../../components/Translation'
import translations from './translations'
import { StatusLabel } from '../../../applets/TaskBoard'
import Utils from '../../../components/Utils' 

import './index.css'

const { Panel } = Collapse;

export default function ChangeLog({
	className = '',
	data
}) {
	const utils = new Utils()

	const lang = React.useContext(LangContext)(translations)

	const changeLog = Array.isArray(data)
		? data.sort((a, b) => moment(b.changed_at).unix() - moment(a.changed_at).unix()).map(d => {
				let data = d["changed_byPersonalData"] || {}
				let changedBy = utils.getFullDisplayName({ data })
				changedBy = changedBy === '' ? d.changed_by : changedBy
				data = d.resource["assignedToPersonalData"] || {}
				const assignedTo = utils.getFullDisplayName({ data })
				return {
					title: <div><div>{utils.toDate({ dateString: d.changed_at })}</div><div>{ changedBy ? `${lang("changed-by")} ${ changedBy}` : '' }</div></div>,
					content: (<div><div>{lang("assigned-to")}</div><div>{ assignedTo }</div></div>),
					description: `${lang("status")} ${StatusLabel(d.status)}`,
					id: d.id,
				}
		  })
		: []

	const changeLogFirst = changeLog.length > 0 ? [changeLog.shift()] : []

	return (
		<div className={`${className} change-log ant-card`}>
			<div className="col-md-12 col-xl-12">
					<div className="">
						<label className="form-label">{lang('title')}</label>
						<List data={changeLogFirst} pagination={null}/>
						{ changeLog.length > 0 ?
							<Collapse ghost >
								<Panel header={lang('show-more')} key="1">
									<List data={changeLog} />
								</Panel>
							</Collapse>
							: null }
					</div>
				</div>
		</div> 
	)
}
