import React from 'react'
import moment from 'moment'
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker

const DateTimePicker = ({
	rangePicker,
	showTime,
	disabledPrevDays = false,
	disabledFutureDays = false,
	onChange,
	disabled,
	defaultValue,
	format = 'DD.MM.YYYY'
}) => {
	const filterDates = current => {
		let res = disabledPrevDays ? moment().add(-1, 'days') >= current : false
		res = disabledFutureDays ? moment() <= current : res
		return res
	}
	const parsedDefaultValue = defaultValue ? moment(new Date(defaultValue), format) : undefined
	const renderDatepicker = (rangePicker, onChange, defaultValue) => {
		return rangePicker ? (
			<RangePicker
				onChange={onChange}
				getPopupContainer={triggerNode => {
					return triggerNode.parentNode
				}}
			/>
		) : (
			<DatePicker
				disabled={disabled}
				onChange={onChange}
				format={format}
				getPopupContainer={triggerNode => {
					return triggerNode.parentNode
				}}
				defaultValue={parsedDefaultValue}
				showTime={showTime}
				disabledDate={filterDates}
			/>
		)
	}
	const datepicker = renderDatepicker(rangePicker, onChange, defaultValue)
	return datepicker
}

export default DateTimePicker
