import { toast } from 'react-toastify'
import { assignForm } from '../../api-new'

const OnboardPatientRequestData = async ({
	backend,
	form,
	newPatientData,
	lang,
	DEFAULT_TOAST_TIMER = 5000,
	setStep,
	setIsHttpsRequestInProgress,
	completion
}) => {
	await form.validateFields()
	let fields = form.getFieldsValue()

	const data = {
		...newPatientData,
		...(fields.intervention ? { intervention_group: fields.intervention } : { risk_group: fields.riskAssessment }),
		replaceLocalUserIdWithKeycloakUserId: true
	}

	const loadingToast = toast.loading(lang('request_processing'), { closeOnClick: true })

	const body = JSON.stringify(data)

	backend.patients({
		type: backend.type.CREATE,
		isOnboarding: true,
		body,
		cb: (data) => {
			try {
				if (process.env.REACT_APP_PROJECT_ID === 'nctol') {
					assignForm(data.userId, 'ONBOARDING')
					assignForm(data.userId, 'THERAPY_MANAGEMENT')
				}
			} catch (error) {
				console.log(error)
			}
			toast.update(loadingToast, {
				render: lang('finish_and_exit'),
				type: 'success',
				isLoading: false,
				closeOnClick: true,
				autoClose: DEFAULT_TOAST_TIMER
			})
			setIsHttpsRequestInProgress(false)
			if (completion) {
				completion(data.userId)
			}
			setStep(1)
		},
		cbError: () => {
			toast.update(loadingToast, {
				render: lang('processing_error'),
				type: 'error',
				isLoading: false,
				closeOnClick: true,
				autoClose: DEFAULT_TOAST_TIMER
			})
			setIsHttpsRequestInProgress(false)
		}
	})
}

export default OnboardPatientRequestData
