import { syncScrypt } from 'scrypt-js'

const scryptOptions = {
	iterationsCount: 16384,
	blockSize: 8,
	parallelismFactor: 1,
	aesKeyLength: 16
}

export async function encryptMessage(keyString, data) {
	// TODO: Generate iv and salt
	const iv = generageRandomBytes(scryptOptions.aesKeyLength)
	const salt = generageRandomBytes(scryptOptions.aesKeyLength)
	const key = await importKey(
		keyString,
		salt,
		scryptOptions.iterationsCount,
		scryptOptions.blockSize,
		scryptOptions.parallelismFactor,
		scryptOptions.aesKeyLength
	)
	const encryptedMessage = await crypto.subtle.encrypt({ name: 'AES-GCM', iv: iv }, key, data)
	const base64Message = arrayBufferToBase64(encryptedMessage)
	return { data: base64Message, options: { ...scryptOptions, iv: iv, salt: salt } }
}

export async function decryptMessage(keyString, message, derivation, options) {
	const key = await importKey(keyString, options.salt, options.iterationsCount, options.blockSize, options.parallelismFactor, options.aesKeyLength)
	const decrypted = await crypto.subtle.decrypt(
		{ name: 'AES-GCM', iv: Uint8Array.from(options.iv), tagLength: options.aesKeyLength * 8 },
		key,
		base64ToArrayBuffer(message)
	)
	return decrypted
}

export function importKey(key, salt, N, r, p, dkLen) {
	var enc = new TextEncoder()
	const keyData = syncScrypt(enc.encode(key), salt, N, r, p, dkLen).buffer
	return crypto.subtle.importKey('raw', keyData, { name: 'AES-GCM' }, false, ['encrypt', 'decrypt'])
}

function generageRandomBytes(length) {
	const randomArray = new Uint8Array(length)
	crypto.getRandomValues(randomArray)
	return randomArray
}

function base64ToArrayBuffer(base64) {
	var binaryString = window.atob(base64)
	var bytes = new Uint8Array(binaryString.length)
	for (var i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i)
	}
	return bytes.buffer
}

function arrayBufferToBase64(buffer) {
	var binary = ''
	var bytes = new Uint8Array(buffer)
	var len = bytes.byteLength
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i])
	}
	return window.btoa(binary)
}
