import React from 'react'

import Utils from '../Utils'
import Tag from '../Tag'

export default function Patient(props) {
	const utils = new Utils()

	const tagsData = Array.isArray(props.data)
		? props.data.map((d) => {
				const tagStudyGroup = utils.isStudyGroupRPA(d.study_group) ? 'Mit RPA' : null
				const tagActive = d.status === 'PARTICIPATION' ? 'Aktiv' : null
				return [tagActive, tagStudyGroup]
		  })
		: []

	return tagsData.map((txt, idx) => {
		return (
			<Tag key={idx + txt} color={idx}>
				{txt}
			</Tag>
		)
	})
}
