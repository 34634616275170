import React, { useState, useEffect } from 'react'

import Table from '../../components/Table'
import { LangContext } from '../../components/Translation'
import translations from './translations'

import { Card } from 'antd'
import CsvDownloader from 'react-csv-downloader'

import Backend from '../../api/Backend'
import Utils from '../../components/Utils'
import Button from '../../components/Button'

const RecruitementPerClinic = ({ title, selectedClinic }) => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()

	const [recruitment, setRecruitment] = useState([])
	const [clinics, setClinics] = useState([])
	const [recruitmentStatistics, setRecruitmentStatistics] = useState()

	useEffect(() => {
		backend.recruitment({ statusCount: true, clinic: selectedClinic, cb: setRecruitment })
		backend.recruitment({ recruitmentStatistics: true, clinic: selectedClinic, cb: (statistics) => {

			setRecruitmentStatistics(
				statistics.map(statistic => {
					
					return {
						date: statistic.month,
						intervention_group: statistic.intervention_group,
						control_group: statistic.control_group,
						total: statistic.total
					}
				})
			)
			
		} })
		backend.clinics({ cb: setClinics })
	}, [selectedClinic])

	const renderDate = ({ row }) => {
		const date = utils.toDate({ dateString: row.ts })
		return <label style={{ marginLeft: '5px' }}>{date}</label>
	}


	const data =
		clinics.length > 0 && recruitment.length > 0
			? recruitment.map((c) => {
					 const clinic = clinics.filter((clinic) => clinic.id === c.clinic)
					return {
						clinic: clinic && clinic.length > 0 ? clinic[0].clinic_name : '',
						status: lang(c.status.toLowerCase()),
						count: c.count
					}
			  })
			: null


		

	const statusSum = recruitment.reduce((prev, next) => prev + Number(next.count), 0 )



	return (
		<Card bordered={false}>
			<h3 className="card-title">{title}</h3>
			<Table
				columns={[
					{ key: 'status', value: lang('status') },
					{ key: 'count', value: lang('number_of_patients') }
				]}
				emptyText={lang('no-data')}
				data={data}
				tableTitle={() => clinics.filter((cl) => cl.id == selectedClinic).map((selClinic) => selClinic.clinic_name)}
				footer={() => lang('status_sum') + ': ' + statusSum}
			/>

			<CsvDownloader datas={recruitmentStatistics} separator=';' filename={'recruitment.csv'}>
				<h3 className="m15 ml20">{lang('center-inclusion-per-month')}</h3>
				<Button disabled={recruitmentStatistics?.length === 0} className="mt15 ml20" type="save">
					{lang('xlsx-download')}
				</Button>
			</CsvDownloader>
		</Card>
	)
}

export default RecruitementPerClinic
