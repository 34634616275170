import NotificationBubble from '../NotificationBubble';
import React, { useState } from 'react'
import './index.css'

export default function PageNavbar(props) {
	const [selected, setSelected] = useState(props.default ?? {})
	const { alarms } = props;


	const handleItemClick = (item) => {
		setSelected(item)
		props.onChange(item)
	}

	const isItemActive = (item) => {
		return selected.key === item.key
	}

	const items = props.menu
		? props.menu.map((item) => {
				return (
					<li
						key={item.key}
						className={`nav-item ${isItemActive(item) ? 'active' : null}`}>
						<div
							className="nav-link"
							onClick={() => {
								handleItemClick(item)
							}}>
							{item.icon ? <span>{item.icon}</span> : null}

							{item.title === 'Alarms' ? (
								<span className="nav-link-title">
									{item.title}

									{alarms.length === 0 ? (
										null
									) : (
										<NotificationBubble alarms={alarms} />
									)}
								</span>
							) : (
								<span className="nav-link-title">{item.title}</span>
							)}
						</div>
					</li>
				)
		  })
		: null

	const content = items ? (
		<header className="page-navbar navbar navbar-expand-md navbar-light d-print-none">
			<div className="container-xl">
				<div className="collapse navbar-collapse" id="navbar-menu">
					<div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center navTitleContainer">
						<ul className="navbar-nav">{items}</ul>
					</div>
				</div>
			</div>
		</header>
	) : null

	return content
}
