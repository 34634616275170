import React, { useState } from 'react';
import "./index.css"

export default function SelectionList(props) {

  const [selected, setSelected] = useState(props.default || []);

  function handleChange(opt){
    const change = opt.target.getAttribute('value');
    const update = selected.includes(change) ? selected.filter(v => v!== change) : [...selected, change];
    setSelected(update);
    props.onChange(update);
  }

  const options = props.options.map((p)=>{
    return (<label key={p.key} className="form-selectgroup-item flex-fill">
              <input type="checkbox" name="form-project-manager[]"  className="form-selectgroup-input" value={p.key} onChange={ handleChange } checked={ selected.includes(p.key) }/>
              <div className="form-selectgroup-label d-flex align-items-center p-3">
                <div className="me-3">
                  <span className="form-selectgroup-check"></span>
                </div>
                <div className="form-selectgroup-label-content d-flex align-items-center">                
                  <div>
                    <div className="font-weight-medium">{ p.value }</div>
                    <div className="text-muted">{ p.subvalue }</div>
                  </div>
                </div>
              </div>
            </label>);
  })

  return (
    <div className="selection-list">
      { props.title ? <label className="form-label">{ props.title }</label> : null }
      <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
        { options }
      </div>
    </div>
  )
}