import React, { useState, useEffect } from 'react'
import Topbar from '../../../components/Topbar'
import ClinicSelect from '../../../components/ClinicSelect'
import PatientSelect from '../../../components/PatientSelect'
import Table from '../../../components/Table'
import LaboritoryFindings from '../../../applets/onko/LaboratoryFindings'

import Utils from '../../../components/Utils'
import Backend from '../../../api/Backend'

import { LangContext } from '../../../components/Translation'
import translations from './translations'
import { Card } from 'antd';


const LaboratoryValues = () => {
	const lang = React.useContext(LangContext)(translations)

	const [selectedClinic, setSelectedClinic] = useState()
	const [selectedPatient, setSelectedPatient] = useState()
	const [selectedLaboritoryFinding, setSelectedLaboritoryFinding] = useState()
	const [patientLabValues, setPatientLabValues] = useState([])

	const backend = new Backend()
	const utils = new Utils()

	useEffect(() => {
		if (selectedLaboritoryFinding) {
			backend.laboratoryValues({ id: selectedLaboritoryFinding.id, cb: setPatientLabValues })
		}
	}, [selectedLaboritoryFinding])

	useEffect(() => {
		setPatientLabValues([])
		setSelectedLaboritoryFinding()
	}, [selectedPatient])
	

	const handleClinicChange = (selected) => {
		setSelectedClinic(selected.value)
	}

	const handlePatientChange = (selected) => {
		setSelectedPatient(selected.key)
	}

	const topItemStyle = {
		display: 'inline-grid',
		marginRight: '20px',
	}

	return (
		<div className="page-wrapper">
			<Topbar
				froozen="true"
				content={
					<div className="topbar-wrapper">
						<div className="mb-3" style={topItemStyle}>
							<ClinicSelect onChange={handleClinicChange} />
						</div>
						<div className="mb-3" style={topItemStyle}>
							<PatientSelect
								onChange={handlePatientChange}
								selectedClinic={selectedClinic}
								optionAll={false}
								studyGroup={1}
							/>
						</div>
					</div>
				}>
				<div className="page-body">
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<div className="col-sm-12 col-lg-12">
								<div className="card">
									<div className="col-sm-12 col-lg-12">
										<LaboritoryFindings
											patient={selectedPatient}
											onSelect={setSelectedLaboritoryFinding}
										/>
									</div>
								</div>
							</div>
							<div className="col-sm-12 col-lg-12">
								<Card bordered={false}>
									<Table
										borderless={false}
										headless={false}
										expandable={true}
										columns={[
											{ key: 'name', value: 'name', fixed: "left" },
											{ key: 'unit', value: 'unit', fixed: "left" },
											{ key: 'value', value: 'value', fixed: "left" },
											{ key: 'value_from', value: 'value_from' },
											{ key: 'value_till', value: 'value_till' },
											{ key: 'grade_1_from', value: 'grade_1_from' },
											{ key: 'grade_1_till', value: 'grade_1_till' },
											{ key: 'grade_2_from', value: 'grade_2_from' },
											{ key: 'grade_2_till', value: 'grade_2_till' },
											{ key: 'grade_3_from', value: 'grade_3_from' },
											{ key: 'grade_3_till', value: 'grade_3_till' },
											{ key: 'grade_4_from', value: 'grade_4_from' },
											{ key: 'grade_4_till', value: 'grade_4_till' },
											{ key: 'category', value: 'category' },
											{
												key: 'norm_value_from',
												value: 'norm_value_from',
												fixed: "right",
											},
											{
												key: 'norm_value_till',
												value: 'norm_value_till',
												fixed: "right",
											},
										]}
										data={patientLabValues}
										title={
											!selectedLaboritoryFinding
												? lang('laboratory_values')
												: lang('laboratory_values_of', {
													date: utils.toDate({
														dateString:
															selectedLaboritoryFinding.created_at,
													}),
												})
										}
									/>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</Topbar>
		</div>
	)
}

export default LaboratoryValues
