import React, { useContext, useEffect, useState, useLayoutEffect } from 'react'
import { Result, Form, Input, Divider, Tag, Card, Checkbox, Steps, Button as AButton, Radio } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { populateHoursArray } from '../HealthServiceProviders'
import { useGlobalContext } from '../../components/AppContext'
import { LangContext } from '../../components/Translation'
import translations from './translations'
import Backend from '../../api/Backend'
import Drawer from '../../components/Drawer'
import OpeningHours from '../../components/OpeningHours'
import Button from '../../components/Button'
import MediaLibrary from '../../applets/MediaLibrary'
import DecisionModal from '../../components/DecisionModal'
import Skeleton from '../../components/Skeleton'
import Thumbnail from '../../components/Thumbnail'
import { toast } from 'react-toastify'
import PageHeader from '../../components/PageHeader'
import Icon from '../../components/Icon'
import { CMS_ENDPOINT } from '../CMS'
import { CategorySelectCard } from '../../components/CategorySelector/CategorySelector'

import './index.css'
import PageContent from '../../components/PageContent'

const { Step } = Steps

const STEPPER = {
	WELCOME: 0,
	CONSENT: 1,
	PROFILE_TYPE: 2,
	EDIT_PROFILE: 3,
	PUBLISH_PROFILE: 4,
	DEBRIEFING: 5,
	EXIT: 999
}

const STATUS = {
	invited: 'INVITED',
	onboarding: 'ONBOARDING',
	active: 'ACTIVE',
	inactive: 'INACTIVE',
	cancelled: 'CANCELLED'
}

export const CATEGORY_TYPES = {
	HSP: 'hsp-categories',
	CONTENT: 'content-categories'
}

const NotFound = () => {
	const lang = React.useContext(LangContext)(translations)
	return <Result status="error" icon={<CloseCircleOutlined />} title={lang('message')} />
}

const EditHSPForm = ({
	HSP,
	handleStepSwitch,
	HSPDashboardStepper,
	HSPForm,
	loading,
	setRefreshRequired,
	selectedThumbnailUrl,
	setSelectedThumbnailUrl,
	PreviousButtonContent
}) => {
	const lang = useContext(LangContext)(translations)
	const backend = new Backend()
	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)

	const [requestedHSPCategories, setRequestedHSPCategories] = useState([])
	const [requestedContentCategories, setRequestedContentCategories] = useState([])

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	useEffect(() => {
		if (HSP) {
			setRequestedHSPCategories(HSP.requested_hsp_categories)
			setRequestedContentCategories(HSP.requested_content_categories)
		}
	}, [HSP])

	const handleStatusColor = (status) => {
		switch (status) {
			case STATUS.active:
				return 'green'
			case STATUS.inactive:
				return 'orange'
			case STATUS.cancelled:
				return 'red'
			case STATUS.onboarding:
				return 'cyan'
			case STATUS.invited:
				return 'lime'
			default:
				return 'gray'
		}
	}

	const handleSaveHSP = (data) => {
		HSPForm.validateFields()
			.then((values) => {
				let hspCategories =
					requestedHSPCategories &&
					requestedHSPCategories.map((category) => {
						return {
							id: category.id
						}
					})
				let contentCategories =
					requestedContentCategories &&
					requestedContentCategories.map((category) => {
						return {
							id: category.id
						}
					})
				const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
				let newBody = {
					name: values.HP_name,
					website: values.HP_website,
					street: values.street,
					streetnr: values.streetnr,
					zip: values.zip,
					city: values.city,
					phone: values.phone,
					service: values.service,
					summary: values.summary,
					available: populateHoursArray(
						values.sunday_hours,
						values.monday_hours,
						values.tuesday_hours,
						values.wednesday_hours,
						values.thursday_hours,
						values.friday_hours,
						values.saturday_hours
					),
					requested_hsp_categories: hspCategories,
					requested_content_categories: contentCategories,
					image: values?.new_display_picture ? values.new_display_picture.id : data?.image?.id
				}

				backend.healthProvider({
					id: data.id,
					type: backend.type.UPDATE,
					body: JSON.stringify(newBody),
					cb: () => {
						toast.update(loadingToast, {
							render: lang('hsp-updated'),
							type: 'success',
							isLoading: false,
							closeOnClick: true,
							autoClose: 5000
						})
						setRefreshRequired((refreshRequired) => refreshRequired + 1)
					},
					cbError: () => {
						toast.update(loadingToast, {
							render: lang('hsp-update-failed'),
							type: 'error',
							isLoading: false,
							closeOnClick: true,
							autoClose: 5000
						})
					}
				})
			})
			.catch((err) => toast.error(err.errorFields[0].errors[0]))
	}

	return (
		<PageHeader
			title={lang('hsp')}
			description={
				<div className="hsp-status-container">
					<div className="ant-form-item-label">
						<strong>{lang('profile-status')}</strong>
					</div>
					<Tag color={handleStatusColor(HSP?.status)}>
						{HSP?.status && HSP?.status === STATUS.inactive
							? lang('waiting-for-review')
							: HSP?.status === STATUS.onboarding
							? lang('fill-out-profile')
							: HSP?.status}
					</Tag>
				</div>
			}
			extra={
				<div style={{ display: 'flex' }}>
					<div className="button-container">
						{HSP && !HSP?.wizard_complete && (
							<>
								<Button type="primary" onClick={() => handleStepSwitch(STEPPER.PROFILE_TYPE)}>
									<PreviousButtonContent />
								</Button>
								<Button
									onClick={() => {
										handleSaveHSP(HSP)
										handleStepSwitch(STEPPER.PUBLISH_PROFILE)
									}}
									danger
									type="save"
									txt={lang('review-changes')}></Button>
							</>
						)}
						{
							HSP && HSP.wizard_complete && <Button onClick={() => handleSaveHSP(HSP)} type="save" txt={lang('save')}></Button>
						}
						
					</div>
				</div>
			}>
			<PageContent>
				<Skeleton hide={!loading}>
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<div className="col-sm-12 col-lg-12">
								<Card>
									<HSPDashboardStepper />
									<Form form={HSPForm} initialValues={HSP} layout="vertical">
										<div className="thumbnail-container">
											<div>
												<div className="ant-col ant-form-item-label">
													<label htmlFor="thumbnail" className="ant-form-item-required">
														{lang('thumbnail')}
													</label>
												</div>
												<Form.Item name="new_display_picture" hidden>
													<Input type="hidden" />
												</Form.Item>
												<br />
												<Thumbnail
													url={
														selectedThumbnailUrl
															? selectedThumbnailUrl
															: HSP?.image?.formats
															? `${CMS_ENDPOINT}` + HSP?.image?.formats?.thumbnail?.url
															: HSP?.image?.url
															? `${CMS_ENDPOINT}` + HSP?.image?.url
															: null
													}
												/>
											</div>
											<div>
												<Button
													type="secondary"
													txt={lang('select-thumbnail')}
													onClick={() => setOpenThumbnailMediaLibrary(true)}
												/>
											</div>
										</div>
										<Drawer
											open={openThumbnailMediaLibrary}
											placement="right"
											onClose={() => {
												setOpenThumbnailMediaLibrary(false)
											}}
											width={'65%'}
											text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
											<MediaLibrary
												onlyUpload
												single
												media={{
													onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
													setSelectedMedia: (thumbnail) => {
														HSPForm.setFieldsValue({
															new_display_picture: {
																id: thumbnail.id,
																url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
															}
														})
													},
													setSelectedMediaUrl: setSelectedThumbnailUrl,
													type: 'Thumbnail',
													mime: ['image/jpeg', 'image/png', 'image/svg+xml']
												}}
											/>
										</Drawer>

										<Form.Item name="HP_name" initialValue={HSP?.name} label={lang('institute')} rules={rules}>
											<Input />
										</Form.Item>
										<Form.Item
											name="email"
											label="Email"
											rules={[
												{
													required: true,
													type: 'email',
													message: lang('email_format')
												}
											]}>
											<Input disabled />
										</Form.Item>

										<Form.Item name="summary" initialValue={HSP?.summary} label={lang('summary')}>
											<Input.TextArea showCount rows={3} maxLength={280} />
										</Form.Item>

										<Divider />

										{HSP && !HSP?.nationwide && (
											<>
												<Input.Group>
													<div style={{ gap: '10px' }} className="doubleFormItem">
														<div style={{ flexGrow: 1, width: '70%' }}>
															<Form.Item name="street" label={lang('street-name')}>
																<Input />
															</Form.Item>
														</div>
														<div style={{ flexGrow: 1 }}>
															<Form.Item name="streetnr" label={lang('street-number')}>
																<Input />
															</Form.Item>
														</div>
													</div>
												</Input.Group>

												<Form.Item
													name="zip"
													label={lang('zip-code')}
													rules={
														HSP &&
														!HSP?.nationwide && [
															{ required: true, message: lang('missing-zip-code') },
															{
																validator(_, value) {
																	if (!value.match(/^\d{5}$/)) {
																		return Promise.reject(lang('invalid-zip-code'))
																	} else {
																		return Promise.resolve()
																	}
																}
															}
														]
													}>
													<Input />
												</Form.Item>

												<Form.Item name="city" label={lang('city')}>
													<Input />
												</Form.Item>
											</>
										)}

										<Form.Item name="phone" label={lang('phone-number')}>
											<Input />
										</Form.Item>

										<Form.Item name="HP_website" initialValue={HSP?.website} label={lang('website')}>
											<Input />
										</Form.Item>

										<Divider />

										<Form.Item name="service" label={lang('service')}>
											<Input.TextArea rows={8} />
										</Form.Item>
										<Divider />
										<label
											style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
											title="selected-categories"
											className="ant-form-item-required">
											<h1>{lang('opening-hours')}</h1>
										</label>
										<div className="hours-container">
											<OpeningHours day="monday" initialValue={HSP?.available} />
											<OpeningHours day="tuesday" initialValue={HSP?.available} />
											<OpeningHours day="wednesday" initialValue={HSP?.available} />
											<OpeningHours day="thursday" initialValue={HSP?.available} />
											<OpeningHours day="friday" initialValue={HSP?.available} />
											<OpeningHours day="saturday" initialValue={HSP?.available} />
											<OpeningHours day="sunday" initialValue={HSP?.available} />
										</div>

										{HSP.status !== 'ACTIVE' && (
											<>
												<Divider />

												<CategorySelectCard
													requestedContentCategories={requestedContentCategories}
													requestedHSPCategories={requestedHSPCategories}
													setRequestedContentCategories={setRequestedContentCategories}
													setRequestedHSPCategories={setRequestedHSPCategories}
												/>
											</>
										)}
									</Form>
								</Card>
							</div>
						</div>
					</div>
				</Skeleton>
			</PageContent>
		</PageHeader>
	)
}

const EditProfileType = ({ HSP, HSPDashboardStepper, profileTypeForm, handleProfileTypeSubmit }) => {
	const lang = useContext(LangContext)(translations)
	const [selectedCard, setSelectedCard] = useState(HSP?.level && HSP.level.toString())
	const [nationwideRadio, setNationwideRadio] = useState(HSP?.nationwide)

	useLayoutEffect(() => {
		setSelectedCard(HSP?.level && HSP.level.toString())
		profileTypeForm.setFieldsValue({ national_switch: HSP?.nationwide })
		profileTypeForm.setFieldsValue({ level: HSP?.level && HSP.level.toString() })
	}, [HSP?.level, HSP?.nationwide, profileTypeForm])

	const handleCardSelect = ({ card }) => {
		setSelectedCard(card)
		profileTypeForm.setFieldsValue({ level: card })
	}

	const handleRadioSelect = (radio) => {
		setNationwideRadio(radio.target.value)
		profileTypeForm.setFieldsValue({ national_switch: radio.target.value })
	}

	return (
		<PageHeader title={lang('register-as-hsp')}>
			<PageContent>
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-12 col-lg-12">
							<Card style={{ overflow: 'auto' }}>
								<HSPDashboardStepper />
								<Form form={profileTypeForm}>
									<div className="hsp-profile-section">
										<div className="hsp-profile-subsection">
											<h2>{lang('hsp-level')}</h2> <div>{lang('hsp-level-explanation')}</div>
										</div>
										<div className="hsp-level-select">
											<Card
												className={selectedCard === '1' ? 'selected-level' : ''}
												headStyle={{ fontSize: '3em', textAlign: 'center' }}
												title={lang('level-1')}
												hoverable
												onClick={() => handleCardSelect({ card: '1' })}>
												<div className="center-icons">
													<Icon type="mama-hands" className="mama-no-outline" />
												</div>
												<div className="hsp-level-body">
													<div className="hsp-features">
														<ul>
															<li>{lang('level-1-features')}</li>
														</ul>
													</div>
													<AButton style={{marginLeft: '35%'}} type="primary" block>
														{lang('select')}
													</AButton>
												</div>
											</Card>
											{/* <Card
												
												className={selectedCard === '2' ? 'selected-level' : ''}
												title={lang('level-2')}
												headStyle={{ fontSize: '3em', textAlign: 'center' }}
												hoverable
												onClick={() => handleCardSelect({ card: '2' })}>
												<div className="center-icons">
													<Icon type="mama-hands" className="mama-no-outline" />
													<Icon type="mama-chat" />
													<Icon type="mama-call" className="mama-outline" />
												</div>
												<div className="hsp-level-body">
													<div className="hsp-features">
														<ul>
															<li>{lang('level-1-features')}</li>
															<li>{lang('level-2-features')}</li>
														</ul>
													</div>
													<AButton type="primary" block>
														{lang('select')}
													</AButton>
												</div>
											</Card> */}
										</div>
										<Form.Item
											hidden
											name="level"
											initialValue={HSP?.level}
											rules={[
												{
													required: true,
													message: lang('missing-input')
												}
											]}>
											<Input hidden />
										</Form.Item>
									</div>

									<div className="hsp-profile-section">
										<div className="hsp-profile-subsection">
											<h2>{lang('nation-wide-hsp')}</h2> <div>{lang('nation-wide-explanation')}</div>
										</div>
										<Form.Item name="national_switch" value={nationwideRadio}>
											<Radio.Group onChange={(e) => handleRadioSelect(e)}>
												<Radio value={false}>{lang('regional-hsp')}</Radio>
												<Radio value={true}>{lang('nationwide-hsp')}</Radio>
											</Radio.Group>
										</Form.Item>
									</div>
								</Form>
								<div style={{ display: 'flex', float: 'right', gap: '10px' }}>
									<Button type="save" onClick={() => handleProfileTypeSubmit(HSP)}>
										{lang('submit')}
									</Button>
								</div>
							</Card>
						</div>
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}
const ConfirmProfile = ({ HSP, HSPDashboardStepper, handleStepSwitch, PreviousButtonContent, NextButtonContent }) => {
	const lang = useContext(LangContext)(translations)
	const backend = new Backend()

	const [openPublishWarning, setOpenPublishWarning] = useState(false)

	const handlePublishHSP = (data) => {
		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
		let newBody = {
			wizard_complete: true,
			status: STATUS.inactive
		}

		backend.healthProvider({
			id: data.id,
			type: backend.type.UPDATE,
			body: JSON.stringify(newBody),
			cb: () => {
				toast.update(loadingToast, {
					render: lang('hsp-published'),
					type: 'success',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
				handleStepSwitch(STEPPER.DEBRIEFING)
			},
			cbError: () => {
				toast.update(loadingToast, {
					render: lang('hsp-update-failed'),
					type: 'error',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
			}
		})
	}

	const availability = () => {
		let retAvailability = {}
		let days = HSP?.available

		if (days && Array.isArray(days) && days.length > 0) {
			days.forEach((availability) => {
				if (availability.weekday in retAvailability) {
					retAvailability[availability.weekday].times.push({ from: [availability.from], till: [availability.till] })
				} else {
					retAvailability[availability.weekday] = { times: [{ from: [availability.from], till: [availability.till] }] }
				}
			})
		} else {
			retAvailability = {
				monday: { times: [{ from: [], till: [] }] },
				tuesday: { times: [{ from: [], till: [] }] },
				wednesday: { times: [{ from: [], till: [] }] },
				thursday: { times: [{ from: [], till: [] }] },
				friday: { times: [{ from: [], till: [] }] },
				saturday: { times: [{ from: [], till: [] }] },
				sunday: { times: [{ from: [], till: [] }] }
			}
		}
		return (
			<div className="availability-container">
				{Object.keys(retAvailability).map((day) => {
					return (
						<div key={day}>
							<strong>{lang(day.toLowerCase())}</strong>
							{retAvailability[day].times.map((time) => {
								return (
									<div key={day + time} className="time-container">
										<div className="time-from">
											{time?.from} - {time?.till}
										</div>
									</div>
								)
							})}
						</div>
					)
				})}
			</div>
		)
	}
	return (
		<>
			<PageHeader title={lang('register-as-hsp')}>
				<PageContent>
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<div className="col-sm-12 col-lg-12">
								<Card style={{ overflow: 'auto' }}>
									<HSPDashboardStepper />
									<div className="hsp-profile-section">
										<div>
											<div>
												<img
													src={
														HSP?.image?.formats
															? `${CMS_ENDPOINT}` + HSP?.image?.formats?.medium?.url
															: HSP?.image?.url
															? `${CMS_ENDPOINT}` + HSP.image.url
															: null
													}
													alt="profile-picture"
													height="300"
												/>
											</div>
										</div>
										<div style={{ marginTop: '40px' }}>
											<h1>{HSP?.name}</h1>
										</div>
										<div className="hsp-profile-subsection">
											<h1>{lang('summary')}</h1>
											<p className="hsp-profile-paragraph">{HSP?.summary}</p>
										</div>
										<div className="hsp-profile-subsection">
											<h2>{lang('profile-type')}</h2>
											<div>
												{HSP?.nationwide ? lang('nationwide-hsp') : lang('regional-hsp')} <br />
												{lang('level')} {HSP?.level}
											</div>
										</div>
										{!HSP?.nationwide && (
											<div className="hsp-profile-subsection">
												<h2>{lang('address')}</h2>{' '}
												<div>
													{HSP?.street} {HSP?.streetnr} <br /> {HSP?.zip} {HSP?.city}
												</div>
											</div>
										)}
										<div className="hsp-profile-subsection">
											<h2>{lang('contact-information')}</h2>{' '}
											<div>
												{HSP?.phone} <br /> {HSP?.email} <br /> {HSP?.website}
											</div>
										</div>
										<div className="hsp-profile-subsection">
											<h2>{lang('availability')}</h2> <div>{availability()}</div>
										</div>
										<div className="hsp-profile-subsection">
											<h2>{lang('service')}</h2>
											<p className="hsp-profile-paragraph">{HSP.service}</p>
										</div>
									</div>
									<div style={{ display: 'flex', float: 'right', gap: '10px' }}>
										<Button type="primary" onClick={() => handleStepSwitch(STEPPER.EDIT_PROFILE)}>
											<PreviousButtonContent txt={'make-changes'} />
										</Button>
										<Button antType="primary" onClick={() => setOpenPublishWarning(HSP)}>
											<NextButtonContent txt={'publish'} />
										</Button>
									</div>
								</Card>
							</div>
						</div>
					</div>
				</PageContent>
			</PageHeader>

			<DecisionModal
				okType={'danger'}
				onOK={() => handlePublishHSP(HSP)}
				open={openPublishWarning}
				onCancel={() => setOpenPublishWarning(false)}
				text={{ title: lang('save-warning'), ok: lang('confirm'), cancel: lang('cancel') }}
			/>
		</>
	)
}

const HSPDashboard = () => {
	const backend = new Backend()
	const context = useGlobalContext()

	const [notFound, setNotFound] = useState(false)
	const [loading, setLoading] = useState(true)
	const [HSP, setHSP] = useState(null)
	const [consent, setConsent] = useState(null)
	const [refreshRequired, setRefreshRequired] = useState(0)

	const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState()

	const [currentStep, setCurrentStep] = useState()

	const [HSPForm] = Form.useForm()
	const [consentForm] = Form.useForm()
	const [profileTypeForm] = Form.useForm()

	const lang = useContext(LangContext)(translations)
	const { isBetterCareProject } = useGlobalContext()

	useLayoutEffect(() => {
		backend.cmsConsent({
			cb: (data) => {
				setConsent(data)
			}
		})
		backend.healthProvider({
			email: context?.globals?.user?.login || context?.globals?.user?.email || context?.globals?.user?.keycloakEmail,
			type: backend.type.GET,
			cb: (data) => {
				setHSP(data)
				setNotFound(false)
				setLoading(false)
			},
			cbError: () => {
				setNotFound(true)
				setLoading(false)
			}
		})
	}, [refreshRequired])

	useLayoutEffect(() => {
		if (HSP && currentStep === undefined) {
			if (HSP.consent_signed !== true || HSP.status === STATUS.invited) {
				setCurrentStep(STEPPER.WELCOME)
			} else {
				setCurrentStep(STEPPER.PROFILE_TYPE)
			}
		}
	}, [HSP])

	useLayoutEffect(() => {
		HSPForm.resetFields()
		setSelectedThumbnailUrl(null)
	}, [HSPForm, HSP])

	const consentRules = [
		() => ({
			validator(_, value) {
				if (!value || value === false) {
					return Promise.reject(new Error(lang('please-check')))
				}
				return Promise.resolve()
			}
		})
	]

	const handleStepSwitch = (step) => {
		if (step === STEPPER.EDIT_PROFILE || step === STEPPER.PUBLISH_PROFILE) {
			setRefreshRequired((refreshRequired) => refreshRequired + 1)
		}
		if (step === STEPPER.EXIT) {
			// wizard_complete is true, refreshing the component to exit the stepper.
			setLoading(true)
			setCurrentStep(STEPPER.EDIT_PROFILE)
			setRefreshRequired((refreshRequired) => refreshRequired + 1)
			return
		}
		setCurrentStep(step)
	}
	const handleProfileTypeSubmit = (data) => {
		profileTypeForm.validateFields().then((values) => {
			const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
			let newBody = {
				nationwide: values.national_switch,
				level: values.level
			}
			backend.healthProvider({
				id: data.id,
				type: backend.type.UPDATE,
				body: JSON.stringify(newBody),
				cb: () => {
					toast.update(loadingToast, {
						render: lang('hsp-updated'),
						type: 'success',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
					handleStepSwitch(STEPPER.EDIT_PROFILE)
					setRefreshRequired(refreshRequired + 1)
				},
				cbError: () => {
					toast.update(loadingToast, {
						render: lang('hsp-update-failed'),
						type: 'error',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
				}
			})
		})
	}

	const handleAgreeToConsent = (data) => {
		consentForm.validateFields().then(() => {
			const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
			let newBody = {
				status: STATUS.onboarding,
				consent_signed: true
			}
			backend.healthProvider({
				id: data.id,
				type: backend.type.UPDATE,
				body: JSON.stringify(newBody),
				cb: () => {
					let newGlobal = context.globals
					newGlobal.user.hsp.status = STATUS.onboarding
					newGlobal.user.hsp.consent_signed = true
					context.setGlobals(newGlobal)

					toast.update(loadingToast, {
						render: lang('hsp-updated'),
						type: 'success',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
					handleStepSwitch(STEPPER.PROFILE_TYPE)
					setRefreshRequired(refreshRequired + 1)
				},
				cbError: () => {
					toast.update(loadingToast, {
						render: lang('hsp-update-failed'),
						type: 'error',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
				}
			})
		})
	}

	const HSPDashboardStepper = () => {
		if (HSP?.wizard_complete === true) {
			return <></>
		}
		return (
			<div style={{ padding: '20px 0px 50px 0px' }}>
				<Steps current={currentStep}>
					<Step title={lang('hsp-step-1')} />
					<Step title={lang('hsp-step-2')} />
					<Step title={lang('hsp-step-3')} />
					<Step title={lang('hsp-step-4')} />
					<Step title={lang('hsp-step-5')} />
					<Step title={lang('hsp-step-6')} />
				</Steps>
			</div>
		)
	}

	const HSP_LINK = isBetterCareProject() ? <a href="better-care.health">better-care.health</a> : <a href="meinmamaherz.com">meinmamaherz.com</a>
	const Welcome = () => {
		return (
			<PageHeader title={lang('register-as-hsp')}>
				<PageContent>
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<div className="col-sm-12 col-lg-12">
								<Card style={{ overflow: 'auto' }}>
									<Skeleton hide={!loading}>
										<HSPDashboardStepper />
										<div style={{ textAlign: 'center', padding: '100px' }}>
											{lang('hsp-welcome-text')} <br /> {HSP_LINK}
										</div>
										<div style={{ float: 'right' }}>
											<Button antType="primary" onClick={() => handleStepSwitch(STEPPER.CONSENT)}>
												<NextButtonContent />
											</Button>
										</div>
									</Skeleton>
								</Card>
							</div>
						</div>
					</div>
				</PageContent>
			</PageHeader>
		)
	}

	const NextButtonContent = ({ txt = 'next' }) => {
		return (
			<div className="previous-next-buttons">
				{lang(txt)}
				<Icon type={'forward-arrow'} />
			</div>
		)
	}

	const PreviousButtonContent = ({ txt = 'previous' }) => {
		return (
			<div className="previous-next-buttons">
				<Icon type={'back-arrow'} />
				{lang(txt)}
			</div>
		)
	}

	const ShowConsent = () => {
		return (
			<PageHeader title={lang('register-as-hsp')}>
				<PageContent>
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<div className="col-sm-12 col-lg-12">
								<Card style={{ overflow: 'auto' }}>
									<HSPDashboardStepper />
									{consent &&
										consent.consent.map((item, index) => {
											if (item.key === 'main-hsp-consent') {
												return (
													<div key={item.title} className="hsp-consent-container">
														<div key={index} className="hsp-consent-item">
															<div className="hsp-consent-item-title">
																<h2>{item.title}</h2>
															</div>
															<div
																dangerouslySetInnerHTML={{ __html: item.text }}
																className="hsp-consent-item-content"
															/>
														</div>
														<div className="agree-checkbox">
															<Form form={consentForm}>
																<Form.Item name="agree" valuePropName="checked" rules={consentRules}>
																	<Checkbox>{lang('agree-to-consent')}</Checkbox>
																</Form.Item>
															</Form>
														</div>

														<div className="button-container">
															<div style={{ float: 'left' }}>
																<Button type="primary" onClick={() => handleStepSwitch(STEPPER.WELCOME)}>
																	<PreviousButtonContent />
																</Button>
															</div>
															<Button type="save" onClick={() => handleAgreeToConsent(HSP)}>
																{lang('submit')}
															</Button>
														</div>
													</div>
												)
											}
										})}
								</Card>
							</div>
						</div>
					</div>
				</PageContent>
			</PageHeader>
		)
	}

	const WaitingForApproval = () => {
		return (
			<PageHeader title={lang('register-as-hsp')}>
				<PageContent>
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<div className="col-sm-12 col-lg-12">
								<Card style={{ overflow: 'auto' }}>
									<HSPDashboardStepper />
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '200px',
											marginBottom: '50px'
										}}>
										{lang('hsp-waiting-for-approval')}
									</div>
									<div style={{ display: 'flex', float: 'right', gap: '10px' }}>
										<Button type="primary" onClick={() => handleStepSwitch(STEPPER.EXIT)}>
											<NextButtonContent txt={'continue'} />
										</Button>
									</div>
								</Card>
							</div>
						</div>
					</div>
				</PageContent>
			</PageHeader>
		)
	}

	if (!loading && notFound) {
		return <NotFound />
	} else {
		return (
			<>
				{HSP?.status === STATUS.cancelled ? (
					<NotFound />
				) : HSP?.wizard_complete === true ? (
					<EditHSPForm
						HSP={HSP}
						handleStepSwitch={handleStepSwitch}
						HSPDashboardStepper={HSPDashboardStepper}
						setRefreshRequired={setRefreshRequired}
						refreshRequired={refreshRequired}
						loading={loading}
						HSPForm={HSPForm}
						selectedThumbnailUrl={selectedThumbnailUrl}
						setSelectedThumbnailUrl={setSelectedThumbnailUrl}
						PreviousButtonContent={PreviousButtonContent}
					/>
				) : currentStep === 0 ? (
					<Welcome />
				) : currentStep === 1 ? (
					<ShowConsent />
				) : currentStep === 2 ? (
					<EditProfileType
						HSP={HSP}
						HSPDashboardStepper={HSPDashboardStepper}
						handleStepSwitch={handleStepSwitch}
						profileTypeForm={profileTypeForm}
						handleProfileTypeSubmit={handleProfileTypeSubmit}
					/>
				) : currentStep === 3 ? (
					<EditHSPForm
						HSP={HSP}
						handleStepSwitch={handleStepSwitch}
						HSPDashboardStepper={HSPDashboardStepper}
						setRefreshRequired={setRefreshRequired}
						refreshRequired={refreshRequired}
						loading={loading}
						HSPForm={HSPForm}
						selectedThumbnailUrl={selectedThumbnailUrl}
						setSelectedThumbnailUrl={setSelectedThumbnailUrl}
						PreviousButtonContent={PreviousButtonContent}
					/>
				) : currentStep === 4 ? (
					<ConfirmProfile
						HSP={HSP}
						HSPDashboardStepper={HSPDashboardStepper}
						selectedThumbnailUrl={selectedThumbnailUrl}
						handleStepSwitch={handleStepSwitch}
						PreviousButtonContent={PreviousButtonContent}
						NextButtonContent={NextButtonContent}
					/>
				) : currentStep === 5 ? (
					<WaitingForApproval />
				) : currentStep === undefined ? (
					<Welcome />
				) : (
					<NotFound />
				)}
			</>
		)
	}
}

export default HSPDashboard
