import React from 'react'
import SymptomIcon from '../SymptomIcon'

import { LangContext } from '../Translation'
import translations from './translations'

import "./index.css"

const SubScore = ({ title, score }) => {

	const lang = React.useContext(LangContext)(translations)

	return <div className='inline symptom-subscore-container'>
		<div className="symptom-subscore-title">
			{lang(title)}
		</div>
		<div className="symptom-subscore-value-title">
			{score?.title}
		</div>
		<div className="symptom-subscore-overlay" style={{ width: `${score ? (100 - ((score.value * 25))) : 100}px` }}>
		</div>
		<div className="symptom-subscore"></div>
	</div>
}

const Symptom = ({ symptom, severity, score = {} }) => {

	const lang = React.useContext(LangContext)(translations)

	const subScore = score.subScore ? score.subScore : {}

	const subScores = [] 
	for(let attr in subScore){
		subScores.push(<SubScore className="mt10" title={attr} score={subScore[attr]}></SubScore>)
	}

	return (
		<span>
			<div className="symptoms-icon inline">
				<SymptomIcon symptom={{ 
						type: symptom,
						severity: severity 
				}} /> 
				<div className='ml10 block'>
					<div style={{ lineHeight: "15px" }}>{symptom}</div>
					<div className="inline" style={{ lineHeight: "20px" }}><div>{severity}</div>
					{ score.value ? <div className="text-muted">&nbsp;({`${lang('score')} ${score.value}`})</div> : undefined }
					</div>
				</div>
			</div>
			{ subScores && subScores.length > 0 ? <div className='symptom-subscores' >{ subScores }</div> : undefined }
		</span>
	)
}

export default Symptom
