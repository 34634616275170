import React from 'react'
import "./index.css"
import { Card } from 'antd';

export default function SegmentsBarChart({
  title,
  values = []
}) {

  return (
    <Card bordered={false}>
      <p className="mb-3">{title}</p>
      <div className="progress progress-separated mb-3">
        {values.map((v) => {
          return <div key={v.title} className={`progress-bar bg-${v.color}`} role="progressbar" style={{ "width": `${v.percent}%` }}></div>
        })}
      </div>
      <div className="row">
        {values.map((v) => {
          return <div key={v.title} className="col-auto d-flex align-items-center pe-2">
            <span className={`legend me-2 bg-${v.color}`}></span>
            <span>{v.title}</span>
            <span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted">{v.value}</span>
          </div>
        })}
      </div>
    </Card>
  )
}