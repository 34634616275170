import React from 'react'
import { Select as ASelect } from 'antd'

import translations from './translations'
import { LangContext } from '../Translation'

import './index.css'

const { Option } = ASelect

export default function Select({
	className = '',
	loading,
	defaultOptions = [],
	value,
	options = [],
	placeholder = null,
	overlap,
	title,
	isMulti = false,
	onChange = () => {},
	style = {},
	allowClear = true,
	disabled = false,
	required = false
}) {
	const lang = React.useContext(LangContext)(translations)

	placeholder = placeholder ? placeholder : lang('placeholder')
	const styles = {...style}
	styles.width = styles.width ? styles.width : "200px"

	const toOptions = el => {
		return  <Option key={el.key} value={el.key} disabled={el.isDisabled}>{el.value}</Option>
	}

	return (
		<div className={`exo-select ${className}`}>
			{title ? <label className={`form-label label-spacing ${required ? 'required': ''}`}>{title}</label> : null}
			<ASelect
				className={overlap ? 'overlap' : null}
				defaultValue={defaultOptions}
				loading={loading}
				value={value ? (isMulti ? value.map(toOptions) : toOptions(Array.isArray(value) ? value[0] : value)) : undefined}
				mode={isMulti ? "multiple" : undefined}
			    allowClear={allowClear}
				disabled={disabled}
				placeholder={placeholder}
				onChange={selection => {
					const ret = options.filter(opt => {
						if(Array.isArray(selection)){
							return selection.includes(opt.key)
						} else {
							return selection === opt.key
						} 
					})
					onChange(Array.isArray(selection) ? ret : ret[0]) // in case of single selection, return object, not array
				}}				
				style={styles}
			>
				{ options.map(toOptions) }
			</ASelect>
		</div>
	)
}
