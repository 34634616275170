import React, { useState, useEffect } from 'react'
import Skeleton from '../../components/Skeleton'
import { Card, Form } from 'antd'
import { LangContext } from '../../components/Translation'
import translations from './translations'

import Backend from '../../api/Backend'
import './index.css'

import Table from '../../components/Table'
import Icon from '../../components/Icon'
import Button from '../../components/Button'
import AllowRender from '../../components/AllowRender'
import Drawer from '../../components/Drawer'
import DecisionModal from '../../components/DecisionModal'

import ActionsEditor from './ActionsEditor'

import { toast } from 'react-toastify'

import { CMS_ENDPOINT } from '../../pages/CMS'

const Actions = ({ refreshActions, setRefreshActions }) => {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()

	const [loading, setLoading] = useState(true)
	const [actions, setActions] = useState([])
	const [selectedAction, setSelectedAction] = useState(null)
	const [resetActionsForm, setResetActionsForm] = useState(1)
	const [centers, setCenters] = useState([])
	const [removeActionDecision, setRemoveActionDecision] = useState(false)

	const [openActionsEditor, setOpenActionsEditor] = useState(false)

	const [actionEditForm] = Form.useForm()

	useEffect(() => {
		backend.action({
			type: backend.type.GET,
			cb: (data) => {
				setActions(data)
				setLoading(false)
			},
			cbError: () => {
				const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
				toast.update(loadingToast, {
					render: lang('an-error-has-occured'),
					type: 'error',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})

				setLoading(false)
			}
		})
		backend.clinics({
			cb: (centers) => {
				setCenters(
					centers
						.map((center) => {
							return { clinic_name: center.clinic_name, id: center.id }
						})
						.concat([{ clinic_name: 'Public', id: '0000' }])
				)
				setLoading(false)
			}
		})
	}, [refreshActions])

	const removeAction = (row) => {
		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
		if (row?.id) {
			backend.action({
				type: backend.type.DELETE,
				id: row?.id,
				cb: () => {
					setRefreshActions((refreshActions) => refreshActions + 1)
					toast.update(loadingToast, {
						render: lang('action-deleted'),
						type: 'success',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
				},
				cbError: () => {
					toast.update(loadingToast, {
						render: lang('an-error-has-occured'),
						type: 'error',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
				}
			})
		} else {
			toast.update(loadingToast, {
				render: lang('an-error-has-occured'),
				type: 'error',
				isLoading: false,
				closeOnClick: true,
				autoClose: 5000
			})
		}
	}

	return (
		<>
			<div className="container-xl">
				<div className="row row-deck row-cards">
					<div className="col-sm-12 col-lg-12">
						<div className="cms-card-container">
							<Card title={lang('actions')}>
								<Skeleton hide={!loading}>
									<Table
										columns={[
											{
												value: lang('image'),
												key: 'thumbnail',
												render: (url) => {
													if (
														!url?.row?.thumbnail?.id ||
														url?.value?.url.includes('cmsnull') ||
														url?.value?.formats?.thumbnail?.url.includes('cmsundefined')
													) {
														return <Icon type="missing-image" style={{ width: '100%', height: '100%' }} />
													}
													return (
														<img
															src={`${CMS_ENDPOINT}${
																url?.value?.formats?.thumbnail ? url.value.formats.thumbnail.url : url?.value?.url
															}`}
															className="cms-image"></img>
													)
												}
											},
											{
												key: 'publicTitle',
												value: lang('public-title'),
												sorter: (a, b) => a.publicTitle.localeCompare(b.publicTitle),
												search: true
											},
											{
												key: 'internalTitle',
												value: lang('internal-title'),
												sorter: (a, b) => a.internalTitle.localeCompare(b.internalTitle),
												search: true
											},
											{
												key: 'action_type',
												value: lang('type'),
												render: ({ row }) => {
													return <span>{lang(row?.action_type?.key)}</span>
												},
												sorter: (a, b) => (a.action_type ? a.action_type.key.localeCompare(b.action_type.key) : b)
											},
											{
												key: 'actions',
												value: lang('actions'),
												render: ({ row }) => {
													return (
														<div className="inline">
															<Button
																type="open"
																onClick={() => {
																	setSelectedAction(row)
																	setOpenActionsEditor(true)
																}}></Button>
															<Button
																className="ml10"
																type="remove"
																onClick={() => {
																	setRemoveActionDecision(row)
																}}></Button>
														</div>
													)
												}
											}
										]}
										data={actions}></Table>
								</Skeleton>
							</Card>
						</div>
					</div>
				</div>
			</div>
			<Drawer
				width="80%"
				open={openActionsEditor}
				onClose={() => {
					setResetActionsForm((resetActionsForm) => resetActionsForm + 1)
					setRefreshActions((actions) => actions + 1)
					actionEditForm.resetFields()
					setSelectedAction(null)
					setOpenActionsEditor(false)
				}}
				onOK={() => {
					actionEditForm.validateFields().then((values) => {
						const data = {
							action_type: values?.action_type,
							recommendedInternalContent: values?.recommendedInternalContent || null,
							recommendedExternalContent: values?.recommendedExternalContent || null,
							page: values?.page,
							visibility:
								values.centerVisibility[0] === 'Public'
									? []
									: values.centerVisibility.map((center) => {
											let newCenter = centers.find((c) => c.clinic_name === center)
											return newCenter
									  }),
							daysVisible: values?.daysVisible,
							groups: values?.groupVisibility,
							internalTitle: values?.internalTitle,
							publicTitle: values?.publicTitle,
							thumbnail: values?.thumbnail?.id
						}
						const parsedData = JSON.stringify(data)
						const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })

						backend.action({
							type: backend.type.UPDATE,
							id: selectedAction?.id,
							body: parsedData,
							cb: () => {
								toast.update(loadingToast, {
									render: lang('action-updated'),
									type: 'success',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
								setResetActionsForm((resetActionsForm) => resetActionsForm + 1)
								setRefreshActions((actions) => actions + 1)
								actionEditForm.resetFields()
								setSelectedAction(null)
								setOpenActionsEditor(false)
							},
							cbError: () => {
								toast.update(loadingToast, {
									render: lang('an-error-has-occured'),
									type: 'error',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
							}
						})
					})
				}}
				text={{
					title: lang('update-action'),
					ok: <AllowRender permissions={['CMS_RW']}>{lang('save')}</AllowRender>,
					cancel: lang('cancel')
				}}>
				<ActionsEditor data={selectedAction} form={actionEditForm} reset={resetActionsForm} />
			</Drawer>

			<DecisionModal
				okType={'danger'}
				open={removeActionDecision}
				onClose={() => {
					setRemoveActionDecision(false)
				}}
				text={{
					title: lang('remove-action-confirmation', { title: removeActionDecision?.internalTitle }),
					cancel: lang('cancel'),
					ok: lang('confirm')
				}}
				onOK={() => {
					removeAction(removeActionDecision)
				}}
			/>
		</>
	)
}

export default Actions
