import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import MarkdownIt from 'markdown-it'
import MdEditor, { Plugins } from 'react-markdown-editor-lite'
import MdVideo from 'markdown-it-video'
import regexPlugin from './CustomPlugins/markdown-it-containers'
import {
	TableOfContents,
	GallerySelection,
	ExternalLink,
	AttachmentCard,
	InternalArticle,
	YoutubeVideo,
	ConditionalTag,
	CollapsibleParagraph,
	InterventionTag
} from '../../applets/MarkdownEditor/plugins'
import 'react-markdown-editor-lite/lib/index.css'
import './index.css'
import Renderer from 'markdown-it/lib/renderer'

const renderer = new Renderer()

const initializeParser = () => {
	const mdParser = new MarkdownIt({
		html: false,
		linkify: false,
		typography: true
	}).use(MdVideo, {
		youtube: { width: 640, height: 390 },
		vimeo: { width: 500, height: 281 },
		vine: { width: 600, height: 600, embed: 'simple' },
		prezi: { width: 550, height: 400 }
	})
	// Table of Contents requirements
	// makes TOC anchor tags scroll to appropriate header
	mdParser.use(require('markdown-it-anchor').default)
	// Insert --> [[TOC]] --> in markdown to generate a TOC.
	mdParser.use(require('markdown-it-table-of-contents'))

	mdParser.use(regexPlugin, {
		name: 'attachments',
		openingRegex: /<attachment_card /gi,
		closingRegex: /><\/attachment_card>/gi
	})
	mdParser.use(regexPlugin, {
		name: 'external',
		openingRegex: /<external_card /gi,
		closingRegex: /><\/external_card>/gi
	})
	mdParser.use(regexPlugin, {
		name: 'internal',
		openingRegex: /<internal_article /gi,
		closingRegex: /><\/internal_article>/gi
	})
	mdParser.use(regexPlugin, {
		name: 'collapsible',
		openingRegex: /<collapsible_paragraph>/gi,
		closingRegex: /<\/collapsible_paragraph>/gi
	})
	mdParser.use(regexPlugin, {
		name: 'conditional',
		openingRegex: /<conditional-\w*>/gi,
		closingRegex: /<\/conditional-(\w*)>/gi
	})

	return mdParser
}

// Initialize a markdown parser
const mdParser = initializeParser()

//https://www.youtube.com/watch?v=leK1wBgbSs4

const MarkdownEditor = forwardRef(({ style = { height: '500px' }, onChange = () => {}, defaultValue, className, readonly = false }, ref) => {
	MarkdownEditor.displayName = 'MarkdownEditor'
	className = `exo-markdown-editor ${className}`

	const [value, setValue] = useState('')

	useEffect(() => {
		setValue(defaultValue)
	}, [defaultValue])

	useImperativeHandle(ref, () => ({
		getData() {
			return value
		}
	}))

	function handleEditorChange({ html, text }) {
		setValue(text)
		onChange({ html, text })
	}

	// Editor buttons are removed here as plugins
	MdEditor.unuse(Plugins.Link)
	MdEditor.unuse(Plugins.BlockCodeInline)
	MdEditor.unuse(Plugins.BlockCodeInline)
	MdEditor.unuse(Plugins.Image)

	// Editor buttons added here as plugins.
	MdEditor.use(InterventionTag)
	MdEditor.use(ConditionalTag)
	MdEditor.use(TableOfContents)
	MdEditor.use(GallerySelection)
	MdEditor.use(ExternalLink)
	MdEditor.use(AttachmentCard)
	MdEditor.use(InternalArticle)
	MdEditor.use(YoutubeVideo)
	MdEditor.use(CollapsibleParagraph)

	return (
		<>
			<MdEditor
				value={value}
				config={{
					view: {
						menu: !readonly,
						md: !readonly,
						html: true,
						fullScreen: true
					}
				}}
				className={className}
				style={style}
				renderHTML={(text) => mdParser.render(text)}
				onChange={handleEditorChange}
			/>
		</>
	)
})

export default MarkdownEditor
export { initializeParser }
