import React from 'react'

import './index.css'

export default function PageContent({ children }) {
	return (
		<div className='page-content' > 
			{ children }
		</div>
	)
}
