import React, { useEffect, useState } from 'react'
import { Collapse, Form, Select, Card } from 'antd'

import { LangContext } from '../../components/Translation'
import translations from './translations'
import './index.css'

import Drawer from '../../components/Drawer'
import Skeleton from '../../components/Skeleton'
import DecisionModal from '../../components/DecisionModal'
import Backend from '../../api/Backend'
import DetailList from '../../components/DetailList'
import DateTimePicker from '../../components/DateTimePicker'
import EventSelect from '../../components/EventSelect'
import Table from '../../components/Table'
import Tabs from '../../components/Tabs'
import Tag from '../../components/Tag'
import Icon from '../../components/Icon'
import Empty from '../../components/Empty'
import Button from '../../components/Button'
import Utils from '../../components/Utils'

import { getSharedColumns, getCareplanActivities, timings, activationTypes, calculationTypes } from '../../applets/Careplan'

import { useGlobalContext } from '../../components/AppContext'

const { Panel } = Collapse



const PatientCareplans = ({ id }) => {
	const [form] = Form.useForm()

	const { isProductionSystem, patientCareplanStatuses } = useGlobalContext()

	const [careplansAvailable, setCareplanAvailableList] = useState([])
	const [assignedCareplans, setAssignedCareplans] = useState([])
	const [showCareplanAddForm, setShowCareplanAddForm] = useState(false)
	const [activationType, setActivationType] = useState()
	const [calculationType, setCalculationType] = useState()
	const [calculationEvent, setCalculationEvent] = useState()
	const [loading, setLoading] = useState(true)
	const [loadingCPAvailable, setLoadingCPAvailable] = useState(false)

	const backend = new Backend()
	const utils = new Utils()
	const lang = React.useContext(LangContext)(translations)

	const handleCareplanCancel = async (dat) => {
		const body = await JSON.stringify({
			status: patientCareplanStatuses.CANCELED
		})
		await backend.patientCareplans({ patientId: dat.patientId, careplanId: dat.id, type: backend.type.UPDATE, body, cb: refresh })
	}

	const handleCareplanAdd = ({ reset }) => {
		form.validateFields().then(async (fields) => {
			setLoading(true)
			const cp = careplansAvailable.filter((cp) => cp.id === fields.careplan)[0]
			const body = JSON.stringify({
				careplanId: fields.careplan,
				changedAt: cp.changed_at,
				activationDate: fields.date
			})
			reset()
			await backend.patientCareplans({
				patientId: id,
				type: backend.type.CREATE,
				body,
				cbError: () => {
					setLoading(false)
				},
				cb: () => {
					refresh()
				}
			})
		})
	}

	const handleTimeMachineNextStep = async (activityId, careplanId) => {
		if (!isProductionSystem()) {
			await backend.setCareplanTimeMachine({
				id: careplanId,
				activityId,
				cb: refresh,
				body: JSON.stringify({ patientId: id }),
				type: backend.type.UPDATE
			})
		}
	}

	const refresh = () => {
		setLoading(true)
		setLoadingCPAvailable(true)
		backend.careplans({
			published: true,
			cb: (data) => {
				setCareplanAvailableList(data)
				setLoadingCPAvailable(false)
			}
		})
		backend.patientCareplans({
			patientId: id,
			cbError: () => {
				setLoading(false)
			},
			cb: (data) => {
				resetCareplanAddForm()
				setLoading(false)
				setAssignedCareplans(data)
			}
		})
	}

	useEffect(() => {
		refresh()
	}, [])

	const getAssignedActiveCareplans = () => {
		return assignedCareplans.filter((assigned) => assigned.status === patientCareplanStatuses.ACTIVE || assigned.status === patientCareplanStatuses.ACTIVATING)
	}

	const careplanOptions = careplansAvailable.map((cp) => (
		<Select.Option
			key={cp.id}
			value={cp.id}
			disabled={getAssignedActiveCareplans().some((assigned) => {
				return assigned.careplanId === cp.resource.id
			})}>
			{cp.resource.title}
		</Select.Option>
	))

	const getStatusColor = (dat) => {
		if (dat.status === patientCareplanStatuses.ACTIVE) {
			return 'teal'
		} else if (dat.status === patientCareplanStatuses.COMPLETED) {
			return 'muted'
		} else if (dat.status === patientCareplanStatuses.CANCELED) {
			return 'danger'
		} else {
			return 'process'
		}
	}

	const tabData = (dat) => {
		const getDateColumn = () => {
			return [
				{
					key: 'scheduledAt',
					value: lang('date'),
					fixed: 'left',
					width: 100,
					render: ({ value, row }) => {
						const date = value ? utils.toDate({ dateString: value, withoutTime: true }) : ''
						const passedDays = utils.daysBetween(value)
						const dayStr = Math.abs(passedDays) === 1 ? lang('day') : lang('days')
						const passedDaysStr =
							passedDays === 0
								? lang('today')
								: passedDays < 0
								? `${lang('in')} ${Math.abs(passedDays)} ${dayStr}`
								: passedDays > 0
								? `${lang('before')} ${Math.abs(passedDays)} ${dayStr}`
								: ''
						return (
							<div className="data-check">
								<span>
									<label>{date}</label>
									<p className="exo-muted-text">{passedDaysStr}</p>
								</span>
							</div>
						)
					}
				}
			]
		}

		const getActivationEventColumn = () => {
			return [
				{
					key: 'exo',
					value: lang('activation-event'),
					width: 150,
					render: ({ row }) => {
						return row.calculationEvent ? (
							<div className="line-align">
								<div className="flex-child one ">
									<EventSelect title="" disabled={true} defaultOptions={row.calculationEvent?.key} />
								</div>
							</div>
						) : null
					}
				}
			]
		}

		const getTimemachineColumn = () => {
			return [
				!isProductionSystem()
					? {
							fixed: 'left',
							key: 'exo',
							value: lang('action'),
							width: 100,
							render: ({ row }) => {
								return !isProductionSystem() && !row.isActive ? (
									<Button
										type="forward"
										onClick={() => {
											handleTimeMachineNextStep(row.id, dat.careplanId)
										}}
									/>
								) : null
							}
					  }
					: undefined
			]
		}

		const getStatusColumn = () => {
			return [
				{
					key: 'isActive',
					value: lang('status'),
					fixed: 'left',
					width: 100,
					render: ({ row }) => {
						return row.isActive ? (
							<div className="data-check">
								<svg
									style={{ marginLeft: '5px', marginTop: '10px' }}
									xmlns="http://www.w3.org/2000/svg"
									className="icon icon-tabler icon-tabler-check"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									strokeWidth="2"
									stroke="green"
									fill="none"
									strokeLinecap="round"
									strokeLinejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<path d="M5 12l5 5l10 -10"></path>
								</svg>
							</div>
						) : undefined
					}
				}
			]
		}

		return [
			{
				key: '1',
				title: { text: lang('order'), icon: <Icon type={'steps'} /> },
				content: (
					<Table
						borderless={true}
						emptyText={lang('no-actvities')}
						loading={loading}
						headless={false}
						expandable={true}
						scroll={{ x: 1800 }}
						columns={[
							...getTimemachineColumn(),
							...getStatusColumn(),
							...getDateColumn(),
							...getActivationEventColumn(),
							...getSharedColumns({ timing: timings.DELAY, lang })
						].filter((c) => c !== undefined)}
						data={dat ? getCareplanActivities(dat.resource.activity, timings.DELAY) : null}
					/>
				)
			},
			{
				key: '2',
				title: { text: lang('events'), icon: <Icon type={'events'} /> },
				content: (
					<Table
						borderless={true}
						emptyText={lang('no-actvities')}
						loading={loading}
						headless={false}
						expandable={true}
						scroll={{ x: 1800 }}
						columns={[
							...getTimemachineColumn(),
							...getStatusColumn(),
							{
								fixed: 'left',
								key: 'exo',
								value: lang('event'),
								width: 100,
								render: ({ row }) => {
									return (
										<div className="line-align">
											<div className="flex-child one">{<Tag className="m5">{row.trigger?.value}</Tag>}</div>
										</div>
									)
								}
							},
							...getSharedColumns({ timing: timings.EVENT, lang })
						].filter((c) => c !== undefined)}
						data={dat ? getCareplanActivities(dat.resource.activity, timings.EVENT) : null}
					/>
				)
			},
			{
				key: '3',
				title: { text: lang('periodical'), icon: <Icon type={'periods'} /> },
				content: (
					<Table
						borderless={true}
						emptyText={lang('no-actvities')}
						loading={loading}
						headless={false}
						expandable={true}
						scroll={{ x: 1800 }}
						columns={[
							...getTimemachineColumn(),
							...getStatusColumn(),
							...getDateColumn(),
							{
								fixed: 'left',
								key: 'exo',
								value: lang('period'),
								width: 100,
								render: ({ row }) => {
									return (
										<div className="line-align">
											<div className="flex-child one ">{row.delayValue}</div>
											<div className="flex-child two ">
												{row.delayValue === 1 ? lang(row.delayUnit) : lang(row.delayUnit + 's')}
											</div>
										</div>
									)
								}
							},
							...getSharedColumns({ timing: timings.PERIODICAL, lang })
						].filter((c) => c !== undefined)}
						data={dat ? getCareplanActivities(dat.resource.activity, timings.PERIODICAL) : null}
					/>
				)
			}
		]
	}

	const handleCareplanChange = () => {
		const careplan = careplansAvailable.filter((cp) => cp.id === form.getFieldValue('careplan'))[0]
		const activationType = careplan?.resource?.activationType
		setActivationType(activationType)
		const calculationType = careplan?.resource?.calculationType
		setCalculationType(calculationType)
		if (calculationType === calculationTypes.EVENT) {
			const calculationEvent = careplan?.resource?.calculationEvent
			setCalculationEvent(calculationEvent)
		}
	}

	const resetCareplanAddForm = () => {
		form.resetFields()
		setActivationType(undefined)
		setCalculationEvent(undefined)
		setShowCareplanAddForm(false)
	}

	return (
		<>
			<Card
				bordered={false}
				title={lang('careplans')}
				extra={
					<>
						<Button type="refresh" className="mr10" onClick={refresh}></Button>
						<Button
							type="add"
							className="mr10"
							onClick={() => {
								setShowCareplanAddForm(true)
							}}>
							{lang('start_careplan')}
						</Button>
					</>
				}>
				<Drawer
					width="700px"
					open={showCareplanAddForm}
					onOK={() => {
						handleCareplanAdd({ reset: resetCareplanAddForm })
					}}
					onCancel={() => {
						resetCareplanAddForm()
					}}
					onClose={() => {
						resetCareplanAddForm()
					}}
					text={{
						title: lang('add_careplan'),
						cancel: lang('cancel'),
						ok: lang('add')
					}}>
					<Skeleton type="card" hide={!loadingCPAvailable}>
						<Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
							<Form.Item
								name="careplan"
								label={lang('careplan')}
								rules={[
									{
										required: true,
										message: lang('select-careplan')
									}
								]}>
								<Select clearIcon={true} onChange={handleCareplanChange} style={{ width: '400px' }} placeholder={lang('select')}>
									{careplanOptions}
								</Select>
							</Form.Item>
							<Form.Item
								name="activationType"
								shouldUpdate
								hidden={activationType === undefined}
								label={lang('careplan-activation-type')}>
								<label> {activationType ? lang(activationType.toLowerCase()) : ''} </label>
							</Form.Item>
							<Form.Item
								name="date"
								hidden={activationType !== activationTypes.DATE}
								label={lang('careplan_start')}
								rules={[
									{
										required: activationType === activationTypes.DATE,
										message: lang('select-start-date')
									}
								]}>
								<DateTimePicker style={{ width: '300px' }} rangePicker={false} disabledPrevDays={true} />
							</Form.Item>

							<Form.Item
								name="calculationType"
								shouldUpdate
								hidden={calculationType === undefined || activationType !== activationTypes.INCLUSION}
								label={lang('careplan-calculation-begin')}>
								<label> {calculationType ? lang(calculationType.toLowerCase()) : ''} </label>
							</Form.Item>
							<Form.Item
								name="event"
								shouldUpdate
								hidden={calculationEvent === undefined || calculationType !== calculationTypes.EVENT}
								label={lang('careplan-calculation-event')}>
								{calculationType === calculationTypes.EVENT ? (
									<EventSelect title="" disabled={true} style={{ width: '300px' }} defaultOptions={calculationEvent} />
								) : null}
							</Form.Item>
						</Form>
					</Skeleton>
				</Drawer>
				<Skeleton type="list" hide={!loading}>
					{assignedCareplans &&
						assignedCareplans.map((dat, idx) => {
							const activationType = dat?.resource?.activationType
							const detailList = [
								[lang('assigned-at'), utils.toDate({ dateString: dat.changedAt })],
								[lang('assigned-by'), dat.changedBy],
								[
									lang('status'),
									<Tag key={dat.status} type={dat.status}>
										{dat.status}
									</Tag>
								]
							]
							const detailListMore = [
								[lang('setting'), dat.settingTitle],
								[
									lang('careplan-activation-type'),
									lang(activationType?.toLowerCase()),
									dat?.resource?.calculationType === calculationTypes.EVENT ? (
										<EventSelect
											title={lang('activation-at-event')}
											className="mt15 details-event"
											disabled={true}
											style={{ width: '300px' }}
											defaultOptions={dat?.resource?.calculationEvent}
										/>
									) : null
								],
								[
									lang('careplan-stop'),
									dat?.resource?.stopEvent ? (
										<EventSelect
											className="details-event"
											disabled={true}
											style={{ width: '300px' }}
											defaultOptions={dat?.resource?.stopEvent}
										/>
									) : (
										'-'
									)
								]
							]
							return (
								<div key={`${dat.careplanId} ${dat.assignedAt}`} className="card card-margin">
									<div className={`card-status-start bg-${getStatusColor(dat)}`}></div>
									<div className="card-header space d-flex">
										<h3 className="card-title">{dat.resource.title}</h3>
										<DecisionModal
											btn={{
												type: 'stop',
												txt: lang('stop_careplan')
											}}
											disabled={!(dat.status === patientCareplanStatuses.ACTIVE || dat.status === patientCareplanStatuses.WAITING)}
											autoClose
											bordered
											onOK={() => handleCareplanCancel(dat)}
											text={{
												cancel: lang('cancel'),
												ok: lang('confirm'),
												title: lang('request'),
												details: lang('double_check')
											}}
										/>
									</div>
									<div className="card-body">
										<DetailList data={detailList} />
										<Collapse defaultActiveKey={['']} ghost>
											<Panel header={lang('details')} key="1">
												<DetailList data={detailListMore} />
											</Panel>
										</Collapse>
										<Collapse defaultActiveKey={['']} ghost>
											<Panel header={lang('activities')} key="2">
												<Tabs data={tabData(dat)} className="" />
											</Panel>
										</Collapse>
									</div>
								</div>
							)
						})}
				</Skeleton>
				{!loading && assignedCareplans && assignedCareplans.length === 0 && <Empty description={lang('no_careplans')} />}
			</Card>
		</>
	)
}

export { PatientCareplans }
