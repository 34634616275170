import React, {useState, useEffect, useLayoutEffect} from 'react'
import {  Form, Checkbox, } from 'antd'
import Button from '../../components/Button'
import { toast } from 'react-toastify'
import { useGlobalContext } from '../../components/AppContext'




import { LangContext } from '../../components/Translation'

import translations from './translations'
import './index.css'



import Backend from '../../api/Backend'


const UserConsent = ({ user, setUserConsentChange }) => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)
	const { setGlobals, globals } = useGlobalContext()

	const [consent, setConsent] = useState(null)

	const [consentForm] = Form.useForm()

	useEffect(() => {
		backend.userConsent({
			consentToAccept: true,
			cb: (data) => {
				setConsent(data)
			}
		})
	}, [globals])

	const handleAgreeToConsent = () => {
		let consentGiven = consentForm.getFieldsValue('agree')
		consentForm.validateFields().then(() => {
			const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
			const newBody = {
				consent_given: consentGiven?.agree
			}
			backend.userConsent({
				id: user.userId,
				type: backend.type.CREATE,
				body: JSON.stringify(newBody),
				cb: (updatedConsent) => {
					let newGlobal = globals
					newGlobal.user.consent_given = updatedConsent
					setGlobals(newGlobal)
					setUserConsentChange(newGlobal)

					toast.update(loadingToast, {
						render: 'consent updated!',
						type: 'success',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
				},
				cbError: () => {
					toast.update(loadingToast, {
						render: 'consent not updated',
						type: 'error',
						isLoading: false,
						closeOnClick: true,
						autoClose: 5000
					})
				}
			})
		})
	}

	const consentRules = [
		() => ({
			validator(_, value) {
				if (!value || value === false) {
					return Promise.reject(new Error(lang('please-check')))
				}
				return Promise.resolve()
			}
		})
	]

	return (
		<div className="scroll-view">
			{consent &&
				consent.map((item, index) => {
					if (item.key === 'user-consent') {
						return (
							<div key={item.title} className="user-consent-container">
								<div key={index} className="user-consent-item">
									<div className="user-consent-item-title">
										<h2>{item.title}</h2>
									</div>
									<div dangerouslySetInnerHTML={{ __html: item.text }} className="user-consent-item-content" />
								</div>
								<div className="agree-checkbox">
									<Form form={consentForm}>
										<Form.Item name="agree" valuePropName="checked" rules={consentRules}>
											<Checkbox>{lang('agree-to-consent')}</Checkbox>
										</Form.Item>
									</Form>
								</div>

								<div className="button-container">
									<div style={{ float: 'left' }}></div>
									<Button type="save" onClick={handleAgreeToConsent}>
										{lang('submit')}
									</Button>
								</div>
							</div>
						)
					}
				})}
		</div>
	)
}

export default UserConsent