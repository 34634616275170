import { io } from 'socket.io-client'

let instance = null

function Socket() {
	let socket = io(process.env.REACT_APP_SOCKET_SERVER_URL)
	return {
		listen: (event, cb) => {
			return socket.on(event, cb)
		},
		getId: () => socket.id,
		isConnected: () => socket.connected,
		types: {
			NOTIFICATION: 'Notification'
		}
	}
}

if (!instance) {
	instance = new Socket()
}

export default instance
