import React, { useState } from 'react'
import { Redirect, useLocation, useHistory } from 'react-router-dom'

import { LangContext } from '../../components/Translation'
import { useGlobalContext } from '../../components/AppContext'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'
import AlarmsTable from '../../applets/AlarmsTable'

import "./index.css"
import translations from './translations'

export default function RPAAlarms() {

  const [selectedPatient, setSelectedPatient] = useState()
 
  const lang = React.useContext(LangContext)(translations)
  const location = useLocation()
  let history = useHistory()

  const context = useGlobalContext()
  const selectedClinic = context.globals.selectedCenter

  const renderPatientRedirect = () => {
		return selectedPatient ? (
			<Redirect
				to={{
					pathname: `/patient/${selectedPatient}`,
					state: { referrer: location.pathname },
				}}
			/>
		) : null
	}
  const handlePatientSelection = (c) => {
		setSelectedPatient(c)
    history.push('/rpaAlarms')
	}

  return (
    <PageHeader
      title={ lang("title") }
    >
      <PageContent>
        {renderPatientRedirect()}
          <div className="container-xl">
            <div className="row row-deck row-cards">
              <div className="col-sm-12 col-lg-12">
                <AlarmsTable goToPatient={handlePatientSelection} clinic={ selectedClinic }/>
              </div>
            </div>
          </div>
      </PageContent>
    </PageHeader>
  )
}
