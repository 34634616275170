import React from 'react'
import { Careplan as CareplanApplet } from '../../applets/Careplan'

import { useParams } from 'react-router-dom'

export default function Careplan(){

	let { id } = useParams()

	return (	
		<CareplanApplet id={id} ></CareplanApplet>	
	)
}