import React from 'react'
import globalTrans from './global'

const LangContext = React.createContext()

export default function Translation(props) {
	const translate = (textID, params, localTrans) => {
		let text = localTrans && localTrans[props.locale] ? localTrans[props.locale][textID] : null
		text = text ? text : globalTrans[props.locale] ? globalTrans[props.locale][textID] : null

		if (!text) {
			//console.warn(`No translation for ID ${textID} found.`)
			return null
		}
		for (let param in params) {
			text = text.replace('${' + param + '}', params[param])
		}
		return text
	}

	const init = (localTranslations) => {
		return (textID, params) => {
			return translate(textID, params, localTranslations)
		}
	}

	return <LangContext.Provider value={init}>{props.children}</LangContext.Provider>
}


export { LangContext }
