import React, { useState, useEffect } from 'react'
import { Layout as ALayout } from 'antd'

import PubSub from 'pubsub-js'

import NoAccess from '../../../pages/NoAccess'
import UserConsent from '../../../pages/UserConsent'
import BugReport from '../../BugReport'
import ECRF from '../../../pages/ECRF'
import ECRFEditor from '../../ECRFEditor'
import Chat from '../../../applets/Chat'
import Dashboard from '../../../pages/Dashboard'
import Appointments from '../../../pages/Appointments'
import Recruitement from '../../../pages/Recruitement'
import PatientScoringExport from '../../../pages/PatientScoringExport'
import OTT from '../../../pages/onko/OTT'
import Laboratory from '../../../pages/onko/Laboratory'
import PatientConsultationNeed from '../../../pages/PatientConsultationRequest'
import RPAAlarms from '../../../pages/RPAAlarms'
import Patients from '../../../pages/Patients'
import Patient from '../../../pages/Patient'
import Tasks from '../../../pages/Tasks'
import Careplan from '../../../pages/Careplan'
import Careplans from '../../../pages/Careplans'
import { Cms } from '../../../pages/CMS'
import UserManagement from '../../../pages/UserManagement'
import CareTeamManagement from '../../../pages/CareTeamManagement'
import EPROScores from '../../../pages/EPROScores'
import Symptoms from '../../../pages/Symptoms'
import GroupKPIs from '../../../pages/GroupKPIs'
import FHIRExport from '../../../pages/FHIRExport'
import HSPDashboard from '../../../pages/HSPDashboard'
import { HealthServiceProviders } from '../../../pages/HealthServiceProviders'
import PatientEngagement from '../../../pages/PatientEngagement'
import AppointmentChart from '../../AppointmentsChart'

import SplashScreen from '../../../components/SplashScreen'
import Icon from '../../../components/Icon'

import { LangContext } from '../../../components/Translation'
import translations from './translations'
import { useGlobalContext } from '../../../components/AppContext'
import { useKeycloak } from '@react-keycloak/web'

import AppNavbar from '../../../components/AppNavbar'
import PageTopbar from '../../../components/PageTopbar'

import { Switch, Route } from 'react-router-dom'

const { Footer } = ALayout

export default function Layout() {
	const lang = React.useContext(LangContext)(translations)

	const { initialized } = useKeycloak()

	const { globals, isProductionSystem, ALLPERMISSIONS, isEnavProject, isBetterCareProject, setUserConsentChange } = useGlobalContext()
	const [openChat, setChatOpen] = useState(false)
	const [unreadChatMessages, setUnreadChatMessages] = useState(0)

	useEffect(() => {
		PubSub.subscribe('chat:start', () => {
			setChatOpen(true)
		})
	}, [])

	const exactMatch = true
	const checkPermissions = (permissions = [], exactMatch = false) => {
		let res = false

		if (!isPermissionsLoaded()) return res

		if (exactMatch) {
			if (globals.user.permissions.length === permissions.length && permissions.every((perm) => globals.user.permissions.includes(perm))) {
				res = true
			}
		} else {
			permissions.forEach((permission) => {
				if (globals.user.permissions.includes(permission)) {
					res = true
				}
			})
		}

		return res
	}

	const isConsentGiven = () => {
		
		return globals.user.consent_given
	}

	const isPermissionsLoaded = () => {
		return globals.user.permissions !== undefined
	}

	if (!initialized || !isPermissionsLoaded()) {
		return <SplashScreen />
	}


	if(isBetterCareProject() &&  (!initialized || !isConsentGiven())) {
		return <UserConsent user={globals.user} setUserConsentChange={setUserConsentChange} />
	}




	const noAccess = <NoAccess />

	const routes = [
		{
			icon: <Icon type={'dashboard'} />,
			title: lang('dashboard'),
			path: '/',
			main: checkPermissions(
				[ALLPERMISSIONS.CAREPLAN_RW, ALLPERMISSIONS.CMS_R, ALLPERMISSIONS.CMS_PUBLISH, ALLPERMISSIONS.STANDART],
				exactMatch
			) ? (
				noAccess
			) : checkPermissions([ALLPERMISSIONS.HSP_RW]) ? (
				<HSPDashboard />
			) : (
				<Dashboard />
			),
			hidden: !checkPermissions([ALLPERMISSIONS.STANDART, ALLPERMISSIONS.ANALYTICS]),
			disabled: false
		},
		{
			icon: <Icon type="administrative" />,
			title: lang('administration'),
			path: '',
			hidden: !checkPermissions([
				ALLPERMISSIONS.CARE_TEAM_RW,
				ALLPERMISSIONS.USER_RW,
				ALLPERMISSIONS.CARE_TEAM_R,
				ALLPERMISSIONS.HSP_MANAGER_R,
				ALLPERMISSIONS.HSP_MANAGER_RW
			]),
			children: [
				{
					icon: <Icon type="edit-user" />,
					title: lang('user-management'),
					path: '/userManagement',
					main: checkPermissions([ALLPERMISSIONS.USER_RW]) ? <UserManagement /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.USER_RW]),
					disabled: false
				},
				{
					icon: <Icon type="edit-user" />,
					title: lang('careteam-management'),
					path: '/CareTeamManagement',
					main: checkPermissions([ALLPERMISSIONS.CARE_TEAM_R]) ? <CareTeamManagement /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.CARE_TEAM_R]),
					disabled: false
				},
				{
					icon: <Icon type="edit-user" />,
					title: lang('health-service-provider-management'),
					path: '/HealthServiceProviders',
					main: checkPermissions([ALLPERMISSIONS.HSP_MANAGER_R, ALLPERMISSIONS.HSP_MANAGER_RW]) ? <HealthServiceProviders /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.HSP_MANAGER_R, ALLPERMISSIONS.HSP_MANAGER_RW]),
					disabled: false
				}
			]
		},
		// CARE MANAGMENT
		{
			icon: <Icon type={'care-management'} />,
			title: lang('care-management'),
			path: '',
			hidden: !checkPermissions([
				ALLPERMISSIONS.PEROSNAL_DATA_R,
				ALLPERMISSIONS.PEROSNAL_DATA_RW,
				ALLPERMISSIONS.PATIENT_R,
				ALLPERMISSIONS.PATIENT_RW,
				ALLPERMISSIONS.CAREPLAN_R,
				ALLPERMISSIONS.CAREPLAN_RW,
				ALLPERMISSIONS.CAREPLAN_PUBLISH
			]),
			children: [
				{
					icon: <Icon type={'patients'} />,
					title: lang('patients'),
					path: '/patients',
					main: checkPermissions([ALLPERMISSIONS.PATIENT_R, ALLPERMISSIONS.PATIENT_RW]) ? <Patients /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.PATIENT_R, ALLPERMISSIONS.PATIENT_RW]),
					disabled: false
				},
				{
					icon: <Icon type={'patients'} />,
					title: lang('tasks'),
					path: '/tasks',
					main: checkPermissions([ALLPERMISSIONS.PATIENT_R, ALLPERMISSIONS.PATIENT_RW]) ? <Tasks /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.PATIENT_R, ALLPERMISSIONS.PATIENT_RW]),
					disabled: false
				},
				{
					icon: <Icon type={'ott'} />,
					title: 'OTT',
					path: '/ott',
					main: checkPermissions([ALLPERMISSIONS.ONKO]) ? <OTT /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.ONKO]),
					disabled: true
				},
				{
					icon: <Icon type={'laboratory'} />,
					title: 'Laboratory',
					path: '/laboratory',
					main: checkPermissions([ALLPERMISSIONS.ONKO]) ? <Laboratory /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.ONKO]),
					disabled: true
				},
				{
					icon: <Icon type={'appointment'} />,
					title: lang('appointments'),
					path: '/appointments',
					main: checkPermissions([ALLPERMISSIONS.PATIENT_R]) ? <Appointments /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.PATIENT_R]),
					disabled: false
				},
				{
					icon: <Icon type={'rpalarms'} />,
					title: lang('symptom-alarms'),
					path: '/rpaAlarms',
					main: checkPermissions([ALLPERMISSIONS.PEROSNAL_DATA_R, ALLPERMISSIONS.PATIENT_RW]) ? <RPAAlarms /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.PEROSNAL_DATA_R, ALLPERMISSIONS.PATIENT_RW]),
					disabled: false
				},
				{
					icon: <Icon type={'recruitement'} />,
					title: lang('recruitement'),
					path: '/recruitement',
					main: checkPermissions([ALLPERMISSIONS.PEROSNAL_DATA_R, ALLPERMISSIONS.PATIENT_RW]) ? <Recruitement /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.PEROSNAL_DATA_R, ALLPERMISSIONS.PATIENT_RW]),
					disabled: false
				},
				{
					icon: <Icon type={'patients'} />,
					title: lang('patient-scoring-export'),
					path: '/scoringExport',
					main: checkPermissions([ALLPERMISSIONS.CAREPLAN_RW]) ? <PatientScoringExport /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.CAREPLAN_RW]) || isBetterCareProject(),
					disabled: false
				},
				{
					icon: <Icon type={'careplans'} />,
					title: lang('careplans'),
					path: '/careplans',
					main: checkPermissions([ALLPERMISSIONS.CAREPLAN_RW, ALLPERMISSIONS.CAREPLAN_PUBLISH]) ? <Careplans /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.CAREPLAN_RW, ALLPERMISSIONS.CAREPLAN_PUBLISH]),
					disabled: false
				}
			]
		},
		{
			icon: <Icon type={'cms'} />,
			title: lang('cms'),
			path: '/cms',
			main: checkPermissions([ALLPERMISSIONS.CMS_RW, ALLPERMISSIONS.CMS_PUBLISH]) ? <Cms /> : noAccess,
			hidden: !checkPermissions([ALLPERMISSIONS.CMS_RW]),
			disabled: false
		}, 
		{
			icon: <Icon type={'ecrf-editor'} />,
			title: lang('ecrf-editor'),
			path: '/ecrfEditor',
			main: checkPermissions([ALLPERMISSIONS.ECRF_EDITOR_RW]) ? <ECRFEditor /> : noAccess,
			hidden: !checkPermissions([ALLPERMISSIONS.ECRF_EDITOR_RW]),
			disabled: false
		},
		{
			icon: <Icon type={'analytics'} />,
			title: lang('analytics'),
			path: '',
			disabled: true,
			hidden: !checkPermissions([ALLPERMISSIONS.ANALYTICS]),
			children: [
				{
					icon: <Icon type={'eproscores'} />,
					title: 'Score QLQC30',
					path: '/eProScores',
					main: checkPermissions([ALLPERMISSIONS.ANALYTICS]) ? <EPROScores /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.ANALYTICS]) || isProductionSystem() || isEnavProject(),
					disabled: false
				},
				{
					icon: <Icon type={'eproscores'} />,
					title: lang('fhir'),
					path: '/FHIR',
					main: checkPermissions([ALLPERMISSIONS.ANALYTICS]) ? <FHIRExport /> : <Dashboard />,
					hidden: !checkPermissions([ALLPERMISSIONS.ANALYTICS]) || isEnavProject(),
					disabled: true
				},
				{
					icon: <Icon type={'eproscores'} />,
					title: lang('group-scores'),
					path: '/GroupKPIs',
					main: checkPermissions([ALLPERMISSIONS.ANALYTICS]) ? <GroupKPIs /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.ANALYTICS]) || isEnavProject(),
					disabled: false
				},
				{
					icon: <Icon type={'eproscores'} />,
					title: lang('symptom-trend'),
					path: '/Symptoms',
					main: checkPermissions([ALLPERMISSIONS.ANALYTICS]) ? <Symptoms /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.ANALYTICS]) || isEnavProject(),
					disabled: false
				},
				{
					icon: <Icon type={'eproscores'} />,
					title: 'Patient Engagement',
					path: '/PatientEngagement',
					main: checkPermissions([ALLPERMISSIONS.ANALYTICS]) ? <PatientEngagement /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.ANALYTICS]),
					disabled: false
				},
				{
					icon: <Icon type={'eproscores'} />,
					title: lang('appointments-chart'),
					path: '/AppointmentsChart',
					main: checkPermissions([ALLPERMISSIONS.ANALYTICS]) ? <AppointmentChart /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.ANALYTICS]) || isEnavProject(),
					disabled: false
				},
				{
					icon: <Icon type={'rpa-analysis'} />,
					title: lang('rpa-analysis'),
					path: '/rpaAnalysis',
					hidden: true,
					disabled: true
				},
				{
					icon: (
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
							<path d="M0 0h24v24H0z" fill="none" />
							<path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z" />
						</svg>
					),
					title: lang('consultation-request'),
					path: '/patientConsultationRequest',
					main: checkPermissions([ALLPERMISSIONS.ANALYTICS]) ? <PatientConsultationNeed /> : noAccess,
					hidden: !checkPermissions([ALLPERMISSIONS.ANALYTICS]) || isEnavProject(),
					disabled: false
				}
			]
		},
		{
			icon: <Icon type={'bug-report'} />,
			title: lang('report-a-bug'),
			path: '/bugReport',
			main: <BugReport />,
			hidden: true,
			disabled: false
		}
	]

	const serializedRoute = []
	const serializeRoute = (e) => {
		if (e.main) {
			serializedRoute.push(e)
		}
		e.children && e.children.forEach(serializeRoute)
	}
	routes.forEach(serializeRoute)
	serializedRoute.push({
		path: '/patient/:id',
		main: checkPermissions([ALLPERMISSIONS.PATIENT_R, ALLPERMISSIONS.PATIENT_RW]) ? <Patient /> : noAccess
	})
	serializedRoute.push({
		path: '/careplan/:id',
		main: checkPermissions([ALLPERMISSIONS.CAREPLAN_RW]) ? <Careplan /> : noAccess
	})

	return (
		<ALayout style={{ minHeight: '100vh' }} className="exo-layout">
			<AppNavbar routes={routes}></AppNavbar>
			<PageTopbar
				unreadChatMessages={unreadChatMessages}
				toogleChat={() => {
					setChatOpen(!openChat)
				}}>
				<Switch>
					{serializedRoute.map((route, index) => (
						<Route key={index} path={route.path} exact={true}>
							{route.main}
						</Route>
					))}
				</Switch>
				<Footer />
			</PageTopbar>
			<Chat display={openChat} onUnreadChatMessages={setUnreadChatMessages}></Chat>
		</ALayout>
	)
}
