
import React from "react"
import "./index.css"

export default function SplashScreen() {

  return (
    <div className='splashscreen'>
        <div className="center">
          <img
              src="/img/logo.svg"
              alt="PIA Logo"
              height="64"
              className="logo"
            />
        </div>
    </div>
  )
}