import React from 'react'

import List from '../../../components/List'

import { LangContext } from '../../../components/Translation'
import translations from './translations'

import './index.css'

export default function CareConnects({
	className = '',
	data
}) {

	const lang = React.useContext(LangContext)(translations)

	const needs = Array.isArray(data)
		? data.map(d => {
				return {
					title: lang("consultation-request"),
					content: d,
					id: d,
				}
		  })
		: []

	return (
		<div className={`${className}`}>
			<div className="col-md-12 col-xl-12">
					<div className="exo-consultation-requests">
						<List data={needs} />
					</div>
				</div>
		</div> 
	)
}
