import React, { useState, useEffect } from 'react'

import sdk from 'matrix-js-sdk'

import Backend from '../../api/Backend'
import Utils from '../../components/Utils'
import MatrixChat from './MatrixChat'

let client = null

const utils = new Utils()

const fetchAccessToken = (backend) => {
	return new Promise((resolve, reject) => {
		try {
			backend.matrixLogin({ cb: resolve })
		} catch (e) {
			console.error('Could not fetch matrix access token', e)
			reject()
		}
	})
}

export default function Chat({ display, onUnreadChatMessages }) {
	const [credentials, setCredentials] = useState()
	const backend = new Backend()

	const login = () => {
		fetchAccessToken(backend)
			.then((credentials) => {
				client = sdk.createClient({
					baseUrl: `https://${credentials.home_server}`,
					accessToken: credentials.access_token,
					userId: credentials.user_id,
					deviceId: `web_portal_device_${credentials.user_id}`
				})
				client.startClient({ initialSyncLimit: 100 })
				setCredentials(credentials)
			})
			.catch((err) => {
				// TODO: show error message to the user
			})
	}

	useEffect(() => {
		login()
	}, [])

	const chat = credentials ? <MatrixChat display={display} client={client} userId={credentials.user_id} onUnreadChatMessages={onUnreadChatMessages} /> : null

	return chat
}

/* 




*/
