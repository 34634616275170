import React from 'react'
import { Select } from 'antd'

import translations from './translations'
import { LangContext } from '../Translation'

import './index.css'

const { Option } = Select

export default function PeopleSelect({
	className = '',
	bordered,
	allowClear,
	value,
	people = [],
	placeholder = null,
	title,
	disabled = false,
	isMulti = false,
	onChange = () => {},
	style = {},
	required = false
}) {
	const lang = React.useContext(LangContext)(translations)

	placeholder = placeholder ? placeholder : lang('placeholder')

	const getOptions = () => {
		if (people) {
			return people.map((d) => {
				return (
					<Option key={d.userId} value={d.name}>
						{
							<div key={d.userId}>
								<div>{d.surname ? `${d.name} ${d.surname}` : `${d.name}`}</div>
							</div>
						}
					</Option>
				)
			})
		} else {
			return []
		}
	}

	return (
		<div className={`exo-select ${className}`} style={style}>
			{title ? <label className={`form-label label-spacing ${required ? 'required' : ''}`}>{title}</label> : null}
			<Select
				bordered={bordered}
				placeholder={placeholder ? placeholder : lang('select')}
				mode={isMulti ? 'multiple' : undefined}
				value={value}
				allowClear={allowClear}
				disabled={disabled}
				onChange={(e, f) => onChange(f.key)}
				style={style}>
				{getOptions()}
			</Select>
		</div>
	)
}
