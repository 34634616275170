import React from 'react'

import { LangContext } from '../Translation'
import translations from './translations'
import Utils from '../Utils'
import PatientChart from '../../applets/PatientChart'

const PatientOverview = ({ id, patientData = {} }) => {
	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()

	return (
		<PatientChart
			patientId={id}
			settingId={patientData ? patientData?.setting_id : null}
			patientName={patientData ? `${patientData?.patient_name}` : ''}
		/>
	)
}

export default PatientOverview
