import React from 'react';
import { Skeleton as ASkeleton } from 'antd';

export default function Skeleton({ 
	hide, 
	type = 'list', 
	className, 
	children,
	rows = 2 
}) {	

	if(hide){
		return children ? children : <React.Fragment></React.Fragment>
	}

	const defaultSkeleton = <ASkeleton active className={className} paragraph={{ rows }} />

	switch(type.toLowerCase()) {
		case 'card':
			return defaultSkeleton;
		case 'list':
			return <ASkeleton active className={className} paragraph={{ rows }} />
		case 'statistics':
			return <ASkeleton avatar active className={className} paragraph={{ rows }} />
		default:
		return defaultSkeleton;
  }
}
