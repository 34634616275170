import React, { useState, useEffect } from 'react'
import Table from '../../../components/Table'
import { LangContext } from '../../../components/Translation'
import translations from './translations'
import Skeleton from '../../../components/Skeleton'

import Utils from '../../../components/Utils'
import Backend from '../../../api/Backend'

const LaboratoryPatientHistory = ({ patient, onSelect }) => {

	const lang = React.useContext(LangContext)(translations)

	const utils = new Utils()
	const backend = new Backend()

	const [patientLabValues, setPatientLabValues] = useState([])

	useEffect(() => {
		//backend.laboratoryFindings({ patient, cb: setLaboratoryFindings })
	}, [patient])

	const renderDate = ({ value }) => {
		const date = utils.toDate({ dateString: value })
		return <label style={{ marginLeft: '5px' }}>{date}</label>
	}

	const renderNorms = ({ row }) => {
		return `${row.norm_value_from} - ${row.norm_value_till}`
	}

	const data = patientLabValues.map((norm) => {
		return {
			name: `${norm.name}`,
			date: utils.toDate({ dateString: norm.created_at }),
			unit: norm.unit,
			norm: renderNorms('value', norm),
		}
	})

	const columns = [
		{ key: 'patient_id', value: lang('patientName'), sfixed: "left" },
		{ key: 'creator_id', value: lang('creator'), fixed: "left" },
		{ key: 'type', value: lang('type'), fixed: "left" },
		{ key: 'created_at', value: lang('createdAt'), render: renderDate  },
		{ key: 'updated_at', value: lang('updatedAt'), render: renderDate  },
		{ key: 'status', value: lang('status') },
		{ key: 'norm', value: lang('norm'), render: renderNorms }
	]

	return (
		<Skeleton type={'list'} hide={ data }>
			<Table
				borderless={true}
				headless={true}
				expandable={true}
				columns={ columns }
				data={ data }				
			/>			
		</Skeleton>
	)
}

export default LaboratoryPatientHistory
