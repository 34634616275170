const CARD_ICONS = {
	attachments: {
		iconLink: `${process.env.PUBLIC_URL}/img/pdf.svg`
	},
	external: {
		iconLink: `${process.env.PUBLIC_URL}/img/arrow.svg`
	},
	internal: {
		iconLink: `${process.env.PUBLIC_URL}/img/arrow.svg`
	}
}

const CONTAINERS = {
	attachments: {
		tagRegex: /attachment-tag="((\w|\s|\d|[.,!'@#$%^&*()/])*)"/gi,
		titleRegex: /attachment-title="((\w|\s|\d|[.,!'@#$%^&*()/])*)"/gi,
		linkRegex: /attachment-link=".*"/gi,
		content: `<div class='attachment-card'>
        <div class='attachment-text'>
          <div class='attachment-tag'>[[tag]]</div>
          <div class='attachment-title'>[[title]]</div>
        </div>
        <a class='attachment-link'>[[link]]</a>
        <div class='attachment-icon'><img src=${CARD_ICONS?.attachments?.iconLink}></img></div>
      </div>`
	},
	external: {
		tagRegex: /external-tag="((\w|\s|\d|[.,!'@#$%^&*()/])*)"/gi,
		titleRegex: /external-title="((\w|\s|\d|[.,!'@#$%^&*()/])*)"/gi,
		linkRegex: /external-link=".*"/gi,
		content: `<div class='external-card'>
        <div class='external-text'>
          <div class='external-tag'>[[tag]]</div>
          <div class='external-title'>[[title]]</div>
        </div>
        <a class='external-link'>[[link]]</a>
        <div class='attachment-icon'><img src=${CARD_ICONS?.external?.iconLink}></img></div>
      </div>`
	},
	internal: {
		imageRegex: /internal-image=".*"/gi,
		linkRegex: /internal-link=".*"/gi,
		titleRegex: /internal-title="((\w|\s|\d|[.,!'@#$%^&*()/])*)"/gi,
		content: `<div class='internal-article'>
                <img class='internal-image' src=[[image]]></img>
                <a class='internal-link'>[[link]]</a>
                <div class='internal-title'>[[title]]</div>
                <div class='internal-icon'><img src=${CARD_ICONS?.internal?.iconLink}></img></div>
              </div>`
	},
	collapsible: {
		contentRegex: /\[\[content\]\]/gi,
		content: `<p>[[content]]</p>`
	},
	conditional: {
		conditionalRegex: /<conditional-\w*>(.*)<\/conditional/gi,
		content: `<div class='conditional-content'>[[conditional]]</div>`
	}
}

const regexReplacer = (state, options) => {
	let match, token

	if (options.openingRegex.test(state.src) && options.closingRegex.test(state.src)) {
		match = state.src.split(options.openingRegex)[1].split(options.closingRegex)[0]

		state.src = state.src.replace(options.openingRegex, '')
		state.src = state.src.replace(options.closingRegex, '')
		state.src = ''
		token = state.push(options.name, match, 0)
		token.content = ''

		return true
	}

	return false
}

export default function regexPlugin(md, options) {
	md.inline.ruler.push(options.name, state => regexReplacer(state, options))

	md.renderer.rules[options.name] = (tokens, idx) => {
		// ATTACHMENTS
		if (options.name === 'attachments') {
			let updatedContainer = CONTAINERS[options.name].content

			if (updatedContainer.includes('[[tag]]') && tokens[idx].tag.includes('attachment-tag')) {
				try {
					let newTag = tokens[idx].tag
						.match(CONTAINERS[options.name].tagRegex)[0]
						.match(/"((.*))"/gi)[0]
						.replace(/"/g, '')
					updatedContainer = updatedContainer.replace('[[tag]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			if (updatedContainer.includes('[[title]]') && tokens[idx].tag.includes('attachment-title')) {
				try {
					let newTag = tokens[idx].tag
						.match(CONTAINERS[options.name].titleRegex)[0]
						.match(/"((.*))"/gi)[0]
						.replace(/"/g, '')
					updatedContainer = updatedContainer.replace('[[title]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			if (updatedContainer.includes('[[link]]') && tokens[idx].tag.includes('attachment-link')) {
				try {
					let newTag = tokens[idx].tag
						.match(CONTAINERS[options.name].linkRegex)[0]
						.match(/"((.*))"/gi)[0]
						.replace(/"/g, '')
					updatedContainer = updatedContainer.replace('[[link]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			return updatedContainer
		} else if (options.name === 'external') {
			let updatedContainer = CONTAINERS[options.name].content

			if (updatedContainer.includes('[[tag]]') && tokens[idx].tag.includes('external-tag')) {
				try {
					let newTag = tokens[idx].tag
						.match(CONTAINERS[options.name].tagRegex)[0]
						.match(/"((.*))"/gi)[0]
						.replace(/"/g, '')
					updatedContainer = updatedContainer.replace('[[tag]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			if (updatedContainer.includes('[[title]]') && tokens[idx].tag.includes('external-title')) {
				try {
					let newTag = tokens[idx].tag
						.match(CONTAINERS[options.name].titleRegex)[0]
						.match(/"((.*))"/gi)[0]
						.replace(/"/g, '')
					updatedContainer = updatedContainer.replace('[[title]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			if (updatedContainer.includes('[[link]]') && tokens[idx].tag.includes('external-link')) {
				try {
					let newTag = tokens[idx].tag
						.match(CONTAINERS[options.name].linkRegex)[0]
						.match(/"((.*))"/gi)[0]
						.replace(/"/g, '')
					updatedContainer = updatedContainer.replace('[[link]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			return updatedContainer
		} else if (options.name === 'internal') {
			let updatedContainer = CONTAINERS[options.name].content

			if (updatedContainer.includes('[[image]]') && tokens[idx].tag.includes('internal-image')) {
				try {
					let newTag = tokens[idx].tag
						.match(CONTAINERS[options.name].imageRegex)[0]
						.match(/"((.*))"/gi)[0]
						.replace(/"/g, '')
					updatedContainer = updatedContainer.replace('[[image]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			if (updatedContainer.includes('[[link]]') && tokens[idx].tag.includes('internal-link')) {
				try {
					let newTag = tokens[idx].tag
						.match(CONTAINERS[options.name].linkRegex)[0]
						.match(/"((.*))"/gi)[0]
						.replace(/"/g, '')
					updatedContainer = updatedContainer.replace('[[link]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			if (updatedContainer.includes('[[title]]') && tokens[idx].tag.includes('internal-title')) {
				try {
					let newTag = tokens[idx].tag
						.match(CONTAINERS[options.name].titleRegex)[0]
						.match(/"((.*))"/gi)[0]
						.replace(/"/g, '')
					updatedContainer = updatedContainer.replace('[[title]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			return updatedContainer
		} else if (options.name === 'collapsible') {
			let updatedContainer = CONTAINERS[options.name].content
			if (updatedContainer.includes('[[content]]')) {
				try {
					let newTag = tokens[idx].tag
					updatedContainer = updatedContainer.replace('[[content]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			return updatedContainer
		} else if (options.name === 'conditional') {
			let updatedContainer = CONTAINERS[options.name].content
			if (updatedContainer.includes('[[conditional]]')) {
				try {
					let newTag = tokens[idx].tag
					updatedContainer = updatedContainer.replace('[[conditional]]', newTag)
				} catch (e) {
					console.log(e)
				}
			}
			return updatedContainer
		}
	}
}
