import React from 'react'
import List from '../../../components/List'
import Tag from '../../../components/Tag'
import Utils from '../../../components/Utils'

export default function Assessment({ className = '', data }) {
	const utils = new Utils()

	return (
		<div className={`${className}`}>
			{data?.length > 0 ? (
				<List
					data={data.map((d) => {
						return {
							title: d.title,
							content: (
								<div>
									<Tag>{d?.status}</Tag>
									<Tag>{d?.resource?.demand}</Tag>
								</div>
							),
							description: (
								<>
									<div>{d.type}</div>
									<div>{utils.toDate({ dateString: d.created_at })}</div>
								</>
							),
							id: d.id
						}
					})}
					pagination={null}
				/>
			) : null}
		</div>
	)
}
