import React, { useState, useEffect } from 'react'

import { toast } from 'react-toastify'

import { LangContext } from '../../components/Translation'
import translations from './translations'

import moment from 'moment'

import Table from '../../components/Table'
import Button from '../../components/Button'
import Drawer from '../../components/Drawer'
import EcrfSelect from '../../components/EcrfSelect/EcrfSelect'
import DecisionModal from '../../components/DecisionModal'

import Backend from '../../api/Backend'

import { FORM_ITEM_TYPES } from '../../pages/ECRFEditor'
import Utils from '../../components/Utils'
import Skeleton from '../../components/Skeleton'
import { Form, Input, InputNumber, Card, Select, DatePicker, Switch } from 'antd'

import './index.css'

const DEFAULT_TOAST_TIMER = 5000

const PatientECRFEditor = ({ openPatientECRFDrawer, setOpenPatientECRFDrawer, setPatientECRF, patientECRF }) => {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()

	const [patientECRFForm] = Form.useForm()

	const [template, setTemplate] = useState()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(true)
		if (patientECRF?.type) {
			backend.ECRF({
				patientECRF: true,
				id: patientECRF.id,
				cb: (data) => {
					if (Array.isArray(data)) {
						let newData = data[0]
						// this mutates the object so that initial values go into DatePicker nicely. ANTD sucks sometimes...
						newData.resource.forEach((item) => {
							if (item.form_type === FORM_ITEM_TYPES.DATE_PICKER && item?.answer) {
								item.answer = moment(item.answer)
							}
						})
						setTemplate(newData)
					}
					setLoading(false)
				},
				cbError: (err) => {
					toast.error('Could not fetch ECRF template: ', err)
				}
			})
		}

		return () => {
			setTemplate(null)
			patientECRFForm.resetFields()
		}
	}, [patientECRF])

	const MainForm = () => {
		if (template?.title) {
			return <InitializedForm />
		} else {
			return <LoadingForm />
		}
	}

	const LoadingForm = () => {
		return <></>
	}

	const InputType = ({ template, name = null }) => {
		if (!template || name === null) {
			return <>Error</>
		}

		let rules = [
			{
				required: false
			}
		]

		const isMandatory = template?.mandatory

		if (isMandatory) {
			rules = [
				{
					required: true,
					message: lang('missing-input')
				}
			]
		}

		if (template.form_type === FORM_ITEM_TYPES.INPUT) {
			return (
				<Form.Item name={[name, 'answer']} rules={rules}>
					<Input />
				</Form.Item>
			)
		} else if (template.form_type === FORM_ITEM_TYPES.INPUT_NUMBER) {
			return (
				<Form.Item name={[name, 'answer']} rules={rules}>
					<InputNumber min={template?.min_value} max={template?.max_value} />
				</Form.Item>
			)
		} else if (template.form_type === FORM_ITEM_TYPES.SELECT) {
			return (
				<Form.Item name={[name, 'answer']} rules={rules}>
					<Select
						options={template.options.map((opp) => {
							return { value: opp, label: opp }
						})}
					/>
				</Form.Item>
			)
		} else if (template.form_type === FORM_ITEM_TYPES.TEXT_AREA) {
			return (
				<Form.Item name={[name, 'answer']} rules={rules}>
					<Input.TextArea />
				</Form.Item>
			)
		} else if (template.form_type === FORM_ITEM_TYPES.DATE_PICKER) {
			return (
				<Form.Item name={[name, 'answer']} rules={rules}>
					<DatePicker />
				</Form.Item>
			)
		} else if (template.form_type === FORM_ITEM_TYPES.SWITCH) {
			return (
				<Form.Item initialValue={false} name={[name, 'answer']} valuePropName="checked" rules={rules}>
					<Switch />
				</Form.Item>
			)
		}

		return template.form_type
	}

	const InitializedForm = () => {
		return (
			<Form form={patientECRFForm} initialValues={template}>
				<Form.List name="ecrf_inputs" initialValue={template?.resource}>
					{(fields) => (
						<>
							{fields.map(({ key, name, ...restField }) => {
								return (
									<Card key={key} style={{ margin: '10px' }}>
										<div className="ecrf-card-container">
											<div className="ecrf-form-card">
												<Form.Item name={[name, 'label']} label={lang('label')}>
													<Input bordered={false} disabled />
												</Form.Item>
												<Form.Item hidden name={[name, 'key']} label={lang('key')}>
													<Input hidden />
												</Form.Item>
												<InputType name={name} template={template.resource[key]} />
											</div>
										</div>
									</Card>
								)
							})}
						</>
					)}
				</Form.List>
			</Form>
		)
	}

	return (
		<Drawer
			width="90vw"
			autoclose
			open={openPatientECRFDrawer}
			onClose={() => {
				setOpenPatientECRFDrawer(false)
				setPatientECRF(null)
			}}
			onOK={() => {
				patientECRFForm.validateFields().then((values) => {
					const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
					backend.ECRF({
						type: backend.type.UPDATE,
						id: patientECRF?.id,
						patientECRF: true,
						body: JSON.stringify(values),
						cb: () => {
							toast.update(loadingToast, {
								render: lang('ecrf-answers-saved'),
								type: 'success',
								isLoading: false,
								closeOnClick: true,
								autoClose: 5000
							})
						},
						cbError: (err) => {
							toast.update(loadingToast, {
								render: err?.message || lang('an-error-has-occured'),
								type: 'error',
								isLoading: false,
								closeOnClick: true,
								autoClose: 5000
							})
						}
					})
				})
			}}
			text={{
				title: lang('fill-out', { title: patientECRF?.title || 'ECRF' }),
				cancel: lang('cancel'),
				ok: lang('ok')
			}}>
			<Skeleton hide={!loading}>
				<MainForm />
			</Skeleton>
		</Drawer>
	)
}

const PatientECRF = ({ patientId }) => {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()
	const utils = new Utils()

	const [ecrfData, setEcrfData] = useState()
	const [ecrfTableData, setEcrfTableData] = useState()
	const [showEcrfOptions, setShowEcrfOptions] = useState(false)
	const [loading, setLoading] = useState(false)

	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const [openPatientECRFDrawer, setOpenPatientECRFDrawer] = useState(false)
	const [patientECRF, setPatientECRF] = useState()

	const handleEcrfSelect = (values) => {
		setEcrfData(values)
	}

	const refresh = () => {
		setLoading(true)
		backend.ECRF({
			patientId,
			all: true,
			patientECRF: true,
			cb: (data) => {
				setEcrfTableData(data)
			}
		})
		setLoading(false)
	}

	useEffect(() => {
		refresh()
	}, [])

	const handleEcrfAssignmentToPatient = () => {
		const loadingToast = toast.loading(lang('request_processing'), { closeOnClick: true })
		backend.ECRF({
			patientId,
			patientECRF: true,
			ecrfType: ecrfData[0]?.type,
			type: backend.type.CREATE,
			cb: () => {
				toast.update(loadingToast, {
					render: lang('patient_ecrf_add'),
					type: 'success',
					isLoading: false,
					closeOnClick: true,
					autoClose: DEFAULT_TOAST_TIMER
				})
				refresh()
			},
			cbError: (err) => {
				toast.update(loadingToast, {
					render: lang('processing_error'),
					type: 'error',
					isLoading: false,
					closeOnClick: true,
					autoClose: DEFAULT_TOAST_TIMER
				})
			}
		})
	}

	const handlePatientEcrfRemoval = ({ row }) => {
		const loadingToast = toast.loading(lang('request_processing'), { closeOnClick: true })
		backend.ECRF({
			rowId: row.id,
			type: backend.type.DELETE,
			patientECRF: true,
			cb: () => {
				toast.update(loadingToast, {
					render: lang('patient_ecrf_remove'),
					type: 'success',
					isLoading: false,
					closeOnClick: true,
					autoClose: DEFAULT_TOAST_TIMER
				})
				refresh()
			},
			cbError: (err) => {
				toast.update(loadingToast, {
					render: lang('processing_error'),
					type: 'error',
					isLoading: false,
					closeOnClick: true,
					autoClose: DEFAULT_TOAST_TIMER
				})
			}
		})
	}

	const EditRowBtn = ({ row }) => {
		return (
			<Button
				className="mr10"
				type="edit"
				onClick={() => {
					setOpenPatientECRFDrawer(true)
					setPatientECRF(row)
				}}
			/>
		)
	}

	const RemoveBtn = ({ row }) => {
		return <Button className="mr10" type="remove" onClick={() => setShowDeleteModal({ row })} />
	}

	return (
		<>
			<Drawer
				width="90vw"
				autoclose
				open={showEcrfOptions}
				onClose={() => {
					setShowEcrfOptions(false)
					setEcrfData(null)
				}}
				onOK={() => {
					setShowEcrfOptions(false)
					handleEcrfAssignmentToPatient()
					setEcrfData(null)
				}}
				text={{
					title: lang('select_ecrf'),
					cancel: lang('cancel'),
					ok: lang('ok')
				}}>
				<EcrfSelect patientId={patientId} onChange={handleEcrfSelect} value={ecrfData} />
			</Drawer>
			<Table
				loading={loading}
				title={lang('patient_ecrf')}
				borderless={false}
				headless={false}
				expandable={true}
				buttons={[
					<Button
						key="addECRFBtn"
						className="ant-btn-primary"
						onClick={() => {
							setShowEcrfOptions(true)
						}}>
						{lang('add_ecrf')}
					</Button>
				]}
				columns={[
					{ key: 'title', value: lang('title'), sorter: (a, b) => (a.title ? a.title.localeCompare(b.title) : false), search: true },
					{ key: 'type', value: lang('type'), sorter: (a, b) => (a.type ? a.type.localeCompare(b.type) : false), search: true },
					{
						key: 'created_at',
						value: lang('created-at'),
						render: ({ value }) => {
							return utils.toDate({ dateString: value, withoutTime: true })
						},
						sorter: (a, b) => moment(a.updatedDate, 'DD.MM.YYYY hh:mm').unix() - moment(b.updatedDate, 'DD.MM.YYYY hh:mm').unix(),
						defaultSortOrder: 'descend'
					},
					{
						key: 'changed_at',
						value: lang('changed-at'),
						render: ({ value }) => {
							return utils.toDate({ dateString: value, withoutTime: true })
						},
						sorter: (a, b) => moment(a.updatedDate, 'DD.MM.YYYY hh:mm').unix() - moment(b.updatedDate, 'DD.MM.YYYY hh:mm').unix()
					},
					{
						key: 'actions',
						value: lang('actions'),
						render: ({ row }) => {
							return (
								<>
									<EditRowBtn row={row} />
									<RemoveBtn row={row} />
								</>
							)
						}
					}
				]}
				data={ecrfTableData}
			/>
			<PatientECRFEditor
				openPatientECRFDrawer={openPatientECRFDrawer}
				setOpenPatientECRFDrawer={setOpenPatientECRFDrawer}
				setPatientECRF={setPatientECRF}
				patientECRF={patientECRF}
			/>
			<DecisionModal
				okType={'danger'}
				open={showDeleteModal}
				onClose={() => {
					setShowDeleteModal(false)
				}}
				text={{
					title: lang('remove-ecrf-confirmation', { title: showDeleteModal.row?.title }),
					cancel: lang('cancel'),
					ok: lang('confirm')
				}}
				onOK={() => {
					handlePatientEcrfRemoval(showDeleteModal)
				}}
			/>
		</>
	)
}

export default PatientECRF
