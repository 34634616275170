import React, { useState, useEffect } from 'react'
import Table from '../../../components/Table'
import { LangContext } from '../../../components/Translation'
import translations from './translations'
import Skeleton from '../../../components/Skeleton'
import Icon from '../../../components/Icon'
import { Card } from 'antd';

import Utils from '../../../components/Utils'
import Backend from '../../../api/Backend'

const LaboratoryFindings = ({ patient, onSelect }) => {

	const lang = React.useContext(LangContext)(translations)

	const utils = new Utils()
	const backend = new Backend()

	const [laboratoryFindings, setLaboratoryFindings] = useState()
	const [laboratoryNorms, setLaboratoryNorms] = useState()
	const [patientLabValues, setPatientLabValues] = useState({})

	useEffect(() => {
		backend.laboratoryNames({ cb: setLaboratoryNorms })
	}, [])
	
	useEffect(() => {
		backend.laboratoryFindings({ patient, cb: setLaboratoryFindings })
	}, [patient])

	const renderDate = ({ value }) => {
		const date = utils.toDate({ dateString: value })
		return <label style={{ marginLeft: '5px' }}>{date}</label>
	}

	const renderActions = ({ row }) => {
		return (
			<button
				type="button"
				style={{ height: '100%' }}
				className="btn btn-ghost-info"
				onClick={() => {
					onSelect(row)
				}}>
				{lang('display')}
			</button>
		)
	}

	const handleNormInputChange = (e, norm) => {
		const values = { ...patientLabValues }
		values[norm.id] = e.target.value
		setPatientLabValues(values)
	}

	const customValidity = (e) => {
		e.target.setCustomValidity(lang('input-required'))
	}

	const handleNew = () => {

		const body = JSON.stringify({
			patientId: patient,
			laboratoryNormValues: patientLabValues,
			type: 'FORMULAR_INPUT',
			status: 'UPLOADED'
		})

		backend.laboratoryValues({ body, type: backend.type.CREATE })
	}

	const handleEdit = () => {
		
	}

	return (
		<Skeleton type={'list'} hide={laboratoryFindings}>
				<Card bordered={false}> 
					<div className="card-header">
						<h3 className='card-title'>{lang('laboritoryFindings')}</h3>
						{/*patient ? (
							<Modal
								btn={{
									className: "ml5",
									type: 'add',
								}}
								onOK={handleNew}
								text={{
									ok: lang('save'),
									cancel: lang('cancel'),
									close: lang('close'),
									title: lang('title_new_finding'),
								}}>
								<form>
									{laboratoryNorms
										? laboratoryNorms.map((norm) => (
												<div key={norm.id}>
													<label htmlFor={norm.name} className="form-label">
														{norm.name}
													</label>
													<input
														type="text"
														className={`form-control ${
															patientLabValues && patientLabValues[norm.key] ? 'is-valid' : ''
														} mb-2`}
														onChange={(e) => handleNormInputChange(e, norm)}
													/>
												</div>
										  ))
										: null}
								</form>
							</Modal>
													) : null */}
						<button id="back-button" type="button" className="btn btn-ghost-info ml5" onClick={handleEdit}>
							<Icon type={"edit"}/>
						</button>
					</div>
					<Table
						borderless={false}
						headless={false}
						expandable={true}
						columns={[
							{ key: 'patient_id', value: lang('patientName'), fixed: "left", },
							{ key: 'creator_id', value: lang('creator'), fixed: "left", },
							{ key: 'type', value: lang('type'), fixed: "left", },
							{ key: 'created_at', value: lang('createdAt'), render: renderDate },
							{ key: 'updated_at', value: lang('updatedAt'), render: renderDate },
							{ key: 'status', value: lang('status') },
							{ key: 'action', value: lang('action'), render: renderActions },
						]}
						data={laboratoryFindings}
					/>
				</Card>
		</Skeleton>
	)
}

export default LaboratoryFindings
