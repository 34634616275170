import React, { useState } from "react"

import { LangContext } from '../../components/Translation'
import translations from './translations'
import { useGlobalContext } from '../../components/AppContext'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'

import SettingSelect from '../../components/SettingSelect'
import SymptomsTrend from '../../applets/SymptomsTrend'

export default function Symptoms() {
  
    const lang = React.useContext(LangContext)(translations)

    const context = useGlobalContext()
    const selectedCenter = context.globals.selectedCenter

    const [selectedSetting, setSelectedSetting] = useState()

    return (
      <PageHeader
        title={ lang("title") }
        extra={
          <SettingSelect className="" onChange={ setSelectedSetting }/>
        }
      >
         <PageContent>
              <div className="container-xl">
                <div className="row row-deck row-cards">
                  <div className="col-12">  
                      <SymptomsTrend center={selectedCenter} setting={selectedSetting ? selectedSetting.key : undefined}/>
                  </div>                  
                </div>                    
              </div>
          </PageContent>
			</PageHeader>
    )
  }
  