import React, { useContext, useEffect, useState } from 'react'
import { LangContext } from '../../components/Translation'
import translations from './translations'
import Backend from '../../api/Backend'
import { Space, Divider, Input, Form, Select, Table, Card } from 'antd'
import Button from '../../components/Button'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import Drawer from '../../components/Drawer'
import Icon from '../../components/Icon'
import MediaLibrary from '../../applets/MediaLibrary'
import DecisionModal from '../../components/DecisionModal'
import HspProviderSelect from '../../components/HspProviderSelect'
import Thumbnail from '../../components/Thumbnail'

import { CMS_ENDPOINT } from '../../pages/CMS'
import { toast } from 'react-toastify'

const HSPCategories = ({ fetchCategories }) => {
	const lang = useContext(LangContext)(translations)

	const backend = new Backend()

	const [loading, setLoading] = useState(false)
	const [categories, setCategories] = useState()
	const [subcategories, setSubcategories] = useState()
	const [newContent, setNewContent] = useState(0)

	const [openAddSubcategories, setOpenAddSubcategories] = useState(false)
	const [removeCategoryDecision, setRemoveCategoryDecision] = useState(false)
	const [removeSubcategoryDecision, setRemoveSubcategoryDecision] = useState(false)
	const [selectedHSPs, setSelectedHSPs] = useState()
	const [openAddContent, setOpenAddContent] = useState(false)
	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)
	const [subcategoryThumbnailUrl, setSubcategoryThumbnailUrl] = useState(null)
	const [categoryThumbnailUrl, setCategoryThumbnailUrl] = useState(null)
	const [resetHspProviderSelect, setResetHspProviderSelect] = useState(1)

	const [categoryForm] = Form.useForm()
	const [subcategoryForm] = Form.useForm()

	useEffect(() => {
		categoryForm.resetFields()
		setCategoryThumbnailUrl(null)
	}, [categoryForm, openAddContent])

	useEffect(() => {
		subcategoryForm.resetFields()
		setSubcategoryThumbnailUrl(null)
	}, [subcategoryForm, openAddSubcategories])

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const renderActions = (item) => {
		if (item?.type === 'CATEGORY') {
			let newArr = categories
			let key = item.key
			return (
				<div className="action-menu">
					<Space size="small">
						<Button
							txt={lang('edit-add-subcategory')}
							type="open"
							onClick={(e) => {
								e.stopPropagation()
								setOpenAddSubcategories(item)
							}}
						/>
						<Button
							type="remove"
							danger={true}
							onClick={(e) => {
								e.stopPropagation()
								setRemoveCategoryDecision(item)
							}}
						/>
					</Space>
					<div className="up-down-icons">
						<div className="incrementor-div" style={key === 0 ? { opacity: '20%' } : null}>
							<UpOutlined
								onClick={(e) => {
									e.stopPropagation()
									if (key > 0) {
										let temp = newArr[key - 1]
										newArr[key - 1] = newArr[key]
										newArr[key] = temp

										newArr[key - 1].category.key = newArr[key - 1].category.key - 1
										newArr[key - 1].key = newArr[key - 1].key - 1

										newArr[key].category.key = newArr[key].category.key + 1
										newArr[key].key = newArr[key].key + 1

										setCategories([...newArr])
										backend.HSPCategories({
											type: backend.type.UPDATE,
											body: JSON.stringify(newArr),
											id: 1,
											dictionary: true
										})
									}
								}}
							/>
						</div>
						<Divider plain={true} style={{ marginTop: 1, marginBottom: 1 }} />
						<div className="incrementor-div" style={key === newArr.length - 1 ? { opacity: '20%' } : null}>
							<DownOutlined
								onClick={(e) => {
									e.stopPropagation()
									if (key < newArr.length - 1) {
										let temp = newArr[key + 1]
										newArr[key + 1] = newArr[key]
										newArr[key] = temp

										newArr[key + 1].category.key = newArr[key + 1].category.key + 1
										newArr[key + 1].key = newArr[key + 1].key + 1

										newArr[key].category.key = newArr[key].category.key - 1
										newArr[key].key = newArr[key].key - 1

										setCategories([...newArr])
										backend.HSPCategories({
											type: backend.type.UPDATE,
											body: JSON.stringify(newArr),
											id: 1,
											dictionary: true
										})
									}
								}}
							/>
						</div>
					</div>
				</div>
			)
		} else if (item?.type === 'SUB-CATEGORY') {
			// SUBCATEGORY
			let parentArr = categories[categories.findIndex((category) => category?.id === item?.category?.id)]
			parentArr = parentArr?.children
			if (!parentArr) {
				return null
			}
			return (
				<div className="action-menu">
					<Space size="small">
						<Button
							txt={lang('edit-add-content')}
							type="open"
							onClick={(e) => {
								e.stopPropagation()
								setOpenAddContent(item)
								setSelectedHSPs(item?.item && item.item.map((hsp) => hsp.id))
							}}
						/>
						<Button
							type="remove"
							danger={true}
							onClick={(e) => {
								e.stopPropagation()
								setRemoveSubcategoryDecision(item)
							}}
						/>
					</Space>
				</div>
			)
		} else {
			return <></>
		}
	}

	useEffect(() => {
		setLoading(true)
		backend.HSPCategories({
			cache: false,
			cb: (res) => {
				if (!res) {
					setLoading(false)
					return
				}
				res = res.data
				// Handling Categories
				let keyIncrement = 0
				let childKeyIncrement = 0
				let contentKeyIncrement = 0
				let categories =
					Array.isArray(res) &&
					res.length > 0 &&
					res.map((category) => {
						if (category?.item && Array.isArray(category.item) && category.item.length > 0) {
							// is a category
							let children = category.item.map((subcategory) => {
								// subcategories
								let contents = subcategory.item.map((content) => {
									// hsps
									return {
										...content,
										key: 9999999 + keyIncrement + childKeyIncrement + contentKeyIncrement++,
										category: { ...content, subcategory },
										id: parseInt(content?.link, 10),
										parent: subcategory?.title,
										title: content?.name
									}
								})
								return {
									...subcategory,
									key: 99999 + keyIncrement + childKeyIncrement++,
									category: { ...subcategory, category },
									parent: category.name,
									children: contents
								}
							})
							return {
								...category,
								category: { ...category, key: keyIncrement },
								key: keyIncrement++,
								children
							}
						} else {
							return {
								...category,
								category: { ...category, key: keyIncrement },
								key: keyIncrement++,
								children: []
							}
						}
					})
				setCategories(categories)
				backend.HSPSubcategories({
					cache: false,
					cb: (subcat) => {
						setSubcategories(subcat)
						setLoading(false)
					}
				})
			}
		})
	}, [newContent, fetchCategories])
	const columns = [
		{
			title: lang('image'),
			dataIndex: 'image',
			key: 'image',
			render: (url, record) => {
				if (!url || url.includes('cmsnull') || url.includes('cmsundefined')) {
					return <Icon type="missing-image" style={{ width: '100%', height: '100%' }} />
				}
				return (
					<img
						src={CMS_ENDPOINT + url}
						className={
							record?.type === 'SUB-CATEGORY' ? 'subcategory-image' : record?.type === 'HSP' ? 'content-category-image' : 'cms-image'
						}></img>
				)
			}
		},
		{
			title: lang('title'),
			dataIndex: 'title',
			key: 'title',
			sorter: (a, b) => a.title.localeCompare(b.title),
			render: (title, row) => {
				return (
					<span
						className={
							row?.type === 'SUB-CATEGORY'
								? 'subcategory-text-style'
								: row?.type === 'HSP'
								? 'content-category-text-style'
								: 'category-text-style'
						}>
						{title}
					</span>
				)
			}
		},
		{
			title: lang('actions'),
			dataIndex: 'category',
			key: 'key',
			render: (category) => <div>{renderActions(category)}</div>
		}
	]

	return (
		<>
			<Card>
				<Table
					columns={columns}
					dataSource={categories}
					expandable={{
						expandRowByClick: true
					}}></Table>
			</Card>

			<DecisionModal
				open={removeCategoryDecision}
				onClose={() => {
					setRemoveCategoryDecision(false)
				}}
				text={{
					title: lang('remove-category-confirmation', { title: removeCategoryDecision?.title }),
					cancel: lang('cancel'),
					ok: lang('confirm')
				}}
				onOK={() => {
					const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
					backend.HSPCategories({
						type: backend.type.DELETE,
						id: removeCategoryDecision.id,
						cb: () => {
							setNewContent(newContent + 1)
							toast.update(loadingToast, {
								render: lang('category-deleted'),
								type: 'success',
								isLoading: false,
								closeOnClick: true,
								autoClose: 5000
							})
						},
						cbError: () => {
							toast.update(loadingToast, {
								render: lang('an-error-has-occured'),
								type: 'error',
								isLoading: false,
								closeOnClick: true,
								autoClose: 5000
							})
						}
					})
				}}
			/>
			<DecisionModal
				open={removeSubcategoryDecision}
				text={{
					title: lang('remove-category-confirmation', { title: removeSubcategoryDecision?.title }),
					cancel: lang('cancel'),
					ok: lang('confirm')
				}}
				onOK={() => {
					let items = JSON.stringify(removeSubcategoryDecision)
					const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
					backend.HSPCategories({
						type: backend.type.UPDATE,
						id: removeSubcategoryDecision.id,
						body: items,
						remove: true,
						cb: (res) => {
							setNewContent(newContent + 1)
							toast.update(loadingToast, {
								render: lang('category-removed'),
								type: 'success',
								isLoading: false,
								closeOnClick: true,
								autoClose: 5000
							})
						},
						cbError: (err) => {
							toast.update(loadingToast, {
								render: lang('an-error-has-occured'),
								type: 'error',
								isLoading: false,
								closeOnClick: true,
								autoClose: 5000
							})
						}
					})
					setRemoveSubcategoryDecision(false)
				}}
			/>
			<Drawer
				open={openAddSubcategories}
				placement="right"
				width="80%"
				onClose={() => {
					setOpenAddSubcategories(false)
				}}
				text={{
					title: lang('category-editor'),
					ok: lang('save'),
					cancel: lang('cancel')
				}}
				onOK={() => {
					subcategoryForm.validateFields().then((values) => {
						const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
						let body = {
							category: openAddSubcategories,
							...values
						}
						body = JSON.stringify(body)
						backend.HSPCategories({
							type: backend.type.UPDATE,
							id: openAddSubcategories.id,
							body,
							cb: () => {
								toast.update(loadingToast, {
									render: lang('category-updated'),
									type: 'success',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
								setOpenAddSubcategories(false)
								subcategoryForm.resetFields()
								setNewContent(newContent + 1)
							},
							cbError: () => {
								toast.update(loadingToast, {
									render: lang('an-error-has-occured'),
									type: 'error',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
								setOpenAddSubcategories(false)
							}
						})
						setOpenAddSubcategories(false)
					})
				}}>
				<Drawer
					open={openThumbnailMediaLibrary}
					placement="right"
					onClose={() => {
						setOpenThumbnailMediaLibrary(false)
					}}
					width={'65%'}
					text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
					<MediaLibrary
						single
						media={{
							onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
							setSelectedMedia: (thumbnail) => {
								subcategoryForm.setFieldsValue({
									new_display_picture: {
										id: thumbnail.id,
										url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
									}
								})
							},
							setSelectedMediaUrl: setSubcategoryThumbnailUrl,
							type: 'Thumbnail',
							mime: ['image/jpeg', 'image/png', 'image/svg+xml']
						}}
					/>
				</Drawer>
				<Form form={subcategoryForm} layout="vertical">
					<Form.Item name="title" label={lang('title')} rules={rules} initialValue={openAddSubcategories.title}>
						<Input placeholder={lang('title-placeholder')} />
					</Form.Item>
					<div className="thumbnail-container">
						<div>
							<div className="ant-col ant-form-item-label">
								<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
									{lang('thumbnail')}
								</label>
							</div>
							<Thumbnail url={subcategoryThumbnailUrl ?? `${CMS_ENDPOINT}` + openAddSubcategories.image} />
							<Form.Item name="new_display_picture" hidden>
								<Input type="hidden" />
							</Form.Item>
						</div>
						<div>
							<Button type="primary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
						</div>
					</div>
					<Form.Item
						name="add_subcategory"
						label={lang('add-or-create-new-subcategory') + ' ' + lang('create-subcategory-helper')}
						initialValue={openAddSubcategories && openAddSubcategories.item.map((el) => el.id)}>
						<Select mode="tags" allowClear placeholder={lang('subcategory-placeholder')}>
							{subcategories &&
								Array.isArray(subcategories) &&
								subcategories.length > 0 &&
								subcategories.map((subcategory) => {
									return <Select.Option key={subcategory.id}>{subcategory.title}</Select.Option>
								})}
						</Select>
					</Form.Item>
				</Form>
			</Drawer>
			<Drawer
				open={openAddContent}
				placement="right"
				width="80%"
				onClose={() => {
					setSelectedHSPs([])
					setOpenAddContent(false)
					setResetHspProviderSelect((resetHspProviderSelect) => resetHspProviderSelect + 1)
				}}
				onOK={() => {
					categoryForm.validateFields().then((values) => {
						const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
						let body = {
							category: openAddContent,
							new_content: selectedHSPs,
							image: values?.new_display_picture ? values.new_display_picture : null,
							...values
						}
						body = JSON.stringify(body)
						backend.HSPCategories({
							type: backend.type.UPDATE,
							id: openAddContent.id,
							body,
							cb: () => {
								toast.update(loadingToast, {
									render: lang('category-updated'),
									type: 'success',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
								setOpenAddContent(false)
								categoryForm.resetFields()
								setNewContent(newContent + 1)
							},
							cbError: () => {
								toast.update(loadingToast, {
									render: lang('an-error-has-occured'),
									type: 'error',
									isLoading: false,
									closeOnClick: true,
									autoClose: 5000
								})
							}
						})
						setOpenAddContent(false)
					})
				}}
				text={{
					title: lang('edit-subcategory'),
					ok: lang('save'),
					cancel: lang('cancel')
				}}>
				<Drawer
					open={openThumbnailMediaLibrary}
					placement="right"
					onClose={() => {
						setOpenThumbnailMediaLibrary(false)
					}}
					width={'65%'}
					text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
					<MediaLibrary
						single
						media={{
							onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
							setSelectedMedia: (thumbnail) => {
								categoryForm.setFieldsValue({
									new_display_picture: {
										id: thumbnail.id,
										url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
									}
								})
							},
							setSelectedMediaUrl: setCategoryThumbnailUrl,
							type: 'Thumbnail',
							mime: ['image/jpeg', 'image/png', 'image/svg+xml']
						}}
					/>
				</Drawer>
				<Form form={categoryForm} layout="vertical">
					<Form.Item name="title" label={lang('title')} rules={rules} initialValue={openAddContent.title}>
						<Input placeholder={lang('title-placeholder')} />
					</Form.Item>
					<div className="thumbnail-container">
						<div>
							<div className="ant-col ant-form-item-label">
								<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
									{lang('thumbnail')}
								</label>
							</div>
							<Thumbnail url={categoryThumbnailUrl ?? `${CMS_ENDPOINT}` + openAddContent.image} />
							<Form.Item name="new_display_picture" hidden>
								<Input type="hidden" />
							</Form.Item>
						</div>
						<div>
							<Button type="primary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
						</div>
					</div>
				</Form>
				<div>
					<div className="ant-col ant-form-item-label">
						<label htmlFor="hsp-select" title="HSP Select" className="ant-form-item">
							{lang('select-hsps')}
						</label>
					</div>
					<HspProviderSelect
						placeholder={lang('select')}
						value={selectedHSPs}
						key={resetHspProviderSelect}
						onChange={(hsps) => setSelectedHSPs(hsps)}
					/>
				</div>
			</Drawer>
		</>
	)
}

export default HSPCategories
