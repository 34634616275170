import React, { useState, useEffect } from 'react'
import { Card } from 'antd'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'

import AssessmentEngagement from '../../applets/AssessmentEngagement'
import Skeleton from '../../components/Skeleton'
import Backend from '../../api/Backend'
import { Redirect, useLocation } from 'react-router-dom'

import translations from './translations'
import { LangContext } from '../../components/Translation'
import { useGlobalContext } from '../../components/AppContext'

import './index.css'

export default function Engagement() {

	const lang = React.useContext(LangContext)(translations)

	const backend = new Backend()
	const location = useLocation()

	const context = useGlobalContext()
	const selectedClinic = context.globals.selectedCenter

	const [loading, setLoading] = useState()
	const [selectedPatientData, setSelectedPatientData] = useState()
	const [engagementData, setEngagementData] = useState()

	useEffect(() => {
		setLoading(true)
		backend.listAssessmentEngagement({
			clinic: selectedClinic,
			amount: 30,
			cb: (data) => {
				setLoading(false)
				setEngagementData(data) 
			},
		})
	}, [selectedClinic])

	const handlePatientSelection = (patientName) => {
		setSelectedPatientData(getPatientData(patientName))
	}

	const getPatientData = (patientName) => {
		return engagementData
			? engagementData.filter((d) => {
				return d.patient_name === patientName
			})[0]
			: null
	}

	const renderPatientRedirect = () => {
		return selectedPatientData ? (
			<Redirect
				to={{
					pathname: `/patient/${selectedPatientData.patient_id}`,
					state: { referrer: location.pathname },
				}}
			/>
		) : null
	}

	return (
			<PageHeader
				title={ lang("title") }
			>
			<PageContent>	
				{renderPatientRedirect()}
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<div className="col-sm-12 col-lg-12">
								<Card bordered={false}>
									<h3 className="card-title">
										Top 30 negatives Patient(inn)en Engagement
									</h3>
									<Skeleton type="statistics" hide={!loading}>
										<AssessmentEngagement
											data={engagementData}
											height={550}
											onSelect={handlePatientSelection}
										/>
									</Skeleton>
								</Card>
							</div>
						</div>
					</div>
			</PageContent>
		</PageHeader>
	)
}
