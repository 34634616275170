import React from 'react'
import  './index.css'


export default function Recipe(props) {

  return (
    <div className="recipe">
     <img src="img/recipe.jpg" className="recipe-img"/>
     <button className="btn approve" type="button" aria-label="Ausstellen" data-bs-toggle="recipe-approve-modal" data-bs-target="#recipeApproval">Ausstellen</button>
     <div className="modal fade" id="recipeApproval" tabIndex="-1" aria-labelledby="recipeApproval" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="approveLabel">Rufaufbau zu: {props.name}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                ....
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Beenden</button>
            </div>
          </div>
        </div>
     </div>
    </div>
  )
}