import React, { useState, useEffect, useRef } from 'react'
import { PageHeader as APageHeader, Breadcrumb } from 'antd'

import './index.css'

export default function PageHeader({ title, subTitle, tags, description, breadcrumb, content, extra, footer, children }) {
	const [height, setHeight] = useState(0)
	const ref = useRef(null)

	useEffect(() => {
		setHeight(ref.current ? ref.current.clientHeight : 30)
	})

	const topBarHeight = 50
	const reduction = height + topBarHeight

	const bcRendered = breadcrumb ? (
		<Breadcrumb>
			{breadcrumb.map((bc) => (
				<Breadcrumb.Item key={bc.value}>{bc.value}</Breadcrumb.Item>
			))}
		</Breadcrumb>
	) : null

	return (
		<div>
			<div ref={ref}>
				<APageHeader
					ghost={false}
					tags={tags}
					title={title}
					className="page-header"
					subTitle={subTitle}
					breadcrumb={bcRendered}
					extra={extra}
					footer={footer}>
					<>
						{description}
						{content}
					</>
				</APageHeader>
			</div>
			<div
				style={{
					height: `calc( 100vh   - ${reduction}px )`,
					display: 'grid'
				}}>
				{children}
			</div>
		</div>
	)
}
