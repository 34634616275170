import React, { useState } from "react"

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'

import Appointments from '../../applets/Appointments'

import { LangContext } from '../../components/Translation'
import translations from './translations'

export default function Tasks() {

  const lang = React.useContext(LangContext)(translations)

  return (
      <PageHeader
        title={lang("title")}
      >
        <PageContent>
          <div className="container-xl">
            <div className="row row-deck row-cards">
              <div className="col-12">
                <Appointments all />
              </div>
            </div>
          </div>
        </PageContent>
      </PageHeader>
  )
}
