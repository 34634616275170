import React from 'react'
import { Tag as ATag } from 'antd'

import { useGlobalContext } from '../AppContext'
import { LangContext } from '../../components/Translation'
import translations from './translations'

export default function Tag({ className = '', children, color, type = 'default' }) {
	const {
		studyStates,
		questionnaireStatuses,
		patientCareplanStatuses,
		careplanStatuses,
		appointmentSchedulerStatuses,
		hspStatuses,
		appointmentSchedulerMedium
	} = useGlobalContext()

	const lang = React.useContext(LangContext)(translations)

	type = type ? type.toUpperCase() : 'DEFAULT' // in case of null
	if (
		[
			'DEFAULT',
			careplanStatuses.DRAFT,
			'OPEN',
			'ANNOUNCED',
			lang(questionnaireStatuses.PENDING.toLowerCase()),
			lang(studyStates.INCLUDED.toLowerCase()),
			appointmentSchedulerStatuses.TO_BE_SCHEDULED
		].includes(type)
	) {
		color = color ? color : 'blue'
	} else if (
		[
			'WARNING',
			'PROCESSING',
			'REVIEWING',
			'EDUCATIONAL',
			appointmentSchedulerMedium.ONSITE,
			patientCareplanStatuses.FINISHED,
			lang(studyStates.ONBOARDING.toLowerCase()),
			'CREATED_BY_PATIENT'
		].includes(type)
	) {
		color = color ? color : 'gold'
	} else if (
		[
			'CANCELED_BY_CAREPLAN',
			'IMMEDIATE',
			'ERROR',
			'CLOSED',
			'CARE ADVICE',
			appointmentSchedulerStatuses.TO_BE_SCHEDULED,
			lang(questionnaireStatuses.EXPIRED.toLowerCase()),
			lang(studyStates.DROP_OUT.toLowerCase()),
			lang(studyStates.EXCLUDED.toLowerCase()),
			lang(studyStates.CANCELED.toLowerCase()),
			lang(hspStatuses.CANCELLED.toLowerCase())
		].includes(type)
	) {
		color = color ? color : 'red'
	} else if (
		[
			'CARE PROGRAM',
			'CARE SERVICE',
			appointmentSchedulerStatuses.SCHEDULED,
			patientCareplanStatuses.ACTIVE,
			careplanStatuses.PUBLISHED,
			lang(hspStatuses.ACTIVE.toLowerCase()),
			lang(studyStates.PARTICIPATION.toLowerCase()),
			lang(questionnaireStatuses.COMPLETED.toLowerCase()),
			lang(studyStates.COMPLETED.toLowerCase())
		].includes(type)
	) {
		color = color ? color : 'green'
	} else if (
		['MANDATORY', lang(studyStates.SCREENING.toLowerCase()), appointmentSchedulerMedium.CHAT, appointmentSchedulerMedium.VIDEO].includes(type)
	) {
		color = color ? color : 'purple'
	} else if ([lang(hspStatuses.INACTIVE.toLowerCase())].includes(type)) {
		color = color ? color : 'orange'
	} else if ([lang(hspStatuses.ONBOARDED.toLowerCase())].includes(type)) {
		color = color ? color : 'cyan'
	} else if ([lang(hspStatuses.INVITED.toLowerCase())].includes(type)) {
		color = color ? color : 'lime'
	}else if (['HSP'].includes(type)) {
		color = color ? color : 'volcano'
	} else {
		color = color ? color : 'blue'
	}

	return (
		<ATag className={className} color={color}>
			{children}
		</ATag>
	)
}
