import Utils from './Utils'

class backend {
	constructor() {
		const utils = new Utils()

		this.type = {
			GET: 'GET',
			UPDATE: 'PUT',
			CREATE: 'POST',
			DELETE: 'DELETE'
		}

		return {
			type: this.type,

			setting: ({ cb, type = this.type.GET }) => {
				return utils.call({
					type,
					cb,
					url: `/api/v1/setting`
				})
			},

			ecrfExportStructure: ({ cb, cbError, body, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/ecrf/export/structure`
				})
			},

			ecrfExport: ({ cb, cbError, body, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/ecrf/export`
				})
			},

			patientContent: ({ cb, cbError, body, patientId, type = this.type.GET }) => {
				const subpath = patientId ? `/${patientId}` : ''
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/patientContent${subpath}`
				})
			},

			patientConsultationRequest: ({ cb, clinic, settings, type = this.type.GET }) => {
				const filter = utils.getFilter({ settings })
				const subpath = clinic ? `/${clinic}` : ''
				return utils.call({
					type,
					cb,
					url: `/api/v1/patientConsultationRequest${subpath}${filter}`
				})
			},

			patientConsultationRequestStats: ({ cb, clinic, settings, type = this.type.GET }) => {
				const filter = utils.getFilter({ settings })
				const subpath = clinic ? `/${clinic}` : ''
				return utils.call({
					type,
					cb,
					url: `/api/v1/patientConsultationRequest/stats${subpath}${filter}`
				})
			},

			patientHealth: ({ cb, patientId, type = this.type.GET }) => {
				return utils.call({
					type,
					cb,
					url: `/api/v1/patientHealth/${patientId}`
				})
			},

			listAssessmentEngagement: ({ clinic, amount, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ clinic, amount })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/listAssessmentEngagement${filter}`
				})
			},

			treatmentType: ({ cb, type = this.type.GET }) => {
				return utils.call({
					cb,
					url: `/api/v1/treatmentType`
				})
			},

			appointmentType: ({ cb, type = this.type.GET }) => {
				return utils.call({
					cb,
					url: `/api/v1/appointmentType`
				})
			},

			appointmentsByType: ({ filter, body, cb, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/appointmentsByType${filter ? `?${filter}` : ''}`
				})
			},

			appointmentsOfCareteam: ({ cb, type = this.type.GET }) => {
				return utils.call({
					type,
					cb,
					url: `/api/v1/appointmentsOfCareteam`
				})
			},

			appointmentsOfPatient: ({ patientId, cb, type = this.type.GET }) => {
				return utils.call({
					type,
					cb,
					url: `/api/v1/appointment/${patientId}`
				})
			},

			appointmentsByScheduler: ({ cb, type = this.type.GET }) => {
				return utils.call({
					type,
					cb,
					url: `/api/v1/appointmentsByScheduler`
				})
			},

			appointments: ({ id, patientId, center, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ patientId, center })
				const subpath = id ? `/${id}` : ''
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/appointment${subpath}${filter ? filter : ''}`
				})
			},

			recruitment: ({ statusCount, recruitmentStatistics, clinic, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ clinic })
				const subpath = statusCount ? '/statusCount' : recruitmentStatistics ? '/recruitmentStatistics' : ''
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/recruitment${subpath}${filter ? filter : ''}`
				})
			},

			recruitmentPerDay: ({ center, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ center })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/recruitmentPerDay${filter ? filter : ''}`
				})
			},

			clinics: ({ body, cb, all = false, type = this.type.GET }) => {
				const subpath = all ? '/all' : ''
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/clinic${subpath}`
				})
			},

			patientAction: ({ filter, body, cb, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/patientAction${filter ? `?${filter}` : ''}`
				})
			},

			action: ({ filter, id, body, cb, cbError, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/action${subpath}`
				})
			},

			bi: ({ id, clinic, setting, body, cb, cbError, type = this.type.GET }) => {
				const filter = utils.getFilter({ clinic, setting })
				const subpath = id ? `/${id}` : ''
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/bi${subpath}${filter}`
				})
			},

			patients: ({
				id,
				isOnboarding = false,
				resetPassword = false,
				patientName,
				limit,
				setting,
				clinic,
				cbError,
				active,
				studyGroup,
				body,
				cb,
				type = this.type.GET
			}) => {
				const filter = utils.getFilter({ id, patientName, setting, clinic, active, limit, studyGroup })
				let subpath = ''
				if (isOnboarding) subpath = '/onboarding'
				if (resetPassword) subpath = `/${id}/reset-password`
				return utils.call({
					type,
					cbError,
					body,
					cb,
					url: `/api/v1/patient${subpath}${filter}`
				})
			},

			patient: ({ id, cb, cbError, type = this.type.GET }) => {
				return utils.call({
					type,
					cbError,
					cb,
					url: `/api/v1/patient/${id}`
				})
			},

			notes: ({ id, patientId, taskId, notesType, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ id, patientId, taskId, type: notesType })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/note${filter}`
				})
			},

			/*
				assignedTo : userId
			*/
			tasks: ({ id, limit, center, types, assignedTo, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ assignedTo, limit, types, center })
				const subpath = id ? `/${id}` : ''
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/task${subpath}${filter}`
				})
			},

			taskChangeLog: ({ id, cb, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''
				return utils.call({
					type,
					cb,
					url: `/api/v1/task${subpath}/changelog`
				})
			},

			system: ({ id, body, cb, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/system${subpath}`
				})
			},

			setCareplanTimeMachine: ({ id, activityId, body, cb, type }) => {
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/careplanTimeMachine/${id}/${activityId}`
				})
			},

			getPatientQuestionnaireScores: ({ requestId, cb, type = this.type.GET }) => {
				let subpath = `response/${requestId}/score`
				return utils.call({
					type,
					cb,
					url: `/api/v1/questionnaire/${subpath}`
				})
			},

			getPatientQuestionnaireAnswers: ({ requestId, cbError, cb, type = this.type.GET }) => {
				let subpath = `${requestId}/answer`
				return utils.call({
					type,
					cbError,
					cb,
					url: `/api/v1/questionnaire/response/${subpath}`
				})
			},

			getPatientQuestionnaires: ({ patientId, scoreExport, requestId, cb, type = this.type.GET }) => {
				let subpath = patientId ? `request/patient/${patientId}` : ''
				subpath = requestId ? `${type === this.type.GET ? 'response' : 'request'}/${requestId}` : subpath
				subpath = scoreExport ? `response/scoreExport` : subpath
				return utils.call({
					type,
					cb,
					url: `/api/v1/questionnaire/${subpath}`
				})
			},
			// dedicated version of getPatientQuestionnaires to overcome limitations and refactoring
			getPatientQuestionnaireRequest: ({ requestId, cb, type = this.type.GET }) => {
				return utils.call({
					type,
					cb,
					url: `/api/v1/questionnaire/request/${requestId}`
				})
			},

			questionnaireTypes: ({ cb, type = this.type.GET }) => {
				return utils.call({
					type,
					cb,
					url: `/api/v1/questionnaire/type`
				})
			},

			notifications: ({ ts, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ ts })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/notification${filter}`
				})
			},

			alarms: ({ clinic, setting, patient, state, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ patient, setting, clinic, state })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/alarm${filter}`
				})
			},

			getOpenAlarms: ({ clinic, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ clinic })
				return utils.call({
					type,
					cb,
					url: `/api/v1/alarm/open${filter}`
				})
			},

			alarmsStats: ({ status, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ status })
				return utils.call({
					type,
					cb,
					url: `/api/v1/alarm${filter}`
				})
			},

			studies: ({ availableStudies, isExternalStudy, patient, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ patient, isExternalStudy, availableStudies })
				const subpath = availableStudies ? '/all-studies' : ''
				return utils.call({
					isExternalStudy,
					availableStudies,
					type,
					body,
					cb,
					url: `/api/v1/studie${subpath}${filter}`
				})
			},

			study: ({ patient, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ patient })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/study${filter}`
				})
			},
			// TODO: remove by laboratoryValues
			laboratory: ({ filter, body, cb, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/laboratory${filter ? `?${filter}` : ''}`
				})
			},

			laboratoryValues: ({ id, patient, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ patient, id })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/laboratoryValue${filter}`
				})
			},

			laboratoryFindings: ({ patient, body, cb, type = this.type.GET }) => {
				const filter = utils.getFilter({ patient })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/laboratoryFinding${filter}`
				})
			},

			laboratoryNames: ({ cb, type = this.type.GET }) => {
				return utils.call({
					type,
					cb,
					url: `api/v1/laboratoryName`
				})
			},

			users: ({
				id,
				name,
				center,
				resetPassword = false,
				disabled = false,
				cbError,
				me = false,
				symptomHandler = false,
				body,
				cb,
				type = this.type.GET
			}) => {
				const filter = utils.getFilter({ name, center })

				let subpath = ''

				if (!disabled && id) subpath = `/${id}`

				if (disabled) subpath = `/${id}/disable-user`

				if (resetPassword) subpath = `/${id}/reset-password`
				if (symptomHandler) subpath = `/symptomHandler`
				if (me) subpath = `/me`

				return utils.call({
					type,
					cbError,
					body,
					cb,
					url: `/api/v1/user${subpath}${filter}`
				})
			},

			healthProvider: ({
				id,
				cb,
				cbError,
				clinicId,
				cache = false,
				passwordReset = false,
				patientId,
				isOnboarded = false,
				email,
				body,
				type = this.type.GET
			}) => {
				let subpath = ''

				if (passwordReset) {
					subpath = `/passwordReset`
				} else if (email) {
					subpath = `/email/${email}`
				} else if (id) {
					subpath = `/hsp/${id}`
				} else if (patientId) {
					subpath = `/hsp/recommend/${patientId}`
				} else if (isOnboarded) {
					subpath = '/onboarded'
				}

				const filter = utils.getFilter({ cache, clinicId })
				return utils.call({
					type,
					cbError,
					body,
					cb,
					url: `/api/v1/healthProvider${subpath}${filter}`
				})
			},

			HSPCategories: ({ dictionary = false, remove = false, cache = false, id, cb, cbError, body, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''
				const removeOrDict = remove ? `/remove` : dictionary ? `/dictionary` : ''
				const filter = utils.getFilter({ cache })
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/healthProvider/categories${removeOrDict}${subpath}${filter}`
				})
			},

			HSPSubcategories: ({ cb, body, cache = false, type = this.type.GET }) => {
				const filter = utils.getFilter({ cache })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/healthProvider/subcategories${filter}`
				})
			},

			matrixPresence: ({ cb, cbError, body, type = this.type.CREATE }) => {
				return utils.call({
					type,
					cbError,
					body,
					cb,
					url: `/api/v1/messaging/matrix/presence`
				})
			},

			matrixLogin: ({ cb, cbError, type = this.type.GET }) => {
				return utils.call({
					type,
					cbError,
					cb,
					url: `/api/v1/messaging/matrix/login`
				})
			},

			matrixRoom: ({ patientId, cb, cbError, type = this.type.GET }) => {
				return utils.call({
					type,
					cbError,
					cb,
					url: `/api/v1/messaging/matrix/room/patient/${patientId}`
				})
			},

			matrixResolveUser: ({ cb, body, cbError, type = this.type.CREATE }) => {
				return utils.call({
					type,
					body,
					cbError,
					cb,
					url: `/api/v1/messaging/matrix/resolve/user`
				})
			},

			patientEngagementLevel: ({ patientId, cb, cbError, body, type = this.type.GET }) => {
				return utils.call({
					type,
					cbError,
					body,
					cb,
					url: `/api/v1/patient/${patientId}/engagementLevel`
				})
			},

			patientMasterData: ({ user_id, body, cb, cbError, type = this.type.GET }) => {
				const filter = utils.getFilter({ user_id })
				return utils.call({
					type,
					body,
					cbError,
					cb,
					url: `/api/v1/patient/masterData${filter}`
				})
			},

			patientsAction: ({ id, body, cb, cbError, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cbError,
					cb,
					url: `/api/v1/patient/${id}/action`
				})
			},

			patientEvents: ({ patientId, cb, cbError, body, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/patient/${patientId}/events`
				})
			},

			patientProfile: ({ patientId, cb, cbError, body, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/patient/${patientId}/profile`
				})
			},

			patientQuestionnaire: ({ patientId, questionnaireId, body, cb, cbError, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cbError,
					cb,
					url: `/api/v1/patient/${patientId}/questionnaire/${questionnaireId}`
				})
			},

			permission: ({ body, cb, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/permission`
				})
			},

			userRoles: ({ body, cb, isHspManagement = false, type = this.type.GET }) => {
				const filter = utils.getFilter({ isHspManagement })

				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/userRole${filter}`
				})
			},

			userConsent: ({ body, cb, id, cbError, consentToAccept = false, type = this.type.GET }) => {
				let subpath = id ? `/${id}` : ''
				if (consentToAccept) {
					subpath = '/consentToAccept'
				}
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/userConsent${subpath}`
				})
			},

			careTeam: ({ id, body, setDefault, cb, type = this.type.GET }) => {
				const subpath = setDefault && id ? `setDefault/${id}` : id ? `user/${id}` : ''
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/careTeam/${subpath}`
				})
			},

			careTeamCategory: ({ body, cb, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/careTeamCategory`
				})
			},

			participantInvolvementType: ({ body, cb, cbError, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cbError,
					cb,
					url: `/api/v1/participantInvolvementType`
				})
			},

			patientCareplans: ({ patientId, careplanId, body, cb, cbError, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cbError,
					cb,
					url: `/api/v1/patient/${patientId}/careplan${careplanId ? `/${careplanId}` : ''}`
				})
			},

			careplans: ({ id, search, published, body, cb, cbError, type = this.type.GET }) => {
				const filter = utils.getFilter({ search, published })
				let subpath = id ? `/${id}` : ''

				return utils.call({
					type,
					body,
					cbError,
					cb,
					url: `/api/v1/careplan${subpath}${filter}`
				})
			},

			patientIntervention: ({ patientId, body, cb, cbError, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cbError,
					cb,
					url: `/api/v1/patient/${patientId}/intervention`
				})
			},

			events: ({ cb, type = this.type.GET }) => {
				return utils.call({
					type,
					cb,
					url: `/api/v1/event`
				})
			},

			triggerEvent: ({ cb, cbError, body, type = this.type.CREATE }) => {
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/event/trigger`
				})
			},

			history: ({ patientId, eventType, cb, type = this.type.GET }) => {
				const subpath = patientId ? `/${patientId}` : ''
				const filter = utils.getFilter({ eventType })
				return utils.call({
					type,
					cb,
					url: `/api/v1/history${subpath}${filter}`
				})
			},

			cms: ({ id, clinicId, group, cb, body, endpoint, cache = false, cbError, type = this.type.GET }) => {
				let subpath = id ? `/${endpoint}/${id}` : ''
				const filter = utils.getFilter({ cache, group, clinicId })

				if (type === this.type.CREATE) {
					subpath = `/${endpoint}`
				}

				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/cms/content${subpath}${filter}`
				})
			},
			cmsType: ({ id, cb, cbError, body, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''

				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/cms/content-types${subpath}`
				})
			},
			cmsLabel: ({ id, cb, body, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''

				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/cms/labels${subpath}`
				})
			},

			cmsFiles: ({ id, cb, cbError, body, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''

				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/cms/upload/files${subpath}`
				})
			},

			cmsUploadFiles: ({ id, cb, cbError, body, type = this.type.POST }) => {
				return utils.call({
					type,
					body,
					cb,
					cbError,
					file: true,
					url: `/api/v1/cms/upload/files`
				})
			},

			cmsModuleGroups: ({ id, cb, body, cbError, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/cms/module-groups${subpath}`
				})
			},
			cmsModules: ({ id, cb, body, cbError, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/cms/modules${subpath}`
				})
			},
			cmsCategories: ({ dictionary = false, remove = false, cache = false, id, cb, cbError, body, type = this.type.GET }) => {
				const subpath = id ? `/${id}` : ''
				const removeOrDict = remove ? `/remove` : dictionary ? `/dictionary` : ''
				const filter = utils.getFilter({ cache })
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/cms/categories${removeOrDict}${subpath}${filter}`
				})
			},
			cmsSubcategories: ({ cb, body, cache = false, type = this.type.GET }) => {
				const filter = utils.getFilter({ cache })
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/cms/subcategories${filter}`
				})
			},
			cmsSymptoms: ({ id, cb, body, type = this.type.GET }) => {
				return utils.call({
					cb,
					id,
					url: `/api/v1/cms/symptoms`
				})
			},
			cmsConsent: ({ id, cb, cache = false, body, type = this.type.GET }) => {
				const filter = utils.getFilter({ cache })
				return utils.call({
					cb,
					id,
					url: `/api/v1/cms/consent${filter}`
				})
			},
			bugReport: ({ cb, body, type = this.type.CREATE }) => {
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/bugReport`
				})
			},
			actionTypes: ({ body, cb, type = this.type.GET }) => {
				return utils.call({
					type,
					body,
					cb,
					url: `/api/v1/action/types`
				})
			},
			ECRF: ({ filter, patientId, all, patientECRF, ecrfType, rowId, id, body, cb, cbError, type = this.type.GET }) => {
				let subpath = ''
				if (ecrfType) {
					subpath = `/${ecrfType}`
				}
				if (rowId) {
					subpath = `/${rowId}`
				}
				if (patientId && ecrfType) {
					subpath = `/${patientId}/${ecrfType}`
				}
				if (patientId && !ecrfType) {
					subpath = `/${patientId}`
				}
				if (id) {
					subpath = `/${id}`
				}
				if (all) {
					subpath = `/all` + subpath
				}
				if (patientECRF) {
					subpath = `/patientECRF` + subpath
				}
				return utils.call({
					type,
					body,
					cb,
					cbError,
					url: `/api/v1/ecrf${subpath}`
				})
			}
		}
	}
}

export default backend
