import React, { useState, useEffect } from 'react'

import Table from '../../components/Table'
import { LangContext } from '../../components/Translation'
import { Form, Input, Select, DatePicker } from 'antd'

import moment from 'moment'

import Drawer from '../../components/Drawer'
import translations from './translations'
import Backend from '../../api/Backend'
import Button from '../../components/Button'
import AllowRender from '../../components/AllowRender'

import { toast } from 'react-toastify'
import { useGlobalContext } from '../../components/AppContext'
import Utils from '../../components/Utils'

const { Option } = Select

const StudyReferences = ({
	renderStart,
	renderFinish,
	resetFields,
	setShowStudyCreateEditor,
	showStudyCreateEditor,
	showExternalStudyEditor,
	setShowExternalStudyEditor,
	rules,
	context,
	patientId,
	updatePatientStatus,
	studies
}) => {
	const lang = React.useContext(LangContext)(translations)

	const { patientsStudyStatus, studyStates } = context

	const [form] = Form.useForm()
	const backend = new Backend()
	const DATE_FORMAT = 'DD.MM.YYYY'

	const [externalStudies, setExternalStudies] = useState()
	const [patientsExternalStudy, setPatientsExternalStudy] = useState()
	const [loading, setLoading] = useState(false)

	const refreshExternalStudies = () => {
		setLoading(true)
		backend.studies({
			isExternalStudy: true,
			patient: patientId,
			cb: (data) => {
				setExternalStudies(data)
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		refreshExternalStudies()
	}, [])

	const addStudyToPatient = async () => {
		await form.validateFields()
		let fields = form.getFieldsValue()

		const data = {
			study: fields.study,
			status: fields.status,
			started: moment.utc(fields.started.toLocaleString()),
			finished: fields.finished ? moment.utc(fields.finished.toLocaleString()) : null,
			dropout: fields.dropout || ''
		}

		const body = JSON.stringify(data)
		backend.studies({
			isExternalStudy: true,
			patient: patientId,
			type: backend.type.CREATE,
			body,
			cb: () => {
				toast.success(lang('study_added'))
				refreshExternalStudies()
			}
		})
	}

	const formSheet = (
		<Form forceRender form={form} layout="vertical">
			<Form.Item name="study" label="Study" rules={rules}>
				<Input />
			</Form.Item>
			<Form.Item name="status" label="Status" rules={rules}>
				<Select>
					{context.patientsStudyStatus.map((status) => (
						<Option key={status.key} value={status.value}>
							{lang(status.value.toLowerCase())}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.status !== currentValues.status}>
				{({ getFieldValue }) =>
					getFieldValue('status') === 'DROP_OUT' ? (
						<Form.Item name="dropout" label={lang('drop-out')} rules={rules}>
							<Input />
						</Form.Item>
					) : null
				}
			</Form.Item>
			<Form.Item name="started" label="Started At" rules={rules}>
				<DatePicker format={DATE_FORMAT} />
			</Form.Item>
			<Form.Item name="finished" label="Finished At">
				<DatePicker format={DATE_FORMAT} />
			</Form.Item>
		</Form>
	)

	const externalEditorFormsheet = (
		<Form forceRender form={form} layout="vertical">
			<Form.Item initialValue={patientsExternalStudy && patientsExternalStudy.study} name="study" label="Study" rules={rules}>
				<Select disabled>
					{studies &&
						studies.map((study) => (
							<Option key={study.key} value={study.value}>
								{study.value}
							</Option>
						))}
				</Select>
			</Form.Item>
			<Form.Item initialValue={patientsExternalStudy && patientsExternalStudy.status} name="status" label="Status" rules={rules}>
				<Select>
					{patientsStudyStatus.map((status) => (
						<Option key={status.key} value={status.value}>
							{status.value}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.status !== currentValues.status}>
				{({ getFieldValue }) =>
					getFieldValue('status') === studyStates.DROP_OUT ? (
						<Form.Item
							initialValue={patientsExternalStudy && patientsExternalStudy.dropout_reason}
							name="dropout"
							label={lang('drop-out')}
							rules={rules}>
							<Input />
						</Form.Item>
					) : null
				}
			</Form.Item>
			<Form.Item initialValue={patientsExternalStudy && moment(patientsExternalStudy.started)} name="started" label="Started At" rules={rules}>
				<DatePicker format={DATE_FORMAT} />
			</Form.Item>
			<Form.Item
				initialValue={patientsExternalStudy && patientsExternalStudy.finished ? moment(patientsExternalStudy.finished) : null}
				name="finished"
				label="Finished At">
				<DatePicker format={DATE_FORMAT} />
			</Form.Item>
		</Form>
	)

	const renderExternalStudyEditor = ({ row }) => {
		return (
			<>
				<AllowRender permissions={['PATIENT_RW']}>
					<Button
						onClick={() => {
							resetFields(form)
							setPatientsExternalStudy(row)
							setShowExternalStudyEditor(true)
						}}
						type="edit"
					/>
				</AllowRender>
			</>
		)
	}

	return (
		<>
			<Drawer
				width="600px"
				onClose={() => {
					setShowStudyCreateEditor(false)
					resetFields(form)
				}}
				open={showStudyCreateEditor}
				onOK={() => {
					addStudyToPatient()
					setShowStudyCreateEditor(false)
				}}
				text={{
					title: lang('enroll_into'),
					cancel: lang('cancel'),
					ok: lang('enroll')
				}}>
				{formSheet}
			</Drawer>
			<Drawer
				key={Utils.randomID()}
				width="600px"
				onClose={() => {
					setShowExternalStudyEditor(false)
					resetFields(form)
				}}
				open={showExternalStudyEditor}
				onOK={() => {
					updatePatientStatus({ form, isExternalStudy: true }, refreshExternalStudies)
				}}
				text={{
					title: lang('update_study'),
					cancel: lang('cancel'),
					ok: lang('save')
				}}>
				{externalEditorFormsheet}
			</Drawer>
			<Table
				style={{ marginTop: '20px' }}
				loading={loading}
				title={lang('study_references')}
				borderless={false}
				headless={false}
				expandable={true}
				buttons={[
					<AllowRender key={'addStudyCreateButton'} permissions={['PATIENT_RW']}>
						<Button
							type="Add"
							onClick={() => {
								setShowStudyCreateEditor(true)
								resetFields(form)
							}}>
							{lang('enroll_into')}
						</Button>
					</AllowRender>
				]}
				columns={[
					{ key: 'study', value: lang('study') },
					{ key: 'status', value: lang('state') },
					{ key: 'dropout_reason', value: lang('drop-out') },
					{ key: 'start', value: lang('started_at'), render: renderStart },
					{ key: 'finished', value: lang('finished_at'), render: renderFinish },
					{ key: 'actions', value: lang('actions'), render: renderExternalStudyEditor }
				]}
				data={externalStudies}
			/>
		</>
	)
}

const Studies = ({ data, refreshStudies, patientId, fetchPatients = () => {}, setOpenPatientId = () => {} }) => {
	const context = useGlobalContext()
	const DATE_FORMAT = 'DD.MM.YYYY'

	const { studyStates } = context

	const [showStudyCreateEditor, setShowStudyCreateEditor] = useState(false)
	const [showInternalStudyUpdateEditor, setShowInternalStudyUpdateEditor] = useState(false)
	const [showExternalStudyEditor, setShowExternalStudyEditor] = useState(false)
	const [studies, setStudies] = useState()
	const [patientsStudy, setPatientsStudy] = useState()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		backend.studies({
			availableStudies: true,
			cb: (data) => {
				setStudies(
					data.map((dat) => {
						return {
							key: dat.study_id,
							value: dat.title
						}
					})
				)
				setLoading(false)
			}
		})
	}, [])

	const [form] = Form.useForm()

	const backend = new Backend()

	const lang = React.useContext(LangContext)(translations)

	const utils = new Utils()

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const statusAvailable = ({ currentStatus, newStatus }) => {
		if (
			currentStatus === studyStates.ONBOARDING &&
			(newStatus === studyStates.INCLUDED || newStatus === studyStates.SCREENING || newStatus === studyStates.COMPLETED)
		)
			return true

		if (
			currentStatus === studyStates.PARTICIPATION &&
			(newStatus === studyStates.INCLUDED || newStatus === studyStates.SCREENING || newStatus === studyStates.ONBOARDING || newStatus === studyStates.EXCLUDED)
		)
			return true
		
		if (
			currentStatus === studyStates.INCLUDED &&
			(newStatus === studyStates.COMPLETED ||
				newStatus === studyStates.DROP_OUT ||
				newStatus === studyStates.ONBOARDING ||
				newStatus === studyStates.PARTICIPATION)
		) {
			return true
		}

		if (currentStatus === studyStates.DROP_OUT || currentStatus === studyStates.EXCLUDED || currentStatus === studyStates.COMPLETED) return true


		return false
	}

	const editorFormSheet = (
		<Form forceRender form={form} layout="vertical">
			<Form.Item initialValue={patientsStudy && patientsStudy.title} name="study" label="Study" rules={rules}>
				<Select disabled>
					{studies &&
						studies.map((study) => (
							<Option key={study.key} value={study.value}>
								{study.value}
							</Option>
						))}
				</Select>
			</Form.Item>
			<Form.Item initialValue={patientsStudy && patientsStudy.status} name="status" label="Status" rules={rules}>
				<Select>
					{context.patientsStudyStatus.map((status) => (
						<Option
							key={status.key}
							disabled={statusAvailable({ currentStatus: patientsStudy?.status, newStatus: status?.value })}
							value={status.value}>
							{lang(status.value.toLowerCase())}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.status !== currentValues.status}>
				{({ getFieldValue }) =>
					getFieldValue('status') === 'DROP_OUT' || getFieldValue('status') === 'EXCLUDED' ? (
						<Form.Item initialValue={patientsStudy && patientsStudy.dropout_reason} name="dropout" label={lang('drop-out')} rules={rules}>
							<Input />
						</Form.Item>
					) : null
				}
			</Form.Item>
			<Form.Item initialValue={patientsStudy && moment(patientsStudy.started)} name="started" label="Started At" rules={rules}>
				<DatePicker format={DATE_FORMAT} />
			</Form.Item>
			<Form.Item
				initialValue={patientsStudy && patientsStudy.finished ? moment(patientsStudy.finished) : null}
				name="finished"
				label="Finished At">
				<DatePicker format={DATE_FORMAT} />
			</Form.Item>
		</Form>
	)

	const resetFields = (form) => {
		form.resetFields()
	}

	const renderStart = ({ row }) => {
		const date = utils.toDate({ dateString: row.started, withoutTime: true })

		return <label style={{ marginLeft: '5px' }}>{date}</label>
	}

	const renderFinish = ({ row }) => {
		const date = row.finished ? utils.toDate({ dateString: row.finished }) : ''
		return <label style={{ marginLeft: '5px' }}>{date}</label>
	}

	const renderStatus = ({row}) => {
		const status = lang(row.status.toLowerCase()) 
		return <label style={{ marginLeft: '5px' }}>{status}</label>

	}

	const renderInternalStudyEditor = ({ row }) => {
		return (
			<>
				<AllowRender permissions={['PATIENT_RW']}>
					<Button
						onClick={() => {
							resetFields(form)
							setPatientsStudy(row)
							setShowInternalStudyUpdateEditor(true)
						}}
						type="edit"
					/>
				</AllowRender>
			</>
		)
	}

	const updatePatientStatus = async ({ form, isExternalStudy = false }, resetStudyFnc = () => {}) => {
		await form.validateFields()
		let fields = await form.getFieldsValue()

		const resource = {
			...fields,
			currentStatus: patientsStudy?.status,
			started: moment.utc(fields.started.toLocaleString()),
			finished: fields.finished ? moment.utc(fields.finished.toLocaleString()) : null,
			...(isExternalStudy
				? { study: fields.study }
				: { study_id: studies && studies.filter((stud) => stud.value === fields.study).map((stu) => stu.key)[0] })
		}

		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })

		const body = JSON.stringify(resource)

		backend.studies({
			isExternalStudy,
			patient: patientId,
			type: backend.type.UPDATE,
			body,
			cb: () => {
				resetStudyFnc()
				toast.update(loadingToast, {
					render: lang('patient_updated'),
					type: 'success',
					isLoading: false,
					closeOnClick: true,
					autoClose: 5000
				})
			}
		})
		setShowInternalStudyUpdateEditor(false)
		setShowExternalStudyEditor(false)
		setOpenPatientId(undefined)
		fetchPatients()
		resetFields(form)
	}

	return (
		<>
			<Drawer
				key={Utils.randomID()}
				width="600px"
				onClose={() => {
					setShowInternalStudyUpdateEditor(false)
					resetFields(form)
				}}
				open={showInternalStudyUpdateEditor}
				onOK={() => {
					updatePatientStatus({ form }, refreshStudies)
				}}
				text={{
					title: lang('update_study'),
					cancel: lang('cancel'),
					ok: lang('save')
				}}>
				{editorFormSheet}
			</Drawer>
			<Table
				loading={loading}
				title={lang('patient_involvement')}
				borderless={false}
				headless={false}
				expandable={true}
				columns={[
					{ key: 'title', value: lang('study') },
					{ key: 'status', value: lang('state'), render: renderStatus },
					{ key: 'dropout_reason', value: lang('drop-out') },
					{ key: 'start', value: lang('started_at'), render: renderStart },
					{ key: 'finished', value: lang('finished_at'), render: renderFinish },
					{ key: 'actions', value: lang('actions'), render: renderInternalStudyEditor }
				]}
				data={data}
			/>
			<StudyReferences
				renderStart={renderStart}
				renderFinish={renderFinish}
				resetFields={resetFields}
				setShowStudyCreateEditor={setShowStudyCreateEditor}
				showStudyCreateEditor={showStudyCreateEditor}
				rules={rules}
				studies={studies}
				context={context}
				patientId={patientId}
				refreshStudies={refreshStudies}
				updatePatientStatus={updatePatientStatus}
				showExternalStudyEditor={showExternalStudyEditor}
				setShowExternalStudyEditor={setShowExternalStudyEditor}
			/>
		</>
	)
}

export default Studies
