import React, { useState, useEffect } from 'react'

import Drawer from '../../../components/Drawer'
import Backend from '../../../api/Backend'
import { LangContext } from '../../../components/Translation'
import translations from './translations'
import { CMS_ENDPOINT } from '../../../pages/CMS'
import DecisionModal from '../../../components/DecisionModal'
import Empty from '../../../components/Empty'
import { toast } from 'react-toastify'
import Skeleton from '../../../components/Skeleton'

const PublishDecisionModal = ({ openModal, setOpenModal, setDrawer, handleTaskCompletion }) => {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()
	return (
		<DecisionModal
			open={openModal}
			onClose={() => {
				setOpenModal(false)
			}}
			onCancel={() => {
				setOpenModal(false)
			}}
			text={{
				title: lang('publish-confirmation', { title: openModal?.name }),
				cancel: lang('cancel'),
				ok: lang('confirm')
			}}
			onOK={() => {
				const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
				backend.healthProvider({
					type: backend.type.UPDATE,
					id: openModal.id,
					body: JSON.stringify({ status: 'ACTIVE' }),
					cb: (res) => {
						toast.update(loadingToast, {
							render: lang('hsp-published'),
							type: 'success',
							isLoading: false,
							closeOnClick: true,
							autoClose: 5000
						})
						handleTaskCompletion()
						setOpenModal(false)
						setDrawer(false)
					},
					cbError: (err) => {
						toast.update(loadingToast, {
							render: lang('an-error-has-occured'),
							type: 'error',
							isLoading: false,
							closeOnClick: true,
							autoClose: 5000
						})
						setOpenModal(false)
					}
				})
			}}
		/>
	)
}

const AlreadyPublished = () => {
	const lang = React.useContext(LangContext)(translations)
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<h1>{lang('hsp-already-published')}</h1>
		</div>
	)
}

const HSPReview = ({ HSPReviewer, setHSPReviewer, handleTaskCompletion = () => {} }) => {
	const lang = React.useContext(LangContext)(translations)

	const backend = new Backend()

	const [loading, setLoading] = useState(false)
	const [HSP, setHSP] = useState()
	const [publishModal, setPublishModal] = useState(false)
	useEffect(() => {
		setLoading(true)
		backend.healthProvider({
			cache: false,
			id: HSPReviewer?.resource?.patientId,
			cb: (data) => {
				setHSP(data)
				setLoading(false)
			}
		})
	}, [HSPReviewer])

	const availability = () => {
		let retAvailability = {}
		let days = HSP?.available

		if (days && Array.isArray(days) && days.length > 0) {
			days.forEach((availability) => {
				if (availability.weekday in retAvailability) {
					retAvailability[availability.weekday].times.push({ from: [availability.from], till: [availability.till] })
				} else {
					retAvailability[availability.weekday] = { times: [{ from: [availability.from], till: [availability.till] }] }
				}
			})
		} else {
			retAvailability = {
				monday: { times: [{ from: [], till: [] }] },
				tuesday: { times: [{ from: [], till: [] }] },
				wednesday: { times: [{ from: [], till: [] }] },
				thursday: { times: [{ from: [], till: [] }] },
				friday: { times: [{ from: [], till: [] }] },
				saturday: { times: [{ from: [], till: [] }] },
				sunday: { times: [{ from: [], till: [] }] }
			}
		}
		return (
			<div className="availability-container">
				{Object.keys(retAvailability).map((day) => {
					return (
						<div key={day}>
							<strong>{day}</strong>
							{retAvailability[day].times.map((time) => {
								return (
									<div key={day + time?.from + time?.till} className="time-container">
										<div className="time-from">
											{time?.from} - {time?.till}
										</div>
									</div>
								)
							})}
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<>
			<Drawer
				placement="right"
				width="90%"
				open={HSPReviewer}
				onClose={() => setHSPReviewer(false)}
				text={{
					title: lang('review-hsp'),
					cancel: lang('cancel'),
					ok: lang('publish')
				}}
				onOK={() => setPublishModal(HSP)}>
				{HSP && HSP?.status === 'ACTIVE' ? (
					<AlreadyPublished />
				) : (
					<Skeleton hide={!loading}>
						<div className="hsp-profile-section">
							<div>
								<div>
									<img
										src={
											HSP?.image?.formats ? (
												`${CMS_ENDPOINT}` + HSP?.image?.formats?.medium?.url
											) : HSP?.image?.url ? (
												`${CMS_ENDPOINT}` + HSP.image.url
											) : (
												<Empty />
											)
										}
										alt="profile-picture"
										height="300"
									/>
								</div>
							</div>
							<div style={{ marginTop: '40px' }}>
								<h1>{HSP?.name}</h1>
							</div>
							<div className="hsp-profile-subsection">
								<h1>{lang('summary')}</h1>
								<p className="hsp-profile-paragraph">{HSP?.summary}</p>
							</div>
							<div className="hsp-profile-subsection">
								<h2>{lang('profile-type')}</h2>
								<div>
									{HSP?.nationwide ? lang('nationwide-hsp') : lang('regional-hsp')} <br />
									{lang('level')} {HSP?.level}
								</div>
							</div>
							{!HSP?.nationwide && (
								<div className="hsp-profile-subsection">
									<h2>{lang('address')}</h2>{' '}
									<div>
										{HSP?.street} {HSP?.streetnr} <br /> {HSP?.zip} {HSP?.city}
									</div>
								</div>
							)}
							<div className="hsp-profile-subsection">
								<h2>{lang('contact-information')}</h2>{' '}
								<div>
									+{HSP?.phone} <br /> {HSP?.email} <br /> {HSP?.website}
								</div>
							</div>
							<div className="hsp-profile-subsection">
								<h2>{lang('availability')}</h2> <div>{availability()}</div>
							</div>
							<div className="hsp-profile-subsection">
								<h2>{lang('service')}</h2>
								<p className="hsp-profile-paragraph">{HSP?.service}</p>
							</div>
						</div>
					</Skeleton>
				)}
			</Drawer>
			<PublishDecisionModal
				openModal={publishModal}
				setOpenModal={setPublishModal}
				setDrawer={setHSPReviewer}
				handleTaskCompletion={handleTaskCompletion}
			/>
		</>
	)
}

export default HSPReview
