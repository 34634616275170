import React, { useState, useEffect } from 'react'
import { Form, Input, InputNumber, Select, Card } from 'antd'

import Thumbnail from '../../../components/Thumbnail'
import Button from '../../../components/Button'
import Drawer from '../../../components/Drawer'

import ContentTypeSelect from '../../../components/ContentTypeSelect'

import MediaLibrary from '../../MediaLibrary'

import { LangContext } from '../../../components/Translation'
import translations from './translations'

import { CMS_ENDPOINT } from '../../../pages/CMS'
import Backend from '../../../api/Backend'

import './index.css'

const ACTION_TYPES = {
	INTERNAL_CONTENT: 'INTERNAL_CONTENT',
	EXTERNAL_CONTENT: 'EXTERNAL_CONTENT',
	PAGE: 'PAGE',
	QUESTION: 'QUESTION',
	MESSAGE: 'MESSAGE'
}

const PAGES = ['weight', 'home', 'careteam']

const ActionsEditor = ({ data, form, reset }) => {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()

	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)
	const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState(null)

	const [actionTypes, setActionTypes] = useState()
	const [selectedActionType, setSelectedActionType] = useState(null)

	const [centers, setCenters] = useState([])

	const [selectedContent, setSelectedContent] = useState([])

	const groups = ['All', 'Control', 'Intervention']

	useEffect(() => {
		backend.actionTypes({
			cb: (types) => {
				setActionTypes(types)
			}
		})
		backend.clinics({
			cb: (centers) => {
				setCenters(
					centers
						.map((center) => {
							return { clinic_name: center.clinic_name, id: center.id }
						})
						.concat([{ clinic_name: 'Public', id: '0000' }])
				)
			}
		})
	}, [])

	useEffect(() => {
		form.resetFields()
	}, [form, reset, data])

	useEffect(() => {
		setSelectedActionType(null)
		setSelectedContent([])
	}, [reset])

	useEffect(() => {
		if (data) {
			if (
				(selectedActionType === ACTION_TYPES.INTERNAL_CONTENT || data?.action_type?.key === ACTION_TYPES.INTERNAL_CONTENT) &&
				data?.recommendedInternalContent
			) {
				backend.cmsType({
					cb: (types) => {
						let key = data?.recommendedInternalContent?.split('/')[1]
						let type = types.find((entry) => data.recommendedInternalContent?.split('/')[0] === entry.key)
						setSelectedContent([{ key: key, type: { key: type.id } }])
						form.setFieldsValue({ recommendedInternalContent: { key: key, type: {id: type.id, strapiKey: type.key} } })
					}
				})
			}
		}
	}, [data, form])

	const handleContentChange = (values) => {
		setSelectedContent(
			values.map((v) => {
				return { key: v?.key, type: v?.type }
			})
		)
		form.setFieldsValue({
			recommendedInternalContent: {
				key: values[0]?.key,
				type: values[0]?.type
			}
		})
	}


	const MediaLibraryDrawer = () => {
		return (
			<Drawer
				open={openThumbnailMediaLibrary}
				placement="right"
				onClose={() => setOpenThumbnailMediaLibrary(false)}
				width={'75%'}
				text={{ title: lang('thumbnail-library'), cancel: lang('cancel') }}>
				<MediaLibrary
					single
					media={{
						onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
						setSelectedMedia: (thumbnail) => {
							form.setFieldsValue({
								thumbnail: {
									id: thumbnail.id,
									url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
								}
							})
						},
						setSelectedMediaUrl: setSelectedThumbnailUrl,
						type: 'Thumbnail',
						mime: ['image/jpeg', 'image/png', 'image/svg+xml']
					}}
				/>
			</Drawer>
		)
	}

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	return (
		<div className="p10">
			<Form form={form} initialValues={data && data} layout="vertical">
				<div className="thumbnail-container">
					<div>
						<div className="ant-col ant-form-item-label">
							<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
								{lang('thumbnail')}
							</label>
						</div>
						<Form.Item name="thumbnail" hidden>
							<Input type={'hidden'} />
						</Form.Item>
						<br />
						<Thumbnail
							url={
								selectedThumbnailUrl
									? selectedThumbnailUrl
									: data?.thumbnail?.formats?.thumbnail
									? `${CMS_ENDPOINT}${data.thumbnail.formats.thumbnail.url}`
									: data?.thumbnail?.url
									? `${CMS_ENDPOINT}${data.thumbnail.url}`
									: null
							}
						/>
					</div>
					<div>
						<Button type="secondary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
					</div>
				</div>

				<Form.Item name="internalTitle" label={lang('internal-title')} rules={rules}>
					<Input placeholder={lang('title-placeholder')} />
				</Form.Item>
				<Form.Item name="publicTitle" label={lang('public-title')} rules={rules}>
					<Input placeholder={lang('title-placeholder')} />
				</Form.Item>
				<div className="action-type-select">
					<Form.Item name="action_type" rules={rules} label={lang('type')}>
						<Select
							placeholder={lang('select-type')}
							onChange={(type, obj) => {
								setSelectedActionType(obj.key)
							}}>
							{actionTypes &&
								Array.isArray(actionTypes) &&
								actionTypes.length > 0 &&
								actionTypes.map((type) => {
									return (
										<Select.Option key={type?.key} value={type?.id}>
											{lang(type.key)}
										</Select.Option>
									)
								})}
						</Select>
					</Form.Item>
				</div>

				{selectedActionType === ACTION_TYPES.INTERNAL_CONTENT || data?.action_type?.key === ACTION_TYPES.INTERNAL_CONTENT ? (
					<div className="hidden-input">
						<Form.Item label={lang('recommended-content')} name="recommendedInternalContent" rules={rules}>
							<Input type={'hidden'} />
						</Form.Item>
						<Card>
							<ContentTypeSelect
								rowsPerPage={5}
								placeholder={lang('select')}
								value={selectedContent}
								onChange={handleContentChange}
								type={'radio'}
								isCms={true}
							/>
						</Card>
					</div>
				) : selectedActionType === ACTION_TYPES.EXTERNAL_CONTENT || data?.action_type?.key === ACTION_TYPES.EXTERNAL_CONTENT ? (
					<Form.Item label={lang('external-link')} name="recommendedExternalContent" rules={rules}>
						<Input />
					</Form.Item>
				) : selectedActionType === ACTION_TYPES.PAGE || data?.action_type?.key === ACTION_TYPES.PAGE ? (
					<Form.Item name="page" rules={rules} label={lang('page')}>
						<Select placeholder={lang('select-page')}>
							{PAGES.map((page) => {
								return (
									<Select.Option key={page} value={page}>
										{lang(page)}
									</Select.Option>
								)
							})}
						</Select>
					</Form.Item>
				) : (
					<></>
				)}

				<div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
					<div style={{ width: '50%' }}>
						<Form.Item
							name="centerVisibility"
							label={lang('center-visibility')}
							initialValue={data?.visibility && data.visibility.length > 0 ? data.visibility.map((el) => el.clinic_name) : ['Public']}
							rules={[
								{
									required: true,
									message: lang('please-select-a-center')
								},
								{
									validator(_, value) {
										if (value && value.length > 1 && value.includes('Public')) {
											return Promise.reject(lang('center-select-conflict'))
										} else {
											return Promise.resolve()
										}
									}
								}
							]}>
							<Select mode="multiple" placeholder={lang('select-visibility')}>
								{centers.map((el) => (
									<Select.Option key={el.id} value={el.clinic_name}>
										{el.clinic_name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
					<div style={{ width: '50%' }}>
						<Form.Item
							name="groupVisibility"
							label={lang('group-visibility')}
							initialValue={data?.groups ? data.groups : 'All'}
							rules={[
								{
									required: true,
									message: lang('please-select-a-group')
								}
							]}>
							<Select placeholder={lang('select-visibility')}>
								{groups.map((group) => (
									<Select.Option key={group} value={group}>
										{lang(group)}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</div>
				</div>
				<Form.Item name="daysVisible" label={lang('days-visible')} rules={rules}>
					<InputNumber min={1} addonAfter={lang('days')}></InputNumber>
				</Form.Item>

				<MediaLibraryDrawer />
			</Form>
		</div>
	)
}

export default ActionsEditor
