import React from 'react'
import { Descriptions as AntdDescription } from 'antd'
import Skeleton from '../Skeleton'
import Empty from '../Empty'

const Description = ({ data, title, emptyText, loading = false, bordered = false, layout = 'horizontal', column = { md: 1 } }) => {
	let placeholder = null

	if (loading) {
		placeholder = <Skeleton className="p10" type="list" />
	} else if (!loading && data && data.length === 0) {
		placeholder = (
			<>
				<h3>{title}</h3>
				<Empty description={emptyText} />
			</>
		)
		
	}

	return placeholder ? placeholder :
		<AntdDescription title={title} bordered={bordered} layout={layout} column={column}>
			{data.map((item) => {
				return (
					<AntdDescription.Item key={item.key} label={item.key}>
						{item.value}
					</AntdDescription.Item>
				)
			})}
		</AntdDescription>
	
}

export default Description
