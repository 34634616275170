import React from 'react'
import { Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

import translations from './translations'
import { LangContext } from '../../components/Translation'

import './index.css'

const { Dragger } = Upload

const AntUploader = ({ props }) => {

	const lang = React.useContext(LangContext)(translations)

	return (
		<Dragger {...props}>
			<p className="ant-upload-drag-icon">
				<InboxOutlined />
			</p>
			<p className="ant-upload-text">{lang("click-or-drag-files-here-to-upload")}</p>
			<p className="ant-upload-hint">{lang("click-or-drag-hint")}</p>
		</Dragger>
	)
}

export default AntUploader
