import React from 'react'
import { Card, Button, Row, Col, Divider } from 'antd'

import Utils from '../../../components/Utils'
import Icon from '../../../components/Icon'
import SymptomIcon from '../../../components/SymptomIcon'
import { states, TypeLabel, StatusColor, StatusLabel, taskTypes } from '../index'

import { LangContext } from '../../../components/Translation'
import translations from '../translations'

import './index.css'

const SymptomFooter = ({ content, lang, onAcknowledge }) => {
	const symptom = content.symptoms.length > 0 ? content.symptoms[0].resource : { resource: {} }
	const name = content?.patient_idPersonalData?.name || ''
	const surname = content?.patient_idPersonalData?.surname || ''
	return (
		<div className="block">
			<div className="action-menu-row">
				<div className="action-menu-details">
					<SymptomIcon
						symptom={{
							type: symptom.symptom,
							severity: symptom.severity
						}}
					/>
				</div>
				<div className="action-menu-details">
					<span className="details-title">{lang('symptom')}</span>
					<span className="details-data">{symptom.symptom}</span>
				</div>
				<div className="action-menu-details">
					<span className="details-title">{lang('degree')}</span>
					<span className="details-data">{symptom.severity}</span>
				</div>
				<div className="action-menu-details">
					<span className="details-title">{lang('score')}</span>
					<span className="details-data">{symptom.score?.value}</span>
				</div>
				<div className="action-menu-details">
					<span className="details-title">{lang('assessment')}</span>
					<span className="details-data">{symptom.type}</span>
				</div>
			</div>
			<div className="action-menu-row mt10">
				<div className="action-menu-details">
					<span className="details-title">{lang('name')}</span>
					<span className="details-data">{name}</span>
				</div>
				<div className="action-menu-details">
					<span className="details-title">{lang('surname')}</span>
					<span className="details-data">{surname}</span>
				</div>
			</div>
		</div>
	)
}

const ConnectFooter = ({ content, utils, date, lang }) => {
	return (
		<>
			<div className="action-menu-details">
				<span className="details-title">{lang('date')}</span>
				<span className="details-data">{utils.toDate({ dateString: date })}</span>
			</div>
			<div className="action-menu-details">
				<span className="details-title">{lang('care-connect')}</span>
				<span className="details-data">dummy txt</span>
			</div>
			<div className="action-menu-details">
				<span className="details-title">todo</span>
				<span className="details-data">text</span>
			</div>
		</>
	)
}

const GenericFooter = ({ content, lang }) => {
	return (
		<>
			<div className="action-menu-details">
				<span className="details-title">{lang('task')}</span>
				<span className="details-data">{content?.resource?.text}</span>
			</div>
		</>
	)
}

const OnboardPatientFooter = ({ content, lang }) => {
	const name = content?.patient_idPersonalData?.name || ''
	const surname = content?.patient_idPersonalData?.surname || ''
	let birthDate = content?.patient_idPersonalData?.birth_date
	if (birthDate) {
		birthDate = new Date(birthDate).toLocaleDateString()
	} else {
		birthDate = ''
	}
	return (
		<>
			<div className="action-menu-details">
				<span className="details-title">{lang('name')}</span>
				<span className="details-data">{name}</span>
			</div>
			<div className="action-menu-details">
				<span className="details-title">{lang('surname')}</span>
				<span className="details-data">{surname}</span>
			</div>
			<div className="action-menu-details">
				<span className="details-title">{lang('birthdate')}</span>
				<span className="details-data">{birthDate}</span>
			</div>
		</>
	)
}

const AssessmentFooter = ({ content, utils, lang }) => {
	const name = content?.patient_idPersonalData?.name || ''
	const surname = content?.patient_idPersonalData?.surname || ''
	return (
		<>
			<div className="action-menu-details">
				<span className="details-title">{lang('name')}</span>
				<span className="details-data">{name}</span>
			</div>
			<div className="action-menu-details">
				<span className="details-title">{lang('surname')}</span>
				<span className="details-data">{surname}</span>
			</div>
		</>
	)
}

const AppointmentFooter = ({ content, date, lang, utils }) => {
	const name = content?.patient_idPersonalData?.name || ''
	const surname = content?.patient_idPersonalData?.surname || ''

	return (
		<div className="block">
			<div className="action-menu-row">
				<div className="action-menu-details">
					<span className="details-title">{lang('appointment')}</span>
					<span className="details-data">{utils.toDate({ dateString: content.resourceAppointment?.scheduledAt })}</span>
				</div>
				<div className="action-menu-details">
					<span className="details-title">Details</span>
					<span className="details-data">{content.resourceAppointment?.medium}</span>
				</div>
				<div className="action-menu-details">
					<span className="details-title">{lang('status')}</span>
					<span className="details-data">{content.resourceAppointment?.status}</span>
				</div>
			</div>
			<div className="action-menu-row mt10">
				<div className="action-menu-details">
					<span className="details-title">{lang('name')}</span>
					<span className="details-data">{name}</span>
				</div>
				<div className="action-menu-details">
					<span className="details-title">{lang('surname')}</span>
					<span className="details-data">{surname}</span>
				</div>
			</div>
		</div>
	)
}

const HSPReviewFooter = ({ content, lang }) => {
	return (
		<>
			<div className="action-menu-details">
				<span className="details-title">{lang('hsp-review')}</span>
				<span className="details-data">{content?.resource?.HSPName}</span>
			</div>
			<div className="action-menu-details">
				<span className="details-title">{lang('status')}</span>
				<span className="details-data">{content?.status}</span>
			</div>
		</>
	)
}

const BirthdateOverdueFooter = ({ content, lang }) => {
	const name = content?.patient_idPersonalData?.name || ''
	const surname = content?.patient_idPersonalData?.surname || ''
	return (
		<>
			<div className="action-menu-details">
				<span className="details-title">{lang('name')}</span>
				<span className="details-data">{name}</span>
			</div>
			<div className="action-menu-details">
				<span className="details-title">{lang('surname')}</span>
				<span className="details-data">{surname}</span>
			</div>
		</>
	)
}

const TaskCard = ({ id, type, title, content, patient, first, last, date, dragging, status, handleClick, onAcknowledge }) => {
	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()

	let footer = null
	if (type === taskTypes.SYMPTOM_ALARM) {
		footer = <SymptomFooter content={content} lang={lang} onAcknowledge={onAcknowledge} />
	} else if (type === taskTypes.APPOINTMENT) {
		footer = <AppointmentFooter content={content} lang={lang} utils={utils} />
	} else if (type === taskTypes.CONNECT) {
		footer = <ConnectFooter content={content} lang={lang} />
	} else if (type === taskTypes.ASSESSMENT) {
		footer = <AssessmentFooter content={content} lang={lang} utils={utils} />
	} else if (type === taskTypes.HSP_REVIEW) {
		footer = <HSPReviewFooter content={content} lang={lang} />
		patient = 'HSP' + content?.resource?.patientId
	} else if (type === taskTypes.ONBOARD_PATIENT) {
		footer = <OnboardPatientFooter content={content} lang={lang} />
	} else if(type === taskTypes.BIRTHDATE_OVERDUE) {
		footer = <BirthdateOverdueFooter content={content} lang={lang} />
	} else if (type === taskTypes.SCHEDULED) {
		footer = <GenericFooter content={content} lang={lang} />
	} else if (type === taskTypes.GENERIC) {
		footer = <GenericFooter content={content} lang={lang} />
	}

	const isAppointmentTask = (content) => {
		return content.type === taskTypes.APPOINTMENT
	}

	const isScheduledTask = (content) => {
		return content.type === taskTypes.SCHEDULED
	}

	const getDesription = (content) => {
		if (isAppointmentTask(content)) {
			return content?.resourceAppointment?.title
		}
		if (isScheduledTask(content)) {
			return content?.resource?.title
		}
		return ''
	}

	return (
		<div className="taskcard">
			<Card
				style={{ width: 383 }}
				onClick={() => {
					handleClick(id)
				}}>
				<div className="status-container">
					<div>
						<span className="date">{`${utils.toDate({ dateString: content?.resource?.createdAt })} | ${lang('modified')} ${utils.toDate({
							dateString: content.changed_at
						})}`}</span>
					</div>
					<div>
						<span className="task-status" style={{ color: StatusColor(status) }}>
							{StatusLabel(status)}
						</span>
					</div>
				</div>
				<div className="title-header">
					<span>{TypeLabel(content.type)}</span>
					<span className="patient-name align-right">{patient ? `${patient} ${first || last ? `- ${first} ${last}` : ''}` : ''}</span>
				</div>
				<div className="description">{getDesription(content)}</div>
				{footer ? <div className="action-menu-container">{footer}</div> : null}
			</Card>
		</div>
	)
}

export default TaskCard
