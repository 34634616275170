import React, { useEffect, useState } from 'react'
import Drawer from '../../components/Drawer'
import ContentTypeSelect from '../../components/ContentTypeSelect'
import Icon from '../../components/Icon'

import MediaLibrary from '../MediaLibrary'
import translations from './translations'
import { LangContext } from '../../components/Translation'
import { Modal, Input, Card, message, Radio, Space } from 'antd'
import Backend from '../../api/Backend'

import { CMS_ENDPOINT } from '../../pages/CMS'

// Documentation for markdown editor plugins is here:
// https://github.com/HarryChen0506/react-markdown-editor-lite/blob/HEAD/docs/plugin.md

const CollapsibleParagraph = (props) => {
	const lang = React.useContext(LangContext)(translations)
	const handleClick = () => {
		props.editor.insertText('<collapsible_paragraph></collapsible_paragraph>')
	}

	return (
		<span className="button" title={lang('collapsible-paragraph')} onClick={handleClick}>
			<Icon type="collapsible-paragraph" />
		</span>
	)
}

const InterventionTag = (props) => {
	const lang = React.useContext(LangContext)(translations)

	const handleClick = () => {
		props.editor.insertText(`<conditional-intervention></conditional-intervention>`)
	}
	return (
		<>
			<span className="button" title={lang('intervention-tag')} onClick={handleClick}>
				INT
			</span>
		</>
	)
}

const ConditionalTag = (props) => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)

	const [openModal, setOpenModal] = useState(false)
	const [selection, setSelection] = useState()
	const [centers, setCenters] = useState([])

	useEffect(() => {
		backend.clinics({
			cb: (centers) => {
				setCenters(centers)
			}
		})
	}, [])

	const handleClick = () => {
		setOpenModal(true)
	}

	const handleOk = () => {
		if (selection) {
			let slct = selection.toLowerCase()
			props.editor.insertText(`<conditional-${slct}></conditional-${slct}>`)
			setSelection('')
			setOpenModal(false)
		} else {
			message.error(lang('select-constraint'))
		}
	}
	const handleCancel = () => {
		setSelection()
		setOpenModal(false)
	}

	const onChange = (e) => {
		setSelection(e.target.value)
	}
	return (
		<>
			<Modal title={lang('conditional-render')} visible={openModal} onOk={handleOk} onCancel={handleCancel}>
				<Radio.Group onChange={onChange} value={selection}>
					<Space direction="vertical">
						{centers &&
							centers.map((center) => (
								<Radio key={center.clinic_name} value={center.clinic_name}>
									{center.clinic_name}
								</Radio>
							))}
					</Space>
				</Radio.Group>
			</Modal>
			<span className="button" title={lang('conditional-render')} onClick={handleClick}>
				<Icon type="conditional-render" />
			</span>
		</>
	)
}

const AttachmentCard = (props) => {
	const [openModal, setOpenModal] = useState(false)
	const [selectedAttachments, setSelectedAttachments] = useState('')

	const [tag, setTag] = useState('')
	const [title, setTitle] = useState('')

	const lang = React.useContext(LangContext)(translations)

	const handleOk = () => {
		props.editor.insertText(
			`<attachment_card attachment-tag="${tag}" attachment-title="${title}" attachment-link="${selectedAttachments}"></attachment_card>`
		)
		setTitle('')
		setSelectedAttachments('')
		setTag('')
		setOpenModal(false)
	}

	const handleCancel = () => {
		setOpenModal(false)
	}

	const handleClick = () => {
		setOpenModal(true)
	}

	return (
		<>
			<span className="button" title={lang('add-attachment')} onClick={handleClick}>
				<Icon type="add-attachment" />
			</span>
			<Modal title={lang('add-attachment')} visible={openModal} onOk={handleOk} width={'60%'} onCancel={handleCancel}>
				{lang('tag')}
				<Input placeholder={lang('insert-tag')} value={tag} onChange={(value) => setTag(value.target.value)}></Input>
				<br />
				<br />
				{lang('title')}
				<Input placeholder={lang('insert-title')} value={title} onChange={(value) => setTitle(value.target.value)}></Input>
				<br />
				<br />
				{lang('attachment')}
				<Input
					placeholder={lang('insert-attachment')}
					disabled
					value={selectedAttachments}
					onChange={(value) => setSelectedAttachments(value.target.value)}></Input>

				<MediaLibrary
					single={true}
					media={{
						onMediaSelect: (media) => {
							return true
						},
						setSelectedMedia: (attachment) => {
							setSelectedAttachments(attachment.url)
						},
						selectedMedia: selectedAttachments,
						type: 'Attachments',
						mime: ['application/pdf']
					}}
				/>
			</Modal>
		</>
	)
}

const ExternalLink = (props) => {
	const [openModal, setOpenModal] = useState(false)

	const [tag, setTag] = useState('')
	const [link, setLink] = useState('')
	const [title, setTitle] = useState('')

	const lang = React.useContext(LangContext)(translations)

	const handleOk = () => {
		props.editor.insertText(`<external_card external-tag="${tag}" external-title="${title}" external-link="${link}"></external_card>`)
		setTitle('')
		setLink('')
		setTag('')
		setOpenModal(false)
	}

	const handleCancel = () => {
		setOpenModal(false)
	}

	const handleClick = () => {
		setOpenModal(true)
	}

	return (
		<>
			<span className="button" title={lang('add-external-link')} onClick={handleClick}>
				<Icon type="add-link" />
			</span>
			<Modal title={lang('add-external-link')} visible={openModal} onOk={handleOk} onCancel={handleCancel}>
				{lang('tag')}
				<Input placeholder={lang('insert-tag')} value={tag} onChange={(value) => setTag(value.target.value)}></Input>
				<br />
				<br />
				{lang('title')}
				<Input placeholder={lang('insert-title')} value={title} onChange={(value) => setTitle(value.target.value)}></Input>
				<br />
				<br />
				{lang('link')}
				<Input placeholder={lang('insert-link')} value={link} onChange={(value) => setLink(value.target.value)}></Input>
			</Modal>
		</>
	)
}

const InternalArticle = (props) => {
	const [openModal, setOpenModal] = useState(false)

	const [selectedImage, setSelectedImage] = useState([])
	const [link, setLink] = useState([])
	const [title, setTitle] = useState('')

	const lang = React.useContext(LangContext)(translations)

	const handleOk = () => {
		props.editor.insertText(
			`<internal_article internal-image="${CMS_ENDPOINT + selectedImage}" internal-title="${title}" internal-link="${
				link[0]?.type && link[0]?.type?.strapiKey + '/' + link[0]?.key
			}"></internal_article>`
		)
		setTitle('')
		setLink([])
		setSelectedImage([])
		setOpenModal(false)
	}

	const handleCancel = () => {
		setOpenModal(false)
	}

	const handleClick = () => {
		setOpenModal(true)
	}

	return (
		<>
			<span className="button" title={lang('link-internal-article')} onClick={handleClick}>
				<Icon type="add-article" />
			</span>
			<Modal width={1040} title={lang('add-internal-article')} visible={openModal} onOk={handleOk} onCancel={handleCancel}>
				{lang('title')}
				<Input placeholder={lang('insert-title')} value={title} onChange={(value) => setTitle(value.target.value)}></Input>
				<br />
				<br />
				{lang('link')}
				<Input
					placeholder={lang('select-content')}
					disabled
					value={link[0]?.type && link[0]?.type?.strapiKey + '/' + link[0]?.key}
					onChange={(value) => setLink(value.target.value)}></Input>
				<ContentTypeSelect placeholder={lang('select')} value={link} onChange={setLink} type={'radio'} isCms={true} />
				<br />
				<br />
				{lang('image')}
				<Input placeholder={lang('insert-image')} disabled value={selectedImage}></Input>
				<Card>
					<div style={{ height: '1000px', overflowY: 'auto' }}>
						<MediaLibrary
							single={true}
							media={{
								onMediaSelect: (media) => {
									return true
								},
								setSelectedMedia: (image) => {
									setSelectedImage(image.url)
								},
								selectedMedia: selectedImage,
								type: 'Thumbnail',
								mime: ['image/jpeg', 'image/png', 'image/svg+xml']
							}}
						/>
					</div>
				</Card>
			</Modal>
		</>
	)
}

const YoutubeVideo = (props) => {
	const [openModal, setOpenModal] = useState(false)
	const [link, setLink] = useState('')

	const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/gm

	const lang = React.useContext(LangContext)(translations)

	const handleOk = () => {
		if (youtubeRegex.test(link)) {
			props.editor.insertText(`@[youtube](${link})`)
			setLink('')
			setOpenModal(false)
		} else {
			message.error(lang('youtube-invalid'))
		}
	}

	const handleCancel = () => {
		setOpenModal(false)
	}

	const handleClick = () => {
		setOpenModal(true)
	}

	return (
		<>
			<span className="button" title={lang('add-youtube')} onClick={handleClick}>
				<Icon type="youtube" />
			</span>
			<Modal title={lang('add-youtube')} visible={openModal} onOk={handleOk} onCancel={handleCancel}>
				<Input placeholder={'https://www.youtube.com/...'} value={link} onChange={(value) => setLink(value.target.value)} />
			</Modal>
		</>
	)
}

const TableOfContents = (props) => {
	const lang = React.useContext(LangContext)(translations)
	const handleClick = () => {
		// Call API, insert TOC to editor, add newline so markdown isnt interfered with.
		// [[TOC]] keyword comes from "markdown-it-table-of-contents"

		// check if TOC is already there, if so, remove it. Else, add it.
		let indexOfTOC = props.editor.state.text.indexOf('[[TOC]]')
		if (indexOfTOC !== -1) {
			// if [[TOC]] is present in the text area
			props.editor.setSelection({ start: indexOfTOC, end: indexOfTOC + 9 }, '')
			props.editor.insertText('', true, { start: 0, end: 0 })
		} else {
			props.editor.insertText('[[TOC]] \n')
		}
	}

	return (
		<span className="button" title={lang('table-of-contents')} onClick={handleClick}>
			TOC
		</span>
	)
}

const GallerySelection = (props) => {
	const lang = React.useContext(LangContext)(translations)
	const [showMediaLibraryModal, setShowMediaLibraryModal] = useState(false)

	const [selectedMediaUrl, setSelectedMediaUrl] = useState(null)

	useEffect(() => {
		if (selectedMediaUrl) {
			const markdownImage = `![](${selectedMediaUrl})`

			props.editor.insertText(markdownImage)
			//setSelectedMediaURL(null)
		}
	}, [selectedMediaUrl])

	const handleClick = () => {
		setShowMediaLibraryModal(true)
	}

	return (
		// TODO: Add icon to button
		<>
			<span className="button" title={lang('media-library')} onClick={handleClick}>
				<Icon type="add-image" />
			</span>
			<Drawer
				open={showMediaLibraryModal}
				placement="right"
				onClose={() => setShowMediaLibraryModal(false)}
				width={'65%'}
				text={{ title: lang('media-library'), cancel: lang('cancel') }}>
				<MediaLibrary
					single
					media={{
						onMediaSelect: () => setShowMediaLibraryModal(false),
						setSelectedMediaUrl: setSelectedMediaUrl,
						setSelectedMedia: () => null,
						type: 'Images',
						mime: ['image/jpeg', 'image/png', 'image/svg+xml']
					}}
				/>
			</Drawer>
		</>
	)
}

// Define default configs
CollapsibleParagraph.align = 'left'
CollapsibleParagraph.pluginName = 'collapsibleparagraph'
InterventionTag.align = 'left'
InterventionTag.pluginName = 'interventiontag'
ConditionalTag.align = 'left'
ConditionalTag.pluginName = 'conditionaltag'
TableOfContents.align = 'left'
TableOfContents.pluginName = 'tableofcontents'
GallerySelection.align = 'left'
GallerySelection.pluginName = 'galleryselection'
ExternalLink.align = 'left'
ExternalLink.pluginName = 'externallink'
AttachmentCard.align = 'left'
AttachmentCard.pluginName = 'attachmentcard'
InternalArticle.align = 'left'
InternalArticle.pluginName = 'internalarticle'
YoutubeVideo.align = 'left'
YoutubeVideo.pluginName = 'youtubevideo'

export {
	TableOfContents,
	GallerySelection,
	ExternalLink,
	AttachmentCard,
	InternalArticle,
	YoutubeVideo,
	ConditionalTag,
	InterventionTag,
	CollapsibleParagraph
}
