import React from 'react'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'

import { TaskBoard } from '../../applets/TaskBoard'
import Appointments from '../../applets/Appointments'

import { useGlobalContext } from '../../components/AppContext'

import { LangContext } from '../../components/Translation'
import translations from './translations'

import './index.css'

export default function Dashboard() {
	const lang = React.useContext(LangContext)(translations)
	const { isEnavProject } = useGlobalContext()

	return (
		<PageHeader title={lang('dashboard')}>
			<PageContent>
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-12 col-lg-12">
							<TaskBoard title={lang('tasks')} userTasks={false} />
						</div>
						{!isEnavProject() ? (
							<div className="col-sm-12 col-lg-12">
								<Appointments />
							</div>
						) : null}
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}
