import React, { useState, useEffect } from 'react'
import { Select } from 'antd'

import Backend from '../../api/Backend'
import { useGlobalContext } from '../AppContext'

import './index.css'

const { Option } = Select

export default function CenterSelect({ style }) {
	const context = useGlobalContext()

	const backend = new Backend()
	const [data, setData] = useState()
	const [loading, setLoading] = useState(true)

	const getDefaultValue = ({ d = data }) => {
		return context?.globals?.selectedCenter ? context.globals.selectedCenter : Array.isArray(d) && d.length > 0 ? d[0].id : null
	}

	useEffect(() => {
		setLoading(true)
		backend.clinics({
			all: true,
			cb: center => {
				setData(center)
				handleChange(getDefaultValue({ d: center }))
				setLoading(false)
			},
		})
	}, [])

	const handleChange = selectedCenter => {
		context.setGlobals({ ...context.globals, selectedCenter })
	}

	const defaultValue = getDefaultValue({})

	const options = data
		? data.map(d => {
				return (
					<Option key={d.id} value={d.id}>
						<div className="flex">
							<div className="center-logo">
								<img src={d.image}></img>
							</div>
							<div className="center-name">{d.clinic_name}</div>
						</div>
					</Option>
				)
		  })
		: null


	if (!loading) {
		return (
			<Select style={style} disabled={options.length < 2} defaultValue={defaultValue} onChange={handleChange} bordered={false}>
				{options}
			</Select>
		)
	} else {
		return null
	}
}
