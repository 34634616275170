import React, { useState, useEffect, useContext } from 'react'
import { LangContext } from '../../components/Translation'
import ReactApexChart from 'react-apexcharts'
import Card from 'antd/lib/card/Card'
import { getDailyReports, getHeartRateExtremumsDaily } from '../../api-new'
import translations from './translations'
import { DatePicker } from 'antd'
import moment from 'moment'

const CandlestickChart = ({ title, data }) => {
	const options = {
		legend: {},
		chart: {
			type: 'candlestick',
			height: 250
		},
		title: {
			text: title,
			align: 'left'
		},
		yaxis: {
			tooltip: {
				enabled: true
			},
			max: (max) => {
				return max
			}
		}
	}
	const series = [
		{
			data: data
		}
	]
	return (
		<div>
			<div id="chart">
				<ReactApexChart options={options} series={series} type="candlestick" height={250} />
			</div>
			<div id="html-dist"></div>
		</div>
	)
}

// eslint-disable-next-line no-undef
Apex = {
	xaxis: {
		type: 'datetime'
	}
}

const SyncingCharts = ({ data }) => {
	const lang = useContext(LangContext)(translations)

	const caloriesData = []
	const distanceData = []
	const stepsData = []
	for (const e of data) {
		const x = Date.parse(e.date)
		caloriesData.push([x, e.calories])
		distanceData.push([x, e.distance])
		stepsData.push([x, e.steps])
	}

	const caloriesSeries = [
		{
			data: caloriesData
		}
	]
	const distanceSeries = [
		{
			data: distanceData
		}
	]
	const stepsSeries = [
		{
			data: stepsData
		}
	]
	const caloriesOptions = {
		chart: {
			id: 'cl',
			group: 'social',
			type: 'line',
			height: 180
		},
		title: {
			text: lang('calories'),
			align: 'left'
		},
		colors: ['#008FFB']
	}
	const distanceOptions = {
		chart: {
			id: 'dt',
			group: 'social',
			type: 'line',
			height: 180
		},
		title: {
			text: lang('distance'),
			align: 'left'
		},
		colors: ['#008FFB'],
		yaxis: {
			labels: {
				forceNiceScale: true,
				decimalsInFloat: 0,
				formatter: (value) => {
					return value.toFixed(1)
				}
			}
		}
	}
	const stepsOptions = {
		chart: {
			id: 'st',
			group: 'social',
			type: 'line',
			height: 180
		},
		title: {
			text: lang('steps'),
			align: 'left'
		},
		colors: ['#008FFB']
	}

	return (
		<div>
			<div id="wrapper">
				<div id="chart-line">
					<ReactApexChart options={caloriesOptions} series={caloriesSeries} type="line" height={180} />
				</div>
				<div id="chart-line2">
					<ReactApexChart options={distanceOptions} series={distanceSeries} type="line" height={180} />
				</div>
				<div id="chart-line3">
					<ReactApexChart options={stepsOptions} series={stepsSeries} type="line" height={180} />
				</div>
			</div>
			<div id="html-dist"></div>
		</div>
	)
}

const IntegrationsOverview = ({ patientId }) => {
	const lang = useContext(LangContext)(translations)
	const [date, setDate] = useState(new Date())
	const [dailyHeartRate, setDailyHeartRate] = useState([])
	const [dailyReports, setDailyReports] = useState([])

	useEffect(() => {
		const refresh = async () => {
			const startDate = new Date(date.getFullYear(), date.getMonth(), 1)
			const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
			try {
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				const response = await getHeartRateExtremumsDaily(patientId, startDate, endDate, timezone)
				var timezoneOffset = new Date().getTimezoneOffset() * 60000
				const data = response
					.map((element) => {
						return {
							x: new Date(Date.parse(`${element.date}`) + timezoneOffset),
							y: [element.minValue, element.maxValue, element.minValue, element.maxValue]
						}
					})
					.sort((first, second) => first.x > second.x)
				setDailyHeartRate(data)
			} catch (error) {
				console.log(error)
			}
			try {
				const response = await getDailyReports(patientId, startDate, endDate)
				setDailyReports(response)
			} catch (error) {
				console.log(error)
			}
		}
		refresh()
	}, [date])

	const onDateChange = (date, dateString) => {
		if (date != undefined && date != null) {
			setDate(date.toDate())
		}
	}

	const filterDates = (current) => {
		let res = moment().add(-1, 'days') < current
		return res
	}

	return (
		<Card>
			<DatePicker picker="month" onChange={onDateChange} disabledDate={filterDates} />
			<CandlestickChart data={dailyHeartRate} title={lang('heart-rate')} />
			<SyncingCharts data={dailyReports} />
		</Card>
	)
}

export default IntegrationsOverview
