import './index.css'
import React from 'react'

const Steps = (props) => {
	const { data, stepNumber } = props
	return (
		<div className="exo-steps steps">
			{data.map((dat, idx) => (
				<a
					key={idx}
					href=""
					className={`step-item ${stepNumber === idx ? 'active' : ''}`}
					data-bs-toggle="tooltip"
					title={dat.description}>
					{dat.description}
				</a>
			))}
		</div>
	)
}

export default Steps

