import React, { useState, useEffect, useRef, useContext } from 'react'
import { Input, Button, Select, Form, Upload } from 'antd'
import { UpOutlined, DownOutlined, PlusCircleOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons'

import Icon from '../../components/Icon'
import Table from '../../components/Table'
import Utils from '../../components/Utils'

import Backend from '../../api/Backend'
import { LangContext } from '../../components/Translation'
import translations from './translations'

import './index.css'

const { Option } = Select
const EditableContext = React.createContext(null)

export default function ECRFStructureEditor() {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()
	const utils = new Utils()

	const [data, setData] = useState([])
	const [sourceData, setSourceData] = useState([])
	const [loading, setLoading] = useState(false)
	const [unsavedChanges, setUnsavedChanges] = useState(false)

	const toLetters = (num) => {
		var mod = num % 26,
			pow = (num / 26) | 0,
			out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z')
		return pow ? toLetters(pow) + out : out
	}

	const mapData = (data) => {
		return data.map((d, idx) => {
			return {
				key: toLetters(idx + 1),
				variable: d.variable,
				source: d.source
			}
		})
	}

	const getTemplateRow = () => {
		return { variable: '', source: '1' }
	}

	const refresh = () => {
		setLoading(true)
		backend.ecrfExportStructure({
			cb: (data) => {
				setData(mapData(data.length > 0 ? data[0]?.resource.data : [getTemplateRow()]))
				setLoading(false)
				setUnsavedChanges(false)
			}
		})
		setSourceData([
			{ key: '1', value: 'GEB' },
			{ key: '2', value: 'DF' }
		])
	}

	useEffect(() => {
		refresh()
	}, [])

	const resetData = () => {
		setData([])
		setUnsavedChanges(true)
	}

	const saveExportStructure = () => {
		setLoading(true)
		const body = JSON.stringify({ data }) // mst be wrapped in object
		backend.ecrfExportStructure({
			type: backend.type.UPDATE,
			body,
			cbError: () => {
				setLoading(false)
				setUnsavedChanges(false)
			},
			cb: () => {
				setLoading(false)
				setUnsavedChanges(false)
			}
		})
	}

	const EditableCell = ({ title, editable, selector, children, dataIndex, record, onCellSave, ...restProps }) => {
		const [editing, setEditing] = useState(false)
		const inputRef = useRef(null)
		const form = useContext(EditableContext)
		useEffect(() => {
			if (editing) {
				inputRef.current.focus()
			}
		}, [editing])

		const toggleEdit = () => {
			setEditing(!editing)
			form.setFieldsValue({
				[dataIndex]: record[dataIndex]
			})
		}

		const save = async () => {
			try {
				const values = await form.validateFields()
				toggleEdit()
				handleCellSave({ ...record, ...values })
			} catch (errInfo) {
				console.log('Save failed:', errInfo)
			}
		}

		let childNode = children

		const roText = ({ resolve = (c) => c }) => (
			<div
				className="editable-cell-value-wrap"
				style={{
					paddingRight: 24
				}}
				onClick={toggleEdit}>
				{resolve(children)}
			</div>
		)

		if (editable) {
			childNode = editing ? (
				<Form.Item
					style={{
						margin: 0
					}}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: `${title} is required.`
						}
					]}>
					<Input ref={inputRef} onPressEnter={save} onBlur={save} />
				</Form.Item>
			) : (
				roText({})
			)
		}

		if (selector) {
			childNode = editing ? (
				<Form.Item
					style={{
						margin: 0
					}}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: `${title} is required.`
						}
					]}>
					<Select
						showSearch
						ref={inputRef}
						onPressEnter={save}
						onBlur={save}
						style={{ width: 200 }}
						placeholder="Search to Select"
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}>
						{sourceData.map((d) => (
							<Option key={d.key} value={d.key}>
								{d.value}
							</Option>
						))}
					</Select>
				</Form.Item>
			) : (
				roText({
					resolve: (key) => {
						return sourceData.filter((d) => d.key === key[1])[0]?.value
					}
				})
			)
		}

		return <td {...restProps}>{childNode}</td>
	}

	const handleCellSave = (row) => {
		const newData = [...data]
		const index = newData.findIndex((item) => row.key === item.key)
		const item = newData[index]
		newData.splice(index, 1, { ...item, ...row })
		setData(newData)
		setUnsavedChanges(true)
	}

	const EditableRow = ({ index, ...props }) => {
		const [form] = Form.useForm()
		return (
			<Form form={form} component={false}>
				<EditableContext.Provider value={form}>
					<tr {...props} />
				</EditableContext.Provider>
			</Form>
		)
	}

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell
		}
	}

	const moveRow = (index, direction) => {
		const arrayMove = (arr, fromIndex, toIndex) => {
			var element = arr[fromIndex]
			arr.splice(fromIndex, 1)
			arr.splice(toIndex, 0, element)
			return arr
		}

		setData(mapData(arrayMove(data, index, index + direction)))
		setUnsavedChanges(true)
	}

	const addRow = (index) => {
		setData(
			mapData(
				data
					.slice(0, index + 1)
					.concat(data[index])
					.concat(data.slice(index, data.length - 1))
			)
		)
		setUnsavedChanges(true)
	}

	const removeRow = (index) => {
		setData(mapData(data.slice(0, index).concat(data.slice(index + 1, data.length))))
		setUnsavedChanges(true)
	}

	const renderActions = ({ row, index }) => {
		return (
			<>
				<Button shape="circle" onClick={() => moveRow(index, 1)} disabled={index === data.length - 1} icon={<DownOutlined />} />
				<Button className="ml5 mr15" shape="circle" onClick={() => moveRow(index, -1)} disabled={index === 0} icon={<UpOutlined />} />
				<Button
					className="ml5"
					shape="circle"
					type="danger"
					disabled={index === 0}
					onClick={() => removeRow(index)}
					icon={<MinusCircleOutlined />}
				/>
				<Button className="ml5" shape="circle" onClick={() => addRow(index)} icon={<PlusCircleOutlined />} />
			</>
		)
	}

	const handleFileUpload = (data) => {
		const rows = data.split(/\r?\n/)
		const newData = rows.map((r) => {
			return {
				variable: r,
				source: '1'
			}
		})
		setData(mapData(newData))
		setUnsavedChanges(true)
	}

	return (
		<Table
			title={lang('title')}
			emptyText={lang('no-mapping')}
			loading={loading}
			headless={true}
			components={components}
			rowClassName={() => 'editable-row'}
			columns={[
				{
					width: 100,
					key: 'key',
					value: lang('column'),
					render: ({ value }) => {
						return value
					},
					search: true
				},
				{
					width: 200,
					key: 'variable',
					value: lang('variable'),
					editable: true,
					onCellSave: handleCellSave,
					sorter: (a, b) => (a.variable ? a.variable.localeCompare(b.variable) : false),
					search: true
				},
				{
					width: 200,
					key: 'source',
					value: lang('source'),
					selector: true
				},
				{ width: 150, fixed: 'right', key: 'actions', value: lang('actions'), render: renderActions }
			]}
			buttons={
				<>
					<Upload
						accept=".csv"
						showUploadList={false}
						beforeUpload={(file) => {
							const reader = new FileReader()
							reader.onload = (e) => {
								handleFileUpload(e.target.result)
							}
							reader.readAsText(file)
							// Prevent upload
							return false
						}}>
						<Button icon={<UploadOutlined />}>Upload content</Button>
					</Upload>
					<Button className="ml20" type={unsavedChanges ? 'primary' : ''} onClick={() => saveExportStructure()}>
						{lang('save')}
					</Button>
					<Button className="ml5" type="download" onClick={() => resetData()}>
						{lang('reset')}
					</Button>
				</>
			}
			data={data}
		/>
	)
}
