import React from 'react'
import { useGlobalContext } from '../AppContext'

/*
	permissions : check for having at least one permission
	inverted: restict access in case of true
	IMPORTANT: Call this as a function if using truthy, not as a react component. e.g.: AllowRender({ permissions: ['CARE_TEAM_RW'], truthy: true })
*/

const AllowRender = ({ permissions = [], inverted = false, children, exactMatch = false, truthy = false, level = undefined }) => {
	const { hspData, currentPermissions, globals } = useGlobalContext()

	const handlePermission = perms => {
		let permitted = false

		if (exactMatch) {
			if (globals.user.permissions.length === permissions.length && permissions.every((perm) => globals.user.permissions.includes(perm))) {
				return false
			}
			return true
		} 

		// allowingRender based on hsp level (for chat/video chat, for example)
		if (level && !isNaN(level) && hspData?.level) {
			if (hspData.level < level || hspData.status !== 'ACTIVE') {
				return false
			}
			return true
		}

		

		const hasPermission = currentPermissions.filter(value => perms.includes(value)).length > 0
		if (inverted && hasPermission) {
			permitted = false
		} else if (inverted && !hasPermission) {
			permitted = true
		} else if (!inverted && hasPermission) {
			permitted = true
		}

		return permitted
	}

	if (truthy) {
		const result = handlePermission(permissions)
		return result
	} else {
		return <>{handlePermission(permissions) ? children : null}</>
	}
}

export default AllowRender
