import React, { useState, useEffect } from 'react'
import { Card } from 'antd'
import { CMS_ENDPOINT } from '../../pages/CMS'
import { initializeParser } from '../../applets/MarkdownEditor'



import Backend from '../../api/Backend'
import Table from '../Table'
import Tag from '../Tag'
import Button from '../Button'
import Skeleton from '../Skeleton'

import { LangContext } from '../Translation'

import './index.css'

const resolveDescription = (desc) => desc.replaceAll('/uploads', `${CMS_ENDPOINT}/uploads`)

export default function ContentTypeSelect({
	key,
	className = '',
	value = [],
	clinicId = undefined,
	onChange = () => {},
	group,
	type = 'checkbox',
	isCms = false,
	rowsPerPage = 10
}) {
	const lang = React.useContext(LangContext)()
	const backend = new Backend()

	// Markdown-it import and plugin setup
	const mdParser = initializeParser()


	const rowKey = (key, type) => {
		return `${key}-${type}`
	}

	const [contentTypes, setContentTypes] = useState([])
	const [loading, setLoading] = useState(true)
	const [selectedRow, setSelectedRow] = useState()

	const refresh = () => {
		setLoading(true)
		backend.cms({
			group,
			clinicId, // e.g. in case of intervention selection
			cb: (data) => {
				setContentTypes(
					data.sort((a, b) =>
						a.id.toString().localeCompare(b.id)
					).map((d) => {
						return {
							d: d,
							id: d.id,
							key: rowKey(d.id, d.type?.id), //antD uses key as table UUID
							title: d.title,
							group: d.group,
							visibility: d.visibility,
							type: {
								title: d.type?.title,
								key: d.type?.id,
								strapiKey: d.type.key // Adding this for content categories link resolving
							}
						}
					})
				)
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		refresh()
	}, [])

	const handleSelection = (items) => {
		onChange(
			items.map((item) => {
				const res = contentTypes.filter((ct) => ct.key === item)[0]
				if (isCms) {
					return {
						key: res.id,
						type: {
							id: res.type.key,
							strapiKey: res.type.strapiKey
						}
					}
				}
				return {
					key: res.id,
					type: {
						id: res.type.key
					}
				}
			})
		)
	}

	return (
		<Skeleton rows={Math.round(rowsPerPage * 2.4)} hide={!loading}>
			<div className="exo-content-type-select inline">
				<div className={`list ${selectedRow ? 'content-small' : ''}`}>
					<Table
						key={key}
						pagination={{ defaultPageSize: rowsPerPage }}
						loading={loading}
						className={className}
						rowSelection={{
							type: type,
							selectedRowKeys: value.map((d) => rowKey(d.key, d.type?.key ? d.type?.key : d.type?.id)),
							onChange: handleSelection
						}}
						onRow={(row) => {
							return {
								onClick: () => {
									setSelectedRow(row)
								}
							}
						}}
						data={contentTypes}
						columns={[
							{ key: 'title', value: lang('title'), sorter: (a, b) => a.title.localeCompare(b.title), search: true },
							{
								key: 'type',
								value: lang('type'),
								render: ({ row }) => <Tag type={row.type?.title}>{row.type?.title}</Tag>,
								sorter: (a, b) => a.type?.title.localeCompare(b.type?.title)
							},
							{
								key: 'group',
								value: lang('group'),
								render: ({ value }) => <Tag type={value}>{value}</Tag>
							},
							{
								key: 'visibility',
								value: lang('center-visibility'),
								render: ({ value }) => {
									return (
										<>
											{value && value.length > 0 ? (
												value.map((center) => <Tag key={center?.clinic_name}>{center?.clinic_name}</Tag>)
											) : (
												<Tag>{lang('all')}</Tag>
											)}
										</>
									)
								}
							}
						]}
					/>
				</div>
				{selectedRow ? (
					<div className="details content-small">
						<Card
							title={selectedRow?.title}
							extra={
								<Button
									type="close"
									onClick={() => {
										setSelectedRow()
									}}></Button>
							}>
							<div className="block markdown-preview-content">
								{selectedRow?.d.description ? (
									<div dangerouslySetInnerHTML={{ __html: mdParser.render(resolveDescription(selectedRow?.d.description)) }}></div>
								) : null}
							</div>
						</Card>
					</div>
				) : null}
			</div>
		</Skeleton>
	)
}
