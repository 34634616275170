import React, { useEffect, useState } from 'react'

import { Table, Card } from 'antd'

export default function TableWithPagination({ columns, fetchData, title, onRow }) {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1
		}
	})

	useEffect(() => {
		const fetch = async () => {
			setLoading(true)
			const response = await fetchData({ page: tableParams.pagination.current - 1 })
			setData(response.data)
			setLoading(false)
			setTableParams({
				...tableParams,
				pagination: { ...tableParams.pagination, total: response.meta.itemCount, pageSize: response.meta.pageSize }
			})
		}
		fetch()
	}, [JSON.stringify(tableParams)])

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			...sorter
		})

		if (pagination.pageSize !== tableParams.pagination?.pageSize) {
			setData([])
		}
	}

	return (
		<Card title={title}>
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={data}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
                onRow={onRow}
			/>
		</Card>
	)
}
