import React, { useState, useEffect, useReducer } from 'react'
import { toast } from 'react-toastify'
import { Input, InputNumber, Card, Menu, Dropdown, Modal, Radio, Row, Col, Checkbox } from 'antd'

import Backend from '../../api/Backend'
import Utils from '../../components/Utils'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'
import Drawer from '../../components/Drawer'
import Table from '../../components/Table'
import Button from '../../components/Button'
import Skeleton from '../../components/Skeleton'
import ButtonGroup from '../../components/ButtonGroup'
import Tag from '../../components/Tag'
import Tabs from '../../components/Tabs'
import DecisionModal from '../../components/DecisionModal'
import SettingSelect from '../../components/SettingSelect'
import EventSelect from '../../components/EventSelect'
import { AppointmentScheduler } from '../../applets/AppointmentScheduler'
import Icon from '../../components/Icon'
import ActionSelect from '../../components/ActionSelect'
import AssessmentTypeSelect from '../../components/AssessmentTypeSelect'
import ContentTypeSelect from '../../components/ContentTypeSelect'
import AssessmentItem from './AssessmentItem'
import ContentItem from './ContentItem'
import AppointmentItem from './AppointmentItem'
import ActionItem from './ActionItem'

import { useGlobalContext } from '../../components/AppContext'

import translations from './translations'
import { LangContext } from '../../components/Translation'

import { Redirect } from 'react-router-dom'
import './index.css'
import AllowRender from '../../components/AllowRender'

const assessmentIcon = <Icon type={'assessment'} />
const appointmentIcon = <Icon type={'appointment'} />
const contentIcon = <Icon type={'content'} />
const actionIcon = <Icon type={'action'} />
const startEventIcon = <Icon type={'play'} />

const timings = {
	PERIODICAL: 'PERIODICAL',
	EVENT: 'EVENT',
	DELAY: 'DELAY'
}

const Action = ({ data }) => {
	const lang = React.useContext(LangContext)(translations)

	const [delayValue, setDelayValue] = useState()
	const [delayUnit, setDelayUnit] = useState()
	const [assessments, setAssessments] = useState()
	const [actions, setActions] = useState()
	const [calculationEvent, setCalculationEvent] = useState()
	const [content, setContent] = useState()
	const [event, setEvent] = useState(data.trigger ? data.trigger : [])
	const [withAppointment, setWithAppointment] = useState(data.appointment.length > 0)

	const delayUnits = [
		{ key: 'day', value: lang('days') },
		{ key: 'week', value: lang('weeks') }
	]

	useEffect(() => {
		setDelayValue(data.delayValue)
		setDelayUnit(data.delayUnit)
		setAssessments(data.assessment)
		setContent(data.content)
		setActions(data.action)
		setCalculationEvent(data.calculationEvent)
	}, [])

	const handleDelayValueChange = (value) => {
		data.delayValue = value
		setDelayValue(data.delayValue)
	}

	const handleDelayUnitChange = (value) => {
		data.delayUnit = value.key
		setDelayUnit(data.delayUnit)
	}

	const handleAssessmentsChange = (values) => {
		data.assessment = values
		setAssessments(values)
	}

	const handleContentChange = (values) => {
		data.content = values.map((v) => {
			return { key: v.key, type: v.type }
		})
		setContent(values)
	}

	const handleEventChange = (values) => {
		data.trigger = values
		setEvent(values)
	}

	const setAppointment = ({ getOutputData, isValid }) => {
		isValid()
			.then((fields) => {
				data.appointment = [getOutputData(fields)]
			})
			.catch((err) => {})
	}

	const removeAppointment = () => {
		data.appointment = []
		setWithAppointment(false)
	}

	const handleCalculationEventChange = (values) => {
		data.calculationEvent = { key: values.key, type: timings.EVENT }
		setCalculationEvent(data.calculationEvent)
		setContent(values)
	}

	const handleActionsChange = (values) => {
		data.action = values
		setActions(values)
	}

	let trigger = null
	if (data.timing === timings.DELAY || data.timing === timings.PERIODICAL) {
		trigger = (
			<div className="flex" style={{ width: '250px', gap: '5px' }}>
				<InputNumber
					min={data.timing === timings.DELAY ? '0' : '1'}
					required
					label={lang('delay')}
					className="w100p"
					value={delayValue}
					onChange={handleDelayValueChange}></InputNumber>
				<ButtonGroup value={delayUnit} options={delayUnits} onChange={handleDelayUnitChange} />
			</div>
		)
	} else if (data.timing === timings.EVENT) {
		trigger = <EventSelect className="mt10" style={{ width: '100%' }} defaultOptions={event.key} onChange={handleEventChange} />
	}

	const tabData = () => {
		return [
			{
				key: '1',
				disabled: data.timing !== timings.DELAY,
				title: { text: lang('appointment'), icon: appointmentIcon },
				content: withAppointment ? (
					<div>
						<Button className="mb10" type="remove" onClick={removeAppointment} block>
							{lang('remove')}
						</Button>
						<AppointmentScheduler
							withGroupVisibility
							className="mt5"
							data={data.appointment.length > 0 ? data.appointment[0] : undefined}
							isTemplate
							onChange={setAppointment}
						/>
					</div>
				) : (
					<Button type="add" onClick={() => setWithAppointment(true)} block>
						{lang('add')}
					</Button>
				)
			},

			{
				key: '2',
				title: { text: lang('actions'), icon: actionIcon },
				content: <ActionSelect onChange={handleActionsChange} value={actions}></ActionSelect>
			},
			{
				key: '3',
				title: { text: lang('assessments'), icon: assessmentIcon },
				content: (
					<div>
						<AssessmentTypeSelect placeholder={lang('select')} value={assessments} onChange={handleAssessmentsChange} />
					</div>
				)
			},
			{
				key: '4',
				title: { text: lang('content'), icon: contentIcon },
				content: (
					<div>
						<ContentTypeSelect placeholder={lang('select')} value={content} onChange={handleContentChange} />
					</div>
				)
			},
			{
				key: '5',
				title: { text: lang('activation-event'), icon: startEventIcon },
				content: (
					<div>
						<EventSelect placeholder={lang('select')} defaultOptions={calculationEvent?.key} onChange={handleCalculationEventChange} />
					</div>
				)
			}
		]
	}

	return (
		<>
			{trigger}
			<Tabs data={tabData()} className="mt30 exo-careplan-tabs" />
		</>
	)
}

const sortCareplanActivities = (activities) => {
	const days = ({ numStr = '0', unitStr = '' }) => {
		return parseInt(numStr) * (unitStr.toLowerCase() === 'week' ? 7 : 1)
	}
	return activities
		.sort((a, b) => {
			return days({ numStr: a.delayValue, unitStr: a.delayUnit }) - days({ numStr: b.delayValue, unitStr: b.delayUnit })
		})
		.map((row) => {
			row.key = row.id
			return row
		})
}

const getCareplanActivities = (activities, timing) => {
	if (timing === timings.DELAY) {
		return sortCareplanActivities(activities.filter((a) => a.timing === timings.DELAY))
	} else if (timing === timings.EVENT) {
		return activities.filter((a) => a.timing === timings.EVENT)
	} else if (timing === timings.PERIODICAL) {
		return activities.filter((a) => a.timing === timings.PERIODICAL)
	}
}

const getSharedColumns = ({ timing, withActions = false, lang = () => {}, resolver = {}, actions = {}, onChange }) => {
	const columns = [
		{
			key: 'assessment',
			value: lang('assessments'),
			width: 500,
			render: ({ value, row }) => {
				// just add on change if set, otherwise the patient careplan viewer will get this function w/o any need
				return value ? (
					<AssessmentItem
						value={value}
						lang={lang}
						onOrderChange={
							onChange
								? (_value) => {
										row.assessment = JSON.parse(JSON.stringify(_value)) // a copy must be created in order to make the table update when the data is set back
										onChange()
								  }
								: null
						}
					/>
				) : null
			}
		},
		{
			key: 'content',
			width: 600,
			value: lang('content'),
			render: ({ value, row }) => {
				return value ? (
					<ContentItem
						value={value}
						lang={lang}
						resolve={resolver.resolveContent}
						onOrderChange={
							onChange
								? (_value) => {
										row.content = JSON.parse(JSON.stringify(_value)) // a copy must be created in order to make the table update when the data is set back
										onChange()
								  }
								: null
						}
					/>
				) : null
			}
		},
		{
			key: 'action',
			width: 600,
			value: lang('actions'),
			render: ({ value, row }) => {
				return value ? (
					<ActionItem
						value={value}
						lang={lang}
						onOrderChange={
							onChange
								? (_value) => {
										row.action = JSON.parse(JSON.stringify(_value)) // a copy must be created in order to make the table update when the data is set back
										onChange()
								  }
								: null
						}
					/>
				) : null
			}
		}
	]

	if (timing === timings.DELAY) {
		columns.unshift({
			key: 'appointment',
			value: lang('appointment'),
			width: 200,
			render: ({ value }) => {
				return <AppointmentItem value={value} resolve={resolver.resolveAppointment} />
			}
		})
	}
	if (withActions) {
		columns.push({
			fixed: 'right',
			value: lang('action'),
			width: 100,
			render: ({ row }) => {
				return (
					<div style={{ margin: 'auto' }} className="df">
						<Button type="edit" ghost onClick={() => actions.handleEditAction(row, timing)}></Button>
						<Button type="remove" ghost className="ml5" onClick={() => actions.handleRemoveStep(row)}></Button>
					</div>
				)
			}
		})
	}
	return columns
}

const activationTypes = { INCLUSION: 'INCLUSION', DATE: 'DATE' }
const calculationTypes = { ASSIGNMENT: 'ASSIGNMENT', EVENT: 'EVENT' }

const Careplan = ({ id, onClose = () => {} }) => {
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()

	const { currentPermissions, domain, careplanStatuses } = useGlobalContext()

	const hasPublishPermission = () => {
		return currentPermissions.includes(domain.permissions.CAREPLAN_PUBLISH)
	}

	const getInitialActionData = (timing) => {
		const d = {
			timing,
			assessment: [],
			appointment: [],
			content: [],
			task: []
		}
		if (timing === timings.EVENT) {
			d.trigger = []
		} else {
			d.delayValue = '1'
			d.delayUnit = 'day'
		}
		return d
	}
	const [loading, setLoading] = useState()
	const [careplan, setCareplan] = useState()
	const [careplanId, setCareplanId] = useState(id)
	const [actionData, setActionData] = useState(getInitialActionData())
	const [activationType, setActivationType] = useState(activationTypes.DATE)
	const [calculationType, setCalculationType] = useState(calculationTypes.ASSIGNMENT)
	const [calculationEvent, setCalculationEvent] = useState()
	const [stopEvent, setStopEvent] = useState()

	const [showActionModal, setShowActionModal] = useState(false)
	const [showRemoveModal, setShowRemoveModal] = useState(false)
	const [showSaveAsModal, setShowSaveAsModal] = useState(false)
	const [showPublishModal, setShowPublishModal] = useState(false)
	const [isSaveHttpsRequestInProgress, setIsSaveHttpsRequestInProgress] = useState(false)

	const [saveAsTitle, setSaveAsTitle] = useState('')
	const [contentTypes, setContentTypes] = useState([])
	const [appointmentTypes, setAppointmentTypes] = useState([])
	const [treatmentTypes, setTreatmentTypes] = useState([])
	const [healthProviders, setHealthProviders] = useState([])


	const [forwardId, setForwardId] = useState()
	const [forwardCareplans, setForwardCareplans] = useState(false)

	const [, forceUpdate] = useReducer((x) => x + 1, 0)

	useEffect(() => {
		const msg = lang('loading_object_failed')
		backend.cms({ cb: setContentTypes })

		if (careplanId) {
			setLoading(true)
			backend.careplans({
				id: careplanId,
				cb: (data) => {
					if (data.length > 0) {
						const careplan = data.pop().resource
						setCareplan(careplan)
						setActivationType(careplan.activationType)
						setCalculationType(careplan.calculationType)
						setCalculationEvent(careplan.calculationEvent)
						setStopEvent(careplan.stopEvent)
						setLoading(false)
					} else {
						toast.error(msg, { toastId: msg })
					}
				}
			})
			backend.appointmentType({ cb: setAppointmentTypes })
			backend.treatmentType({ cb: setTreatmentTypes })
			backend.healthProvider({ cb: setHealthProviders })
		} else {
			resetCareplan()
		}
		resetActionData()
	}, [careplanId])

	const isUpdateState = () => {
		return careplanId !== undefined
	}

	const titleChange = (key, value) => {
		if (careplan) {
			careplan.title = value
			setCareplan({ ...careplan })
		}
	}

	const handleSettingChange = (selected) => {
		if (careplan) {
			careplan.setting = selected.key
			setCareplan({ ...careplan })
		}
	}

	const handleCalculationEventChange = (event) => {
		setCalculationEvent(event.key)
		careplan.calculationEvent = event.key
	}

	const handleStopEventChange = (event) => {
		setStopEvent(event.key)
		careplan.stopEvent = event.key
	}

	const handleCareplanSave = ({ checkState = true, textKey = 'save_success' }) => {
		setLoading(true)
		if (backend.type.UPDATE && checkState) {
			setCareplanDraftState()
		}
		backend.careplans({
			id: careplanId,
			body: JSON.stringify(careplan),
			type: isUpdateState() ? backend.type.UPDATE : backend.type.CREATE,
			cb: ({ id }) => {
				setLoading(false)
				toast.success(lang(textKey, { name: careplan.title }))
				setCareplanId(id)
				setIsSaveHttpsRequestInProgress(false)
			}
		})
	}

	const saveCareplanAs = () => {
		if (saveAsTitle && saveAsTitle.length > 0) {
			setCareplanDraftState()
			careplan.title = saveAsTitle
			backend.careplans({
				body: JSON.stringify(careplan),
				type: backend.type.CREATE,
				cb: (data) => {
					toast.success(lang('save_success', { name: careplan.title }))
					setLoading(false)
					onClose()
					if (data?.id) {
						setForwardId(data.id)
					}
				}
			})
			setSaveAsTitle('')
		}
	}

	const handleCareplanPublish = () => {
		if (hasPublishPermission()) {
			setCareplanPublishedState()
			handleCareplanSave({ checkState: false, textKey: 'publish_success' })
		}
	}

	const handleCareplanRemove = () => {
		backend.careplans({
			id: careplanId,
			type: backend.type.DELETE,
			cb: () => {
				toast.success(lang('remove_success', { name: careplan.title }))
				onClose()
				setForwardCareplans(true)
			}
		})
	}

	const getActionByDelay = (delayValue, delayUnit, timing) => {
		return careplan.activity.filter((a) => a.delayValue === delayValue && a.delayUnit === delayUnit && a.timing === timing).pop()
	}

	const removeAction = ({ delayValue, delayUnit, eventTrigger }) => {
		if (eventTrigger) {
			careplan.activity = careplan.activity.filter((a) => !(a.trigger?.key === eventTrigger.key))
		} else {
			careplan.activity = careplan.activity.filter((a) => !(a.delayValue === delayValue && a.delayUnit === delayUnit))
		}
	}

	const handleActionChange = () => {
		const mergeActions = (actionData, action) => {
			action.calculationEvent = action.calculationEvent ? action.calculationEvent : actionData.calculationEvent
			action.assessment = action.assessment.concat(actionData.assessment)
			action.content = action.content.concat(actionData.content)
			action.action = action.action.concat(actionData.action)
			action.appointment = actionData.appointment.length === 0 ? action.appointment : actionData.appointment // only one item per day supported for now
			action.task = actionData.task.length === 0 ? action.task : actionData.task // only one item per day supported for now
		}

		const overwriteAction = (actionData, action) => {
			action.calculationEvent = actionData.calculationEvent
			action.assessment = actionData.assessment
			action.action = actionData.action
			action.content = actionData.content
			action.appointment = actionData.appointment
			action.task = actionData.task
			if (actionData.timing === timings.EVENT) {
				action.trigger = actionData.trigger
			}
		}

		const addAction = (actionData) => {
			actionData.id = Utils.getRandomUUID()
			careplan.activity.push(actionData)
		}

		const isUpdate = isActionDataUpdate()
		let targetAction = null
		if (actionData.timing === timings.DELAY || actionData.timing === timings.PERIODICAL) {
			const isMove = isUpdate && (actionData.delayValue !== actionData._d.delayValue || actionData.delayUnit !== actionData._d.delayUnit)
			targetAction = getActionByDelay(actionData.delayValue, actionData.delayUnit, actionData.timing)
			if (isMove) {
				handleRemoveStep({ delayValue: actionData._d.delayValue, delayUnit: actionData._d.delayUnit })
				if (targetAction) {
					mergeActions(actionData, targetAction)
				} else {
					addAction(actionData)
				}
			} else if (isUpdate) {
				overwriteAction(actionData, targetAction)
			} else {
				if (targetAction) {
					mergeActions(actionData, targetAction)
				} else {
					addAction(actionData)
				}
			}
		} else if (actionData.timing === timings.EVENT) {
			if (isUpdate) {
				targetAction = careplan.activity.filter((a) => a.trigger === actionData._d.trigger)[0]
				overwriteAction(actionData, targetAction)
			} else {
				addAction(actionData)
			}
		}
		setShowActionModal(false)
		delete actionData._d // avoid storing _d
		resetActionData()
	}

	const setCareplanPublishedState = () => {
		careplan.status = careplanStatuses.PUBLISHED
	}

	const setCareplanDraftState = () => {
		careplan.status = careplanStatuses.DRAFT
	}

	const handleEditAction = (row, timing) => {
		setActionData({
			_d: row,
			timing,
			trigger: row.trigger,
			delayValue: row.delayValue ? row.delayValue : 0,
			delayUnit: row.delayUnit ? row.delayUnit : 'day',
			calculationEvent: row.calculationEvent ? row.calculationEvent : null,
			assessment: row.assessment ? row.assessment : [],
			content: row.content ? row.content : [],
			action: row.action ? row.action : [],
			appointment: row.appointment ? row.appointment : [],
			task: row.task ? row.task : []
		})
		setShowActionModal(true)
	}

	const resetCareplan = () => {
		setCareplan({
			activity: [],
			created: null,
			id: null,
			period: null,
			resourceType: 'CAREPLAN',
			activationType: activationTypes.DATE,
			status: careplanStatuses.DRAFT,
			title: ''
		})
	}

	const resetActionData = (timing) => {
		setActionData(getInitialActionData(timing))
	}

	const isActionDataUpdate = () => {
		return actionData._d !== undefined
	}

	const handleRemoveStep = (step) => {
		if (step.timing === timings.EVENT) {
			removeAction({ eventTrigger: step.trigger })
		} else {
			removeAction({ delayValue: step.delayValue, delayUnit: step.delayUnit })
		}
		resetActionData()
	}

	const resolveContent = ({ id, type }) => {
		return contentTypes.filter((item) => item.id === id && item.type?.id === type)[0]
	}

	const resolveAppointment = (appointmentData) => {
		const appointment = { ...appointmentData }
		const appType = appointmentTypes.filter((item) => item.id === appointment.type?.id)[0]
		appointment.type = appType ? appType : appointment.type
		const treatmentType = treatmentTypes.filter((item) => item.id === appointment.treatment?.id)[0]
		appointment.treatment = treatmentType ? treatmentType : appointment.treatment
		const healthProvider = healthProviders.filter((item) => item.id === appointment.healthProvider?.id)[0]
		appointment.healthProvider = healthProvider ? healthProvider : appointment.healthProvider
		return appointment
	}

	const modalAction = (
		<Drawer
			open={showActionModal}
			placement="right"
			frameless
			onClose={() => setShowActionModal(false)}
			onOK={handleActionChange}
			width={'90vw'}
			text={{
				title: isActionDataUpdate() ? lang('title-edit-therapie-step') : lang('title-add-therapie-step'),
				cancel: lang('cancel'),
				ok: lang('ok')
			}}>
			<div style={{ margin: '23px' }}>
				<Action key={showActionModal} data={actionData} />
			</div>
		</Drawer>
	)

	const renderHomeRedirect = () => {
		return forwardCareplans ? (
			<Redirect
				to={{
					pathname: `/careplans`
				}}
			/>
		) : null
	}

	const renderSaveAsRedirect = () => {
		return forwardId ? (
			<Redirect
				to={{
					pathname: `/careplan/${forwardId}`
				}}
			/>
		) : null
	}

	const getActivityList = () => {
		return [
			{
				key: '1',
				title: { text: lang('order'), icon: <Icon type={'steps'} /> },
				content: (
					<Table
						title={lang('activities')}
						borderless={true}
						emptyText={lang('no-actvities')}
						loading={loading}
						headless={false}
						expandable={true}
						scroll={{ x: 1800 }}
						buttons={[
							<Button
								key="addActivity"
								type="add"
								className=""
								onClick={() => {
									resetActionData(timings.DELAY)
									setShowActionModal(Utils.randomID())
								}}
							/>
						]}
						columns={[
							{
								fixed: 'left',
								key: 'exo',
								value: lang('timing'),
								width: 100,
								render: ({ row }) => {
									return (
										<div className="line-align">
											<div className="flex-child one ">{row.delayValue}</div>
											<div className="flex-child two ">
												{row.delayValue === 1 ? lang(row.delayUnit) : lang(row.delayUnit + 's')}
											</div>
										</div>
									)
								}
							},
							{
								fixed: 'left',
								key: 'exo',
								value: lang('activation-event'),
								width: 150,
								render: ({ row }) => {
									return row.calculationEvent ? (
										<div className="line-align">
											<div className="flex-child one ">
												<EventSelect title="" disabled={true} value={row.calculationEvent} />
											</div>
										</div>
									) : null
								}
							},
							...getSharedColumns({
								timing: timings.DELAY,
								withActions: true,
								lang,
								actions: { handleEditAction, handleRemoveStep },
								resolver: { resolveContent, resolveAppointment },
								onChange: forceUpdate
							})
						]}
						data={careplan ? getCareplanActivities(careplan.activity, timings.DELAY) : null}
					/>
				)
			},
			{
				key: '2',
				title: { text: lang('events'), icon: <Icon type={'events'} /> },
				content: (
					<Table
						title={lang('activities')}
						borderless={true}
						emptyText={lang('no-actvities')}
						loading={loading}
						headless={false}
						expandable={true}
						scroll={{ x: 1800 }}
						buttons={[
							<Button
								key="addEventActivity"
								type="add"
								className=""
								onClick={() => {
									resetActionData(timings.EVENT)
									setShowActionModal(Utils.randomID())
								}}
							/>
						]}
						columns={[
							{
								fixed: 'left',
								key: 'exo',
								value: lang('event'),
								width: 300,
								render: ({ row }) => {
									return (
										<div className="line-align">
											<div className="flex-child one">{<Tag className="m5">{row.trigger?.value}</Tag>}</div>
										</div>
									)
								}
							},
							...getSharedColumns({
								timing: timings.EVENT,
								withActions: true,
								lang,
								actions: { handleEditAction, handleRemoveStep },
								resolver: { resolveContent, resolveAppointment }
							})
						]}
						data={careplan ? getCareplanActivities(careplan.activity, timings.EVENT) : null}
					/>
				)
			},
			{
				key: '3',
				title: { text: lang('periodical'), icon: <Icon type={'periods'} /> },
				content: (
					<Table
						title={lang('activities')}
						borderless={true}
						emptyText={lang('no-actvities')}
						loading={loading}
						headless={false}
						expandable={true}
						scroll={{ x: 1800 }}
						buttons={[
							<Button
								key="addPeriodicActivity"
								type="add"
								className=""
								onClick={() => {
									resetActionData(timings.PERIODICAL)
									setShowActionModal(Utils.randomID())
								}}
							/>
						]}
						columns={[
							{
								fixed: 'left',
								key: 'exo',
								value: lang('period'),
								width: 100,
								render: ({ row }) => {
									return (
										<div className="line-align">
											<div className="flex-child one ">{row.delayValue}</div>
											<div className="flex-child two ">
												{row.delayValue === 1 ? lang(row.delayUnit) : lang(row.delayUnit + 's')}
											</div>
										</div>
									)
								}
							},
							...getSharedColumns({
								timing: timings.PERIODICAL,
								withActions: true,
								lang,
								actions: { handleEditAction, handleRemoveStep },
								resolver: { resolveContent, resolveAppointment }
							})
						]}
						data={careplan ? getCareplanActivities(careplan.activity, timings.PERIODICAL) : null}
					/>
				)
			}
		]
	}

	const actions = {
		SAVE: 'SAVE',
		SAVE_AS: 'SAVE_AS',
		REMOVE: 'REMOVE',
		PUBLISH: 'PUBLISH'
	}

	const renderActions = () => {
		const handleMenuClick = (e) => {
			if (e.key === actions.SAVE_AS) {
				setShowSaveAsModal(true)
			} else if (e.key === actions.REMOVE) {
				setShowRemoveModal(true)
			} else if (e.key === actions.PUBLISH) {
				setShowPublishModal(true)
			}
		}

		const enablePublish = hasPublishPermission() && !isSaveHttpsRequestInProgress

		const menu = (
			<Menu onClick={handleMenuClick}>
				{isUpdateState() ? (
					<>
						<Menu.Item key={actions.SAVE_AS}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('save-as')}
							</a>
						</Menu.Item>
						<Menu.Item key={actions.REMOVE}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('remove')}
							</a>
						</Menu.Item>
					</>
				) : null}
			</Menu>
		)
		return (
			<>
				{enablePublish ? (
					<AllowRender permissions={['CAREPLAN_PUBLISH']}>
						<Button className="mr10" txt={lang('publish')} disabled={!enablePublish} onClick={() => setShowPublishModal(true)} />
					</AllowRender>
				) : null}
				<Dropdown.Button  onClick={() => {
					setIsSaveHttpsRequestInProgress(true)
					handleCareplanSave({})
				} } overlay={menu}>
					<Icon type="save"></Icon>
					{lang('save')}
				</Dropdown.Button>
			</>
		)
	}

	const renderActivityLists = () => {
		const lists = [
			<div key="activityList" className="col-sm-12 col-lg-12">
				<Card>
					<Tabs data={getActivityList()} className="" />
				</Card>
			</div>
		]
		return lists
	}

	return (
		<PageHeader
			title={lang('careplan')}
			tags={
				<Tag className="ml5" type={careplan ? careplan.status : null}>
					{careplan ? careplan.status.toUpperCase() : careplanStatuses.DRAFT}
				</Tag>
			}
			extra={<div className="">{careplan ? <div className="flex">{renderActions()}</div> : null}</div>}>
			<PageContent>
				<div className="container-xl exo-careplan">
					{renderHomeRedirect()}
					{renderSaveAsRedirect()}
					<div className="row row-deck row-cards">
						<DecisionModal
							open={showRemoveModal}
							onOK={() => {
								handleCareplanRemove()
								setShowRemoveModal(false)
							}}
							onCancel={() => setShowRemoveModal(false)}
							okType="danger"
							text={{
								title: lang('remove-careplan'),
								ok: lang('remove'),
								details: lang('remove_question', { name: careplan ? careplan.title : '' })
							}}
						/>
						<DecisionModal
							open={showPublishModal}
							onOK={() => {
								handleCareplanPublish()
								setShowPublishModal(false)
							}}
							onCancel={() => setShowPublishModal(false)}
							okType="danger"
							text={{
								title: lang('publish-careplan'),
								ok: lang('publish'),
								details: lang('publish-question', { name: careplan ? careplan.title : '' })
							}}
						/>

						<Modal
							title={lang('save-as')}
							visible={showSaveAsModal}
							onOk={() => {
								setShowSaveAsModal(false)
								saveCareplanAs()
							}}
							onCancel={() => {
								setShowSaveAsModal(false)
							}}>
							<Input onChange={(e) => setSaveAsTitle(e.target.value)} />
						</Modal>

						<div className="col-sm-12 col-lg-12">
							<Card>
								<div className="">
									<Skeleton hide={careplan}>
										<div className="grid">
											<label className="mb10">{lang('title')}</label>
											<Input
												className="w100p"
												value={careplan?.title}
												onChange={(e) => titleChange('title', e.target.value)}></Input>
											<SettingSelect
												className="mt10"
												showAllOption={false}
												defaultKey={careplan?.setting}
												onChange={handleSettingChange}
												style={{ width: '300px' }}
											/>
											<Row gutter={[16, 16]} className="timing-box">
												<Col span={12} className="grid">
													<label className="mt15 mb10">{lang('start-type')}</label>
													<Radio.Group
														options={[
															{ label: lang(activationTypes.DATE.toLowerCase()), value: activationTypes.DATE },
															{ label: lang(activationTypes.INCLUSION.toLowerCase()), value: activationTypes.INCLUSION }
														]}
														onChange={(e) => {
															const selection = e.target.value
															setActivationType(selection)
															careplan.activationType = selection
															if (selection === activationTypes.DATE) {
																careplan.calculationEvent = null
																setCalculationEvent(undefined)
															}
														}}
														value={activationType}
														optionType="button"
														buttonStyle="solid"
													/>
													{activationType === activationTypes.INCLUSION ? (
														<>
															<label className="mt15 mb10">{lang('calculation-type')}</label>
															<Row>
																<Radio.Group
																	options={[
																		{
																			label: lang(calculationTypes.ASSIGNMENT.toLowerCase()),
																			value: calculationTypes.ASSIGNMENT
																		},
																		{
																			label: lang(calculationTypes.EVENT.toLowerCase()),
																			value: calculationTypes.EVENT
																		}
																	]}
																	onChange={(e) => {
																		const selection = e.target.value
																		setCalculationType(selection)
																		careplan.calculationType = selection
																		if (selection === calculationTypes.ASSIGNMENT) {
																			careplan.calculationEvent = null
																			setCalculationEvent(undefined)
																		}
																	}}
																	value={calculationType}
																	optionType="button"
																	buttonStyle="solid"
																/>
																<Checkbox
																	className={'ml20 mt5'}
																	defaultChecked={careplan.includePreviousActivityOnInclusion}
																	onChange={(e) => {
																		careplan.includePreviousActivityOnInclusion = e.target.checked
																	}}>
																	{lang('include-previous-activity-on-activation')}
																</Checkbox>
															</Row>

															{calculationType === calculationTypes.EVENT ? (
																<EventSelect
																	className={`mt10`}
																	placeholder="-"
																	title={lang('calculation-begin-event')}
																	style={{ width: '300px' }}
																	defaultOptions={calculationEvent}
																	onChange={handleCalculationEventChange}
																/>
															) : null}
														</>
													) : null}
												</Col>

												<Col span={12}>
													<EventSelect
														className={`mt10`}
														placeholder="-"
														title={lang('stop-event')}
														style={{ width: '300px' }}
														defaultOptions={stopEvent}
														onChange={handleStopEventChange}
													/>
												</Col>
											</Row>
										</div>
									</Skeleton>
								</div>
							</Card>
							{modalAction}
						</div>
						{renderActivityLists()}
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}

export { Careplan,  timings, getSharedColumns, getCareplanActivities, activationTypes, calculationTypes }
