import React from 'react'

import Icon from '../Icon'
import Tag from '../Tag'

const ContentItem = ({ value, renderTag = false, iconType = 'content', resolve = () => {} }) => {
	const contentIcon = <Icon type={iconType} />
	const style = { margin: '0px 10px 10px 0px' }

	return (
		<>
			{value ? (
				value.map((v) => {
					const title = v.title ? v.title : resolve(v.key)?.title
					const type = v.type.title ? v.type.title : v.contentType ? v.contentType : resolve(v.key)?.title
					return (
						<div key={v?.id} className="inline" style={style}>
							<div>{contentIcon}</div>
							<div className="block">
								<div>{title}</div>
								{renderTag ? (
									<div>
										<Tag type={type}>{type}</Tag>
									</div>
								) : (
									<></>
								)}
							</div>
						</div>
					)
				})
			) : (
				<React.Fragment></React.Fragment>
			)}
		</>
	)
}

export default ContentItem
