import React, { useState, useEffect, useRef } from 'react'
import { Form, Select, Input, Checkbox, Switch } from 'antd'

import Backend from '../../api/Backend'

import MarkdownEditor from '../../applets/MarkdownEditor'
import MediaLibrary from '../../applets/MediaLibrary'
import { CMS_ENDPOINT } from '../../pages/CMS'

import Button from '../../components/Button'
import Drawer from '../../components/Drawer'
import Skeleton from '../../components/Skeleton'
import AllowRender from '../../components/AllowRender'
import Thumbnail from '../../components/Thumbnail'

import translations from './translations'
import { LangContext } from '../../components/Translation'

import './index.css'

const CmsEditor = ({ contentId = null, contentType, createdContentType = null, form, key, centers }) => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)

	const [description, setDescription] = useState('')
	const [selectedThumbnail, setSelectedThumbnail] = useState(null)

	const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState(null)

	const [predefinedContentTypes, setPredefinedContentTypes] = useState([])
	const [predefinedContentLabels, setPredefinedContentLabels] = useState([])
	const [predefinedSymptoms, setPredefinedSymptoms] = useState([])

	const [content, setContent] = useState(null)

	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)

	const [loading, setLoading] = useState(false)

	const ref = useRef()

	const contentState = [
		{ key: 'PUBLISHED', value: 'PUBLISHED' },
		{ key: 'DRAFT', value: 'DRAFT' }
	]

	const groups = ['All', 'Control', 'Intervention']

	useEffect(() => {
		setLoading(true)
		backend.cmsType({ cb: setPredefinedContentTypes })
		backend.cmsLabel({ cb: setPredefinedContentLabels })
		backend.cmsSymptoms({
			cb: (symptoms) => {
				setPredefinedSymptoms(symptoms)
			}
		})
		setLoading(false)

		if (contentId) {
			setLoading(true)
			backend.cms({
				cache: false,
				id: contentId,
				cb: (data) => {
					let article = {
						...data,
						symptoms: data?.symptoms && data.symptoms.map((el) => el.id)
					}
					setContent(article)
					setLoading(false)
				},
				endpoint: contentType
			})
		}
	}, [contentId])

	useEffect(() => {
		if (content) {
			setLoading(true)
			setContent({ ...content, thumbnail: selectedThumbnail })
			setLoading(false)
		}
	}, [selectedThumbnail])

	const resolveDescription = (desc) => desc.replaceAll('/uploads', `${CMS_ENDPOINT}/uploads`)

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	useEffect(() => {
		form.resetFields()
		form.setFieldsValue(content)
	}, [form, content, key])

	return (
		<Skeleton hide={!loading}>
			<div className="p10">
				<Form form={form} layout="vertical">
					<div className="">
						<div className="thumbnail-container">
							<div>
								<div className="ant-col ant-form-item-label">
									<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
										{lang('thumbnail')}
									</label>
								</div>
								<Form.Item name="new_display_picture" hidden>
									<Input type={'hidden'} />
								</Form.Item>
								<br />
								<Thumbnail
									url={
										selectedThumbnailUrl
											? selectedThumbnailUrl
											: content?.thumbnail?.formats
											? `${CMS_ENDPOINT}` + content?.thumbnail?.formats?.thumbnail?.url
											: content?.thumbnail
											? `${CMS_ENDPOINT}` + content?.thumbnail.url
											: null
									}
								/>
							</div>
							<div>
								<Button type="secondary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
							</div>
						</div>
						<Form.Item name="title" label={lang('title')} rules={rules} initialValue={content?.title}>
							<Input />
						</Form.Item>
						<AllowRender permissions={['CMS_PUBLISH']}>
							<Form.Item name="state" label={lang('state')} rules={rules} initialValue={content?.state}>
								<Select>
									{contentState.map((el) => (
										<Select.Option key={el.key} value={el.key}>
											{el.value}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</AllowRender>

						<div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
							<div style={{ width: '50%' }}>
								<Form.Item
									name="center"
									label={lang('center-visibility')}
									initialValue={
										content?.visibility && content.visibility.length > 0
											? content.visibility.filter((el) => el !== null).map((el) => el.clinic_name)
											: ['Public']
									}
									rules={[
										{
											required: true,
											message: lang('please-select-a-center')
										},
										{
											validator(_, value) {
												if (value && value.length > 1 && value.includes('Public')) {
													return Promise.reject(lang('center-select-conflict'))
												} else {
													return Promise.resolve()
												}
											}
										}
									]}>
									<Select mode="multiple">
										{centers.map((el) => (
											<Select.Option key={el.id} value={el.clinic_name}>
												{el.clinic_name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</div>
							<div style={{ width: '50%' }}>
								<Form.Item
									name="groupSelection"
									initialValue={content?.group || lang('all')}
									label={lang('group-visibility')}
									rules={[
										{
											required: true,
											message: lang('please-select-a-group')
										}
									]}>
									<Select>
										{groups.map((group) => (
											<Select.Option key={group} value={group}>
												{lang(group)}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</div>
						</div>
						<Form.Item
							name="cms_label"
							label={lang('label')}
							initialValue={content?.labels && content.labels.length > 0 ? content.labels.map((lbl) => lbl.title) : undefined}>
							<Select mode="multiple">
								{predefinedContentLabels.map((el) => (
									<Select.Option key={el.id} value={el.title}>
										{el.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						{((content && content.type && content.type.key === 'care-advices') ||
							(createdContentType && createdContentType.key === 'care-advices')) && (
							<>
								<Form.Item name="symptoms" label={lang('symptom')}>
									<Select mode="multiple">
										{predefinedSymptoms &&
											predefinedSymptoms.map((el) => {
												return (
													<Select.Option key={el.id} value={el.id}>
														{el.title}
													</Select.Option>
												)
											})}
									</Select>
								</Form.Item>
								<Form.Item
									name="fatigue_intervention"
									valuePropName="checked"
									initialValue={content?.fatigue_intervention}
									label={lang('fatigue-intervention')}>
									<Switch />
								</Form.Item>
							</>
						)}

						<Form.Item name="markdownContent" initialValue={content?.description} hidden />

						<div className="mt-2 mb-3 p10 flex-column">
							<Drawer
								open={openThumbnailMediaLibrary}
								placement="right"
								onClose={() => setOpenThumbnailMediaLibrary(false)}
								width={'75%'}
								text={{ title: lang('thumbnail-library'), cancel: lang('cancel') }}>
								<MediaLibrary
									single
									media={{
										onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
										setSelectedMedia: (thumbnail) => {
											form.setFieldsValue({
												new_display_picture: {
													id: thumbnail.id,
													url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
												}
											})
										},
										setSelectedMediaUrl: setSelectedThumbnailUrl,
										type: 'Thumbnail',
										mime: ['image/jpeg', 'image/png', 'image/svg+xml']
									}}
								/>
							</Drawer>
						</div>
						<div className="p10">
							<MarkdownEditor
								ref={ref}
								defaultValue={content ? resolveDescription(content.description) : description}
								onChange={() => form.setFieldsValue({ markdownContent: ref.current.getData() })}
							/>
						</div>
					</div>
				</Form>
			</div>
		</Skeleton>
	)
}

export default CmsEditor
