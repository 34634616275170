import React, { useState, useEffect } from 'react'
import { Drawer as AntDrawer } from 'antd'
import Button from '../../components/Button'
import './index.css'

export default function Drawer({
	children,
	style = {},
	key = null,
	width,
	placement = 'right',
	className = '',
	frameless = false,
	autoClose = false, // close on ok
	disabled = false,
	onCancel = () => {},
	onClose = () => {},
	onBack = () => {},
	onOK = () => {},
	onExtra = () => {},
	extraDisabled,
	btn,
	text = { title: null, cancel: null, ok: null },
	open = false // external trigger & default state usage
}) {
	const [show, setShow] = useState(open)

	useEffect(() => {
		setShow(open)
	}, [open])

	const toogleDisplay = () => {
		setShowWithNotice(!show)
	}

	const setShowWithNotice = nextState => {
		if (nextState === false && onClose) onClose()
		setShow(nextState)
	}

	const handleBack = () => {
		setShowWithNotice(true)
		if (onBack) {
			onBack()
		}
	}

	const handleCancel = () => {
		setShowWithNotice(false)
		if (onCancel) {
			onCancel()
		}
	}

	const handleClose = () => {
		if (onClose) {
			onClose()
			setShow(false)
		} else {
			setShow(false)
		}
	}

	const handleOK = () => {
		if (autoClose) setShowWithNotice(false)
		if (onOK) onOK()
	}

	const handleExtra = () => {
		if (onExtra) onExtra()
	}

	if (btn) {
		btn.type = btn.type ? btn.type : 'info'
		btn.txt = btn.txt ? btn.txt : ''
		btn.className = btn.className ? btn.className : ''
		btn.ghost = btn.ghost !== undefined ? btn.ghost : false
		btn.onClick = toogleDisplay
		btn.disabled = disabled
	}

	return (
		<>
			{btn ? <Button {...btn}></Button> : null}
			<AntDrawer
				key={key}
				style={style}
				className={`${className} ${frameless ? 'frameless' : ''}`}
				title={text.title}
				placement={placement}
				width={width}
				visible={show}
				onClose={handleClose}
				footer={
					<div className="exo-drawer-footer">
						{text.cancel ? <Button className={'mr5'} type="cancel" ghost txt={text.cancel} onClick={handleCancel}></Button> : null}
						{text.back ? <Button className={'mr5'} type="back-arrows" ghost txt={text.back} onClick={handleBack}></Button> : null}
						{text.ok ? <Button type="info" className={'ok-button'} txt={text.ok} disabled={disabled} onClick={handleOK}></Button> : null}
						{text.extra ? (
							<Button
								type="info"
								style={{ marginLeft: '5px' }}
								className={`ant-btn ant-btn-primary`}
								txt={text.extra}
								disabled={extraDisabled}
								onClick={handleExtra}></Button>
						) : null}
					</div>
				}>
				<>{children}</>
			</AntDrawer>
		</>
	)
}
