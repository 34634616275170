const UIConstants = {
	ALL: '__ALL__',
}

export default class Utils {

	titleCase(str) {
		var splitStr = str.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			// You do not need to check if i is larger than splitStr length, as your for does that for you
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
		}
		return splitStr.join(' '); 
	 }

	daysBetween = date => {
		const currentDate = new Date()
		const utcTime = currentDate.toUTCString()
		const today = new Date(utcTime).setHours(0, 0, 0, 0)
		return Math.round((today - new Date(date)) / (1000 * 60 * 60 * 24))
	}

	calendarWeek = date => {
		var d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
		var dayNum = d.getUTCDay() || 7
		d.setUTCDate(d.getUTCDate() + 4 - dayNum)
		var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
		return Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
	}

	copy = data => {
		return JSON.parse(JSON.stringify(data))
	}

	static getLocale = (keycloak) => {
		return keycloak.authenticated && keycloak.tokenParsed && keycloak.tokenParsed.locale ? keycloak.tokenParsed.locale : 'en'	
	}

	setTemplateData = ({ dict, template }) => {
		let tmplStr = JSON.stringify(template)
		dict.forEach(entry => {
			tmplStr = tmplStr.replace(`%${entry.key}%`, entry.value)
		})
		return JSON.parse(tmplStr)
	}

	getAlarmColor = t => {
		let res = ''
		if (t.score === 2 || t.severity === 'B_MEDIUM' || t.severity === 'SECOND_GRADE' || t.severity === 'FIRST_GRADE') {
			res = `warning`
		} else if (t.score >= 3 || t.severity === 'C_HIGH' || t.severity === 'THIRD_GRADE' || t.severity === 'FOURTH_GRADE') {
			res = `critical`
		} else {
			console.error(`could not retrieve color for symptom severity: ${t.severity} , score: ${t.score}`)
			res = `info`
		}
		return res
	}

	getStudyColor = study => {
		switch (study.status) {
			case 'PARTICIPATION':
				return 'green'
			case 'SCREENING':
				return 'blue'
			case 'EXCLUDED':
				return 'red'
			case 'CANCEL':
				return 'orange'
			default:
				return 'yellow'
		}
	}

	getCareplanColor = cm => {
		switch (cm.status) {
			case 'PARTICIPATION':
				return 'green'
			case 'SCREENING':
				return 'blue'
			case 'EXCLUDED':
				return 'red'
			case 'CANCEL':
				return 'orange'
			default:
				return 'yellow'
		}
	}

	getAlarmSymptomTxt = (t, lang) => {
		let txt =
			t.symptom &&
			t.symptom
				.replace('SMARTVISIT_', '')
				.replace('QLQ_C30_', '')
				.replace('PROMIS_29_', '')
				.toLowerCase()
		return lang(txt) ? lang(txt) : txt
	}

	getAlarmImage = ({ row, withInfos = false }) => {
		let infos = withInfos ? `?id=${this.alarmKey(row)}&type=alarm` : ''
		let res = ''
		if (row.symptom) {
			if (row.symptom.includes('SMARTVISIT')) {
				res = `/img/symptoms/${row.symptom.replace('SMARTVISIT_', '').toLowerCase()}.svg${infos}`
			} else if (row.symptom.includes('QLQ_C30')) {
				res = `/img/symptoms/${row.symptom.replace('QLQ_C30_', '').toLowerCase()}.svg${infos}`
			} else if (row.symptom.includes('PROMIS_29')) {
				res = `/img/symptoms/${row.symptom.replace('PROMIS_29_', '').toLowerCase()}.svg${infos}`
			} else {
				res = `/img/actions/assessment.svg${infos}`
			}
		}
		return res
	}

	alarmKey = (v, nounce) => {
		return v.patient_id + v.ts_created + v.type + v.symptom + nounce
	}

	alarmGroupKey = v => {
		return v.group
	}

	lpad(n) {
		return (n < 10 ? '0' : '') + n
	}

	toDate = ({ dateString, timestamp, withoutTime = false }) => {
		if(dateString || timestamp){
			let date = new Date(dateString || timestamp)
			const res = `${this.lpad(date.getDate())}.${this.lpad(date.getMonth() + 1)}.${this.lpad(date.getFullYear())}`
			return withoutTime ? res : `${res} ${this.lpad(date.getHours())}:${this.lpad(date.getMinutes())}`	
		} else {
			return ""
		}
	}

	getFullDisplayName = ({ data }) => {
		return `${data.name ? data.name : ''} ${data.surname ? data.surname : ''}`.trim()
	}

	workingHoursBetweenDates(startDateString, endDateString) {
		const startDate = new Date(startDateString)
		const endDate = endDateString ? new Date(endDateString) : new Date()
		// Store minutes worked
		var minutesWorked = 0

		// Validate input
		if (endDate < startDate) {
			return 0
		}

		// Loop from your Start to End dates (by hour)
		var current = startDate

		// Define work range
		var workHoursStart = 9
		var workHoursEnd = 17

		// Loop while currentDate is less than end Date (by minutes)
		while (current <= endDate) {
			// Is the current time within a work day (and if it
			// occurs on a weekend or not)
			if (current.getHours() >= workHoursStart && current.getHours() < workHoursEnd && current.getDay() !== 0 && current.getDay() !== 6) {
				minutesWorked++
			}

			// Increment current time
			current.setTime(current.getTime() + 1000 * 60)
		}

		// Return the number of hours
		return minutesWorked / 60
	}

	dateAhaed = ahaed => {
		const date = new Date()
		const unit = ahaed.slice(-1).toUpperCase()
		const amount = parseInt(ahaed.slice(0, -1))
		if (unit === 'D') {
			date.setDate(date.getDate() + amount)
		} else if (unit === 'W') {
			date.setDate(date.getDate() + 7 * amount)
		}
		return date
	}

	hash = str => {
		if (!str) return null
		var hash = 0,
			i,
			chr
		if (str.length === 0) return hash
		for (i = 0; i < str.length; i++) {
			chr = str.charCodeAt(i)
			hash = (hash << 5) - hash + chr
			hash |= 0 // Convert to 32bit integer
		}
		return hash
	}

	static getRandomUUID = () => {
		const s4 = () => {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1)
		}
	
		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
	}

	static randomID = () => {
		var result = ''
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		var charactersLength = characters.length
		for (let i = 0; i < 8; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength))
		}
		return result
	}

	parseUrlParams = search => {
		const hashes = search.slice(search.indexOf('?') + 1).split('&')
		const params = {}
		hashes.map(hash => {
			const [key, val] = hash.split('=')
			params[key] = decodeURIComponent(val)
		})
		return params
	}
	
	isStudyGroupRPA = param => {
		return param === 1
	}
}

export { UIConstants, Utils }

