import React, { useState, useEffect } from 'react'

import { LangContext } from '../../components/Translation'
import { useGlobalContext } from '../../components/AppContext'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'

import SegmentsBarChart from '../../components/charts/SegmentsBarChart'
import Backend from '../../api/Backend'
import RecruitementTrendChart from '../../components/charts/RecruitementTrendChart'
import RecruitementPerClinic from '../../applets/RecruitementPerClinic'

import translations from './translations'

export default function Recruitement() {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)

	const context = useGlobalContext()
	const selectedClinic = context.globals.selectedCenter

	const [recruitmentData, setRecruitmentData] = useState([])

	const totalRaw = {
		actual: 0,
		max: 0
	}

	useEffect(() => {
		backend.recruitment({ clinic: selectedClinic, cb: setRecruitmentData })
	}, [selectedClinic])

	recruitmentData.forEach((clinic) => {
		totalRaw.max = totalRaw.max + parseInt(clinic.max_patient)
		totalRaw.actual = totalRaw.actual + parseInt(clinic.patient)
	})

	const totalValues = [
		{
			value: totalRaw.actual,
			title: lang('actual'),
			percent: Math.round((totalRaw.actual / totalRaw.max) * 100),
			color: 'success'
		},
		{
			value: totalRaw.max - totalRaw.actual,
			title: lang('open'),
			percent: Math.round(((totalRaw.max - totalRaw.actual) / totalRaw.max) * 100),
			color: 'light'
		}
	]

	return (
		<PageHeader title={lang('title')}>
			<PageContent>
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-6 col-lg-6">
							<RecruitementTrendChart  center={selectedClinic} />
						</div>
						<div className="col-sm-6 col-lg-6">
							<RecruitementPerClinic selectedClinic={selectedClinic} title={lang('per_clinic')} />
						</div>
						<div className="col-sm-12 col-lg-12">
							<SegmentsBarChart
								title={lang('segmentation-bar-header', { actual: totalRaw.actual, max: totalRaw.max })}
								values={totalValues}
							/>
						</div>
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}
