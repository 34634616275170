import "./index.css"

import React from 'react'

export default function PerformanceChart({ 
  performance = 1, 
  unit = { singular: '', plural: '' },
  max = 14, 
  colors = {
    OK: '#03a9f4',
    WARNING: '#f59f00',
    CRITICAL: '#d63939'
  }}) {

    console.log(performance)

  const bars = []
  let color = colors.OK  
  const treshold1 = Math.round(max * 1/3)
  const treshold2 = performance

  for(let i=0; i <= max; i++){
    console.log(i)
    if (i >= performance) { break; }
    if (i < 4) {
		color = colors.OK
	} else if (i < 3) {
		color = colors.CRITICAL
	} else {
		color = colors.WARNING
	}
    color = i >= treshold1 ? colors.CRITICAL : color
    color = i >= treshold2 ? colors.WARNING : color
    bars.push(<div key={i} className="tick" style={{ backgroundColor: color }}/>)
  }

 

  return (
    <div className="performance-chart">
      <div className="bars">
        { bars }
      </div>
      <div className="performance">
        { performance } { performance === 1 ? unit.singular : unit.plural }
      </div>
    </div>)
}