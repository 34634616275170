import React, { useState, useEffect } from 'react'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import DetailList from '../../components/DetailList'
import Skeleton from '../../components/Skeleton'
import Backend from '../../api/Backend'
import translations from './translations'
import { LangContext } from '../../components/Translation'
import { Card } from 'antd'

import './index.css'

export default function PatientConsultationRequest() {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)

	const [selectedCenter] = useState()
	const [consultationRequest, setConsultationRequest] = useState()
	const [consultationRequestStats, setConsultationRequestStats] = useState([])

	useEffect(() => {
		backend.patientConsultationRequest({
			clinic: selectedCenter,
			settings: null, //TODO make switch
			cb: setConsultationRequest
		})
	}, [selectedCenter])

	useEffect(() => {
		backend.patientConsultationRequestStats({
			clinic: selectedCenter,
			settings: null, //TODO make switch
			cb: setConsultationRequestStats
		})
	}, [selectedCenter])

	return (
		<PageHeader title={lang('title')}>
			<PageContent>
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-12 col-lg-12">
							<div className="col-sm-12 col-lg-12">
								<Card bordered={false}>
									<div className="card-header">
										<div>
											<h3 className="card-title">Patient Consultation Requests</h3>
											<DetailList
												className="mt10"
												inLine
												data={[
													[
														lang('patients'),
														consultationRequestStats.length > 0 ? consultationRequestStats[0].patients : ''
													],
													[
														lang('assessments'),
														consultationRequestStats.length > 0 ? consultationRequestStats[0].assessments : ''
													]
												]}
											/>
										</div>
									</div>
									<Skeleton type="statistics" hide={consultationRequest}>
										<Table
											styles={{ overflowY: 'none' }}
											borderless={true}
											headless={false}
											title={lang('base_data')}
											columns={[
												{
													key: 'consultation',
													value: 'Category',
													render: ({ value }) => {
														return (
															<div className="line-align">
																<div className="flex-child one ">{value.category.de}</div>
															</div>
														)
													}
												},
												{
													key: 'consultation',
													value: 'Sub Category',
													render: ({ value }) => {
														return (
															<div className="line-align">
																<div className="flex-child one ">{value.subCategory.de}</div>
															</div>
														)
													}
												},
												{
													key: 'requested',
													value: 'Requested by',
													render: ({ row }) => {
														return (
															<div className="line-align">
																<div className="flex-child one ">{`${Math.round(
																	(parseInt(row.requested) / parseInt(row.total)) * 100
																)} %`}</div>
															</div>
														)
													}
												}
											]}
											data={consultationRequest}
										/>
									</Skeleton>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}
