import React, { useState, useEffect } from 'react'
import { Form, Input, Select, DatePicker, Space, message } from 'antd'

import Skeleton from '../../components/Skeleton'
import Button from '../../components/Button'
import Tag from '../../components/Tag'
import Backend from '../../api/Backend'
import Utils from '../../components/Utils'



import { LangContext } from '../../components/Translation'
import translations from './translations'
import moment from 'moment'

import { useGlobalContext } from '../../components/AppContext'


import './index.css'

const { Option } = Select



const AppointmentScheduler = ({
	className = '',
	withGroupVisibility = false,
	data = {},
	onChange = null,
	onCreate = () => {},
	patientId,
	externalScheduler, // parent setter to trigger the schedule of the appointment
	isTemplate = false,
	isCreateMode = false,
}) => {
	const { globals, appointmentSchedulerStatuses, appointmentSchedulerMedium } = useGlobalContext()
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)
	const DATE_FORMAT = 'DD.MM.YYYY HH:mm'

	const [form] = Form.useForm()
	const [loading, setLoading] = useState(true)
	const [appointmentData, setAppointmentData] = useState(data)
	const [appointmentTypes, setAppointmentTypes] = useState()
	const [showTreatmentTypes, setShowTreatmentTypes] = useState(false)
	const [treatmentTypes, setTreatmentTypes] = useState()
	const [healthProvider, setHealthprovider] = useState()
	const [careTeams, setCareTeams] = useState()

	const updateForm = (data) => {
		form.setFieldsValue({
			status: lang(data.status),
			title: data.title,
			groupVisibility: data.groupVisibility,
			address: data.address,
			note: data.note,
			type: data.type ? data.type.id : undefined,
			healthProvider: data.healthProvider ? data.healthProvider.id : undefined,
			treatment: data.treatment ? data.treatment.id : undefined,
			participant: data.participant ? data.participant.map((u) => u.id).pop() : undefined,
			medium: data.medium,
			date: moment(data.scheduledAt)
		})
	}

	const refresh = () => {
		setLoading(true)

		const next = () => {
			backend.treatmentType({
				cb: (data) => {
					setTreatmentTypes(data)
					backend.healthProvider({
						cb: (data) => {
							setHealthprovider(data)
							setLoading(false)
						}
					})
					backend.careTeam({
						cb: (teams) => {
							setCareTeams(
								teams?.resultObject?.data.map((team) => {
									return {
										id: team.resource.careTeamId,
										name: team.resource.name,
										zip: team.resource.zip,
										description: team.resource.description,
										default: team.resource.default,
										clinic: team.resource.clinic[0]
									}
								}).filter(team => team.clinic === globals.selectedCenter)
							)
						}
					})
				}
			})
		}

		backend.appointmentType({
			cb: (data) => {
				setAppointmentTypes(data)
				next()
			}
		})
	}

	let healthProvidersAndCareTeams

	if (Array.isArray(healthProvider) && healthProvider.length > 0 && Array.isArray(careTeams) && careTeams.length > 0) {
		healthProvidersAndCareTeams = [...healthProvider, ...careTeams]
	}

	const isUpdateState = () => {
		return data.id !== undefined || (appointmentData && appointmentData.id !== undefined)
	}

	const actionTxt = isUpdateState() ? lang('re-schedule') : lang('schedule')

	const isValid = () => {
		// wrapper for buggy antD issue: outOfDate
		return new Promise((resolve, reject) => {
			form.validateFields()
				.then(resolve)
				.catch((err) => {
					if (err.errorFields.length === 0) {
						resolve(err.values)
					}
					reject()
				})
		})
	}

	const getOutputData = (fields) => {
		const res = {}
		/*Object.keys(res).forEach((key) => { // clear resolved data before save
			if(key.toLowerCase.includes("personal")){
				delete res[key]
			}	
		})*/
		res.scheduledAt = !isTemplate ? fields.date.toISOString() : null
		res.patientId = isTemplate && patientId ? patientId : appointmentData.patientId
		res.title = fields.title
		res.medium = fields.medium
		res.groupVisibility = fields.groupVisibility
		res.healthProvider = fields.healthProvider ? { id: fields.healthProvider } : {}
		res.participant = fields.participant && !isTemplate ? [{ id: fields.participant }] : []
		res.type = fields.type ? { id: fields.type } : {}
		res.treatment = showTreatmentTypes && fields.treatment ? { id: fields.treatment } : {}
		res.note = fields.note
		res.status = isTemplate ? appointmentSchedulerStatuses.PLANNED : appointmentSchedulerStatuses.SCHEDULED
		res.id = data.id ? data.id : Utils.getRandomUUID()
		res.image = resolveImage(res)
		return res
	}

	const scheduleAppointment = ({ onSave = () => {} }) => {
		isValid().then((fields) => {
			const outputData = getOutputData(fields)
			setAppointmentData(outputData)
			const body = JSON.stringify(outputData)
			backend.appointments({
				id: isCreateMode ? null : outputData.id,
				body,
				type: isUpdateState() ? backend.type.UPDATE : backend.type.CREATE,
				cb: (res) => {
					if (!isUpdateState()) {
						appointmentData.id = res.id
						setAppointmentData(appointmentData)
						updateForm(appointmentData)
						onCreate(appointmentData)
					}
					message.success(lang('save-success'))
					onSave()
				}
			})
		})
	}

	useEffect(() => {
		refresh()
		if (externalScheduler) {
			externalScheduler({ scheduleAppointment })
		}
	}, [])

	useEffect(() => {
		updateForm(data)
		if (externalScheduler) {
			externalScheduler({ scheduleAppointment })
		}
	}, [data.id])

	const resolveImage = (data) => {
		const appointmentType = appointmentTypes.filter((type) => data.type.id === type.id)
		return appointmentType.length > 0 ? appointmentType[0].image : null
	}

	const isAppointmentTypeTreatment = (data) => {
		const appointmentType = appointmentTypes.filter((type) => data.type === type.id)
		return appointmentType.length > 0 ? appointmentType[0].isTreatment === true : showTreatmentTypes
	}

	const handleChange = (data) => {
		if (onChange) {
			onChange({ getOutputData, scheduleAppointment, isValid })
		}
		setShowTreatmentTypes(isAppointmentTypeTreatment(data))
	}

	const rules = (required) => {
		return [
			{
				required,
				message: lang('missing-input')
			}
		]
	}

	const groups = [
		<Option key="all" value="all">
			{lang('all')}
		</Option>,
		<Option key="control" value="control">
			{lang('control-group')}
		</Option>,
		<Option key="intervention" value="intervention">
			{lang('intervention-group')}
		</Option>
	]


	return (
		<div className={`${className} change-log ant-card`}>
			<div className="col-md-12 col-xl-12">
				<Skeleton hide={!loading}>
					<Form form={form} layout="vertical" onValuesChange={handleChange}>
						<Space direction="horizontal">
							{!isCreateMode && <Form.Item name="status" hidden={isTemplate} label="Status">
								<Tag type={appointmentData.status}>{lang(appointmentData.status)}</Tag>
							</Form.Item>}
							<Form.Item name="date" hidden={isTemplate} rules={rules(!isTemplate)} label="Date">
								<DatePicker format={DATE_FORMAT} showTime />
							</Form.Item>
						</Space>
						{withGroupVisibility ? (
							<Form.Item name="groupVisibility" rules={rules(true)} label={lang('group-visibility')}>
								<Select placeholder={lang('select')}>{groups}</Select>
							</Form.Item>
						) : null}
						<Form.Item name="healthProvider" rules={rules(false)} label={'Health Service & Care Provider'}>
							<Select
								showSearch
								allowClear
								placeholder={lang('select')}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}>
								{healthProvidersAndCareTeams
									? healthProvidersAndCareTeams.map((d) => {
											return (
												<Option key={d.id} value={d.id}>
													{d.name}
												</Option>
											)
									  })
									: []}
							</Select>
						</Form.Item>
						<Form.Item name="title" rules={rules(true)} label={lang('subject')}>
							<Input />
						</Form.Item>
						<Form.Item name="type" label={lang('appointment-type')} rules={rules(true)}>
							<Select placeholder={lang('select')}>
								{appointmentTypes
									? appointmentTypes.map((d) => {
											return (
												<Option key={d.id} value={d.id}>
													{d.title}
												</Option>
											)
									  })
									: []}
							</Select>
						</Form.Item>
						<Form.Item name="treatment" hidden={!showTreatmentTypes} label={lang('treatment-type')} rules={rules(showTreatmentTypes)}>
							<Select placeholder={lang('select')}>
								{treatmentTypes
									? treatmentTypes.map((d) => {
											return (
												<Option key={d.id} value={d.id}>
													{d.title}
												</Option>
											)
									  })
									: []}
							</Select>
						</Form.Item>
						<Form.Item name="medium" rules={rules(true)} label="Appointment medium">
							<Select placeholder={lang('select')}>
								<Option value={appointmentSchedulerMedium.VIDEO}>{lang('video')}</Option>
								<Option value={appointmentSchedulerMedium.CHAT}>{lang('chat')}</Option>
								<Option value={appointmentSchedulerMedium.ONSITE}>{lang('on-site')}</Option>
							</Select>
						</Form.Item>
						<Form.Item name="note" label={lang('note')}>
							<Input.TextArea rows={4} />
						</Form.Item>
					</Form>
					{isTemplate || externalScheduler ? null : (
						<Button style={{ width: '100%' }} antType="primary" txt={actionTxt} onClick={scheduleAppointment} />
					)}
				</Skeleton>
			</div>
		</div>
	)
}

export { AppointmentScheduler }
