import React, { useState, useEffect } from 'react'

import Skeleton from '../../components/Skeleton'
import MultiLineChart from '../../components/charts/MultiLineChart'
import List from '../../components/List'
import Stats from '../../components/Stats'
import { Card } from 'antd'

import Backend from '../../api/Backend'

import './index.css'

import { LangContext } from '../../components/Translation'
import translations from './translations'

export default function GroupKPIChart({ center, setting }) {

	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()

	const colors = {
		intervention: '#f28e2c',
		control: '#4e79a7'
	}
	const [kpis, setKPIs] = useState([])
	const [loading, setLoading] = useState(true)
	const [selectedKPI, setSelectedKPI] = useState("QLQ_C30_GLOBAL_HEALTH")

	const refresh = () => {
		setLoading(true)
		backend.bi({
			id: 'groupKPIs', setting, clinic: center, cb: (data) => {
				data.forEach(d => { d.color = d.intervention_group ? colors.intervention : colors.control })
				setKPIs(data)
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		refresh()
	}, [])

	useEffect(() => {
		refresh()
	}, [center, setting])


	const getKPIList = () => {
		const list = []
		kpis.forEach((d) => {
			if (!list.includes(d.kpi)) list.push(d.kpi)
		})
		return list
	}

	const listData = () => {
		return getKPIList().map((kpi) => {
			return {
				key: kpi,
				title: kpi,
				onRowClick: setSelectedKPI
			}
		})
	}

	const filterSelectedKPI = () => {
		return kpis.filter(d => d.kpi === selectedKPI)
	}

	let statsData = []

	if (kpis && !loading) {
		const total = kpis.reduce((p, c) => { return parseInt(p) + parseInt(c.num_assessments) }, 0)
		statsData = [[
			{ title: lang("total-assessments"), key: "total", value: total },
			{ title: lang("assessments-intervention"), key: "intervention", value: kpis.filter(d => !d.intervention_group).map(k => k.num_assessments).length },
			{ title: lang("assessments-control"), key: "control", value: kpis.filter(d => d.intervention_group).map(k => k.num_assessments).length }
		]]
	}

	return (
		<div className="row row-deck row-cards">
			<div className="col-12">
				<Stats loading={loading} title={"Group Stats"} data={statsData} />
			</div>
			<div className="col-8">
				<Card bordered={false}>
					<h3 className="card-title"><span style={{ color: colors.intervention }}>Intervention</span> vs. <span style={{ color: colors.control }}>Control</span> Group</h3>
					<Skeleton type="list" hide={!loading}>
						<div className="overflow-auto" style={{ "height": "100%", padding: "15px" }}>
							<MultiLineChart
								data={filterSelectedKPI(kpis)}
								width="700"
								height="600"
								skala={[{ key: "0", value: "Bad" }, { key: "100", value: "Good" }]}
								yAxisLabel={"Symptoms Average value"}
								xAxisLabel={"Therapy Points"}
							/>
						</div>
					</Skeleton>
				</Card>
			</div>
			<div className="col-4">
				<Card bordered={false}>
					<h3 className="card-title">Available KPIs</h3>
					<Skeleton type="list" hide={!loading}>
						<div className="overflow-auto" style={{ "height": "100%" }}>
							<List pagination={undefined} data={listData()} styles={{ maxHeight: '500px', overflow: 'auto' }} />
						</div>
					</Skeleton>
				</Card>
			</div>
		</div>
	)
}
