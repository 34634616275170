import React from 'react';

export default function Contact(props) {

  return (
   <div className="m10">
      <div className="mb-3">
        <label className="form-label">Optionen</label>
        <div className="row">
          <div className="col">
            <button type="button" className="btn btn-white w-100" data-bs-toggle="modal" data-bs-target="#videoCall">             
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" className="mr5" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4zM15 16H5V8h10v8zm-6-1h2v-2h2v-2h-2V9H9v2H7v2h2z"/></svg>
              Video Call
              </button>
          </div>
          <div className="col">
            <a href="#" className="btn btn-white w-100">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" className="mr5" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 4v7H5.17l-.59.59-.58.58V4h11m1-2H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm5 4h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1z"/></svg>
              Chat
            </a>
          </div>
          <div className="col">
            <a href="#" className="btn btn-white w-100">
              <svg xmlns="http://www.w3.org/2000/svg" className="mr5" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/></svg>
              email
            </a>
          </div>
        </div>
      </div>

      <div className="modal fade" id="videoCall" tabIndex="-1" aria-labelledby="videoCallLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="videoCallLabel">Rufaufbau zu: {props.name}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                ....
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Beenden</button>
            </div>
          </div>
        </div>
      </div>

   </div>

  )
}