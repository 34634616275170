import React from 'react'

import Table from '../../../components/Table'
import Tag from '../../../components/Tag'

const AssessmentItem = ({ value, onOrderChange, lang }) => {
	return (
		<Table
			emptyText={lang('no-mapping')}
			rowMover={onOrderChange !== undefined}
			showHeader={false}
			onRowMove={onOrderChange}
			pagination={false}
			columns={[
				{
					width: 150,
					key: 'value',
					value: lang('title')
				},
				{
					width: 100,
					key: 'demand',
					value: lang('demand'),
					render: ({ row }) => {
						return <Tag type={row.demand}>{lang(row.demand?.toLowerCase())}</Tag>
					},
					searchWithRender: true
				}
			]}
			data={value}
		/>
	)
}

export default AssessmentItem
