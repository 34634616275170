import { Empty } from 'antd'
import React from 'react'

const Thumbnail = ({ url = null }) => {
	const thumbnailUrl = url ? url : null

	if (url && !thumbnailUrl.includes('undefined') && !thumbnailUrl.includes('cmsnull')) {
		return (
			<div className="img-background-container primary-color">
				<img src={thumbnailUrl} alt="thumbnail" height="200" width="auto" />
			</div>
		)
	} else {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
	}
}

export default Thumbnail
