import React, { useState, useEffect, useRef } from 'react'

import MediaLibrary from '../../applets/MediaLibrary'

import Utils from '../../components/Utils'
import Button from '../../components/Button'
import Skeleton from '../../components/Skeleton'
import Drawer from '../../components/Drawer'
import DecisionModal from '../../components/DecisionModal'
import Icon from '../../components/Icon'

import { Space, Card, Table, Form, Input, Select, Collapse, message, Divider } from 'antd'
import { PlusOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'

import { LangContext } from '../../components/Translation'
import translations from './translations'

import Backend from '../../api/Backend'
import './index.css'

import { CMS_ENDPOINT, contexts } from '../../pages/CMS'

const Modules = ({ type, newModuleContent, setNewModuleContent }) => {
	const [loading, setLoading] = useState(false)
	const [moduleGroups, setModuleGroups] = useState([])
	const [modules, setModules] = useState([])

	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()

	const backend = new Backend()

	useEffect(() => {
		setLoading(true)
		backend.cmsModules({
			cb: (data) => {
				setModules(data)
			}
		})
		backend.cmsModuleGroups({
			cb: (data) => {
				setModuleGroups(data)
				setLoading(false)
			}
		})
	}, [newModuleContent])

	let keyIncrement = 0
	let childKeyIncrement = 0

	const getContentData = (data, type) => {
		if (data) {
			return (
				data &&
				Array.isArray(data) &&
				data.map((item) => {
					let thumbnailUrl = item.display_picture && item.display_picture?.formats && item.display_picture.formats.thumbnail.url
					let updatedDate = utils.toDate({ dateString: item.updated_at })

					if (type === contexts.MODULE_GROUPS) {
						let children = item.modules.map((module) => {
							let childUpdatedDate = utils.toDate({ dateString: module.updated_at })

							return {
								title: `${module.title}`,
								imageURL: `${CMS_ENDPOINT}${module.display_picture?.formats?.thumbnail?.url}`,
								date: childUpdatedDate,
								moduleGroup: { ...module, parent: item },
								parent: item,
								type: lang(module.type),
								key: `${keyIncrement}` + `${childKeyIncrement++}`
							}
						})

						return {
							key: keyIncrement++,
							imageURL: `${CMS_ENDPOINT}${thumbnailUrl}`,
							title: item.title,
							date: updatedDate,
							moduleGroup: item,
							type: item.state,
							modules: item.modules,
							children: children
						}
					} else {
						return {
							key: keyIncrement++,
							imageURL: `${CMS_ENDPOINT}${thumbnailUrl}`,
							title: item.title,
							date: updatedDate,
							moduleGroup: item,
							type: lang(item.type),
							modules: item.modules
						}
					}
				})
			)
		}
	}

	if (type === contexts.MODULES) {
		return (
			<div className="container-xl">
				<div className="row row-deck row-cards">
					<div className="col-sm-12 col-lg-12">
						<div className="cms-card-container">
							<Card title={lang('module-list')}>
								<Skeleton hide={!loading}>
									<Content
										data={getContentData(modules, type)}
										moduleList={modules}
										lang={lang}
										backend={backend}
										setNewModuleContent={setNewModuleContent}
										type={'modules'}
										setLoading={setLoading}
									/>
								</Skeleton>
							</Card>
						</div>
					</div>
				</div>
			</div>
		)
	} else {
		return (
			<div className="container-xl">
				<div className="row row-deck row-cards">
					<div className="col-sm-12 col-lg-12">
						<div className="cms-card-container">
							<Card title={lang('module-group-list')}>
								<Skeleton hide={!loading}>
									<Content
										data={getContentData(moduleGroups, type)}
										moduleList={modules}
										lang={lang}
										backend={backend}
										setNewModuleContent={setNewModuleContent}
										newModuleContent={newModuleContent}
										type={'module-groups'}
										setLoading={setLoading}
									/>
								</Skeleton>
							</Card>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const Content = ({ lang, data, moduleList, backend, setNewModuleContent, newModuleContent, type, setLoading }) => {
	const [showModuleEditor, setShowModuleEditor] = useState(false)
	const [defaultFormValues, setDefaultFormValues] = useState()
	const [resetForm, setResetForm] = useState('123')
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
	const [oldSteps, setOldSteps] = useState()
	const [form] = Form.useForm()

	const renderActions = (item) => {
		return (
			<Space size="small">
				<Button
					type="open"
					onClick={(e) => {
						e.stopPropagation()
						setShowModuleEditor(true)
						setDefaultFormValues(item)
					}}
				/>
				<Button
					type="remove"
					danger={true}
					onClick={(e) => {
						e.stopPropagation()
						setShowDeleteConfirm(item)
					}}
				/>
			</Space>
		)
	}

	const columns = [
		{
			title: lang('image'),
			dataIndex: 'imageURL',
			key: 'image',
			render: (url) => {
				if (!url || url.includes('cmsnull') || url.includes('cmsundefined')) {
					return <Icon type="missing-image" style={{ width: '100%', height: '100%' }} />
				}
				return <img src={url} className="cms-image"></img>
			}
		},
		{
			title: lang('title'),
			dataIndex: 'title',
			key: 'title',
			sorter: (a, b) => (a.title ? a.title.localeCompare(b.title) : b)
		},
		{
			title: lang('last-change'),
			dataIndex: 'date',
			key: 'date'
		},
		{
			title: lang('type'),
			dataIndex: 'type',
			key: 'type',
			sorter: (a, b) => (a.type ? a.type.localeCompare(b.type) : b)
		},
		{
			title: lang('actions'),
			dataIndex: 'moduleGroup',
			key: 'action',
			render: (moduleGroup) => renderActions(moduleGroup)
		}
	]

	return (
		<div>
			<Drawer
				open={showModuleEditor}
				placement="right"
				onOK={async () => {
					let fields = form.getFieldsValue()
					form.validateFields()
					if (fields.form_type === 'module_group' || fields?.state) {
						let newModuleGroup = {
							title: fields.title,
							state: fields?.state ? fields.state : 'DRAFT',
							description: fields.description,
							modules: fields.select_modules,
							display_picture: fields?.new_display_picture ? fields.new_display_picture.id : defaultFormValues.display_picture.id
						}
						delete newModuleGroup.form_type
						newModuleGroup = JSON.stringify(newModuleGroup)
						backend.cmsModuleGroups({
							id: defaultFormValues.id,
							type: backend.type.UPDATE,
							body: newModuleGroup,
							cb: (res) => {
								message.success(lang('module-group-updated'))

								setResetForm(resetForm + 1)
								setNewModuleContent(newModuleContent + 1)
								setShowModuleEditor(false)
								setResetForm(resetForm + 1)
							},
							cbError: (err) => {
								message.error(lang('module-group-update-failed'))
							}
						})
					} else {
						// updating a module
						let newModule = {
							title: fields.title,
							description: fields.description,
							type: fields.type,
							new_steps: fields.new_steps,
							display_picture: fields?.new_display_picture ? fields.new_display_picture.id : defaultFormValues.display_picture.id,
							oldStepsOrder: oldSteps,
							...fields
						}
						delete newModule.form_type

						newModule = JSON.stringify(newModule)
						backend.cmsModules({
							id: defaultFormValues.id,
							type: backend.type.UPDATE,
							body: newModule,
							cb: (res) => {
								message.success(lang('module-updated'))

								setResetForm(resetForm + 1)
								setNewModuleContent(newModuleContent + 1)
								setShowModuleEditor(false)
								setResetForm(resetForm + 1)
							},
							cbError: (err) => {
								message.error(lang('module-update-failed'))
							}
						})
					}
				}}
				onClose={() => {
					form.resetFields()
					form.setFieldsValue({ ['new_steps']: undefined })
					setResetForm(resetForm + 1)
					setShowModuleEditor(false)
				}}
				width="80%"
				text={{
					title: defaultFormValues?.type === null ? lang('edit-module-group') : lang('edit-module'),
					ok: lang('save'),
					cancel: lang('cancel')
				}}>
				<div>
					<ModuleEditor
						lang={lang}
						form={form}
						moduleList={moduleList}
						defaultFormValues={defaultFormValues}
						oldSteps={oldSteps}
						setOldSteps={setOldSteps}
						key={resetForm}
					/>
				</div>
			</Drawer>
			<DecisionModal
				open={showDeleteConfirm}
				onClose={() => setShowDeleteConfirm(false)}
				onOK={() => {
					setLoading(true)
					if ('state' in showDeleteConfirm) {
						// module group
						backend.cmsModuleGroups({
							id: showDeleteConfirm.id,
							type: backend.type.DELETE,
							cb: (res) => {
								message.success(lang('module-group-deleted'))

								setNewModuleContent(newModuleContent + 1)
								setResetForm(resetForm + 1)
								setLoading(false)
							},
							cbError: (err) => {
								message.error(lang('module-group-delete-failed'))
							}
						})
					} else {
						// module
						if (type === 'modules') {
							// in module-editor, delete module
							backend.cmsModules({
								id: showDeleteConfirm.id,
								type: backend.type.DELETE,
								cb: (res) => {
									message.success(lang('module-deleted'))

									setNewModuleContent(newModuleContent + 1)
									setResetForm(resetForm + 1)
									setLoading(false)
								},
								cbError: (err) => {
									message.error(lang('module-delete-failed'))
									setLoading(false)
								}
							})
						} else {
							// in module-groups editor, remove module from module group
							let parentId = showDeleteConfirm.parent.id
							let moduleId = showDeleteConfirm.id

							let updatedModuleGroup = {
								...showDeleteConfirm.parent,
								modules: showDeleteConfirm.parent.modules.filter((module) => module.id !== moduleId)
							}
							updatedModuleGroup = JSON.stringify(updatedModuleGroup)

							backend.cmsModuleGroups({
								id: parentId,
								type: backend.type.UPDATE,
								body: updatedModuleGroup,
								cb: (res) => {
									message.success(lang('module-removed'))

									setNewModuleContent(newModuleContent + 1)
									setResetForm(resetForm + 1)
									setLoading(false)
								},
								cbError: (err) => {
									message.error(lang('module-removal-failed'))
									setLoading(false)
								}
							})
						}
					}
				}}
				autoClose={true}
				text={{
					title:
						type === 'modules' || (type === 'module-groups' && showDeleteConfirm !== false && !('state' in showDeleteConfirm))
							? lang('remove-module')
							: lang('delete-module-group'),
					ok: lang('remove'),
					cancel: lang('cancel'),
					details:
						type === 'module-groups' && showDeleteConfirm !== false && !('state' in showDeleteConfirm)
							? lang('remove-module-from-group', { module: showDeleteConfirm.title, moduleGrp: showDeleteConfirm.parent.title })
							: lang('delete-module-group-description', { moduleGrp: showDeleteConfirm.title })
				}}
			/>
			<Table
				scroll={{ x: '100%' }}
				dataSource={data}
				columns={columns}
				expandable={{
					expandRowByClick: true
				}}
				pagination={{
					pageSizeOptions: ['20', '50', '70', '100'],
					position: ['bottomCenter'],
					showSizeChanger: true
				}}
			/>
		</div>
	)
}

const moduleTypes = {
	audio: 'audio_meditation',
	reading: 'reading',
	video: 'video',
	challenge: 'interactive_challenge'
}

const moduleGroupTypes = {
	draft: 'DRAFT',
	published: 'PUBLISHED'
}

const moduleStepTypes = {
	introduction: 'Introduction',
	reading: 'Reading',
	audio: 'Audio',
	video: 'Video',
	question: 'Question'
}

const moduleStepComponents = {
	[moduleStepTypes.introduction]: 'steps.intro',
	[moduleStepTypes.reading]: 'steps.text',
	[moduleStepTypes.audio]: 'steps.audio',
	[moduleStepTypes.video]: 'steps.video',
	[moduleStepTypes.question]: 'steps.question'
}

const ModuleEditor = ({ lang, defaultFormValues, oldSteps, setOldSteps, moduleList, key, form }) => {
	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)
	const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState()

	const [openAudioMediaLibrary, setOpenAudioMediaLibrary] = useState(false)

	const [openVideoMediaLibrary, setOpenVideoMediaLibrary] = useState(false)

	const [moduleStepType, setModuleStepType] = useState({})
	const [deletedSteps, setDeletedSteps] = useState([])

	const [resetOldSteps, setResetOldSteps] = useState(0)

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	useEffect(() => {
		form.resetFields()
		form.setFieldsValue(defaultFormValues)
	}, [form, defaultFormValues, key])

	useEffect(() => {
		setOldSteps(defaultFormValues.steps)
	}, [defaultFormValues])

	const introStep = ({ step, rules, name = 'introduction' }) => {
		return (
			<div>
				<Form.Item name={[name, 'title']} label={lang('title')} initialValue={step?.title} rules={rules}>
					<Input />
				</Form.Item>
				<Form.Item name={[name, 'description']} label={lang('description')} initialValue={step?.description} rules={rules}>
					<Input.TextArea />
				</Form.Item>
			</div>
		)
	}

	const textStep = ({ step, rules, name = 'reading' }) => {
		return (
			<div>
				<Form.Item name={[name, 'title']} label={lang('title')} initialValue={step?.title} rules={rules}>
					<Input />
				</Form.Item>
				<Form.Item name={[name, 'body']} label={lang('body')} initialValue={step?.body} rules={rules}>
					<Input.TextArea />
				</Form.Item>
			</div>
		)
	}
	const [selectedAudio, setSelectedAudio] = useState(null)
	const audioStep = ({ step, rules, name = 'audio' }) => {
		if ('audio' in step) {
			let audio
			let fields = form.getFieldsValue()
			if (name in fields) {
				audio = new Audio(`${CMS_ENDPOINT}` + fields[name]?.url)
			} else {
				form.setFieldsValue({ [name]: step.audio })
				audio = new Audio(`${CMS_ENDPOINT}` + step?.audio?.url)
			}

			audio.load()
			return (
				<div className="media-container">
					<div>
						<Form.Item name={[name + 'title']} label={lang('title')} initialValue={step?.audio?.name} rules={rules}>
							<Input disabled />
						</Form.Item>
						<Form.Item name={name} initialValue={step.audio} hidden />
						<Button
							type="primary"
							onClick={() => {
								audio.play()
							}}>
							{lang('play')}
						</Button>
						<Button
							type="primary"
							onClick={() => {
								audio.pause()
							}}>
							{lang('pause')}
						</Button>
						<Button
							type="primary"
							onClick={() => {
								audio.currentTime = 0
							}}>
							{lang('reset')}
						</Button>
					</div>
					<Button
						type="primary"
						onClick={() => {
							setOpenAudioMediaLibrary(name)
							setSelectedAudio(name)
						}}>
						{lang('select-audio')}
					</Button>
					<Drawer
						open={openAudioMediaLibrary === name}
						placement="right"
						onClose={() => {
							setOpenAudioMediaLibrary(false)
						}}
						width="80%"
						text={{ title: lang('select-audio'), cancel: lang('cancel') }}>
						<MediaLibrary
							single
							media={{
								onMediaSelect: () => {
									setOpenAudioMediaLibrary(false)
								},
								setSelectedMedia: (audio) => {
									let newAudio = {
										name: audio.name,
										url: audio.url,
										id: audio.id,
										audio: { ...audio }
									}
									form.setFieldsValue({ [selectedAudio]: newAudio })
									form.setFieldsValue({ [selectedAudio + 'title']: audio.name })
								},
								type: 'Audio',
								mime: ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg']
							}}
						/>
					</Drawer>
				</div>
			)
		} else {
			let step_name = [name, 'audio_name']
			let audio
			let audioSource = form.getFieldsValue()
			const { new_steps } = audioSource
			let audioUrl = new_steps[name]?.url

			if (audioUrl) {
				audio = new Audio(`${CMS_ENDPOINT}` + audioUrl)
				audio.load()
			}
			return (
				<div className="media-container">
					<div>
						<Form.Item name={[name, 'audio_name']} label={lang('title')} initialValue={step?.audio?.name} rules={rules}>
							<Input disabled />
						</Form.Item>
						<Form.Item name={name} hidden />
						<Button
							type="primary"
							onClick={() => {
								audio && audio.play()
							}}>
							{lang('play')}
						</Button>
						<Button
							type="primary"
							onClick={() => {
								audio && audio.pause()
							}}>
							{lang('pause')}
						</Button>
						<Button
							type="primary"
							onClick={() => {
								audio && (audio.currentTime = 0)
							}}>
							{lang('reset')}
						</Button>
					</div>
					<Button
						type="primary"
						onClick={() => {
							setOpenAudioMediaLibrary(name)
						}}>
						{lang('select-audio')}
					</Button>
					<Drawer
						open={openAudioMediaLibrary === name}
						placement="right"
						onClose={() => {
							setOpenAudioMediaLibrary(false)
						}}
						width="80%"
						text={{ title: lang('select-audio'), cancel: lang('cancel') }}>
						<MediaLibrary
							single
							media={{
								onMediaSelect: () => {
									setOpenAudioMediaLibrary(false)
								},
								setSelectedMedia: (audio) => {
									let fields = form.getFieldsValue()
									const { new_steps } = fields

									Object.assign(new_steps[step_name[0]], {
										audio_name: audio.name,
										id: audio.id,
										url: audio.url,
										audio: { ...audio }
									})
									form.setFieldsValue({ new_steps })
								},
								type: 'Audio',
								mime: ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg']
							}}
						/>
					</Drawer>
				</div>
			)
		}
	}

	const [selectedVideo, setSelectedVideo] = useState(null)
	const videoStep = ({ step, rules, name }) => {
		if ('video' in step) {
			// oldstep
			return (
				<div className="media-container">
					<div>
						<Form.Item name={[name + 'video_title']} label={lang('title')} initialValue={step?.video?.name} rules={rules}>
							<Input disabled />
						</Form.Item>
					</div>
					<Form.Item name={name} initialValue={step.video} hidden />
					<Button
						type="primary"
						onClick={(e) => {
							setOpenVideoMediaLibrary(name)
							setSelectedVideo(name)
						}}>
						{lang('select-video')}
					</Button>
					<Drawer
						open={openVideoMediaLibrary === name}
						placement="right"
						onClose={() => {
							setOpenVideoMediaLibrary(false)
						}}
						width="80%"
						text={{ title: lang('select-video'), cancel: lang('cancel') }}>
						<MediaLibrary
							single
							media={{
								onMediaSelect: () => setOpenVideoMediaLibrary(false),
								setSelectedMedia: (video) => {
									let newVideo = {
										name: video.name,
										id: video.id,
										video: { ...video }
									}
									form.setFieldsValue({ [selectedVideo]: newVideo })
									form.setFieldsValue({ [name + 'video_title']: video.name })
								},
								type: 'Video',
								mime: ['video/mp4', 'video/webm']
							}}
						/>
					</Drawer>
				</div>
			)
		} else {
			let step_name = [name, 'video_title']
			return (
				<div className="media-container">
					<div>
						<Form.Item name={[name, 'video_title']} label={lang('title')} initialValue={step?.video?.name} rules={rules}>
							<Input disabled />
						</Form.Item>
					</div>
					<Form.Item name={name} hidden />
					<Button
						type="primary"
						onClick={() => {
							setOpenVideoMediaLibrary(name)
						}}>
						{lang('select-video')}
					</Button>
					<Drawer
						open={openVideoMediaLibrary === name}
						placement="right"
						onClose={() => {
							setOpenVideoMediaLibrary(false)
						}}
						width="80%"
						text={{ title: lang('select-video'), cancel: lang('cancel') }}>
						<MediaLibrary
							single
							media={{
								onMediaSelect: () => setOpenVideoMediaLibrary(false),
								setSelectedMedia: (video) => {
									let fields = form.getFieldsValue()
									const { new_steps } = fields

									Object.assign(new_steps[step_name[0]], {
										video_title: video.name,
										id: video.id,
										video: { ...video }
									})
									form.setFieldsValue({ new_steps })
								},
								type: 'Video',
								mime: ['video/mp4', 'video/webm']
							}}
						/>
					</Drawer>
				</div>
			)
		}
	}

	const questionStep = ({ step, rules, name }) => {
		return (
			<div>
				<Form.Item name={[name, 'quiz']} label={lang('quizzes')} initialValue={JSON.stringify(step.quiz)} rules={rules}>
					<Input.TextArea />
				</Form.Item>
			</div>
		)
	}

	const deleteStepButton = (index) => {
		return <Button type="remove" txt={lang('delete-step')} onClick={() => setDeletedSteps((oldArray) => [...oldArray, index])} />
	}

	const handleRemoveModuleStepType = (index) => {
		let hitFlag = false
		let data = moduleStepType
		let saveState = form.getFieldsValue()
		let { new_steps } = saveState
		if (Object.keys(data).length === 1) {
			setModuleStepType({})
			return true
		}
		for (let i = 0; i < Object.keys(data).length; i++) {
			if (i === index) {
				hitFlag = true
				continue
			}
			if (hitFlag) {
				data[i - 1] = data[i]
				new_steps[i - 1] = {}
				Object.assign(new_steps[i - 1], new_steps[i])
				delete data[i]
			}
		}
		form.setFieldsValue({ new_steps })
		setModuleStepType(data)
	}

	const moveStepUpOrDown = (step, index) => {
		let newArr = oldSteps
		return (
			<div className="up-down-icons">
				<div className="incrementor-div">
					<UpOutlined
						onClick={(e) => {
							e.stopPropagation()
							if (index > 0) {
								let temp = newArr[index - 1]
								newArr[index - 1] = newArr[index]
								newArr[index] = temp
								setOldSteps(newArr)
								setResetOldSteps(resetOldSteps + 1)
							}
						}}
					/>
				</div>
				<Divider plain={true} style={{ marginTop: 1, marginBottom: 1 }} />
				<div className="incrementor-div">
					<DownOutlined
						onClick={(e) => {
							e.stopPropagation()
							if (index < newArr.length - 1) {
								let temp = newArr[index + 1]
								newArr[index + 1] = newArr[index]
								newArr[index] = temp
								setOldSteps(newArr)
								setResetOldSteps(resetOldSteps + 1)
							}
						}}
					/>
				</div>
			</div>
		)
	}

	const OldStepsList = () => {
		return (
			<div>
				<Collapse>
					{oldSteps &&
						oldSteps.map((step, index) => {
							const name = step.__component + step.id
							if (deletedSteps.includes(index)) {
								return null
							}
							switch (step.__component) {
								case 'steps.intro':
									return (
										<Collapse.Panel forceRender header={lang(step.__component)} key={index} extra={moveStepUpOrDown(step, index)}>
											{introStep({ step, rules, name })}
											{deleteStepButton(index)}
										</Collapse.Panel>
									)
								case 'steps.audio':
									return (
										<Collapse.Panel forceRender header={lang(step.__component)} key={index} extra={moveStepUpOrDown(step, index)}>
											{audioStep({ step, rules, name })}
											<div style={{ marginTop: '25px' }}>{deleteStepButton(index)}</div>
										</Collapse.Panel>
									)
								case 'steps.text':
									return (
										<Collapse.Panel forceRender header={lang(step.__component)} key={index} extra={moveStepUpOrDown(step, index)}>
											{textStep({ step, rules, name })}
											{deleteStepButton(index)}
										</Collapse.Panel>
									)
								case 'steps.video':
									return (
										<Collapse.Panel forceRender header={lang(step.__component)} key={index} extra={moveStepUpOrDown(step, index)}>
											{videoStep({ step, rules, name })}
											{deleteStepButton(index)}
										</Collapse.Panel>
									)
								default:
									return (
										<Collapse.Panel forceRender header={lang(step.__component)} key={index} extra={moveStepUpOrDown(step, index)}>
											{questionStep({ step, rules, name })}
											{deleteStepButton(index)}
										</Collapse.Panel>
									)
							}
						})}
				</Collapse>
			</div>
		)
	}

	const stepsSheet = (
		<>
			Steps:
			<OldStepsList key={resetOldSteps} />
			<Form.List name="new_steps">
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => {
							return (
								<Card key={key}>
									<div>
										<Form.Item
											{...restField}
											name={[name, 'step_type']}
											label={lang('step-type')}
											initialValue={moduleStepTypes.introduction}>
											<Select
												onChange={(value) => {
													setModuleStepType((prevState) => ({
														...prevState,
														[name]: value
													}))
												}}>
												{Object.keys(moduleStepTypes).map((step) => {
													return (
														<Select.Option key={step} value={moduleStepTypes[step]}>
															{moduleStepTypes[step]}
														</Select.Option>
													)
												})}
											</Select>
										</Form.Item>

										{moduleStepType[name] === moduleStepTypes.introduction && introStep({ step: restField, rules, name })}
										{moduleStepType[name] === moduleStepTypes.reading && textStep({ step: restField, rules, name })}
										{moduleStepType[name] === moduleStepTypes.audio && audioStep({ step: restField, rules, name })}
										{moduleStepType[name] === moduleStepTypes.video && videoStep({ step: restField, rules, name })}
										{moduleStepType[name] === moduleStepTypes.question && questionStep({ step: restField, rules, name })}
										<Button
											type="remove"
											txt={lang('remove-step')}
											onClick={() => {
												if (handleRemoveModuleStepType(name)) {
													remove(name)
												} else {
													remove(name + 1)
												}
											}}
										/>
									</div>
								</Card>
							)
						})}
						<Form.Item>
							<Button
								type="primary"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
								block
								onClick={(e) => {
									setModuleStepType((prevState) => ({
										...prevState,
										[fields.length]: moduleStepTypes.introduction
									}))
									add()
								}}>
								{lang('add-step')} <PlusOutlined />
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</>
	)

	const formSheet = (
		<div>
			<Form form={form} initialValues={defaultFormValues} layout="vertical">
				<Form.Item name="title" label={lang('title')} rules={rules}>
					<Input />
				</Form.Item>
				<Form.Item name="description" label={lang('description')} rules={rules}>
					<Input />
				</Form.Item>

				<div className="thumbnail-container">
					<div>
						<div className="ant-col ant-form-item-label">
							<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
								{lang('thumbnail')}
							</label>
						</div>
						<Form.Item name="new_display_picture" hidden>
							<Input type="hidden" />
						</Form.Item>
						<br />
						<img
							src={
								selectedThumbnailUrl ?? defaultFormValues?.display_picture?.formats?.thumbnail
									? `${CMS_ENDPOINT}` + defaultFormValues.display_picture.formats?.thumbnail?.url
									: defaultFormValues?.display_picture?.url
									? `${CMS_ENDPOINT}` + defaultFormValues.display_picture.url
									: null
							}
							alt="thumbnail"
							height="100"
							width="auto"
						/>
					</div>
					<div>
						<Button type="secondary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
					</div>
				</div>
				<Drawer
					open={openThumbnailMediaLibrary}
					placement="right"
					onClose={() => {
						setOpenThumbnailMediaLibrary(false)
					}}
					width={'65%'}
					text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
					<MediaLibrary
						single
						media={{
							onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
							setSelectedMedia: (thumbnail) => {
								form.setFieldsValue({
									new_display_picture: {
										id: thumbnail.id,
										url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
									}
								})
							},
							setSelectedMediaUrl: setSelectedThumbnailUrl,
							type: 'Thumbnail',
							mime: ['image/jpeg', 'image/png', 'image/svg+xml']
						}}
					/>
				</Drawer>

				{/* MODULES */}
				{defaultFormValues?.type && defaultFormValues?.steps && (
					<>
						<Form.Item name="form_type" hidden initialValue={'module'}>
							<Input type="hidden" />
						</Form.Item>
						<Form.Item name="type" label={lang('type')} rules={rules}>
							<Select>
								{Object.keys(moduleTypes).map((key) => (
									<Select.Option key={key} value={moduleTypes[key]}>
										{lang(key)}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						{stepsSheet}
					</>
				)}

				{/* MODULE GROUPS */}
				{defaultFormValues?.modules && (
					<>
						<Form.Item name="form_type" hidden initialValue={'module_group'}>
							<Input type="hidden" />
						</Form.Item>
						<Form.Item name="state" label={lang('type')} rules={rules}>
							<Select>
								{Object.keys(moduleGroupTypes).map((key) => (
									<Select.Option key={key} value={moduleGroupTypes[key]}>
										{lang(key)}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item
							name="select_modules"
							label={lang('select-modules')}
							initialValue={defaultFormValues.modules.map((module) => module.id)}>
							<Select mode="multiple" placeholder={lang('select-modules')}>
								{moduleList.map((module) => (
									<Select.Option key={module.id} value={module.id}>
										{module.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</>
				)}
			</Form>
		</div>
	)

	return <div>{formSheet}</div>
}

export { Modules, moduleGroupTypes, moduleTypes, moduleStepTypes, moduleStepComponents }
