import React from 'react'

import Icon from '../../../components/Icon'
import Tag from '../../../components/Tag'

const AppointmentItem = ({ value, resolve }) => {

	return (
		<>
			{ value ? <div style={{ display: 'grid' }}>
				{ value.map((v) => {
					const d = resolve ? resolve(v) : v
					return	<div
								style={{
									display: 'block',
									lineHeight: '22px',
								}}
								key={d.id}>
								<div className='m5'>{d.title}</div>
								<div className='m5'>{d.healthProvider?.name}</div>
								<div className='m5'><Icon type={d.image} className="ml10"/> {d.type?.title}</div>
								{ d.treatment?.title ? <Tag className='m5'>{d.treatment?.title}</Tag> : null }
								<Tag>{ d.groupVisibility?.toUpperCase() }</Tag>
							</div>
				}) }
			</div> : null }
		</>
	)
}

export default AppointmentItem
