import React from "react"

import { Result } from 'antd'
import { LockOutlined } from '@ant-design/icons';

import { LangContext } from '../../components/Translation'
import translations from './translations'

export default function NoAccess() {
  
    const lang = React.useContext(LangContext)(translations)

    return (
      <div className="page-wrapper">          
            <div className="page-body">
              <div className="container-xl">
                <div className="row row-deck row-cards">
                  <div className="col-12">  
                    <Result
                      icon={<LockOutlined />}
                      title={ lang("message") }
                    />                    
                  </div>                  
                </div>                    
              </div>
            </div>
        </div>
    )
  }
  