import React, { useState, useEffect } from 'react'

import Backend from '../../api/Backend'
import { LangContext } from '../Translation'
import translations from './translations'
import Select from '../Select'

import Utils from '../Utils'

export default function PatientSelect(props) {

	const lang = React.useContext(LangContext)(translations)

	const backend = new Backend()
	const utils = new Utils()
	const [patientOptions, setPatientOptions] = useState()

	useEffect(() => {
		backend.patients({
			clinic: props.selectedClinic,
			studyGroup: props.studyGroup,
			cb: (data) => {
				data = Array.isArray(data)
					? data.map((d) => {
							return {
								key: d.user_id,
								value: d.resource.patient_name,
								tags: [
									utils.isStudyGroupRPA(d.resource.study_group) ? 'Intervention' : null,
									d.resource.terms_accepted ? 'terms' : null,
								],
							}
					  })
					: [{}]
				if (props.optionAll !== false) {
					data.push({ key: undefined, value: lang('all') })
				}
				setPatientOptions(data)
			},
		})
	}, [props.selectedClinic])

	const defaultKey = patientOptions && patientOptions.length > 0 ? patientOptions[0].key : null

	return (
		<Select
			title="Patient(in)"
			default={defaultKey}
			loading={!patientOptions}
			options={patientOptions}
			closeMenuOnSelect
			onChange={props.onChange}
			style={{ maxWidth: 'fit-content', zIndex: 100}}></Select>
	)
}
