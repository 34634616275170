import React, { useState, useEffect } from 'react'

import Drawer from '../../components/Drawer'
import Tag from '../../components/Tag'
import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'
import Button from '../../components/Button'
import { Careplan } from '../../applets/Careplan'
import Backend from '../../api/Backend'
import Table from '../../components/Table'
import DecisionModal from '../../components/DecisionModal'
import Utils from '../../components/Utils'

import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { LangContext } from '../../components/Translation'
import translations from './translations'

import './index.css'

const Careplans = () => {
	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()

	const [careplans, setCareplans] = useState([])
	const [showCareplanModal, setShowCareplanModal] = useState(false)
	const [displayCMDetails, setDisplayCMDetails] = useState()
	const [showRemoveModal, setShowRemoveModal] = useState({ show: false })
	const [loading, setLoading] = useState(true)

	const backend = new Backend()
	const location = useLocation()
	let history = useHistory()

	const buttonType = {
		ADD: 'add',
		REFRESH: 'refresh',
		REMOVE: 'remove',
		EDIT: 'edit'
	}

	useEffect(() => {
		refresh()
	}, [])

	const refresh = () => {
		setLoading(true)
		backend.careplans({
			cb: (data) => {
				setCareplans(
					data.map((el) => {
						return {
							key: el.id,
							title: el.resource.title,
							status: el.resource.status,
							setting: el.setting_title,
							changedAt: el.changed_at
						}
					})
				)
				setLoading(false)
			}
		})
	}

	const handleCareplanRemove = () => {
		setCareplans(null)
		backend.careplans({ id: showRemoveModal.cp.key, type: backend.type.DELETE, cb: refresh })
	}

	const handleCareplanDisplayDetails = (c) => {
		setDisplayCMDetails(c.key)
		history.push('/careplans')
	}

	const renderCMRedirect = () => {
		return displayCMDetails ? (
			<Redirect
				to={{
					pathname: `/careplan/${displayCMDetails}`,
					state: { referrer: location.pathname }
				}}
			/>
		) : null
	}

	return (
		<PageHeader
			title={lang('careplans')}
			extra={
				<div className="topbar-wrapper">
					<div className="mt-2 mb-3">
						<Drawer
							width="90vw"
							frameless
							text={{ title: lang('create-new-cm-title'), cancel: lang('cancel') }}
							open={showCareplanModal}
							onClose={() => setShowCareplanModal(false)}>
							<Careplan
								onClose={() => {
									setShowCareplanModal(false)
									refresh()
								}}
							/>
						</Drawer>
					</div>
				</div>
			}>
			<PageContent>
				{renderCMRedirect()}
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-12 col-lg-12">
							<DecisionModal
								open={showRemoveModal.show}
								bordered
								onOK={() => {
									handleCareplanRemove()
									setShowRemoveModal({ show: false })
								}}
								onCancel={() => setShowRemoveModal({ show: false })}
								okType="danger"
								text={{
									title: lang('remove-careplan'),
									ok: lang('remove'),
									details: lang('remove_question', { name: showRemoveModal.cp ? showRemoveModal.cp.title : '' })
								}}
							/>
							<Table
								title={lang('careplan-list')}
								emptyText={lang('no-careplans')}
								loading={loading}
								headless={true}
								columns={[
									{
										key: 'title',
										value: lang('title'),
										sorter: (a, b) => (a.title ? a.title.localeCompare(b.title) : false),
										search: true
									},
									{
										key: 'setting',
										value: lang('setting'),
										sorter: (a, b) => (a.setting ? a.setting.localeCompare(b.setting) : false)
									},
									{
										key: 'status',
										value: lang('status'),
										render: ({ value }) => {
											return <Tag type={value}>{lang(value)}</Tag>
										},
										sorter: (a, b) => (a.status ? a.status.localeCompare(b.status) : false),
										searchWithRender: true
									},
									{
										key: 'changedAt',
										value: lang('changed-at'),
										render: ({ value }) => {
											return utils.toDate({ dateString: value })
										},
										dateSorter: 'changedAt'
									},
									{
										value: lang('actions'),
										render: ({ row }) => {
											return (
												<>
													<Button type={buttonType.EDIT} onClick={() => handleCareplanDisplayDetails(row)} />
													<Button
														type={buttonType.REMOVE}
														className="ml10"
														onClick={() => setShowRemoveModal({ show: true, cp: row })}
													/>
												</>
											)
										}
									}
								]}
								buttons={[
									<Button key="refresh" type={buttonType.REFRESH} className="mr10" onClick={refresh} />,
									<Button
										key="add"
										type={buttonType.ADD}
										className=""
										txt={lang('create-new')}
										onClick={() => setShowCareplanModal(!showCareplanModal)}
									/>
								]}
								data={careplans}
							/>
						</div>
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}

export default Careplans
