import React, { useState, useEffect } from 'react'
import { message, Alert, Select, Input, Radio } from 'antd'
import { Redirect, useLocation } from 'react-router-dom'

import Patient from '../../pages/Patient'

import Skeleton from '../../components/Skeleton'
import Drawer from '../../components/Drawer'
import Button from '../../components/Button'
import Notes from '../../components/Notes'
import PeopleSelect from '../../components/PeopleSelect'
import ChangeLog from './ChangeLog'
import Assessment from './Assessment'
import CareConnects from './CareConnects'
import SymptomAlarms from './SymptomAlarms'
import { AppointmentScheduler } from '../../applets/AppointmentScheduler'
import OnboardPatientView from '../../components/OnboardPatientView'

import Backend from '../../api/Backend'
import Utils from '../../components/Utils'
import { useGlobalContext } from '../../components/AppContext'


import { states, StatusLabel, TypeLabel, taskTypes } from '../../applets/TaskBoard/index'
import { LangContext } from '../../components/Translation'
import translations from './translations'

import './index.css'
import HSPReview from './HSPReview'
import AllowRender from '../../components/AllowRender'

export default function TaskEditor({
	taskId,
	birthdate,
	patientFullName,
	defaultData, // in template Case
	isTemplate = false,
	onChange = () => {}
}) {
	const backend = new Backend()
	const utils = new Utils()
	const { globals } = useGlobalContext()


	const lang = React.useContext(LangContext)(translations)
	const location = useLocation()

	const [loadingTask, setLoadingTask] = useState(false)
	const [loadingUser, setLoadingUser] = useState(false)
	const [loadingQuestionnaire, setLoadingQuestionnaire] = useState(false)

	const [loadingChangeLog, setLoadingChangeLog] = useState(false)
	const [appointmentSchedulerAPI, setAppointmentSchedulerAPI] = useState()
	const [data, setData] = useState({ resource: {} })
	const [questionnaireData, setQuestionnaireData] = useState([])
	const [changeLogData, setChangeLogData] = useState([])
	const [users, setUsers] = useState()
	const [patients, setPatients] = useState()
	const [gotoPatient, setGotoPatient] = useState()
	const [HSPReviewer, setHSPReviewer] = useState(false)
	const [showPatientOnboardingForm, setShowPatientOnboardingForm] = useState(false)

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const getTaskId = () => {
		return taskId !== undefined ? taskId : data && data.id ? data.id : taskId
	}

	const refresh = () => {
		setLoadingUser(true)
		setLoadingChangeLog(true)

		backend.users({
			symptomHandler: true,
			center: globals.selectedCenter || undefined,
			cb: (users) => {
				setUsers(users)
				setLoadingUser(false)
			}
		})

		backend.taskChangeLog({
			id: getTaskId(),
			cb: (res) => {
				setChangeLogData(res)
				setLoadingChangeLog(false)
			}
		})
		setLoadingTask(true)
		if (isUpdateState()) {
			backend.tasks({
				id: getTaskId(),
				cb: (data) => {
					const taskDetails = data.pop()
					updateData(taskDetails)
					loadQuestionnaireData({ requestId: taskDetails?.resource?.questionnaireRequestId })
					taskDetails.patient_idPersonalData.patientId = taskDetails?.patient_id
					setPatients([taskDetails?.patient_idPersonalData])
					setLoadingTask(false)
				}
			})
		} else if (taskId === null) {
			backend.patients({
				cb: (patients) => {
					if (defaultData) {
						defaultData.resource = defaultData.resource ? defaultData.resource : { ...defaultData }
						updateData(defaultData)
					} else {
						const type = taskTypes[Object.keys(taskTypes)[0]]
						updateData({ type, resource: { type } })
					}
					setPatients(patients)
					setLoadingTask(false)
				}
			})
		}
	}

	const loadQuestionnaireData = ({ requestId }) => {
		if (requestId) {
			setLoadingQuestionnaire(true)
			backend.getPatientQuestionnaireRequest({
				requestId,
				cb: (res) => {
					setQuestionnaireData(res)
					setLoadingQuestionnaire(false)
				}
			})
		}
	}

	useEffect(() => {
		if (taskId !== undefined) {
			refresh()
		}
	}, [taskId])

	const getStateOptions = () => {
		const res = []
		for (let state in states) {
			res.push(
				<Radio.Button key={state} value={state}>
					{StatusLabel(states[state])}
				</Radio.Button>
			)
		}
		return res
	}


	const isAssignedToValid = (messages) => {
		if (data.resource.assignedTo || isTemplate) {
			return true
		}
		messages.push(<Alert message={lang('set-assigned-to')} type="error" showIcon />)
		return false
	}

	const isPatientValid = (messages) => {
		if (data.resource.patientId || isTemplate) {
			return true
		}
		messages.push(<Alert message={lang('set-patient')} type="error" showIcon />)
		return false
	}

	const isBaseDataValid = () => {
		const messages = []
		let valid = true
		valid = isAssignedToValid(messages) ? valid : false
		valid = isPatientValid(messages) ? valid : false
		return valid
	}

	const createTask = () => {
		const doCreate = () => {
			const body = JSON.stringify(data.resource)
			backend.tasks({
				body,
				type: backend.type.CREATE,
				cb: (res) => {
					data.id = res.id // change to update state
					refresh()
					message.success(lang('save-success'))
				}
			})
		}

		if (isBaseDataValid()) {
			if (!isUpdateState() && isAppointmentTask()) {
				appointmentSchedulerAPI.scheduleAppointment({ onSave: doCreate })
			} else {
				doCreate()
			}
		}
	}

	const renderPatientRedirect = () => {
		return gotoPatient ? (
			<Redirect
				to={{
					pathname: `/patient/${gotoPatient}`,
					state: { referrer: location.pathname }
				}}
			/>
		) : null
	}

	const newPatientDataWithResourceProp =
		patients &&
		patients.map((pat) => {
			return {
				resource: {
					name: pat.name,
					surname: pat.surname,
					email: pat.email
				},
				id: pat.patientId,
				status: 'INCLUDED'
			}
		})

	const getAssignedTo = (userId) => {
		const assignedTo = users && users.length > 0 ? users?.find((user) => user.userId === userId) : []
		return assignedTo ? `${assignedTo.name} ${assignedTo.surname}` : null
	}

	const gotoPatientPage = () => {
		setGotoPatient(data.patient_id)
	}

	const isUpdateState = () => {
		return taskId !== null || (data && data.id !== undefined)
	}

	const isAppointmentTask = () => {
		return data.type === taskTypes.APPOINTMENT || taskId === taskTypes.APPOINTMENT
	}

	const isSymptomAlarmTask = () => {
		return data.type === taskTypes.SYMPTOM_ALARM || taskId === taskTypes.SYMPTOM_ALARM
	}

	const isCareConnect = () => {
		return data.type === taskTypes.CARE_CONNECT || taskId === taskTypes.CARE_CONNECT
	}

	const isHSPReview = () => {
		return data.type === taskTypes.HSP_REVIEW || taskId === taskTypes.HSP_REVIEW
	}

	const isAssessment = () => {
		return data.type === taskTypes.ASSESSMENT || taskId === taskTypes.ASSESSMENT
	}

	const isScheduled = () => {
		return data.type === taskTypes.SCHEDULED || taskId === taskTypes.SCHEDULED
	}

	const isTask = () => {
		return data.type === taskTypes.TASK || taskId === taskTypes.TASK
	}

	const handleStatusChange = (newStatus) => {
		data.resource.status = states[newStatus]
		data.status = data.resource.status
		const body = data.resource
		backend.tasks({
			type: backend.type.CREATE,
			body: JSON.stringify(body)
		})
		updateData(data)
	}

	const handleAssignedToChange = (sel) => {
		if (sel !== data.resource.assignedTo && sel !== null) {
			data.resource.assignedTo = sel
			if (isUpdateState()) {
				setLoadingTask(true)
				const body = JSON.stringify(data.resource)
				backend.tasks({ body, type: backend.type.CREATE, cb: refresh })
			}
			updateData(data)
		}
	}

	const handlePatientChange = (patientId) => {
		data.resource.patientId = patientId
		data.patient_id = patientId
		updateData(data)
	}

	const handleTaskCompletion = () => {
		handleStatusChange(states.COMPLETED)
		refresh()
	}

	const updateData = (data) => {
		setData({ ...data })
		onChange({
			isValid: () =>
				new Promise((resolve, reject) => {
					if (isBaseDataValid()) {
						resolve(data)
					} else {
						reject()
					}
				})
		})
	}

	const patientSelect = (
		<div className="patient exo-row mt20">
			<label style={{ lineHeight: '35px', fontWeight: '600px' }}>{lang('patient')}</label>
			<div className="ml10 mr10" style={{ lineHeight: '35px' }}>
				<PeopleSelect
					bordered={false}
					disabled={isUpdateState()}
					style={{ width: '25rem' }}
					placeholder={lang('select')}
					isMulti={false}
					value={`${data?.patient_name || '*'} |  ${
						data?.patient_idPersonalData?.name + ' ' + data?.patient_idPersonalData?.surname || patientFullName
					} | ${utils.toDate({ dateString: data?.patient_idPersonalData?.birth_date, withoutTime: true }) || birthdate}`} //TODO: Make new changes to it
					onChange={handlePatientChange}
					people={
						patients
							? patients.map((p) => {
									return {
										name: p?.name,
										clinic_name: p?.resource?.clinic_name,
										userId: p?.patientId
									}
							  })
							: null
					}
				/>
			</div>
			{data.patient_id ? (
				<div className="align-right flex" style={{ height: '35px' }}>
					{data.type === 'ONBOARD_PATIENT' && (
						<Button onClick={() => setShowPatientOnboardingForm(true)} className="ant-btn-primary mr5">
							ONBOARD
						</Button>
					)}
					<Drawer
						placement="right"
						width="90%"
						btn={{ type: 'open' }}
						text={{
							title: `${lang('patient')}`,
							cancel: lang('cancel')
						}}>
						<Patient id={data.patient_id}></Patient>
					</Drawer>
					<Button className="ml5" type={'goto'} onClick={gotoPatientPage} />
				</div>
			) : null}
		</div>
	)

	return (
		<>
			{renderPatientRedirect()}
			<div className="exo-task-editor">
				<div style={{ display: 'flex' }}>
					<div className="task-title">{TypeLabel(data.type)}</div>
					<div className="state-selector align-right">
						{isUpdateState() && !isTemplate ? (
							<Radio.Group value={data.status} onChange={(e) => handleStatusChange(e.target.value)}>
								{getStateOptions()}
							</Radio.Group>
						) : null}
					</div>
				</div>
				{isTask(data) && data.resource.message ? (
					<div className="exo-row mt20">
						<div className="mb5">{lang('message')}</div>
						<Input.TextArea
							rows={5}
							defaultValue={data.resource.message}
							onChange={(e) => {
								data.resource.message = e.target.value
								updateData(data)
							}}></Input.TextArea>
					</div>
				) : null}

				{!isTemplate && !isHSPReview(data) ? (
					<Skeleton type="list" hide={!loadingTask}>
						{' '}
						{patientSelect}{' '}
					</Skeleton>
				) : null}

				<Skeleton type="list" hide={!loadingTask && (!isCareConnect(data) &&  !data[0]?.interventions.length > 0)}>
					{isCareConnect(data) ? <CareConnects className="mt20" data={data ? data.resource.consultation : null} /> : null}
					{isSymptomAlarmTask(data) ? (
						<SymptomAlarms
							key={JSON.stringify(data?.symptoms)}
							className="mt20"
							patientId={data.patient_id}
							clinicId={data.clinic}
							patientPseudoName={data.patient_name}
							patientFullName={patientFullName}
							interventionGroup={data?.interventionGroup}
							data={data ? data.symptoms : null}
							onInterventionTriggered={handleTaskCompletion}
						/>
					) : null}
					{isAppointmentTask(data) ? (
						<div className="patient exo-row">
							<AppointmentScheduler
								className="transparent"
								externalScheduler={!isUpdateState() ? setAppointmentSchedulerAPI : null}
								data={data.resourceAppointment}
								patientId={data.resource.patientId}
								onCreate={(appointmentData) => {
									data.resource.appointmentId = appointmentData.id
									updateData(data)
								}}
							/>
						</div>
					) : null}
					{isAssessment(data) ? (
						<div className="exo-row mt20">
							<Skeleton type="list" hide={!loadingQuestionnaire}>
								<Assessment data={questionnaireData}></Assessment>
							</Skeleton>
						</div>
					) : null}
					{isCareConnect(data) ? <div>todo select connect services or text area</div> : null}
					{isHSPReview(data) ? (
						<div style={{ marginTop: '40px', marginBottom: '40px' }}>
							<Button
								className="ant-btn-primary"
								block
								size="large"
								disabled={AllowRender({ permissions: ['HSP_MANAGER_RW'], truthy: true, inverted: true })}
								onClick={() => setHSPReviewer(data)}>
								{lang('review-hsp-now')}
							</Button>

							<HSPReview setHSPReviewer={setHSPReviewer} HSPReviewer={HSPReviewer} handleTaskCompletion={handleTaskCompletion} />
						</div>
					) : null}
					{isScheduled(data) ? (
						<div className='exo-row mt20 mb5'>
							<div className="bold-text">
								{data.resource.title}
							</div>
							<div>
								{data.resource.text}
							</div>
						</div>
					) : null}
				</Skeleton>

				{!isTemplate ? (
					<>
						<div className="assign-to exo-row mt20">
							<Skeleton type="list" hide={!loadingUser}>
								{users && (
									<PeopleSelect
										allowClear={true}
										title={lang('assigned-to')}
										style={{ width: '400px' }}
										placeholder={lang('select')}
										isMulti={false}
										value={getAssignedTo(data?.resource?.assignedTo)}
										onChange={handleAssignedToChange}
										people={users}
									/>
								)}
							</Skeleton>
							<OnboardPatientView
								rules={rules}
								showPatientOnboardingForm={showPatientOnboardingForm}
								setShowPatientOnboardingForm={setShowPatientOnboardingForm}
								newPatientData={newPatientDataWithResourceProp && newPatientDataWithResourceProp[0]}
							/>
						</div>
						<Notes className="mt20" taskId={taskId} patientId={data.patient_id} />
						{isUpdateState() ? (
							<Skeleton type="list" hide={!loadingChangeLog}>
								<ChangeLog className="mt20" data={changeLogData ? changeLogData : null} />
							</Skeleton>
						) : null}
					</>
				) : null}
			</div>
		</>
	)
}
