import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import { Redirect } from 'react-router-dom'

import sdk from 'matrix-js-sdk'

import PubSub from 'pubsub-js'

import Icon from '../../../../components/Icon'

import translations from './translations'
import { LangContext } from '../../../../components/Translation'

import './index.css'

export default function Video({ show, client, addRoom, onCallStart, onCallEnd, resolveMatrixId }) {
	const lang = React.useContext(LangContext)(translations)

	const [opponent, setOpponent] = useState()
	const [call, setCall] = useState()
	const [showPatient, setShowPatient] = useState()

	const incomingCallNotification = ({ userName, onAccept, onDecline }) => {
		const key = `open${Date.now()}`
		const btn = (
			<>
				<Button
					type="primary"
					size="small"
					className="mr20"
					onClick={() => {
						notification.close(key)
						onDecline()
					}}>
					{lang('decline')}
				</Button>
				<Button
					type="primary"
					size="small"
					onClick={() => {
						notification.close(key)
						onAccept()
					}}>
					{lang('accept')}
				</Button>
			</>
		)
		notification.info({
			message: lang('incoming-call-title'),
			description: userName,
			duration: 0,
			btn,
			key,
			onClose: () => {
				notification.close(key)
			}
		})
	}

	const outgoingCallNotification = ({ userName, onAccept, onDecline }) => {
		const key = `open${Date.now()}`
		const btn = (
			<>
				<Button
					type="primary"
					className="mr20"
					size="small"
					onClick={() => {
						notification.close(key)
						onDecline()
					}}>
					{lang('cancel')}
				</Button>
				<Button
					type="primary"
					shape="circle"
					style={{ backgroundColor: 'green' }}
					icon={<Icon type="call" />}
					size="large"
					onClick={() => {
						notification.close(key)
						onAccept()
					}}></Button>
			</>
		)
		notification.info({
			message: lang('outgoing-call-title'),
			description: userName,
			duration: 0,
			btn,
			key,
			onClose: () => {
				notification.close(key)
			}
		})
	}

	const handleHangUp = () => {
		if (call) {
			call.hangup()
			onCallEnd()
		}
	}

	const addCallListeners = (call) => {
		call.on('hangup', function () {
			console.log('hangup')
			onCallEnd()
		})
		call.on('error', function (err) {
			call.hangup()
			console.log('error', err.message)
			onCallEnd()
		})
		call.on('feeds_changed', function (feeds) {
			const localFeed = feeds.find((feed) => feed.isLocal())
			const remoteFeed = feeds.find((feed) => !feed.isLocal())

			const remoteElement = document.getElementById('exo-video-remote')
			const localElement = document.getElementById('exo-video-local')

			if (remoteFeed) {
				remoteElement.srcObject = remoteFeed.stream
				remoteElement.play()
			}
			if (localFeed) {
				localElement.muted = true
				localElement.srcObject = localFeed.stream
				localElement.play()
			}
		})
	}

	const handleIncomingCall = async (call) => {
		const userNames = await resolveMatrixId({ matrixIds: [call.opponentMember.userId] })
		const userName = userNames.map((entry) => entry.userName)
		incomingCallNotification({
			userName,
			onAccept: () => {
				setOpponent({ userName, patientId: call.opponentMember.userId })
				onCallStart()
				call.answer()
				setCall(call)
				addCallListeners(call)
			},
			onDecline: handleHangUp
		})
	}

	const handleOutgoingCall = async ({ roomId, userName, patientId }) => {
		outgoingCallNotification({
			userName,
			onAccept: () => {
				setOpponent({ userName, patientId })
				onCallStart()
				const call = sdk.createNewMatrixCall(client, roomId)
				addCallListeners(call)
				setCall(call)
				call.placeVideoCall()
			},
			onDecline: handleHangUp
		})
	}

	const renderPatientRedirect = () => {
		return showPatient ? (
			<Redirect
				to={{
					pathname: `/patient/${showPatient}`
				}}
			/>
		) : null
	}

	useEffect(() => {
		client.on('Call.incoming', handleIncomingCall)

		PubSub.subscribe('video:start', (e, { patientId, patientName }) => {
			addRoom({
				patientId,
				patientName,
				cb: (roomId) => {
					handleOutgoingCall({ roomId, userName: patientName, patientId })
				}
			})
		})
	}, [client])

	return show ? (
		<div className="exo-video">
			{renderPatientRedirect()}
			<div className="exo-video-header">
				<div className="exo-video-menu">
					<Button type="primary" shape="circle" danger onClick={handleHangUp} icon={<Icon type="call-end" />} size={'large'} />
				</div>
				<div className="exo-video-title mr20">{opponent?.userName}</div>
				<Button type="primary" onClick={() => setShowPatient(opponent?.patientId)} icon={<Icon type="open" />} size={'large'}>
					{lang('display-patient-details')}
				</Button>
			</div>
			<video id="exo-video-remote" className="exo-video-screen"></video>
			<video id="exo-video-local" className="exo-video-screen"></video>
		</div>
	) : null
}
