const config = {
	dev: {
		users: {
			realm: process.env.REACT_APP_KC_REALM,
			url: process.env.REACT_APP_KC_URL,
			onLoad: 'login-required',
			clientId: process.env.REACT_APP_KC_CLIENT_ID,
			checkLoginIframe: false,
			resource: process.env.REACT_APP_KC_REALM,
			confidentialPort: 0,
			requireHttps: true,
		}
	},
	prod: {
		users: {
			realm: process.env.REACT_APP_KC_REALM,
			url: process.env.REACT_APP_KC_URL, 
			onLoad: 'login-required',
			clientId: process.env.REACT_APP_KC_CLIENT_ID,
			checkLoginIframe: false,
			resource: process.env.REACT_APP_KC_REALM,
			confidentialPort: 0,
			requireHttps: true,
		}
	},
	test: {
		users: {
			realm: process.env.REACT_APP_KC_REALM,
			url: process.env.REACT_APP_KC_URL,
			onLoad: 'login-required',
			clientId: process.env.REACT_APP_KC_CLIENT_ID,
			checkLoginIframe: false,
			resource: process.env.REACT_APP_KC_REALM,
			confidentialPort: 0,
			requireHttps: true,
		}
	},
}

export default process.env.NODE_ENV === 'production'
	? config.prod
	: process.env.NODE_ENV === 'development'
	? config.dev
	: config.test
