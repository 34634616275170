import React, { useState, useEffect } from 'react'
import * as d3 from 'd3'

import Skeleton from '../../components/Skeleton'
import MultiLineChart from '../../components/charts/MultiLineChart'
import Stats from '../../components/Stats'
import { Card } from 'antd';

import Backend from '../../api/Backend'

import './index.css'

import { LangContext } from '../../components/Translation'
import translations from './translations'

export default function SymptomsTrend({ center, setting = '__ALL__' }) {
	
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()

	const color = d3.scaleOrdinal(d3.schemeTableau10);

	const [alarms, setAlarms] = useState([])
	const [loading, setLoading] = useState(true)
	const [patients, setPatients] = useState([])

	const visits = 6

	const refresh = () => {
		setLoading(true)
		backend.alarms({ clinic: center, setting, active: false, cb: (data) => {
			setAlarms(data)
			setLoading(false)
		}})
	}

	useEffect(() => {
		setLoading(true)
		backend.patients({ clinic: center, active: true, cb: (data) => { // no setting filter, is applied in ui
			setPatients(data)
			setLoading(false)
		}})
	}, [center])

	useEffect(() => {
		refresh()
	}, [center, setting])

	let parseDate = d3.timeParse("%Y-%m-%d")
	const mapSeries =  (d) => Math.round(d3.scaleTime().domain([parseDate('2021-03-15'), parseDate('2021-12-31')]).range([1, visits])(d))

	let alarmsData = alarms.filter(d => d.type === 'SMART_VISIT')

	const getNumAlarmsByDegree = ({ degree, alarmsData }) => {
		return alarmsData.filter(alarm => alarm.score === degree).length
	}

	const onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index;
	}

	const getNumOfPatientsWithSetting = ({ patients, setting }) => {		
		return patients.filter(p => p.resource.setting_id === setting || setting === '__ALL__').length;
	}

	const getNumOfPatients = (patients) => {		
		return patients.map(v => v.patient_id).filter(onlyUnique).length;
	}

	const getNumOfPatientsWithSymptoms = (alarms) => {		
		return getNumOfPatients(alarms)
	}

	const numPatientsWithSetting = getNumOfPatientsWithSetting({ patients, setting })

	const statsData = [
		[
			{ title: "Patients Total", key: "totalPatients", value: patients.length },
			{ title: "Patients w/ Setting", key: "settingPatients", value: numPatientsWithSetting },
			{ title: "Patients w/ Symptoms", key: "patientsSymptoms", value: `${getNumOfPatientsWithSymptoms(alarmsData)} (${ numPatientsWithSetting ? (Math.round((getNumOfPatientsWithSymptoms(alarmsData) / numPatientsWithSetting) * 100)) : '-'}% of Setting)`},
			{ title: "Symptoms Total", key: "totalSymptoms", value: alarmsData.length }
		],[
	  		{ title: "Grade 4", key: "grade4", value: getNumAlarmsByDegree({ alarmsData, degree: 4}) },
			{ title: "Grade 3", key: "grade3", value: getNumAlarmsByDegree({ alarmsData, degree: 3}) },
			{ title: "Grade 2", key: "grade2", value: getNumAlarmsByDegree({ alarmsData, degree: 2}) },
			{ title: "Grade 1", key: "grade1", value: getNumAlarmsByDegree({ alarmsData, degree: 1}) }
		]
	]

	const mapAlarms = (alarmsData) => {
		const dict = {}
		const res = []

		alarmsData.forEach((d) => {
			let id = d.symptom
			if(!id) return
			id = id.replace("SMARTVISIT_","")
			if(!dict[id]){
				dict[id] = { kpi: id, values: [] }
			}
			const date = parseDate(d.alarm_created_at.split("T")[0])
			dict[id].values.push({ series: mapSeries(date), value: d.score })
		})
		for(let kpi in dict){
			res.push(dict[kpi])
		}
		return res
	}

	const sum = (data) => {		
		const sumPerDay = (values, v) => {
			const day = values.filter(item => {
				return item.series === v.series
			})
			if(day.length === 1){
				day[0].value++
			} else {
				v.value = 1
				values.push(v)
			}
		}
	    data.forEach((kpi) => {
			const _values = []
			kpi.values.forEach((v) => {
				sumPerDay(_values, v)
			})
			kpi.values = _values.sort((a,b) => b.series - a.series )
			kpi.max = Math.max(...kpi.values.map(v => v.value))
			kpi.min = Math.min(...kpi.values.map(v => v.value))
	  })
		return data
	}

	const filterTop = (data) => {
		return data.sort((a,b) => { return b.max - a.max }).slice(0,5)
 	}

	const colorize = (data) => {
		return data.map((d, idx) => { 
			d.color = color(idx)
			return d
		})
	}

	alarmsData = colorize(filterTop(sum(mapAlarms(alarmsData))))

	function getKPIs(){
		return alarmsData.map((kpi)=>{ 
			let className = "kpi-list-item list-group-item";
			return (
				<div className={className} key={ kpi.kpi } id={ kpi.kpi } onClick={ ()=>{} }>
					<div className="row">
						<div className="col-auto">
							<div className="exo-point" style={{ background: kpi.color }}></div>
						</div>
						<div className="col">
							<div className="text-muted mt-n1">{ kpi.kpi }</div>
						</div>
					</div>
				</div>)
		  })
	}

	return (
		<div className="row row-deck row-cards">
			<div className="col-12">  
              <Stats title={"RPA Symptom Alarms Overview"} data={statsData}/>
            </div>    
			<div className="col-8"> 	 
					<Card bordered={false}>
							<h3 className="card-title">{ lang("title") }</h3>
						<Skeleton type="statistics" hide={!loading}>
							<div className="overflow-auto" style={{"height": "100%", padding: "15px"}}>
								<MultiLineChart
									data={alarmsData}
									visits={visits}
									width="700"
									height="600"
									yAxisLabel={"Symptom Occurences"}
									xAxisLabel={"Therapy Points"}
									/>
							</div>
						</Skeleton>
					</Card>
			</div>
			<div className="col-4">  
					<Card bordered={false}>
							<h3 className="card-title">Top 5 Symptom Alarms</h3>
						<Skeleton type="list" hide={!loading}>
							<div className="overflow-auto" style={{"height": "100%"}}>
								{ getKPIs() }
							</div>
						</Skeleton>
					</Card>
			</div>
		</div>
	)
}
