import React, { useState } from 'react'

import load from './data/distress_pepper_cleaned.json'
import Topbar from '../../components/Topbar'

import ComparisonChart from '../../components/charts/ComparisonChart'
import SimpleLineChart from '../../components/charts/SimpleLineChart'
import Select from '../../components/Select'
import { Card } from 'antd'

export default function EPROScores() {
	const qlqc30 = load[0]

	addKPITitles(qlqc30)

	const patients = qlqc30._patients
	const kpis = qlqc30._kpis
	let kpi

	for (kpi in qlqc30) {
		if (kpi[0] == '_') continue
		qlqc30[kpi] = qlqc30[kpi]
			.filter((d) => d.value !== 'NA')
			.map((d) => {
				d.value = parseInt(d.value)
				return d
			})
	}

	const qlqc30_Q4Q5 = {}
	for (kpi in qlqc30) {
		if (kpi.includes('Q4') || kpi.includes('Q5') || kpi.includes('_')) qlqc30_Q4Q5[kpi] = qlqc30[kpi]
	}

	const qlqc30_Q1Q2Q3 = {}
	for (kpi in qlqc30) {
		if (!kpi.includes('Q4') && !kpi.includes('Q5')) qlqc30_Q1Q2Q3[kpi] = qlqc30[kpi]
	}

	const patientSelectFilter = ['236', '30', '57', '80', '84', '189', '290', '326', '329', '319'] // TODO remove after demo
	// const comparisonOptions = [{ key: "all", value: "Kollektiv" }, { key: "pvk", value: "Patientin - Kollektiv" }, { key: "ivk", value: "Interventionsgr. - Kontrollgr." }]
	// const filterDef = [{ key: 'Setting', title: 'Setting', options: [{ key: 1, value: 'Neoadjuvant' }, { key: 2, value: 'Operation' }, { key: 3, value: 'Radiatio' }, { key: 4, value: 'Post-Neoadjuvant' }, { key: 5, value: 'Adjuvant' }, { key: 5, value: 'Metastasiert' }] }];

	// const [selectedComparison, setSelectedComparison] = useState(comparisonOptions[0].key)
	const [selectedPatient, setSelectedPatient] = useState(patientSelectFilter[0])
	const [selectedKPI, setSelectedKPI] = useState(kpis[0])
	const [filter] = useState([])

	const patientOptions = patients
		.filter((p) => patientSelectFilter.includes(p.key))
		.map((patient) => {
			return { key: patient.key, value: patient.key }
		})

	const data = qlqc30[selectedKPI.key]

	function onlyPatientsData(data) {
		return data.filter((d) => patients.filter((p) => p.key == d.key).length > 0)
	}

	// function handleCompChange(selected) {
	// 	setSelectedComparison(selected.key)
	// }

	function handlePatientChange(selected) {
		setSelectedPatient(selected.key)
	}

	// function getSelectedComparisonTxt() {
	// 	const selected = comparisonOptions.filter(opt => opt.key === selectedComparison);
	// 	return selected.length > 0 ? selected[0].value : '';
	// }

	function handleKPIChange(selected) {
		setSelectedKPI(kpis.filter((kpi) => kpi.key === selected.currentTarget.id).pop())
	}

	function applyFilter(_patients) {
		const patients = [..._patients]
		let i
		for (let restriction of filter) {
			i = patients.length
			while (i--) {
				if (patients[i][restriction.key] !== restriction.value.key) {
					patients.splice(i, 1)
				}
			}
		}
		return patients
	}

	function addKPITitles(data) {
		//TODO: retrieve from backend
		const skala1 = [
			{ key: 1, value: 'Überhaupt nicht' },
			{ key: 2, value: 'Wenig' },
			{ key: 3, value: 'Mäßig' },
			{ key: 4, value: 'Sehr' }
		]
		const skala2 = [
			{ key: 1, value: 'Sehr schlecht' },
			{ key: 7, value: 'Ausgezeichnet' }
		]
		data['_kpis'] = [
			{ key: 'Q1Chal.1', txt: 'Physische Schwäche', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q1Chal.2', txt: 'Schwier. lange Spazierg.', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q1Chal.3', txt: 'Schwier. kurze Spazierg.', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q1Chal.4', txt: 'Tagsüber viel Liegen', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q1Chal.5', txt: 'Hilfe beim Anziehen, Essen, WC', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.1', txt: 'Einschränkung Alltag', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.2', txt: 'Einschränkung Hobbies', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.3', txt: 'Kurzatmigkeit', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.4', txt: 'Schmerzen', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.5', txt: 'Benötigte Ruhe', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.6', txt: 'Schlafstörungen', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.7', txt: 'Schwächegefühl', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.8', txt: 'Appetitmangel', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.9', txt: 'Übelkeit', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.10', txt: 'Erbrochen', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q2LW1.11', txt: 'Verstopfung', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.1', txt: 'Durchfall', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.2', txt: 'Müdigkeit', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.3', txt: 'Schmerzen beeinschränkten Alltag', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.4', txt: 'Konzentrationsschwierigkeiten', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.5', txt: 'Angespanntheit', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.6', txt: 'Sorgen', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.7', txt: 'Reizbarkeit', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.8', txt: 'Niedergeschlagenheit', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.9', txt: 'Erinnerungsschwierigkeiten', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.10', txt: 'Beeinträchtigung Familienleben', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.11', txt: 'Beeinträchtigung mit Anderen', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q3LW2.12', txt: 'Finanzielle Schwierigkeiten', min: 1, max: 4, skala: skala1, positive: 'min' },
			{ key: 'Q4GenHeal', txt: 'Gesundheitszustand', min: 1, max: 7, skala: skala2, positive: 'max' },
			{ key: 'Q5QoL', txt: 'Lebensqualität', min: 1, max: 7, skala: skala2, positive: 'max' }
		]
	}

	function getKPIs() {
		return kpis.map((kpi) => {
			let className = 'kpi-list-item list-group-item'
			className = selectedKPI.key === kpi.key ? className + ' selected' : className

			const patientData = qlqc30[kpi.key].filter((p) => p.key === selectedPatient)
			const groupData = qlqc30[kpi.key]
			return (
				<div className={className} key={kpi.key} id={kpi.key} onClick={handleKPIChange}>
					<div className="row">
						<div className="col-auto">
							<a href="#">
								<SimpleLineChart width={75} height={50} patientData={patientData} groupData={groupData} kpi={kpi} />
							</a>
						</div>
						<div className="col">
							<div className="text-muted mt-n1">{kpi.txt}</div>
						</div>
					</div>
				</div>
			)
		})
	}

	return (
		<div className="page-wrapper">
			<Topbar
				froozen="true"
				content={
					<div className="topbar-wrapper">
						<div className="mb-3">
							<Select
								title="Patient(in)"
								default={patientOptions[0].key}
								options={patientOptions}
								onChange={handlePatientChange}></Select>
						</div>
					</div>
				}>
				<div className="page-body">
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<div className="col-8">
								<Card bordered={false}>
									<ComparisonChart
										width={600}
										height={600}
										data={onlyPatientsData(data)}
										xAxisTitle="Zeitpunkt"
										xLabelRenderer={(v) => {
											return `Th ${v}`
										}}
										yAxisTitle={`Feedback Patient: ${selectedPatient} vs. Kollektiv`}
										selectedKPI={selectedKPI}
										patients={applyFilter(patients)}
										selectedPatient={selectedPatient}
									/>
								</Card>
							</div>
							<div className="col-4">
								<Card>
									<h3 className="card-title">QLQC30 KPI</h3>
									<div className="kpi-list list-group list-group-flush overflow-auto" style={{ height: '100%' }}>
										{getKPIs()}
									</div>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</Topbar>
		</div>
	)
}
