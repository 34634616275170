import React, { useState, useEffect } from 'react'
import Backend from '../../api/Backend'
import Select from '../Select'
import { LangContext } from '../Translation'
import translations from './translations'
import { UIConstants } from '../Utils'

export default function SettingSelect({
	required = false,
	defaultKey,
	defaultIsAll = true,
	showAllOption = true,
	onAfterInit = () => {},
	onChange = () => {},
	style,
	className
}) {
	const lang = React.useContext(LangContext)(translations)

	const backend = new Backend()
	const [settingOptions, setSettingOptions] = useState()
	const allOption = { key: UIConstants.ALL, value: lang('all') }

	const getDefaultOption = () => {
		return defaultIsAll && showAllOption ? [allOption.key] : defaultKey
	}

	useEffect(() => {
		backend.setting({
			cb: (data) => {
				const options = Array.isArray(data)
					? data.map((d) => {
							return { key: d.id, value: d.title }
					  })
					: []
				if (options.length > 0 && showAllOption) {
					options.push(allOption)
				}
				onAfterInit(getDefaultOption({ options }))
				setSettingOptions(options)
			}
		})
	}, [])

	const defaultOption = getDefaultOption({ options: settingOptions })

	return settingOptions ? ( // loading done and defaultOption finally defined
		<Select
			required={required}
			className={className}
			title={lang('setting')}
			defaultOptions={defaultOption}
			options={settingOptions}
			onChange={onChange}
			allowClear={false}
			style={style}></Select>
	) : null
}
