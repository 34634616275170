import React from 'react'

import Table from '../../../components/Table'
import Tag from '../../../components/Tag'

const ContentItem = ({ value, onOrderChange, lang, resolve }) => {
	return (
		<Table
			emptyText={lang('no-mapping')}
			rowMover={onOrderChange !== undefined}
			showHeader={false}
			onRowMove={onOrderChange}
			pagination={false}
			columns={[
				{
					width: 150,
					key: 'title',
					value: lang('title'),
					render: ({ row }) => {
						return row.title ? row.title : (resolve ? resolve({ id: row.key, type: row.type.id })?.title : "Unauffindbar: "+row.key)
					}
				},
				{
					width: 100,
					key: 'type',
					value: lang('type'),
					render: ({ row }) => {
						const title = row.type?.title ? row.type?.title : (resolve ? resolve({ id: row.key, type: row.type.id })?.type?.title : "Unauffindbar: "+row.key)
						return <Tag type={title}>{title}</Tag>
					}
				},
				{
					width: 100,
					key: 'group',
					value: lang('group'),
					render: ({ row }) => {
						const group = row.group !== undefined ? row.group :  (resolve ? resolve({ id: row.key, type: row.type.id })?.group : "Unauffindbar: "+row.key)
						return <Tag type={group}>{lang(group?.toLowerCase())}</Tag>
					}
				}
			]}
			data={value}
		/>
	)
}

export default ContentItem
