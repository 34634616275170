import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import Topbar from '../../../components/Topbar'
import ClinicSelect from '../../../components/ClinicSelect'
import PatientSelect from '../../../components/PatientSelect'
import SelectionList from '../../../components/SelectionList'
import Utils from '../../../components/Utils'
import Recipe from '../../../components/Recipe'
import AlarmsTable from '../../../applets/AlarmsTable'
import Contact from '../../../applets/Contact'
import Notes from '../../../components/Notes'
import Therapy from '../../../applets/onko/Therapy'
import LaboratoryPatientHistory from '../../../applets/onko/LaboratoryPatientHistory'
import Backend from '../../../api/Backend'
import apexLocals from '../../../components/charts/apex/Locals'
import { LangContext } from '../../../components/Translation'
import translations from './translations'
import PageNavbar from '../../../components/PageNavbar'

import './index.css'

export default function OTT() {
	const lang = React.useContext(LangContext)(translations)

	const utils = new Utils()
	const backend = new Backend()

	const [selectedClinic, setSelectedClinic] = useState()
	const [selectedPatient, setSelectedPatient] = useState('none')
	const [details, setDetails] = useState([])
	const [infos, setInfos] = useState([])

	const [tasks, setTasks] = useState()
	const [assessments, setAssessments] = useState()
	const [alarms, setAlarms] = useState()
	const [appointments, setAppointments] = useState()

	useEffect(() => {
		backend.tasks({ filter: `patient=${selectedPatient}`, cb: setTasks })
		backend.getPatientQuestionnaires({
			type: 'EQVAS',
			patientId: selectedPatient,
			cb: setAssessments
		})
		backend.alarms({ patient: selectedPatient, active: true, cb: setAlarms })
		backend.appointments({ patientId: selectedPatient, cb: setAppointments })
	}, [selectedPatient])

	const thColor = '#CCC'
	const chartGroup = 'g1'

	const markerInfo = {
		size: 28,
		fillColor: 'transparent',
		strokeColor: 'blue',
		strokeWidth: 3,
		shape: 'rect',
		radius: 2,
		OffsetX: 0,
		OffsetY: -14,
		cssClass: ''
	}

	const markerWarning = {
		size: 28,
		fillColor: 'transparent',
		strokeColor: 'orange',
		strokeWidth: 3,
		shape: 'rect',
		radius: 2,
		OffsetX: 0,
		OffsetY: -14,
		cssClass: ''
	}

	const markerCritical = {
		size: 28,
		fillColor: 'transparent',
		strokeColor: 'red',
		strokeWidth: 3,
		shape: 'rect',
		radius: 2,
		OffsetX: 0,
		OffsetY: -14,
		cssClass: ''
	}

	const marker = {
		size: 22,
		fillColor: 'transparent',
		strokeColor: '#CCC',
		strokeWidth: 3,
		shape: 'circle',
		radius: 2,
		OffsetX: 0,
		OffsetY: 0,
		cssClass: ''
	}

	const points = [],
		annotations = []

	const getDaysArray = function (start, end) {
		for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
			arr.push(new Date(dt).toDateString())
		}
		return arr
	}

	const mapValuesToDates = ({ dates, values, today = new Date() }) => {
		let value = null
		return Array.isArray(dates)
			? dates.map((d) => {
					if (values) {
						for (let v of values) {
							if (new Date(v.finished).toDateString() === d) {
								value = parseInt(v.answer)
							} else if (today < Date.parse(d)) {
								value = null
							}
						}
					}
					return value
			  })
			: []
	}

	let today = new Date().getTime()
	const min = new Date()
	min.setMonth(min.getMonth() - 6)
	const max = new Date()
	max.setMonth(max.getMonth() + 9)

	let dates = getDaysArray(min, max)
	const data = mapValuesToDates({ dates, values: assessments })

	// TEST

	const fakeDosage = function () {
		const arr = []
		for (let step = 0; step < 200; step++) {
			if ((step > 120 && step < 160) || (step > 80 && step < 90)) {
				arr.push(null)
			} else if (step < 100) {
				arr.push(2)
			} else if (step > 100) {
				arr.push(3)
			} else {
				arr.push(1)
			}
		}
		return arr
	}

	const dataDosage = fakeDosage()

	const getMarker = (d) => {
		if (d.severity === 'C_HIGH') {
			return markerCritical
		} else if (d.severity === 'B_MEDIUM') {
			return markerWarning
		} else if (d.severity === 'A_LOW') {
			return marker
		} else {
			return markerInfo
		}
	}

	if (details.includes('tasks') && tasks) {
		tasks.forEach((t) => {
			points.push({
				yAxisIndex: 0,
				x: new Date(t.created).getTime(),
				y: 8,
				marker: getMarker(t),
				image: {
					path: `/actions/assessment.svg?id=${t.id}&type=task`,
					width: 24,
					height: 24,
					offsetX: 0,
					offsetY: 35
				}
			})
		})
	}

	if (details.includes('alarms') && alarms) {
		utils.groupAlarms(alarms).forEach((t) => {
			points.push({
				yAxisIndex: 0,
				x: new Date(t.ts_created).getTime(),
				y: 30,
				marker: {
					size: 28,
					fillColor: 'transparent',
					strokeColor: 'red',
					strokeWidth: 3,
					shape: 'rect',
					radius: 2,
					OffsetX: 0,
					OffsetY: -14
				},
				image: {
					path: utils.getAlarmImage({ row: t }),
					width: 36,
					height: 36,
					offsetX: 0,
					offsetY: 40
				}
			})
		})
	}

	if (details.includes('careadvice')) {
		points.push({
			yAxisIndex: 0,
			x: new Date('23 Apr 2021').getTime(), //TODO: use timestamp with time
			y: 8,
			marker: marker,
			image: {
				path: '/actions/advice.svg',
				width: 24,
				height: 24,
				offsetX: 0,
				offsetY: 35
			}
		})
	}

	if (details.includes('careplan') && appointments) {
		appointments.forEach((t) => {
			annotations.push({
				x: new Date(t.ts).getTime(),
				strokeDashArray: 0,
				borderColor: thColor,
				label: {
					borderColor: thColor,
					style: {
						color: '#fff',
						background: thColor
					},
					text: t.therapy_type + ', ' + t.title
				}
			})
		})
	}

	const series = [
		{
			name: selectedPatient,
			type: 'line',
			data
		}
	]

	if (details.includes('dosage')) {
		series.push({
			name: 'Ribociclib 100 mg',
			type: 'area',
			data: dataDosage
		})
	}

	const handleChartClick = function (event) {
		// retrieve id von image href
		const href = event.target.href
		let val = ''
		if (href) {
			val = utils.parseUrlParams(href.baseVal)
			if (val) {
				setInfos(val)
			}
		}
	}

	const options = {
		chart: {
			locales: apexLocals.locales,
			defaultLocale: apexLocals.defaultLocale,
			id: 'rpachart',
			group: chartGroup,
			events: {
				click: handleChartClick
			}
		},
		annotations: {
			points: points,
			xaxis: [
				...annotations,
				{
					x: today,
					strokeDashArray: 2,
					borderColor: '#000',
					label: {
						borderColor: '#000',
						style: {
							color: '#fff',
							background: '#000'
						},
						text: lang('today')
					}
				}
			]
		},
		colors: ['#4e69b4', '#4eb4b2', '#66C7F4'],
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: 'straight'
		},
		grid: {
			padding: {
				right: 30,
				left: 20
			}
		},
		tooltip: {
			shared: true,
			y: {
				formatter: function (value) {
					return value
				}
			}
		},
		title: {
			text: lang('progression'),
			align: 'left'
		},
		labels: dates,
		xaxis: {
			type: 'datetime'
		},
		yaxis: [
			{
				title: {
					text: lang('EQVAS')
				},
				min: 0,
				max: 100
			},
			{
				opposite: true,
				title: {
					text: lang('strength')
				},
				labels: {
					minWidth: 30
				},
				min: 0,
				max: 3,
				tickAmount: 3,
				floating: false
			}
		]
	}

	const topItemStyle = {
		display: 'inline-grid',
		marginRight: '20px'
	}

	const handleClinicChange = (selected) => {
		setSelectedClinic(selected.value)
	}

	const handlePatientChange = (selected) => {
		setSelectedPatient(selected.key)
	}

	const renderInfoContent = (t) => {
		let attributes = Object.keys(t).map((k) => {
			return (
				<tr key={k}>
					<td>{k}</td>
					<td>{t[k]}</td>
				</tr>
			)
		})
		return (
			<div style={{ padding: '10px' }}>
				<table>{attributes}</table>
			</div>
		)
	}

	if (infos.type === 'alarm' && alarms) {
		alarms.forEach((t) => {
			if (utils.alarmKey(t) === infos.id) {
				renderInfoContent(t)
			}
		})
	}

	if (infos.type === 'task' && tasks) {
		tasks.forEach((t) => {
			if (t.id === infos.ids) {
				renderInfoContent(t)
			}
		})
	}
	const menu = {
		THERAPY: 'THERAPY',
		ALARMS_TABLE: 'ALARMS_TABLE',
		LABRATORY_PATIENT_HISTORY: 'LABRATORY_PATIENT_HISTORY',
		RECIPE: 'RECIPE',
		CONTACT: 'CONTACT',
		NOTES: 'NOTES'
	}

	const menuItems = [
		{
			title: lang('therapy_management'),
			key: menu.THERAPY,
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="mr5"
					enableBackground="new 0 0 24 24"
					height="24px"
					viewBox="0 0 24 24"
					width="24px"
					fill="#000000">
					<g>
						{' '}
						<rect fill="none" height="24" width="24" />{' '}
						<path d="M17,12c-2.76,0-5,2.24-5,5s2.24,5,5,5c2.76,0,5-2.24,5-5S19.76,12,17,12z M18.65,19.35l-2.15-2.15V14h1v2.79l1.85,1.85 L18.65,19.35z M18,3h-3.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H6C4.9,3,4,3.9,4,5v15c0,1.1,0.9,2,2,2h6.11 c-0.59-0.57-1.07-1.25-1.42-2H6V5h2v3h8V5h2v5.08c0.71,0.1,1.38,0.31,2,0.6V5C20,3.9,19.1,3,18,3z M12,5c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1C13,4.55,12.55,5,12,5z" />
					</g>
				</svg>
			)
		},
		{
			title: lang('symptoms_sides'),
			key: menu.ALARMS_TABLE,
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="mr5"
					enableBackground="new 0 0 24 24"
					height="24px"
					viewBox="0 0 24 24"
					width="24px"
					fill="#000000">
					{' '}
					<rect fill="none" height="24" width="24" />{' '}
					<path d="M7.32,10.56L8.38,9.5L7.32,8.44l1.06-1.06L10.5,9.5l-2.12,2.12L7.32,10.56z M4.5,9c0.03,0,0.05,0.01,0.08,0.01 C5.77,6.07,8.64,4,12,4c2.19,0,4.16,0.88,5.61,2.3c0.15-0.6,0.45-1.29,0.81-1.96C16.68,2.88,14.44,2,11.99,2 c-4.88,0-8.94,3.51-9.81,8.14C2.74,9.44,3.59,9,4.5,9z M21,10.5c-0.42,0-0.82-0.09-1.19-0.22C19.93,10.83,20,11.41,20,12 c0,4.42-3.58,8-8,8c-3.36,0-6.23-2.07-7.42-5.01C4.55,14.99,4.53,15,4.5,15c-0.52,0-1.04-0.14-1.5-0.4 c-0.32-0.18-0.59-0.42-0.82-0.7c0.89,4.61,4.93,8.1,9.8,8.1C17.52,22,22,17.52,22,12c0-0.55-0.06-1.09-0.14-1.62 C21.58,10.45,21.3,10.5,21,10.5z M21,3c0,0-2,2.9-2,4c0,1.1,0.9,2,2,2s2-0.9,2-2C23,5.9,21,3,21,3z M15.62,7.38L13.5,9.5l2.12,2.12 l1.06-1.06L15.62,9.5l1.06-1.06L15.62,7.38z M8.56,17c0.69-1.19,1.97-2,3.44-2s2.75,0.81,3.44,2h1.68c-0.8-2.05-2.79-3.5-5.12-3.5 c-0.87,0-1.7,0.2-2.43,0.57l0,0L5.99,12c0-0.52-0.26-1.02-0.74-1.29c-0.72-0.41-1.63-0.17-2.05,0.55c-0.41,0.72-0.17,1.63,0.55,2.05 c0.48,0.28,1.05,0.25,1.49,0l2.97,1.72l0,0C7.64,15.56,7.18,16.24,6.88,17H8.56z" />
				</svg>
			)
		},
		{
			title: lang('values'),
			key: menu.LABRATORY_PATIENT_HISTORY,
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="mr5"
					enableBackground="new 0 0 24 24"
					height="24px"
					viewBox="0 0 24 24"
					width="24px"
					fill="#000000">
					{' '}
					<g>
						{' '}
						<rect fill="none" height="24" width="24" />{' '}
					</g>{' '}
					<g>
						{' '}
						<path d="M13,11.33L18,18H6l5-6.67V6h2 M15.96,4H8.04C7.62,4,7.39,4.48,7.65,4.81L9,6.5v4.17L3.2,18.4C2.71,19.06,3.18,20,4,20h16 c0.82,0,1.29-0.94,0.8-1.6L15,10.67V6.5l1.35-1.69C16.61,4.48,16.38,4,15.96,4L15.96,4z" />{' '}
					</g>
				</svg>
			)
		},
		{
			title: lang('recipes'),
			key: menu.RECIPE,
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" className="mr5" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
					{' '}
					<path d="M0 0h24v24H0V0z" fill="none" />{' '}
					<path d="M13 11h-2v3H8v2h3v3h2v-3h3v-2h-3zm1-9H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />{' '}
				</svg>
			)
		},
		{
			title: lang('contact'),
			key: menu.CONTACT,
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="mr5"
					enableBackground="new 0 0 24 24"
					height="24px"
					viewBox="0 0 24 24"
					width="24px"
					fill="#000000">
					{' '}
					<rect fill="none" height="24" width="24" />{' '}
					<path d="M11,14H9c0-4.97,4.03-9,9-9v2C14.13,7,11,10.13,11,14z M18,11V9c-2.76,0-5,2.24-5,5h2C15,12.34,16.34,11,18,11z M7,4 c0-1.11-0.89-2-2-2S3,2.89,3,4s0.89,2,2,2S7,5.11,7,4z M11.45,4.5h-2C9.21,5.92,7.99,7,6.5,7h-3C2.67,7,2,7.67,2,8.5V11h6V8.74 C9.86,8.15,11.25,6.51,11.45,4.5z M19,17c1.11,0,2-0.89,2-2s-0.89-2-2-2s-2,0.89-2,2S17.89,17,19,17z M20.5,18h-3 c-1.49,0-2.71-1.08-2.95-2.5h-2c0.2,2.01,1.59,3.65,3.45,4.24V22h6v-2.5C22,18.67,21.33,18,20.5,18z" />{' '}
				</svg>
			)
		},
		{
			title: lang('notes'),
			key: menu.NOTES,
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" className="mr5" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
					{' '}
					<path d="M0 0h24v24H0V0z" fill="none" /> <path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" />{' '}
				</svg>
			)
		}
	]
	const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0])

	let content = 'No content'
	if (selectedMenuItem.key === menu.THERAPY) {
		content = <Therapy patient={selectedPatient} />
	} else if (selectedMenuItem.key === menu.ALARMS_TABLE) {
		content = <AlarmsTable patient={selectedPatient} active={true} redirect={false} />
	} else if (selectedMenuItem.key === menu.LABRATORY_PATIENT_HISTORY) {
		content = <LaboratoryPatientHistory patient={selectedPatient} />
	} else if (selectedMenuItem.key === menu.RECIPE) {
		content = <Recipe />
	} else if (selectedMenuItem.key === menu.CONTACT) {
		content = <Contact />
	} else {
		content = <Notes patientId={selectedPatient} type="OTT" />
	}

	const handleMenuChange = (item) => {
		setSelectedMenuItem(item)
	}

	return (
		<div className="page-wrapper">
			<Topbar
				froozen="true"
				content={
					<div className="topbar-wrapper">
						<div className="mb-3" style={topItemStyle}>
							<ClinicSelect onChange={handleClinicChange} />
						</div>
						<div className="mb-3" style={topItemStyle}>
							<PatientSelect onChange={handlePatientChange} selectedClinic={selectedClinic} optionAll={false} studyGroup={1} />
						</div>
					</div>
				}>
				<div className="page-body">
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<div className="col-sm-10 col-lg-10">
								<div className="card">
									<Chart options={options} series={series} type="line" height={350} />
								</div>
							</div>
							<div className="col-sm-2 col-lg-2">
								<div className="card">
									<SelectionList
										title="Details"
										default={['eqvas']}
										onChange={setDetails}
										options={[
											{ key: 'dosage', value: lang('dosage') },
											{ key: 'eqvas', value: lang('EQVAS') },
											{ key: 'tasks', value: lang('tasks') },
											{ key: 'alarms', value: lang('alarms') },
											{ key: 'careadvice', value: lang('care_advices') },
											{ key: 'careplan', value: lang('care_plan') }
										]}
									/>
								</div>
							</div>

							<div className="col-sm-12 col-lg-12">
								<div className="card">
									<PageNavbar default={menuItems[0]} menu={menuItems} onChange={handleMenuChange} className="navTitleItems" />
									<div className="card-body" style={{ border: '1px solid #e7eaef', padding: '0px' }}>
										<div className="tab-content h400 overflow-y-hidden fullHeight">{content}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Topbar>
		</div>
	)
}
